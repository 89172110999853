import apiInstance from './config';
import type { UserLocation } from '../types';

export const getCountryList = () => {
  return apiInstance
    .get(`api/location/countries/?limit=1000`, {})
    .then((response: any) => {
      return response;
    })
    .catch((err: any) => err);
};

export const updateTimeZone = (timezone: string) =>
  apiInstance.patch<UserLocation>(`api/location/locations/me/`, {
    timezone,
  });
