import { toTitleCase } from './text';

type MasterClassProps = {
  skill: string;
  link: string;
  eventType: string;
  hostName: string;
  hostCompanyName?: string;
  eventStartTime: string;
};

export const getMasterclassSocialText = ({
  skill,
  link,
  hostName,
  eventType,
  hostCompanyName,
  eventStartTime,
}: MasterClassProps) => {
  return {
    linkedinText: `Want to learn more about ${toTitleCase(
      skill
    )}?\n\nJoin DigitalBoost’s ${toTitleCase(
      skill
    )} ${eventType} hosted by ${hostName}${
      hostCompanyName ? ` from ${hostCompanyName}` : ''
    } at ${eventStartTime}!\n\nPlease register here: ${link} \n\n#digitalboost #${toTitleCase(
      skill
    ).replace(/\s+/g, '')}`,
    facebookText: `Want to learn more about ${toTitleCase(
      skill
    )}?\n\nJoin DigitalBoost's ${toTitleCase(
      skill
    )} ${eventType} hosted by ${hostName}${
      hostCompanyName ? ` from ${hostCompanyName}` : ''
    } at ${eventStartTime}!\n\nRegister here: ${link} #${toTitleCase(
      skill
    ).replace(/\s+/g, '')}`,
    twitterText: `Want to learn more about ${toTitleCase(
      skill
    )}?\n\nJoin @digitalboost_uk's ${toTitleCase(
      skill
    )} ${eventType} hosted by ${hostName}${
      hostCompanyName ? ` from ${hostCompanyName}` : ''
    } at ${eventStartTime}!\n\nRegister here:`,
  };
};
