import React, { memo, useEffect, useRef, useState } from 'react';
import { BoostCallEvent } from '../../types';
import useGetJoinCall from '../../hooks/useGetJoinCall';
import { displayTime, getMomentWithTimezone } from '../../utils/time';
import { toTitleCase } from '../../utils/text';
import './addToCalendarButton.scss';
import { Helmet } from 'react-helmet';
import { defaultDateFormat, defaultTimeFormat } from '../../constants';
import { DashboardEmptyButton } from 'styling/buttons';
import DateRangeIcon from '@mui/icons-material/DateRange';
import useSelectProfileData from 'hooks/useSelectProfileData';
import FullScreenLoading from 'components/FullScreenLoading';

const baseUrl = process.env.REACT_APP_BASE_URL;
const currentURL = window.location.href;

// Extract the desired portion of the URL
const extractedURL =
  currentURL.indexOf('org.uk/') !== -1
    ? currentURL.substring(0, currentURL.indexOf('org.uk/') + 7)
    : baseUrl;
type Props = {
  callInfo: BoostCallEvent;
  timeZoneString: string;
};

// Note: This component relies on the 'addevent' third party <script> being loaded
const AddToCalendarButton = ({ callInfo, timeZoneString }: Props) => {
  const {
    id: callId,
    call_url: originalCallUrl,
    start_time: startTime,
    duration,
    title,
    partner_name,
    session_title,
    volunteer,
    organisation,
    top_priority_name: topPriority,
  } = callInfo;
  const { organisationProfile, hasFinishedLoadingOrganisationProfile } =
    useSelectProfileData({ autoFetch: true });
  let displayPartnerTitle;

  if (
    organisationProfile &&
    organisationProfile.partnerInfo &&
    organisationProfile.partnerInfo.white_label
  ) {
    displayPartnerTitle =
      organisationProfile.partnerInfo.name || partner_name || 'Digital Boost';
  } else {
    displayPartnerTitle = 'Digital Boost';
  }
  let displaySessionTitle;

  if (
    organisationProfile &&
    organisationProfile.partnerInfo &&
    organisationProfile.partnerInfo.session_title &&
    organisationProfile.partnerInfo.white_label
  ) {
    displaySessionTitle = organisationProfile.partnerInfo.session_title;
  } else if (session_title) {
    displaySessionTitle = session_title;
  } else {
    displaySessionTitle = title;
  }
  const { url: dbCallUrl } = useGetJoinCall({ callInfo });
  const calendarButtonRef = useRef<HTMLDivElement>(null);

  const [shouldOpenCalendar, setShouldOpenCalendar] = useState(false);
  // // Fixes a bug requiring a reload of the <script>
  useEffect(() => {
    if (callInfo) {
      // @ts-ignore
      setTimeout(() => window.addeventatc?.refresh(), 1000);
    }
  }, [callInfo]);

  useEffect(() => {
    if (shouldOpenCalendar) {
      (async () => {
        // 2 second delay needed to load calendar script
        calendarButtonRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
        // @ts-ignore
        await window.addeventatc?.refresh();
        calendarButtonRef?.current?.click();
        setShouldOpenCalendar(false);
      })();
    }
  }, [shouldOpenCalendar, setShouldOpenCalendar, calendarButtonRef]);

  if (!organisation) return null;
  const businessName = volunteer.employer;
  const detailsLink = `${extractedURL}call-details?type=events&cid=${callId}`;
  const rescheduleCancelLink = `${extractedURL}call-details?type=events&event_update_options=true&cid=${callId}`;
  if (hasFinishedLoadingOrganisationProfile)
    return (
      <div className="addToCalendarWrapper">
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdn.addevent.com/libs/atc/themes/fff-theme-6/theme.css"
            type="text/css"
            media="all"
          />
          <script
            type="text/javascript"
            src="https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js"
          ></script>
        </Helmet>
        <div
          title="Add to calendar"
          className="addeventatc"
          data-styling="none"
          ref={calendarButtonRef}
          onClick={() => {
            setShouldOpenCalendar(!shouldOpenCalendar);
          }}
        >
          <DashboardEmptyButton
            variant="text"
            sx={{
              fontSize: '14px',
              fontWeight: 'bold',
              paddingLeft: '2px',
            }}
          >
            <DateRangeIcon sx={{ marginRight: '5px' }} />
            <div>Add to calendar</div>
          </DashboardEmptyButton>
          <span className="start">
            {displayTime(
              startTime,
              timeZoneString,
              `${defaultDateFormat} ${defaultTimeFormat}`
            )}
          </span>
          <span className="end atc-hidden-text">
            {getMomentWithTimezone(startTime, timeZoneString)
              .add(duration, 'minutes')
              .format(`${defaultDateFormat} ${defaultTimeFormat}`)}
          </span>
          <span className="timezone atc-hidden-text">{timeZoneString}</span>
          <span className="title atc-hidden-text">{`${displayPartnerTitle} ${toTitleCase(
            displaySessionTitle
          )} with ${volunteer?.first_name} ${volunteer?.last_name}`}</span>
          <span className="description atc-hidden-text">
            {`${displayPartnerTitle} ${toTitleCase(displaySessionTitle)} with ${
              volunteer?.first_name
            } ${volunteer.last_name}${
              businessName ? ` from ${businessName}` : ''
            } ${
              topPriority ? `about ${toTitleCase(topPriority)}` : ''
            }.\n \n For more details about this call, visit\n${detailsLink}\n\nIf you need to reschedule or cancel your call, click here:\n${rescheduleCancelLink}`}
          </span>
          <span className="location atc-hidden-text">
            {!!dbCallUrl ? dbCallUrl : originalCallUrl}
          </span>
        </div>
      </div>
    );
  else return <FullScreenLoading />;
};

export default memo(AddToCalendarButton);
