import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import api from '../../api';
import {
  CancelBoostCallProps,
  ProposeRescheduleRequestProps,
  AcceptRescheduleRequestProps,
  DeclineRescheduleRequestProps,
} from '../../api/boostCallEvents';
import { logout } from '../auth';
import { markMessagesReadAction } from '../messages';
import { setBoostCallEventStatusCancelled } from './utils';
import { BoostCallEventWithVolunteerProfile, MakeOptional } from '../../types';

/* --- SLICE --- */

export interface BoostCallEventsState {
  status: 'init' | 'pending' | 'fulfilled' | 'rejected';
  error?: string | null;
  count: number;
  events: BoostCallEventWithVolunteerProfile[];
  next?: string;
  previous?: string;
  cancelStatus?: 'pending' | 'fulfilled' | 'rejected' | null;
  cancelId?: number;
  cancelError?: string | null;
  rescheduleStatus?: 'pending' | 'fulfilled' | 'rejected' | null;
  rescheduleId?: number;
  rescheduleType?: 'propose' | 'accept' | 'decline'; // determines whether we're dealing with proposing, accepting or declining a reschedule request
  rescheduleError?: string | null;
}

const initialState = {
  status: 'init',
  error: null,
  count: 0,
  events: [],
} as BoostCallEventsState;

const boostCallEventsSlice = createSlice({
  name: 'boostCallEvents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // logout
      .addCase(logout, () => initialState)
      // fetchBoostCallEventsAction
      .addCase(fetchBoostCallEventsAction.pending, (state, action) => {
        state.status = action.meta.requestStatus;
        state.error = null;
      })
      .addCase(fetchBoostCallEventsAction.fulfilled, (state, action) => {
        state.events = action.payload.results;
        state.count = action.payload.count;
        state.status = action.meta.requestStatus;
        state.next = action.payload.next;
        state.previous = action.payload.previous;
        state.cancelStatus = initialState.cancelStatus;
      })
      .addCase(fetchBoostCallEventsAction.rejected, (state, action) => {
        const { error } = action;
        state.error = error.message;
        state.status = action.meta.requestStatus;
      })
      // cancelBoostCallEventAction
      .addCase(cancelBoostCallEventAction.pending, (state, action) => {
        const { id } = action.meta?.arg;
        state.cancelStatus = action.meta.requestStatus;
        state.cancelId = id;
        state.cancelError = null;
      })
      .addCase(cancelBoostCallEventAction.fulfilled, (state, action) => {
        state.cancelStatus = action.meta.requestStatus;
        state.events = state.cancelId
          ? setBoostCallEventStatusCancelled(state.events, state.cancelId)
          : state.events;
        state.cancelId = initialState.cancelId;
      })
      .addCase(cancelBoostCallEventAction.rejected, (state, action) => {
        const { error } = action;
        state.cancelError = error.message;
        state.cancelStatus = action.meta.requestStatus;
        state.cancelId = initialState.cancelId;
      })
      // reschedule - propose
      .addCase(proposeRescheduleRequestAction.pending, (state, action) => {
        const { id } = action.meta?.arg;

        state.rescheduleStatus = action.meta.requestStatus;
        state.rescheduleId = id;
        state.rescheduleType = 'propose';
        state.rescheduleError = null;
      })
      .addCase(proposeRescheduleRequestAction.fulfilled, (state, action) => {
        const { payload } = action;

        state.rescheduleStatus = action.meta.requestStatus;
        state.rescheduleId = initialState.rescheduleId;
        state.rescheduleType = initialState.rescheduleType;
        state.events = state.events.map((event) =>
          event.id === payload?.id ? payload : event
        );
      })
      .addCase(proposeRescheduleRequestAction.rejected, (state, action) => {
        const { error } = action;

        state.rescheduleError = error.message;
        state.rescheduleStatus = action.meta.requestStatus;
        state.rescheduleId = initialState.rescheduleId; // clear rescheduleId
      })
      // reschedule - accept
      .addCase(acceptRescheduleRequestAction.pending, (state, action) => {
        const { id } = action.meta?.arg;

        state.rescheduleStatus = action.meta.requestStatus;
        state.rescheduleId = id;
        state.rescheduleType = 'accept';
        state.rescheduleError = null;
      })
      .addCase(acceptRescheduleRequestAction.fulfilled, (state, action) => {
        const { payload } = action;

        state.rescheduleStatus = action.meta.requestStatus;
        state.rescheduleId = initialState.rescheduleId;
        state.rescheduleType = initialState.rescheduleType;
        state.events = state.events.map((event) =>
          event.id === payload?.id ? payload : event
        );
      })
      .addCase(acceptRescheduleRequestAction.rejected, (state, action) => {
        const { error } = action;

        state.rescheduleError = error.message;
        state.rescheduleStatus = action.meta.requestStatus;
        state.rescheduleId = initialState.rescheduleId;
      })
      // reschedule - decline
      .addCase(declineRescheduleRequestAction.pending, (state, action) => {
        const { id } = action.meta?.arg;

        state.rescheduleStatus = action.meta.requestStatus;
        state.rescheduleId = id;
        state.rescheduleType = 'decline';
        state.rescheduleError = null;
      })
      .addCase(declineRescheduleRequestAction.fulfilled, (state, action) => {
        const { payload } = action;

        state.rescheduleStatus = action.meta.requestStatus;
        state.rescheduleId = initialState.rescheduleId;
        state.rescheduleType = initialState.rescheduleType;
        state.events = state.events.map((event) =>
          event.id === payload?.id ? payload : event
        );
      })
      .addCase(declineRescheduleRequestAction.rejected, (state, action) => {
        const { error } = action;
        state.rescheduleError = error.message;
        state.rescheduleStatus = action.meta.requestStatus;
        state.rescheduleId = initialState.rescheduleId;

        // another way to handle errors is to use the thunkAPI's rejectWithValue - see: https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
        // e.g. below
        // const { payload, meta } = action || {};
        // state.error = payload || "";
        // state.verifyAccountStatus = meta?.requestStatus;
      })
      // markMessagesRead
      .addCase(markMessagesReadAction.fulfilled, (state, action) => {
        const { boostCallEventId } = action.meta.arg;
        state.events = state.events.map((event) =>
          event.id === boostCallEventId
            ? { ...event, organisation_new_messages_count: 0 }
            : event
        );
      });
  },
});

/* --- SELECTORS --- */

// fetchBoostCallEvents
export const selectBoostCallEvents = (state: RootState) =>
  state.boostCallEvents.events;
export const selectSingleBoostCallEvent = (
  state: RootState,
  boostCallEventId: number
) => state.boostCallEvents.events.find((e) => e.id === boostCallEventId);
export const selectBoostCallEventsStatus = (state: RootState) =>
  state.boostCallEvents.status;
export const selectHasNotFetchedBoostCallEvents = (state: RootState) =>
  selectBoostCallEventsStatus(state) === 'init';
export const selectHasFinishedLoadingBoostCallEvents = (state: RootState) =>
  selectBoostCallEventsStatus(state) === 'fulfilled' ||
  selectBoostCallEventsStatus(state) === 'rejected';

// cancelBoostCallEvent
export const selectBoostCallEventCancelStatus = (state: RootState) =>
  state.boostCallEvents.cancelStatus;
export const selectIsLoadingCancelBoostCallEvent = (state: RootState) =>
  selectBoostCallEventCancelStatus(state) === 'pending';
export const selectHasCancelledBoostCallEvent = (state: RootState) =>
  selectBoostCallEventCancelStatus(state) === 'fulfilled';
export const selectCancelBoostCallEventError = (state: RootState) =>
  state.boostCallEvents.cancelError;

// reschedule
export const selectRescheduleStatus = (state: RootState) =>
  state.boostCallEvents.rescheduleStatus;
export const selectRescheduleType = (state: RootState) =>
  state.boostCallEvents.rescheduleType;
export const selectIsLoadingRescheduleType = (state: RootState) =>
  selectRescheduleStatus(state) === 'pending';
export const selectHasSubmittedRescheduleCall = (state: RootState) =>
  selectRescheduleStatus(state) === 'fulfilled';
export const selectRescheduleError = (state: RootState) =>
  state.boostCallEvents.rescheduleError;

// reschedule - propose
export const selectIsProposeRescheduleType = (state: RootState) =>
  selectRescheduleType(state) === 'propose';
export const selectIsLoadingProposeReschedule = (state: RootState) =>
  selectIsLoadingRescheduleType(state) && selectIsProposeRescheduleType(state);
export const selectHasFinishedProposeReschedule = (state: RootState) =>
  selectHasSubmittedRescheduleCall(state) &&
  selectIsProposeRescheduleType(state);
export const selectProposeRescheduleError = (state: RootState) =>
  selectRescheduleError(state) && selectIsProposeRescheduleType(state)
    ? selectRescheduleError(state)
    : null;

// reschedule - accept
export const selectIsAcceptRescheduleType = (state: RootState) =>
  selectRescheduleType(state) === 'accept';
export const selectIsLoadingAcceptReschedule = (state: RootState) =>
  selectIsLoadingRescheduleType(state) && selectIsAcceptRescheduleType(state);
export const selectHasFinishedAcceptReschedule = (state: RootState) =>
  selectHasSubmittedRescheduleCall(state) &&
  selectIsAcceptRescheduleType(state);
export const selectAcceptRescheduleError = (state: RootState) =>
  selectRescheduleError(state) && selectIsAcceptRescheduleType(state)
    ? selectRescheduleError(state)
    : null;

// reschedule - decline
export const selectIsDeclineRescheduleType = (state: RootState) =>
  selectRescheduleType(state) === 'decline';
export const selectIsLoadingDeclineReschedule = (state: RootState) =>
  selectIsLoadingRescheduleType(state) && selectIsDeclineRescheduleType(state);
export const selectHasFinishedDeclineReschedule = (state: RootState) =>
  selectHasSubmittedRescheduleCall(state) &&
  selectIsDeclineRescheduleType(state);
export const selectDeclineRescheduleError = (state: RootState) =>
  selectRescheduleError(state) && selectIsDeclineRescheduleType(state)
    ? selectRescheduleError(state)
    : null;

/* --- ACTIONS --- */

// to be used if we ever create a non-thunk action
// export const {} = boostCallEventsSlice.actions;

/* --- THUNKS --- */

// fetchBoostCallEvents
export const fetchBoostCallEventsAction = createAsyncThunk(
  `${boostCallEventsSlice.name}/fetchBoostCallEvents`,
  async (arg, thunkAPI) => {
    const response = await api.getBoostCallEvents();
    return response.data;
  }
);

type CancelBoostCallEventAction = MakeOptional<
  CancelBoostCallProps,
  'cancellationReasonId'
>;

// cancelBoostCallEvent
export const cancelBoostCallEventAction = createAsyncThunk(
  `${boostCallEventsSlice.name}/cancelBoostCallEvent`,
  async (
    { id, cancellationReasonId, message }: CancelBoostCallEventAction,
    thunkAPI
  ) => {
    if (!cancellationReasonId)
      throw new Error('Please select a reason for cancellation.');
    const response = await api.cancelBoostCallEvent({
      id,
      cancellationReasonId,
      message,
    });
    return response.data;
  }
);

export type ProposeRescheduleRequestActionProps = MakeOptional<
  ProposeRescheduleRequestProps,
  'newStartDateTime'
>;

// proposeRescheduleRequest
export const proposeRescheduleRequestAction = createAsyncThunk(
  `${boostCallEventsSlice.name}/proposeRescheduleRequest`,
  async (
    { id, newStartDateTime, newCallUrl }: ProposeRescheduleRequestActionProps,
    thunkAPI
  ) => {
    if (!newStartDateTime)
      throw new Error('Please select a new time for your call.');
    const response = await api.proposeRescheduleRequest({
      id,
      newStartDateTime,
      newCallUrl,
    });
    return response.data;
  }
);
// acceptRescheduleRequest
export const acceptRescheduleRequestAction = createAsyncThunk(
  `${boostCallEventsSlice.name}/acceptRescheduleRequest`,
  async ({ id, newStartDateTime }: AcceptRescheduleRequestProps, thunkAPI) => {
    const response = await api.acceptRescheduleRequest({
      id,
      newStartDateTime,
    });

    return response.data;
  }
);

// declineRescheduleRequest
export const declineRescheduleRequestAction = createAsyncThunk(
  `${boostCallEventsSlice.name}/declineRescheduleRequest`,
  async (
    { id, cancel, newStartDateTime, message }: DeclineRescheduleRequestProps,
    thunkAPI
  ) => {
    const response = await api.declineRescheduleRequest({
      id,
      newStartDateTime,
      cancel,
      message,
    });
    return response.data;
  }
);

export default boostCallEventsSlice.reducer;
