import React from 'react';
import Box from '@mui/material/Box';
import { CustomFormLabel, CustomTextField } from 'styling/generalStyling';

type Props = {
  setValue: (value: any, input: string) => void;
  value?: {
    first_name: string;
    last_name: string;
  };
};
export default function EditNameForm({ value, setValue }: Props) {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <CustomTextField
        inputProps={{ maxLength: 30 }}
        id="outlined-basic"
        label={<CustomFormLabel>First Name</CustomFormLabel>}
        defaultValue={value?.first_name}
        onChange={(event) => setValue(event.target.value, 'first_name')}
        variant="outlined"
      />
      <CustomTextField
        inputProps={{ maxLength: 30 }}
        id="outlined-basic"
        label={<CustomFormLabel>Last Name</CustomFormLabel>}
        defaultValue={value?.last_name}
        onChange={(event) => setValue(event.target.value, 'last_name')}
        variant="outlined"
      />
    </Box>
  );
}
