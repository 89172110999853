import React from 'react';
import { allowedFileExtensions } from './fileExtensions';

interface FileUploadComponentProps {
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setPopupErrorOpen: any;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  maxSize: number;
}

const FileUploadComponent = ({
  handleFileUpload,
  setPopupErrorOpen,
  setErrorMessage,
  maxSize,
}: FileUploadComponentProps) => {
  const verifyFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;
    let totalSizeMB = 0;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const selectedFile = files[i];

        // Get the file extension
        const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();

        if (fileExtension === undefined) return;
        if (parseFloat((selectedFile.size / (1000 * 1000)).toFixed(2)) > 10) {
          setErrorMessage(
            'Oops, it looks like that file is too big! All uploads must be under 10MB. Choose another one'
          );
          setPopupErrorOpen(true);
          event.target.value = '';
          return;
        }
        totalSizeMB = totalSizeMB + selectedFile.size / (1024 * 1024);

        if (!allowedFileExtensions.includes(`.${fileExtension}`)) {
          setErrorMessage(
            'Oops! That file format is not supported. Please try uploading another file type'
          );
          setPopupErrorOpen(true);
          event.target.value = ''; // Clear the file input
          return;
        }
      }
      if (totalSizeMB > maxSize) {
        setErrorMessage(
          'Oops, it looks like you do not have enough storage to upload the file. Choose another one or delete some files'
        );
        setPopupErrorOpen(true);
        event.target.value = '';
        return;
      }

      handleFileUpload(event);
    }
  };
  return (
    <input
      type="file"
      id="fileInput"
      multiple
      style={{ display: 'none' }}
      onChange={verifyFileUpload}
    />
  );
};

export default FileUploadComponent;
