import { useAxios } from '../../api/config';
import {
  BoostCallEvents,
  BoostCallEventWithVolunteerProfile,
} from '../../types';

const useGetBoostCallEventsCompleted = (nextURL?: string) => {
  let URL = nextURL
    ? `api/event/boostcallevents/search/` + nextURL.split('search/')[1]
    : `api/event/boostcallevents/search/?status=complete&limit=4&offset=0`;

  const [{ data, loading, error }, refetch] = useAxios<BoostCallEvents>({
    url: URL,
  });

  return {
    count: data?.count,
    next: data?.next,
    boostCallEventsCompleted:
      (data?.results as BoostCallEventWithVolunteerProfile[]) || [],
    boostCallEventsCompletedCount: data?.count || 0,
    hasFinishedLoadingBoostCallEventsCompleted: !loading,
    boostCallEventsCompletedError: error,
    refetchBoostCallEventsCompleted: refetch,
  };
};

export default useGetBoostCallEventsCompleted;
