import React, { memo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import useSelectProfileData from 'hooks/useSelectProfileData';
import { DashboardFilledButton } from 'styling/buttons';
import NewAddToCalendarButton from 'atoms/NewAddToCalendarButton';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from 'features/reduxStore/sessionTitle';

type Props = {
  event: any;
};
const CallConfirmedPage = ({ event }: Props) => {
  const navigate = useNavigate();

  const { userLocation } = useSelectProfileData();
  const { timezone } = userLocation || {};
  const sessionTitle = useSelector(selectSessionTitle);
  const { volunteer, start_time } = event;

  return (
    <div className="call-confirmation">
      <div className="call-confirmation-title">
        Your {sessionTitle} is confirmed!
      </div>
      <div className="confirmation-avatar">
        <img src={'/high-five.png'} height="300px" alt="confirmation-avatar" />
      </div>
      <div className="call-confirmation-header-text">
        {sessionTitle} with {volunteer?.first_name} {volunteer?.last_name}
      </div>
      <div className="date-time-confirmation-call">
        {moment(start_time).format('MMMM DD YYYY')},{' '}
        {moment(event.reschedule_request_time || event.start_time).format(
          'HH:mm'
        )}
      </div>
      <div className="confirmation-text">
        We have sent you a confirmation email and have added it to your
        dashboard. Make sure to add it to your calendar.
      </div>

      <div className="add-calendar-container">
        {event && timezone && (
          <NewAddToCalendarButton callInfo={event} timeZoneString={timezone} />
        )}
      </div>

      <DashboardFilledButton
        variant="contained"
        sx={{
          width: '182px',
          alignSelf: 'center',
        }}
        onClick={() => navigate('/dashboard/actions')}
      >
        Go to Dashboard
      </DashboardFilledButton>
    </div>
  );
};

export default memo(CallConfirmedPage);
