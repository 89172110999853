import React, { Fragment, memo } from 'react';
import './mentor-search-results.scss';
import Grid from '@mui/material/Grid';
import MentorCard from '../MentorCard';
import {
  getDayAndConvertMonToSun,
  filterByVolunteerAvailabilityHours,
} from '../../utils/time';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { useSystemTogglesContext } from '../../context/SystemToggles';
import { Mentor } from '../../../types';
import { TimeSlot, ValueToggle } from '../../types';

interface Props {
  mentors: Mentor[];
  addRemoveMentor?: any;
  selectedMentorsList?: number[];
  showRemoveButton?: boolean;
  hideSelect?: boolean;
  disableButton?: boolean;
  hideBio?: boolean;
  selectedTopPriority?: number;
  compact?: boolean;
  timeSlots?: TimeSlot[];
}
const findCommonDay = (arr1: number[], arr2: number[]) => {
  return arr1.some((day) => arr2.includes(day));
};

const MentorSearchResults = ({
  mentors,
  showRemoveButton,
  hideSelect = false,
  hideBio = false,
  addRemoveMentor = () => {},
  selectedMentorsList,
  disableButton = false,
  selectedTopPriority,
  compact = false,
  timeSlots,
}: Props) => {
  const timeSlotDays =
    timeSlots?.length &&
    timeSlots
      .map((ts: TimeSlot) => getDayAndConvertMonToSun(ts.start_time))
      .filter((d): d is number => !!d); // filter out null | undefined
  const { userLocation } = useSelectProfileData();
  const { timezone = Intl.DateTimeFormat().resolvedOptions().timeZone } =
    userLocation || {};
  const { toggles } = useSystemTogglesContext();
  const blockNonBusinessWorkingHours: ValueToggle =
    toggles.BLOCK_NON_BUSINESS_WORKING_HOURS as ValueToggle;

  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={{ xs: 0, sm: 0, md: 3, lg: 3 }}
    >
      {mentors.map((mentor, i) => {
        const dayAvailabilityMismatch =
          (timeSlotDays &&
            timeSlotDays.length &&
            !findCommonDay(timeSlotDays, mentor.availability.days)) ||
          false;
        const timeSlotMatches = timeSlots?.map((ts) =>
          filterByVolunteerAvailabilityHours(
            new Date(ts.start_time),
            blockNonBusinessWorkingHours,
            [mentor],
            timezone
          )
        );
        const timeAvailabilityMismatch =
          timeSlots && !timeSlotMatches?.some((ts) => !!ts);
        const availabilityMismatch =
          dayAvailabilityMismatch || timeAvailabilityMismatch;

        const skillMismatch =
          selectedTopPriority &&
          !mentor.interests_ids.find((i: number) => i === selectedTopPriority);

        const disabled = skillMismatch || availabilityMismatch;
        const disabledReason = skillMismatch
          ? 'skills'
          : availabilityMismatch
          ? 'availability'
          : undefined;

        return (
          <Fragment key={`mentor-${i}`}>
            <MentorCard
              mentor={mentor}
              onCardClick={() => addRemoveMentor(mentor.id)}
              onAddRemoveClick={() => addRemoveMentor(mentor.id)}
              selected={selectedMentorsList?.includes(mentor.id)}
              hideSelect={hideSelect}
              hideBio={hideBio}
              disabled={disabled}
              disabledReason={disabledReason}
              disableButton={disableButton}
              showRemoveButton={showRemoveButton}
              compact={compact}
              key={i}
            />
          </Fragment>
        );
      })}
    </Grid>
  );
};

export default memo(MentorSearchResults);
