import apiInstance from './config';

import Cookie from 'js-cookie';
import { getOrgProfile } from './user';
import { getUrlParam } from 'utils/url';
import { getAccessTokenCookie } from 'utils/auth';

export const searchMentors = async (
  ids: any[],
  limit: number,
  noPartner?: boolean
) => {
  const partner_id = Cookie.get('partner_id');
  const response = await getOrgProfile();
  let partnerId = null;

  if (response && response.partner && !noPartner)
    partnerId = '&partner_id=' + response.partner;
  else if (partner_id && !noPartner) partnerId = '&partner_id=' + partner_id;
  let searchIdString = '';
  if (ids.length === 0) return null;
  ids.forEach((id) => {
    searchIdString += '&id=' + id;
  });
  const url = partnerId
    ? `api/volunteer/search/volunteers/?limit=${limit}${searchIdString}${partnerId}`
    : `api/volunteer/search/volunteers/?limit=${limit}${searchIdString}`;
  return apiInstance
    .get(url, {})
    .then((response: any) => {
      return response.data;
    })
    .catch((err: any) => {
      return err.response;
    });
};

export const searchUniqueMentor = async (id: any, limit: number) => {
  const loggedIn = !!getAccessTokenCookie();
  const response = await getOrgProfile();
  const partner_id = loggedIn
    ? response?.partner
    : getUrlParam('id')
    ? parseInt(getUrlParam('id') as string)
    : null;

  let partnerId = '&partner_id=' + partner_id;
  let searchIdString = '&id=' + id;
  const url = partner_id
    ? `api/volunteer/search/volunteers/?limit=${limit}${searchIdString}${partnerId}`
    : `api/volunteer/search/volunteers/?limit=${limit}${searchIdString}`;

  return apiInstance
    .get(url, {})
    .then((response: any) => {
      return response.data;
    })
    .catch((err: any) => {
      return err.response;
    });
};
