import React from 'react';
import { DashboardFilledButton } from 'styling/buttons';
import './select-button.scss';

interface Props {
  selected?: boolean;
  onClick: any;
  disabled?: boolean;
}

const SelectButton = ({ selected, onClick, disabled }: Props) => {
  const status = selected ? 'Selected' : 'Select';
  const disabledState = disabled ? 'disabled' : '';

  return (
    <DashboardFilledButton
      variant="outlined"
      sx={{
        backgroundColor: status === 'Select' ? 'transparent' : 'primary',
        borderWidth: '3px',
        borderColor: 'primary',
        color: status === 'Select' ? '#333' : 'undefined',
        borderRadius: '38px',
        width: '195px',
        alignSelf: 'center',
        height: '38px',
      }}
      onClick={onClick}
      className={`select-button ${status} ${disabledState}`}
      disabled={disabled}
      data-cy="select-button"
    >
      {status}
    </DashboardFilledButton>
  );
};

export default SelectButton;
