import { FormControl, OutlinedInput } from '@mui/material';
import useWindowSize from 'hooks/useWindowSize';
import React, { memo } from 'react';
import { KnowledgeButton } from 'styling/buttons';
import { CustomSelectAvailabilityTypography } from 'styling/generalStyling';
import { mobileBreakpoint } from 'styling/screenSizes';

type Props = {
  knowledge: string | undefined;
  setKnowledge: any;
  setMessage: any;
  shortMessageError: string | null;
};

const NeededSupport = ({
  knowledge,
  setKnowledge,
  setMessage,
  shortMessageError,
}: Props) => {
  const { width } = useWindowSize();
  const isMobile = width < mobileBreakpoint;
  return (
    <div className="support-container">
      <CustomSelectAvailabilityTypography
        sx={{
          fontWeight: '800',
          fontSize: isMobile ? '13px' : '16px',
          display: 'inline-flex',
          columnGap: '5px',
          flexDirection: 'row',
          rowGap: '10px',
          width: 'fit-content',
          lineHeight: isMobile ? '16.25px' : '20px',
          whiteSpace: 'nowrap',
          flexWrap: isMobile ? 'wrap' : 'nowrap',
          alignItems: 'center',
        }}
      >
        My current knowledge:
        <KnowledgeButton
          onClick={() => setKnowledge('nothing_at_all')}
          variant="outlined"
          sx={{
            fontSize: isMobile ? '10px' : '13px',
            height: isMobile ? '32px' : '35px',
            backgroundColor: knowledge === 'nothing_at_all' ? '#B2D5EF' : null,
          }}
        >
          Nothing at all
        </KnowledgeButton>
        <KnowledgeButton
          onClick={() => setKnowledge('i_know_some')}
          variant="outlined"
          sx={{
            fontSize: isMobile ? '10px' : '13px',
            height: isMobile ? '32px' : '35px',
            backgroundColor: knowledge === 'i_know_some' ? '#B2D5EF' : null,
          }}
        >
          Some
        </KnowledgeButton>
        <KnowledgeButton
          onClick={() => setKnowledge('i_know_a_lot')}
          variant="outlined"
          sx={{
            fontSize: isMobile ? '10px' : '13px',
            height: isMobile ? '32px' : '35px',
            backgroundColor: knowledge === 'i_know_a_lot' ? '#B2D5EF' : null,
          }}
        >
          Just need help with some specifics
        </KnowledgeButton>
      </CustomSelectAvailabilityTypography>
      <CustomSelectAvailabilityTypography
        sx={{
          fontWeight: '800',
          fontSize: isMobile ? '13px' : '16px',
          lineHeight: isMobile ? '16.25px' : '20px',
        }}
      >
        In this session I’d like to cover…
      </CustomSelectAvailabilityTypography>
      {shortMessageError ? (
        <CustomSelectAvailabilityTypography
          sx={{
            fontSize: isMobile ? '9px' : '12px',
            lineHeight: '20px',
            color: '#FF0000',
            fontWeight: '800',
            marginBottom: '-5px',
            marginTop: '-5px',
            alignSelf: isMobile ? 'center' : 'left',
          }}
        >
          {shortMessageError}
        </CustomSelectAvailabilityTypography>
      ) : null}

      <FormControl
        sx={{ width: '100%', minHeight: 'fit-content', marginBottom: '18px' }}
      >
        <OutlinedInput
          sx={{
            fontFamily: 'Gilroy',
            width: '100%',
            maxHeight: '100%',
            minHeight: '130px',
            borderRadius: '15px',
            fontSize: isMobile ? '13px' : '16px',
            lineHeight: isMobile ? '15.3px' : '18px',
          }}
          multiline={true}
          onChange={(event) => setMessage(event.target.value)}
          placeholder="Describe your challenge and share what outcome you’d like to achieve with your mentor’s help. Include any extra information that you think will help your mentor to support you best!"
        />
      </FormControl>
    </div>
  );
};

export default memo(NeededSupport);
