import React, { useEffect, useRef } from 'react';

declare global {
  interface Window {
    tableau: any;
  }
}

// tableau comes from a javascript reference in the index.html
const { tableau } = window;

interface Props {
  url: string;
  height: string;
}

const TableauEmbeded = ({ url, height }: Props) => {
  const myRef = useRef(null);

  var options = {
    hideTabs: true,
    width: '100%',
    height: height,
    onFirstInteractive: function () {
      // The viz is now ready and can be safely used.
    },
  };

  const initViz = () => {
    new tableau.Viz(myRef.current, url, options);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initViz, []);

  return <div className="tableau-body" ref={myRef} />;
};

export default TableauEmbeded;
