import React from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { logoutPartner } from '../../../utils/auth';
import './navigation-menu.scss';

interface Props {
  userName: string;
}

const PartnerNavigationMenu = ({ userName }: Props) => {
  const handleLogoutButtonClick = () => {
    logoutPartner();
  };

  return (
    <div className="nav-link-container-partner">
      <span className="nav-link-partner" style={{ display: 'none' }} />
      <span
        className="nav-link-separator-partner"
        style={{ display: 'none' }}
      />
      <span className="nav-link-partner">
        <span>
          <span className="profile-pic-partner">
            {/* {userProfile.profile_picture &&
                        <img height={'24px'} width={'24px'} alt='avatar' src={userProfile.profile_picture} />
                    } */}
            <img height={'24px'} alt="avatar" src={'/avatar.png'} />
          </span>
          <span>{userName}</span>
        </span>
        <IoMdArrowDropdown color="#333333" />
        <div className="nav-items-partner">
          <div className="menu-box-partner">
            <div>
              <a href="/premium-partner">Insights</a>
            </div>
            <div>
              <a href="/premium-partner-preferences">Preferences</a>
            </div>
            <div onClick={handleLogoutButtonClick}>Logout</div>
          </div>
        </div>
      </span>
    </div>
  );
};

export default PartnerNavigationMenu;
