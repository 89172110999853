import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { TailSpin } from 'react-loader-spinner';
import { FcCancel } from 'react-icons/fc';
import { MdUpdate } from 'react-icons/md';
import styles from './cancel-reschedule-modal.module.scss';
import Button from '../../components/Button';
import DatePicker from '../../components/DatePicker';
import useRescheduleBoostCallRequest from '../../features/boostCallEvents/hooks/useRescheduleBoostCallRequest';
import useCancelGroupEventRegistration from '../../apiHooks/groupEvents/useCancelGroupEventRegistration';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { displayTime } from '../../utils/time';
import { defaultTimeFormat } from '../../constants';

import { BoostCallEventWithVolunteerProfile, GroupEvent } from '../../types';

type Props = {
  boostCall: BoostCallEventWithVolunteerProfile | GroupEvent;
  onClose: () => void;
  confirmText?: string;
  withOverlay?: boolean;
  onDecline?: () => void;
  onCancelGroupEventAttendance?: () => void;
  isGroupEvent?: boolean;
  className?: string;
};

const filterDatePickerDate = (selectedTime: Date, startTime: string) => {
  const dateIsInFuture =
    selectedTime.getTime() > new Date(Date.now()).getTime();
  const isSameAsScheduleTime =
    selectedTime.getTime() === new Date(startTime).getTime();
  return dateIsInFuture && !isSameAsScheduleTime;
};

const CancelRescheduleModal = ({
  boostCall,
  onClose,
  confirmText,
  withOverlay,
  className,
  isGroupEvent,
  onDecline,
  onCancelGroupEventAttendance,
}: Props) => {
  const {
    id: callId,
    start_time,
    volunteer: { first_name: volunteerName } = {},
    organisation: { first_name: orgName } = {},
  } = boostCall as BoostCallEventWithVolunteerProfile;

  const { userLocation } = useSelectProfileData();
  const navigate = useNavigate();
  const { timezone } = userLocation || {};

  const {
    proposeRescheduleRequest,
    isLoadingProposeReschedule,
    proposeRescheduleError,
  } = useRescheduleBoostCallRequest();
  const {
    cancelGroupEventRegistration,
    cancelGroupEventRegistrationError,
    isLoadingCancelGroupEventRegistration,
  } = useCancelGroupEventRegistration({
    attendeeId: (boostCall as GroupEvent).attendee_id,
  });

  const [cancelModalMode, setCancelModalMode] = useState<
    'reschedule' | 'cancel' | null
  >(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [successProposeReschedule, setSuccessProposeReschedule] =
    useState(false);
  const boostCallCancelLink = `/cancel-call?id=${callId}`;
  const originalTime = displayTime(
    start_time,
    timezone,
    `${defaultTimeFormat}`
  );
  const originalDate = displayTime(start_time, timezone, 'DD MMM');

  const closeAndReset = useCallback(() => {
    setStartDate(null);
    onClose();
    setCancelModalMode(null);
  }, [onClose]);

  const rescheduleCall = useCallback(async () => {
    await proposeRescheduleRequest({
      id: callId,
      newStartDateTime: startDate?.toISOString(),
    });
    setSuccessProposeReschedule(true);
  }, [proposeRescheduleRequest, callId, startDate]);

  const cancelCall = async () => {
    if (isGroupEvent) {
      try {
        await cancelGroupEventRegistration();
        onDecline && (await onDecline());
        onCancelGroupEventAttendance && (await onCancelGroupEventAttendance());
        closeAndReset();
      } catch (err) {
        //  errors are dealt with & returned in the api-hook
        // we are catching here to avoid issues from uncaught errors
      }
    } else {
      navigate(boostCallCancelLink);
    }
  };

  return (
    <>
      {withOverlay && (
        <div className={styles.overlay} onClick={closeAndReset} aria-hidden />
      )}
      <div
        className={`${styles.modal} ${className && className}`}
        style={{ width: isGroupEvent ? '75%' : '95%' }}
      >
        <Button
          text="&#x00D7;"
          className={styles.closeButton}
          type="ghost"
          onClickFunction={closeAndReset}
        />
        {cancelModalMode === 'reschedule' ? (
          <div className={styles.choiceContainer}>
            {successProposeReschedule && !proposeRescheduleError ? (
              <div className={styles.rescheduleConfirmedContainer}>
                <div className={styles.rescheduleConfirmedTitleContainer}>
                  <IoIosCheckmarkCircle
                    className={styles.confirmedIcon}
                    size={30}
                    fill={'#00AB6B'}
                  />
                  <p className={styles.choiceTitle}>Thank you {orgName}!</p>
                </div>
                <p className={styles.choiceSubtitle}>
                  We will ask {volunteerName} if this new time is ok. If yes,
                  the new time will be automatically confirmed.{' '}
                </p>
                <p className={styles.choiceSubtitle}>
                  If they do not accept, the meeting will remain at{' '}
                  <b>{originalTime}</b> on <b>{originalDate}</b> unless you
                  cancel it.
                </p>
              </div>
            ) : (
              <>
                <p className={styles.choiceTitle}>
                  Please select a date to propose
                </p>
                {!startDate && (
                  <p className={styles.choiceSubtitle}>
                    We recommend you send a message to {volunteerName} to agree
                    a new time before rescheduling.
                  </p>
                )}
                <div className={styles.datePickerContainer}>
                  <DatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    position={'bottom'}
                    filterTime={(selectedTime) =>
                      filterDatePickerDate(selectedTime, start_time)
                    }
                    dayBuffer={0}
                  />
                </div>
                {!!startDate && (
                  <Button
                    type="secondary"
                    text={`Submit reschedule request to ${volunteerName}`}
                    onClickFunction={rescheduleCall}
                    className={styles.rescheduleConfirmButton}
                    loading={isLoadingProposeReschedule}
                  />
                )}
                {!!proposeRescheduleError && (
                  <p className={styles.errorText}>{proposeRescheduleError}</p>
                )}
              </>
            )}
          </div>
        ) : (
          <div className={styles.innerContainer}>
            {!isGroupEvent && (
              <button
                className={styles.choiceButton}
                onClick={() => setCancelModalMode('reschedule')}
              >
                <MdUpdate size={45} className={styles.rescheduleIcon} />
                <span className={styles.choiceText}>Reschedule</span>
              </button>
            )}
            <div className={styles.cancelContainer}>
              {isGroupEvent && (
                <p className={styles.cancelText}>
                  Would you like to cancel your registration?
                </p>
              )}
              <button className={styles.choiceButton} onClick={cancelCall}>
                {isLoadingCancelGroupEventRegistration ? (
                  <div className={styles.loadingContainer}>
                    <TailSpin color="#414141" height={45} width={45} />
                  </div>
                ) : (
                  <FcCancel size={45} className={styles.cancelIcon} />
                )}
                <span className={styles.choiceText}>Cancel</span>
              </button>
              {!!cancelGroupEventRegistrationError && (
                <p className={styles.errorText}>
                  {cancelGroupEventRegistrationError}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CancelRescheduleModal;
