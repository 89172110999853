import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import {
  GetMessagesProps,
  SendMessageProps,
  MarkMessagesReadProps,
  BlockMessagesProps,
} from '../../api/messages';
import { logout } from '../auth';
import { selectSingleBoostCallEvent } from '../boostCallEvents';
import type { RootState } from '../../store';
import type { Message } from '../../types';

/* --- SLICE --- */

interface MessagesState {
  status: 'init' | 'pending' | 'fulfilled' | 'rejected';
  messages: Message[];
  boostCallEventId?: number | null;
  count: number | null;
  next?: string | null;
  previous?: string | null;
  error?: string | null;
  sendStatus?: 'pending' | 'fulfilled' | 'rejected' | null;
  sendError?: string | null;
  blockStatus?: 'pending' | 'fulfilled' | 'rejected' | null;
  blockError?: string | null;
}

const initialState = {
  status: 'init',
  messages: [],
  boostCallEventId: null,
  count: null,
  error: null,
} as MessagesState;

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    clearMessages: (state) => {
      return initialState;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // logout
      .addCase(logout, () => initialState)
      // fetchMessages
      .addCase(fetchMessagesAction.pending, (state, action) => {
        state.error = null;
        const { boostCallEventId } = action.meta.arg;
        state.boostCallEventId = boostCallEventId;
        state.status = action.meta.requestStatus;
      })
      .addCase(fetchMessagesAction.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const { requestStatus } = meta;
        const { results, count, next, previous } = payload;

        state.status = requestStatus;
        state.messages = results.sort(
          (m1, m2) =>
            new Date(m1.created).getTime() - new Date(m2.created).getTime()
        );
        state.count = count;
        state.next = next;
        state.previous = previous;
      })
      .addCase(fetchMessagesAction.rejected, (state, action) => {
        const { error } = action;
        state.error = error.message;
        state.status = action.meta.requestStatus;
      })
      // sendMessage
      .addCase(sendMessageAction.pending, (state, action) => {
        state.sendError = null;
        state.sendStatus = action.meta.requestStatus;
      })
      .addCase(sendMessageAction.fulfilled, (state, action) => {
        state.sendStatus = action.meta.requestStatus;
      })
      .addCase(sendMessageAction.rejected, (state, action) => {
        const { error } = action;
        state.sendError = error.message;
        state.sendStatus = action.meta.requestStatus;
      })
      // blockMessages
      .addCase(blockMessagesAction.pending, (state, action) => {
        state.blockError = null;
        state.blockStatus = action.meta.requestStatus;
      })
      .addCase(blockMessagesAction.fulfilled, (state, action) => {
        state.blockStatus = action.meta.requestStatus;
      })
      .addCase(blockMessagesAction.rejected, (state, action) => {
        const { error } = action;
        state.blockError = error.message;
        state.blockStatus = action.meta.requestStatus;
      });
  },
});

/* --- SELECTORS --- */

// fetchMessages
export const selectMessages = (state: RootState) => state.messages.messages;
export const selectMessagesStatus = (state: RootState) => state.messages.status;
export const selectHasNotFetchedMessages = (state: RootState) =>
  selectMessagesStatus(state) === 'init';
export const selectIsLoadingMessages = (state: RootState) =>
  selectMessagesStatus(state) === 'pending';
export const selectHasFinishedLoadingMessages = (state: RootState) =>
  selectMessagesStatus(state) === 'fulfilled' ||
  selectMessagesStatus(state) === 'rejected';
export const selectMessagesError = (state: RootState) => state.messages.error;

// sendMessages
export const selectSendMessageStatus = (state: RootState) =>
  state.messages.sendStatus;
export const selectIsLoadingSendMessage = (state: RootState) =>
  selectSendMessageStatus(state) === 'pending';
export const selectHasFinishedLoadingSendMessage = (state: RootState) =>
  selectSendMessageStatus(state) === 'fulfilled' ||
  selectSendMessageStatus(state) === 'rejected';
export const selectSendMessageError = (state: RootState) =>
  selectSendMessageStatus(state) === 'rejected' && state.messages.error;

// blockMessages
export const selectBlockMessagesStatus = (state: RootState) =>
  state.messages.blockStatus;
export const selectIsLoadingBlockMessages = (state: RootState) =>
  selectBlockMessagesStatus(state) === 'pending';
export const selectBlockedByOrganisation = (
  state: RootState,
  boostCallEventId: number
) => selectSingleBoostCallEvent(state, boostCallEventId)?.org_blocked_comments;
export const selectBlockedByVolunteer = (
  state: RootState,
  boostCallEventId: number
) => selectSingleBoostCallEvent(state, boostCallEventId)?.vol_blocked_comments;
export const selectBlockMessagesError = (state: RootState) =>
  state.messages.blockError;

/* --- ACTIONS --- */

export const { clearMessages, setMessages } = messagesSlice.actions;

/* --- THUNKS --- */

// fetchMessages
export const fetchMessagesAction = createAsyncThunk(
  `${messagesSlice.name}/fetchMessages`,
  async ({ boostCallEventId }: GetMessagesProps, { getState }) => {
    const response = await api.getMessages({ boostCallEventId });
    return response.data;
  }
);

// markMessagesRead
export const markMessagesReadAction = createAsyncThunk(
  `${messagesSlice.name}/markMessagesRead`,
  async ({ boostCallEventId }: MarkMessagesReadProps, thunkAPI) => {
    const response = await api.markMessagesRead({ boostCallEventId });
    return response.data;
  }
);

// sendMessage
export const sendMessageAction = createAsyncThunk(
  `${messagesSlice.name}/sendMessage`,
  async ({ text, boostCallEventId }: SendMessageProps, thunkAPI) => {
    const response = await api.sendMessage({ text, boostCallEventId });
    return response.data;
  }
);

// blockMessages
export const blockMessagesAction = createAsyncThunk(
  `${messagesSlice.name}/blockMessages`,
  async ({ boostCallEventId, blocked }: BlockMessagesProps, thunkAPI) => {
    const response = await api.blockMessages({ boostCallEventId, blocked });
    return response.data;
  }
);

export default messagesSlice.reducer;
