import React, { memo } from 'react';
import Grid from '@mui/material/Grid';
import './card-with-header.scss';

type Props = {
  headerText?: string;
  headerNumber?: number;
  colour?:
    | 'darkest'
    | 'darker'
    | 'darkish'
    | 'dark'
    | 'light'
    | 'lightish'
    | 'lighter'
    | 'lightest';
  className?: string;
  innerClassName?: string;
  headerClassName?: string;
  children?: React.ReactNode;
  hideHeader?: boolean;
};

const CardWithHeader = ({
  headerText,
  headerNumber,
  colour = 'darker',
  headerClassName,
  className,
  innerClassName,
  children,
  hideHeader = false,
}: Props) => {
  return (
    <Grid
      container
      className={`card-with-header__card ${className ? className : ''}`}
    >
      {!hideHeader && (
        <Grid
          className={`card-with-header__header  ${
            !headerNumber ? `card-with-header__${colour}` : ''
          } ${headerClassName ? headerClassName : ''}`}
        >
          {headerNumber && (
            <Grid
              alignItems="center"
              className={`card-with-header__header-number-container ${
                headerNumber ? `card-with-header__${colour}` : ''
              }`}
            >
              <span>{headerNumber}</span>
            </Grid>
          )}
          <span className="card-with-header__header-text">{headerText}</span>
        </Grid>
      )}
      <Grid container className={`${innerClassName ? innerClassName : ''}`}>
        {children}
      </Grid>
    </Grid>
  );
};

export default memo(CardWithHeader);
