import React, { useState, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import ReactTooltip from 'react-tooltip';
import './styling.scss';
import './login-signup-modal.scss';
import { Helmet } from 'react-helmet';
import { getUrlParam } from 'utils/url';
import useFetchPartner from 'apiHooks/partner/useFetchPartner';
import { Partner } from 'types';
import SignInForm from './signinForm';
import FullScreenLoading from 'components/FullScreenLoading';

type Props = {
  redirect: undefined | string;
};
const LoginModal = ({ redirect }: Props) => {
  const { partner: profilePartner, hasFinishedLoadingPartner } =
    useSelectProfileData();
  const [, setPartner] = useState<Partner | null>();
  let partnerId = parseInt(getUrlParam('id') as string);

  if (profilePartner) {
    partnerId = profilePartner?.id;
  }
  const navigate = useNavigate();

  const { partner: fetchPartnerResponseData } = useFetchPartner(partnerId);

  useEffect(() => {
    if (profilePartner) {
      setPartner(profilePartner);
    } else if (fetchPartnerResponseData) {
      setPartner(fetchPartnerResponseData);
    }
  }, [profilePartner, partnerId, fetchPartnerResponseData]);

  const org_title =
    !profilePartner ||
    profilePartner === null ||
    profilePartner?.organisation_title === null ||
    profilePartner?.organisation_title === ''
      ? 'Mentee'
      : profilePartner?.organisation_title;

  const welcomeText = `Welcome back!`;
  const primaryOptionTitle = `${org_title} Login`;
  const secondaryOptionTitle = "Don't have an account?";
  let titleTag = '';
  let metaDescription = '';
  titleTag = 'Digital Boost Organisation Portal | Log in to your account';
  metaDescription = 'Log in to your Digital Boost Organisation Portal';

  if (!hasFinishedLoadingPartner) {
    return <FullScreenLoading />;
  }

  return (
    <div className="login-signup-page-container">
      <Helmet>
        <title>{titleTag}</title>
        <meta name="description" content={metaDescription}></meta>
      </Helmet>
      <ReactTooltip place={'top'} />

      <div className="login-signup__container">
        <div
          className="login-signup__primary-container"
          data-cy="sign-up-container"
        >
          <h1 className="login-signup__welcome-text">{welcomeText}</h1>
          <h2>{primaryOptionTitle}</h2>
          <SignInForm />
        </div>
        <div className="login-signup__secondary-container">
          <h3>{secondaryOptionTitle}</h3>
          <Button
            type={'primary'}
            text={'Signup'}
            onClickFunction={() =>
              redirect && redirect?.length > 1
                ? navigate(`/signup?redirect=${redirect}`)
                : navigate(`/signup`)
            }
            dataCy="login-modal-button"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(LoginModal);
