import { createSlice } from '@reduxjs/toolkit';
import { Taxonomy } from 'types';

export const taxonomySlice = createSlice({
  name: 'taxonomy',
  initialState: {
    value: [] as Taxonomy[],
  },
  reducers: {
    updateTaxonomy: (state, action) => {
      return { ...state, value: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateTaxonomy } = taxonomySlice.actions;

export default taxonomySlice.reducer;
