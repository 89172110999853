import React, { useEffect, useState } from 'react';
import apiInstance from '../../api/config';
import { useNavigate } from 'react-router-dom';
import { getUrlParam } from '../../utils/url';
import { getErrorMessageFromResponse } from '../../utils/error';
import {
  declineProposedTime,
  declineFollowUpRequest,
} from '../../api/callRequests';
import './confirm-call.scss';
import { DashboardFilledButton } from 'styling/buttons';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from 'features/reduxStore/sessionTitle';

const DeclineCall = () => {
  const idOrCode = !!getUrlParam('follow_up')
    ? getUrlParam('follow_up')
    : getUrlParam('code');
  const isFollowUp = !!getUrlParam('follow_up');
  const navigate = useNavigate();
  const [isSent, setIsSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const titleText = isFollowUp
    ? 'You have successfully declined your follow-up request'
    : 'You have successfully declined your request';
  const subtitleText = isFollowUp ? `` : 'Thank you for using our platform.';
  const session_title = useSelector(selectSessionTitle);
  const getDataFromCode = async () => {
    try {
      if (isFollowUp) {
        const res = await apiInstance.get(
          `api/netpromoterscore/followuprequest/?limit=100`
        );
        const requests = res?.data?.results;

        const isRequestValid =
          requests.filter(
            (request: any) =>
              parseInt(request?.id) === parseInt(idOrCode as string) &&
              request?.follow_up_status === 'requested'
          )?.length > 0;

        if (isRequestValid) {
          await declineFollowUpRequest(String(idOrCode), () => setIsSent(true));
        } else {
          navigate('/dashboard/actions');
        }
      } else {
        await declineProposedTime({ code: String(idOrCode) });
        setIsSent(true);
      }

      // /* currently we are just auto declining the call.
      // If we want in the future to give them the option to tell us why we just remove the declineCall() function
      // */
    } catch (err) {
      const errorMessageFromResponse = getErrorMessageFromResponse(err);
      setErrorMessage(errorMessageFromResponse);
    }
  };

  useEffect(() => {
    getDataFromCode();

    // eslint-disable-next-line
  }, []);

  if (isSent) {
    return (
      <div className="decline-call__thank-you">
        <h2>{titleText}</h2>

        <h3>{subtitleText}</h3>

        <div className="decline-call__button-container">
          <DashboardFilledButton
            sx={{ width: '210px' }}
            variant="contained"
            onClick={() => navigate('/dashboard/actions')}
          >
            Return To Dashboard
          </DashboardFilledButton>
        </div>
      </div>
    );
  } else {
    return isFollowUp ? (
      <div className="decline-call__loading-container">
        <img
          className="decline-call__loading-spinner"
          src="/loading.gif"
          alt="loading"
        />
      </div>
    ) : (
      <div className="confirm-call">
        <h1>Decline {session_title} Request</h1>

        {errorMessage && <h3 className="error">{errorMessage}</h3>}
      </div>
    );
  }
};

export default DeclineCall;
