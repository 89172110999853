import axios from 'axios';
import Cookie from 'js-cookie';
import LogRocket from 'logrocket';
import { nonAuthProtectedRoutes } from '../App';
import { UserProfile, OrgProfile } from '../types';

const apiUrl = process.env.REACT_APP_API_URL;

// this util checks current route against list of non-protected routes
// if protected: redirect to login
export const redirectProtecedRoutesToLogin = async () => {
  const { pathname, search } = window.location;
  const attemptedDomain = pathname + search;
  const encodedAttemptedDomain = encodeURIComponent(attemptedDomain);
  const redirectPath = '/login';

  // auth-protect only certain routes
  if (
    nonAuthProtectedRoutes.indexOf(pathname) >= 0 ||
    pathname === redirectPath
  )
    return;
  window.location.href = `${redirectPath}?redirect=${encodedAttemptedDomain}`;
};

// this util checks current route against list of non-protected routes
// if protected: redirect to finish signup
export const redirectToFinishSignup = async () => {
  const { pathname } = window.location;
  const redirectPath = '/add-details';

  // auth-protect only certain routes
  if (
    nonAuthProtectedRoutes.indexOf(pathname) >= 0 ||
    pathname === redirectPath
  )
    return;
  window.location.href = redirectPath;
};

// obtain the token at the point the function is called
export const getAccessTokenCookie = () => Cookie.get('accessOrgTokenDB');
export const getRefreshTokenCookie = () => Cookie.get('refreshOrgTokenDB');

export const loginWithRefreshToken = async (): Promise<string | undefined> => {
  const refreshToken = getRefreshTokenCookie();

  // Note: Using a different instance of axios from our api
  // This is so exception thrown by axios when the refreshToken itself is stale or missing won't get caught in the interceptor created by the axios-auth-refresh module
  try {
    const refreshResponse = await axios.post(`${apiUrl}api/token/refresh/`, {
      refresh: refreshToken,
    });

    const newAccessToken = refreshResponse.data.access;
    // set cookie that expires in a day
    Cookie.set('accessOrgTokenDB', newAccessToken, {
      expires: 1,
    });
    return newAccessToken;
  } catch (err) {
    throw err;
  }
};

export const loginWithOTLC = async (otlc: string) => {
  try {
    // Note: Using a different instance of axios from our api
    // This is so exception thrown by axios when the otlc is stale  won't get caught in the interceptor created by the axios-auth-refresh module
    const response = await axios.post(apiUrl + `api/token/otlc/`, {
      otlc: otlc,
      user_type: 'organisation',
    });
    // set cookie that expires in a day
    Cookie.set('refreshOrgTokenDB', response.data.refresh, { expires: 90 });
    Cookie.set('accessOrgTokenDB', response.data.access, {
      expires: 1,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export async function ifPartnerLoggedIn() {
  /* check if they are logged in first by checking if have an access token,
    then by checking they have a refresh token and trying to receive a n access token from it

    then check if they have an otlc and try loging in with that
    
    if they dont have any then redirect them to the login page but hold the link they were
    trying to get to in the query string
    
    */

  const attemptedDomain = window.location.pathname + window.location.search;

  if (!Cookie.get('accessPartnerTokenDB')) {
    if (!Cookie.get('refreshPartnerTokenDB')) {
      window.location.href = '/partner-login?redirect=' + attemptedDomain;
    } else {
      const response = await partnerLoginUsingRefreshToken(
        Cookie.get('refreshPartnerTokenDB')
      );
      if (response.status === 401) {
        window.location.href = '/partner-login?redirect=' + attemptedDomain;
      }
    }
  }
}

export const logoutPartner = () => {
  /** 
    To logout remove cookies and redirect to login page
    */

  if (Cookie.get('accessPartnerTokenDB')) {
    Cookie.remove('accessPartnerTokenDB');
  }

  if (Cookie.get('refreshPartnerTokenDB')) {
    Cookie.remove('refreshPartnerTokenDB');
  }

  window.location.href = '/partner-login';
};

const partnerLoginUsingRefreshToken = async (refreshToken: any) => {
  return axios
    .post(
      apiUrl + `api/token/refresh/`,
      {
        refresh: refreshToken,
      },
      {
        headers: {},
      }
    )
    .then((response: any) => {
      // set cookie that expires in a day

      Cookie.set('accessPartnerTokenDB', response.data.access, {
        expires: 1,
      });

      const attemptedDomain = window.location.pathname + window.location.search;
      window.location.href = attemptedDomain;
      return response;
    })
    .catch((err: any) => {
      console.log(err.response);
    });
};

export async function logRocketIdentify({
  userProfile,
  orgProfile,
}: {
  userProfile: UserProfile;
  orgProfile: OrgProfile;
}) {
  try {
    if (
      orgProfile?.user &&
      userProfile?.username &&
      process.env.REACT_APP_LOGROCKET_ON === 'true'
    ) {
      const userFullName = `${orgProfile.first_name} ${orgProfile.last_name}`;
      const userId = orgProfile.user;
      const userEmail = userProfile.username;

      LogRocket.identify(userId.toString(), {
        name: userFullName,
        email: userEmail,
      });
    }
  } catch (err) {
    console.log('err:', err);
  }
}
