import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import './style.scss';
import '../components/styling/card-styling.scss';

import '../components/styling/card-styling.scss';
import api from 'api';
import {
  acceptFollowUpRequest,
  declineFollowUpRequest,
} from 'api/callRequests';
import { useNavigate } from 'react-router-dom';
import { DashboardFilledButton, DashboardEmptyButton } from 'styling/buttons';
import { Mentor } from '../../../../types';
import MentorModal from 'components/MentorCard/MentorModal';

interface Props {
  followup: any;
  clickViewDetails: (e: any) => void;
}

const NewFollowupCard = ({ followup, clickViewDetails }: Props) => {
  const navigate = useNavigate();
  const { id, score, interests } = followup;

  const [interest, setInterest] = useState<any>(null);
  const closeModal = () => {
    setMentor(undefined);
    setOpenMentorModal(false);
  };
  useEffect(() => {
    api.getTaxonomy(interests).then((taxonomy: any) => {
      const interest = taxonomy?.filter((item: any) => item.child);
      setInterest(interest);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interests]);
  const [mentor, setMentor] = useState<Mentor | undefined>(undefined);
  const [openMentorModal, setOpenMentorModal] = useState<boolean>(false);

  const openModal = async () => {
    const { results } = await api.searchMentors([score?.volunteer?.id], 1);

    if (results && results.length && results.length > 0) {
      setMentor(results[0]);
      setOpenMentorModal(true);
    }
  };

  return (
    <div className="requests-box">
      <Box
        sx={{
          '& > :not(style)': {
            width: '100%',
            height: '100%',
          },
        }}
      >
        <Paper
          key={id}
          sx={{
            borderRadius: '8px',
            boxShadow: '8px 5px 30px 0 rgba(0, 0, 0, 0.06)',
          }}
        >
          <div>
            <div className="dashboard-card__container_followup">
              <div
                onClick={openModal}
                className="profile-photo-card"
                style={{
                  cursor: 'pointer',
                  backgroundImage: score?.volunteer?.profile_picture
                    ? `url(${score?.volunteer?.profile_picture})`
                    : 'url(/avatar.png)',
                }}
              ></div>

              {mentor && openMentorModal ? (
                <MentorModal
                  mentor={mentor}
                  modalOpen={Boolean(mentor)}
                  closeModal={closeModal}
                  hideSelect
                />
              ) : null}
              <div className="dashboard-card-header-info">
                <div className="dashboard-card-title">
                  <div className="dashboard-card-name">
                    {score?.volunteer?.first_name} {score?.volunteer?.last_name}{' '}
                    has requested a follow-up session
                  </div>
                </div>
                <div className="request-paper-organisation">
                  {score?.volunteer?.employer}
                </div>
                <div className="request-paper-topic">
                  {interest?.[0]?.child?.[0]?.name || ''}
                </div>

                <div className="request-paper-button-wrapper-followup">
                  <div className="dashboard-button-container">
                    <DashboardFilledButton
                      sx={{ width: '150px' }}
                      variant="contained"
                      onClick={() =>
                        acceptFollowUpRequest(
                          interests,
                          [score.volunteer.id],
                          () =>
                            navigate(
                              `/volunteer-details?volunteer[]=${score?.volunteer?.id}&follow_up=${id}&keyword=follow-up`
                            )
                        )
                      }
                    >
                      Schedule
                    </DashboardFilledButton>
                  </div>{' '}
                  <div className="dashboard-link-button-container">
                    <DashboardEmptyButton
                      variant="text"
                      sx={{
                        color: '#333333',
                        marginLeft: '10px',
                        width: '150px',
                      }}
                      onClick={() => clickViewDetails(followup)}
                    >
                      View Details
                    </DashboardEmptyButton>
                  </div>
                  <div className="dashboard-link-button-container">
                    <DashboardEmptyButton
                      variant="text"
                      sx={{
                        color: '#ff5f5f',
                      }}
                      onClick={() =>
                        declineFollowUpRequest(String(id), () =>
                          window.location.reload()
                        )
                      }
                    >
                      Decline
                    </DashboardEmptyButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default NewFollowupCard;
