import React from 'react';
import './style.scss';
import '../components/styling/card-styling.scss';
import moment from 'moment';
import { Button } from '@mui/material';
import { BoostCallEventReview } from 'types';

interface Props {
  review: BoostCallEventReview;
}

const NewFeedbackCard = ({ review }: Props) => {
  const reviewLength = review.review.length;
  const [displayReview, setDisplayReview] = React.useState(
    reviewLength > 150 ? review.review.slice(0, 150) : review.review
  );
  const date = moment(review.created).format('D MMMM, 	H:m');
  const updateDisplayReview = () => setDisplayReview(review.review);
  return (
    <div className="dashboard-box">
      <div className="feedback-paper-box">
        <div className="feedback-card-container">
          <div className="feedback-card-date">{date}</div>
          <div className="feedback-card-info">
            {displayReview.length < reviewLength
              ? displayReview + ' ...'
              : displayReview}
          </div>
          {displayReview.length < reviewLength ? (
            <Button
              sx={{
                textTransform: 'none',
                width: '100px',
                alignSelf: 'flex-end',
              }}
              onClick={() => updateDisplayReview()}
            >
              View more
            </Button>
          ) : null}
          <div className="feedback-card-organiser-container">
            <div
              className="reviewer-photo-card"
              style={{
                backgroundImage: review.reviewer_profile_picture
                  ? `url(${review.reviewer_profile_picture})`
                  : 'url(/avatar.png)',
              }}
            ></div>
            <div className="feedback-card-org-info">
              <div className="feedback-organiser-name">
                {review.reviewer_name ? review.reviewer_name : 'Anonymous'}
              </div>
              <div className="feedback-organisation-name">
                {review.organisation_name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFeedbackCard;
