import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Actions from './components/actions';
import Bookings from './components/bookings';
import Impact from './components/impact';
import Recommendation from './components/recommendation';
import Events from './components/events';
import { PlanDetailsManagement } from 'pages/ActionPlan/planDetails';
import ActionPlansDashboard from 'pages/ActionPlan/actionPlansDashboard';

type Props = {
  tab: string;
  data: any;
  subTab?: string;
};
const DashboardInfo = ({ tab, data, subTab }: Props) => {
  if (tab === 'actions')
    return (
      <Actions
        upcoming={data.upcomingCallsAndGroupEvents}
        requested={data.upcomingRequests}
        groupEventRequests={data.groupEventRequestData}
        hideGroupEvents={data.hideGroupEvents}
        events={[
          ...data.recommendedEventsInvited,
          ...data.recommendedEvents,
        ].sort(
          (a: any, b: any) =>
            (new Date(a.start_time) as any) - (new Date(b.start_time) as any)
        )}
        followup={data.followupRequests}
        mentors={data.recommendedMentors}
        timeZoneString={data.timezone}
        refetchEvents={data.refetchEvents}
        refetchRecommendedData={data.refetchRecommendedData}
      />
    );
  else if (tab === 'mentoring')
    return (
      <Bookings
        upcoming={data.upcomingCalls}
        groupEventsCompleted={data.boostCallGroupEventsCompleted}
        timeZoneString={data.timezone}
        refetchEvents={data.refetchEvents}
        requested={data.upcomingRequests}
      />
    );
  else if (tab === 'actionplan') return <ActionPlansDashboard />;
  else if (tab === 'planDetails') return <PlanDetailsManagement />;
  else if (tab === 'events')
    return (
      <Events
        upcomingGroupEvents={data.upcomingGroupEvents}
        groupEventsCompleted={data.boostCallGroupEventsCompleted}
        timeZoneString={data.timezone}
        refetchEvents={data.refetchEvents}
      />
    );
  else if (tab === 'recommendations')
    return (
      <Recommendation
        events={[
          ...data.recommendedEventsInvited,
          ...data.recommendedEvents,
        ].sort(
          (a: any, b: any) =>
            (new Date(a.start_time) as any) - (new Date(b.start_time) as any)
        )}
        hideGroupEvents={data.hideGroupEvents}
        mentors={data.recommendedMentors?.filter(
          (mentor: any) => !mentor?.is_access_paused
        )}
        timeZoneString={data.timezone}
        refetchRecommendedData={data.refetchRecommendedData}
      />
    );
  else if (tab === 'your-progress')
    return (
      <Impact
        totalSessions={data.totalSessions}
        masterclasses={data.masterclasses}
        topInterests={data.topInterests}
        subTab={subTab}
      />
    );
  else return null;
};

export default DashboardInfo;
