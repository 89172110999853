import { useEffect } from 'react';
import { AsyncThunk } from '@reduxjs/toolkit';
import useRedux from './useRedux';
import { RootState } from '../store';

type Options = {
  autoFetch?: boolean;
};

const useAutoFetch = (
  someFetchActionCreator: AsyncThunk<any, void, any>,
  // someFetchActionCreator: () => AsyncThunkAction<AxiosResponse<any>, any, {}>,
  hasNotFetchedSelector: (state: RootState) => any,
  options: Options = { autoFetch: true }
) => {
  const { autoFetch } = options;
  const { dispatch, useSelector } = useRedux();
  const hasNotFetched = useSelector(hasNotFetchedSelector);

  useEffect(() => {
    if (!!autoFetch && hasNotFetched) dispatch(someFetchActionCreator());
  }, [dispatch, hasNotFetched, autoFetch, someFetchActionCreator]);
};

export default useAutoFetch;
