import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import {
  CustomFormControlLabel,
  CustomTypography,
} from 'styling/generalStyling';

type Props = {
  setValue: (value: any, input: string) => void;
  value: boolean;
};

export default function EditNumberOfEmployeeesForm({ value, setValue }: Props) {
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="mentoring-radio-buttons-group-label"
        defaultValue={value}
        name="radio-buttons-group"
    
      >
        <CustomFormControlLabel
          value="me"
          onClick={() => setValue('me', 'number_of_employees')}
          control={<Radio />}
          label={<CustomTypography>Just Me</CustomTypography>}
        />
        <CustomFormControlLabel
          value="2_5"
          onClick={() => setValue('2_5', 'number_of_employees')}
          control={<Radio />}
          label={<CustomTypography>2-5 Employees</CustomTypography>}
        />
        <CustomFormControlLabel
          value="6_9"
          onClick={() => setValue('6_9', 'number_of_employees')}
          control={<Radio />}
          label={<CustomTypography>6-9 Employees</CustomTypography>}
        />
        <CustomFormControlLabel
          value="10_49"
          onClick={() => setValue('10_49', 'number_of_employees')}
          control={<Radio />}
          label={<CustomTypography>10-49 Employees</CustomTypography>}
        />
        <CustomFormControlLabel
          value="50_249"
          onClick={() => setValue('50_249', 'number_of_employees')}
          control={<Radio />}
          label={<CustomTypography>50-249 Employees</CustomTypography>}
        />
        <CustomFormControlLabel
          value="other"
          onClick={() => setValue('50_249', 'number_of_employees')}
          control={<Radio />}
          label={<CustomTypography>Other</CustomTypography>}
        />
      </RadioGroup>
    </FormControl>
  );
}
