import { useEffect } from 'react';
import { useAxios } from '../api/config';
import Cookie from 'js-cookie';
import { GetInterests } from '../types';

type Props = {
  interestSkillId: number | string | null;
};

const useGetInterests = ({ interestSkillId }: Props) => {
  const headers = { Authorization: `Bearer ${Cookie.get('accessOrgTokenDB')}` };

  const [{ data, loading, error }, executeCall] = useAxios<GetInterests[]>(
    {
      url: `api/taxonomy/relatedtaxonomy/suggestions/?interest=${interestSkillId}`,
      method: 'GET',
      headers: headers,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (!!interestSkillId) executeCall();
  }, [executeCall, interestSkillId]);

  return {
    interests: data,
    isLoadingInterests: loading,
    interestsError: error,
  };
};

export default useGetInterests;
