import { useEffect, useCallback } from 'react';
import useRedux from '../../../hooks/useRedux';
import {
  fetchMessagesAction,
  sendMessageAction,
  blockMessagesAction,
  selectMessages,
  selectIsLoadingMessages,
  selectHasFinishedLoadingMessages,
  selectHasNotFetchedMessages,
  selectMessagesError,
  selectIsLoadingSendMessage,
  selectHasFinishedLoadingSendMessage,
  selectSendMessageError,
  clearMessages as clearMessagesAction,
  markMessagesReadAction,
  selectIsLoadingBlockMessages,
  selectBlockedByOrganisation,
  selectBlockedByVolunteer,
  selectBlockMessagesError,
} from '../index';
import { SendMessageProps } from '../../../api/messages';

type Props = {
  boostCallEventId: number;
};

const useMessages = ({ boostCallEventId }: Props) => {
  /* --- HOOKS --- */
  const { useSelector, dispatch } = useRedux();

  /* --- ACTIONS --- */
  // fetchMessages
  const fetchMessages = useCallback(
    () => dispatch(fetchMessagesAction({ boostCallEventId })),
    [boostCallEventId, dispatch]
  );

  // markMessagesRead
  const markMessagesRead = useCallback(
    () => dispatch(markMessagesReadAction({ boostCallEventId })),
    [boostCallEventId, dispatch]
  );
  const refetchAndMarkRead = useCallback(async () => {
    await fetchMessages();
    markMessagesRead();
  }, [fetchMessages, markMessagesRead]);

  // sendMessage
  const sendMessage = ({ text }: SendMessageProps) =>
    dispatch(sendMessageAction({ text, boostCallEventId }));

  const clearMessages = () => dispatch(clearMessagesAction());

  /* --- SELECTORS --- */
  // fetchMessages
  const messages = useSelector(selectMessages);
  const hasNotFetchedMessages = useSelector(selectHasNotFetchedMessages);
  const isLoadingMessages = useSelector(selectIsLoadingMessages);
  const hasFinishedLoadingMessages = useSelector(
    selectHasFinishedLoadingMessages
  );
  const messagesError = useSelector(selectMessagesError);

  // sendMessage
  const isLoadingSendMessage = useSelector(selectIsLoadingSendMessage);
  const hasFinishedLoadingSendMessage = useSelector(
    selectHasFinishedLoadingSendMessage
  );
  const sendMessageError = useSelector(selectSendMessageError);

  // blockMessages
  const isLoadingBlockMessages = useSelector(selectIsLoadingBlockMessages);
  const blockMessagesError = useSelector(selectBlockMessagesError);
  const blockedByOrganisation = useSelector((state) =>
    selectBlockedByOrganisation(state, boostCallEventId)
  );
  const blockedByVolunteer = useSelector((state) =>
    selectBlockedByVolunteer(state, boostCallEventId)
  );
  const blockMessages = () =>
    dispatch(
      blockMessagesAction({ boostCallEventId, blocked: !blockedByOrganisation })
    );

  /* --- AUTO-FETCH --- */
  useEffect(() => {
    (async () => {
      await refetchAndMarkRead();
    })();
  }, [fetchMessages, markMessagesRead, refetchAndMarkRead]);

  return {
    /* --- fetchMessage --- */
    fetchMessages,
    refetchAndMarkRead,
    messages,
    isLoadingMessages,
    hasFinishedLoadingMessages,
    hasNotFetchedMessages,
    messagesError,
    clearMessages,
    /* --- sendMessage --- */
    sendMessage,
    isLoadingSendMessage,
    hasFinishedLoadingSendMessage,
    sendMessageError,
    /* --- markMessagesRead --- */
    markMessagesRead,
    /* --- blockMessages --- */
    blockMessages,
    isLoadingBlockMessages,
    blockedByOrganisation,
    blockedByVolunteer,
    blockMessagesError,
  };
};

export default useMessages;
