import React from 'react';
import styles from './full-screen-loading.module.scss';

const FullScreenLoading = () => {
  return (
    <div className={styles.container}>
      <img src={'/loading.gif'} alt="loading" />
    </div>
  );
};

export default FullScreenLoading;
