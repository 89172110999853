import React from 'react';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ThumbUpOffAlt from '@mui/icons-material/ThumbUpOffAlt';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { PersonOutlined } from '@mui/icons-material';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
export const tabs = [
  {
    label: 'Dashboard',
    link: 'actions',
  },
  {
    label: 'Mentoring',
    link: 'mentoring',
  },
  {
    label: 'Events',
    link: 'events',
  },
  {
    label: 'My Action Plans',
    link: 'actionplan',
  },
  {
    label: 'Recommendations',
    link: 'recommendations',
  },
  {
    label: 'Resources',
    link: 'resources',
  },

  {
    label: 'My Progress',
    link: 'your-progress',
  },
  {
    label: 'Preferences',
    link: 'preferences',
  },
];

export const displayIcon = (label: string) => {
  if (label === 'Dashboard') return <HomeOutlinedIcon />;
  else if (label === 'Mentoring') return <PeopleAltOutlinedIcon />;
  else if (label === 'Recommendations') return <ThumbUpOffAlt />;
  else if (label === 'Account') return <PersonOutlined />;
  else if (label === 'My Action Plans') return <AssignmentOutlinedIcon />;
  else if (label === 'Preferences') return <TuneOutlinedIcon />;
  else if (label === 'Logout') return <FileUploadOutlinedIcon />;
  else if (label === 'My Progress') return <WorkspacePremiumIcon />;
  else if (label === 'Events') return <EmojiEventsOutlinedIcon />;
  else if (label === 'Resources') return <MenuBookOutlinedIcon />;
  else return null;
};

export const bookingsBadge = (upcomingCallsAndGroupEvents: any[]) => (
  <span> ({upcomingCallsAndGroupEvents.length})</span>
);
