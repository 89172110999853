import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Popover } from '@mui/material';
import { CustomTimeLabel } from 'pages/Dashboard/styling';
import { AvailabilityActionPlanButton } from 'styling/generalStyling';
import useWindowSize from 'hooks/useWindowSize';
import './style.scss';

type Props = {
  setNewDate: Dispatch<SetStateAction<string | undefined>>;
  date?: string;
};

const SelectAvailabilityTask = ({ setNewDate, date }: Props) => {
  const [calendarDay, setCalendarDay] = useState<any>(
    date ? new Date(date) : null
  );
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;
  useEffect(() => {});
  const setDate = (date: any, save: string) => {
    if (save === 'calendar') {
      setCalendarDay(date);
      setNewDate(date);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={{ width: isMobile ? '240px' : '190px', height: '50px' }}>
      <AvailabilityActionPlanButton
        sx={{
          border: '1px solid #E8E8E8',
          height: '50px',
          color: '#333',
          justifyContent: 'flex-start',
          display: 'inline-flex',
        }}
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
      >
        {calendarDay ? (
          calendarDay.toString().slice(0, 10)
        ) : (
          <CustomTimeLabel
            sx={{
              textAlign: 'left',
              width: '100%',
              fontFamily: 'Gilroy',
              fontSize: '15px',
              color: '#A3A3A3',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '120%',
              letterSpacing: ' 0.15px',
            }}
          >
            Select date
          </CustomTimeLabel>
        )}
      </AvailabilityActionPlanButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DatePicker
          dateFormat="Pp"
          selected={calendarDay}
          onChange={(date) => {
            setDate(date, 'calendar');
            handleClose();
          }}
          minDate={new Date(moment().toDate())}
          disabledKeyboardNavigation
          inline
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div>
              <div className="calendar-date-task">
                {moment(date).format('MMMM YYYY')}
              </div>
              <Grid>
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <IoIosArrowBack size={14} />
                </button>
                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <IoIosArrowForward size={14} />
                </button>
              </Grid>
            </div>
          )}
        />
      </Popover>
    </div>
  );
};

export default SelectAvailabilityTask;
