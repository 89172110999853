import { useEffect } from 'react';
import { useAxios } from '../../api/config';
import Cookie from 'js-cookie';
import { FollowUpRequest } from '../../types';

type Props = {
  followUpId: number | string | null;
};

const useGetFollowUpRequest = ({ followUpId }: Props) => {
  const headers = { Authorization: `Bearer ${Cookie.get('accessOrgTokenDB')}` };

  const [{ data, loading, error }, executeCall] = useAxios<FollowUpRequest>(
    {
      url: `api/netpromoterscore/followuprequest/${followUpId}/`,
      method: 'GET',
      headers: headers,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (!!followUpId) executeCall();
  }, [executeCall, followUpId]);

  return {
    followUpRequest: data,
    isLoadingFollowUpRequest: loading,
    hasFinishedLoadingFollowUpRequest: !loading,
    followUpRequestError: error,
    refetchFollowUpRequest: executeCall,
  };
};

export default useGetFollowUpRequest;
