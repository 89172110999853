import React, { useState, useCallback, useEffect } from 'react';
import { Card } from './Card';
import update from 'immutability-helper';
import { List } from 'immutable';
import axios from 'axios';
import Cookie from 'js-cookie';
import LockedMetric from '../../locked-component';

const apiUrl = process.env.REACT_APP_API_URL;
const token = Cookie.get('accessPartnerTokenDB');

const style = {
  width: '100%',
  marginTop: '10px',
};

export interface Item {
  id: number;
  text: string;
  metricType: string;
  locked: boolean;
  allowDragAndDrop: boolean;
}

export interface ContainerState {
  cards: Item[];
}

interface Props {
  parentCallback: (items: Item[]) => void;
}

export const Container: React.FC<Props> = ({ parentCallback }) => {
  const [cards, setCards] = useState<Item[]>([]);
  //const [allowOrderMetrics, setAllowOrderMetrics] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      getRegionMetricsConfig();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRegionMetricsConfig = () => {
    let allowOrderMetrics = false;
    axios
      .get(apiUrl + `api/partner/partneradmins/`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response: any) => {
        response.data.results.forEach((item: any) => {
          //get partner
          axios
            .get(apiUrl + `api/partner/partners/${item.partner}/`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((responsePartner: any) => {
              allowOrderMetrics = responsePartner.data.allow_order_metrics;

              axios
                .get(apiUrl + `api/partner/partneradminsregionmetrics/`, {
                  headers: { Authorization: `Bearer ${token}` },
                })
                .then((response: any) => {
                  let immutableList: List<any> = List(response.data.results);
                  let orderedData = immutableList
                    .filter((x) => !x.hide)
                    .sortBy((i) => i.order);
                  let objectToRender = orderedData.map((item) => {
                    return {
                      id: item.id,
                      text: item.region_title,
                      metricType: item.type,
                      locked: item.locked,
                      allowDragAndDrop: allowOrderMetrics,
                    };
                  });
                  if (objectToRender) {
                    setCards(objectToRender.toJS() as Item[]);
                  }
                })
                .catch((err: any) => console.log(err));
            })
            .catch((err: any) => console.log(err));
        });
      })
      .catch((err: any) => console.log(err));
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
      parentCallback(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [cards, parentCallback]
  );
  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    metricId: number
  ) => {
    var data = cards;
    var index = data.findIndex(function (c) {
      return c.id === metricId;
    });

    var updatedModel = update(data[index], {
      metricType: { $set: e.target.value },
    });

    var newData = update(data, {
      $splice: [[index, 1, updatedModel]],
    });

    parentCallback(newData);
  };

  const renderCard = (
    card: {
      id: number;
      text: string;
      metricType: string;
      locked: boolean;
      allowDragAndDrop: boolean;
    },
    index: number
  ) => {
    if (card.locked)
      return <LockedMetric key={card.id} label={card.text} content="" />;
    else
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          metricType={card.metricType}
          moveCard={moveCard}
          handleOnChange={handleChange}
          locked={false}
          allowDragAndDrop={card.allowDragAndDrop}
        />
      );
  };

  return (
    <>
      <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
    </>
  );
};
