import { useAxios } from '../../api/config';
import { getAccessTokenCookie } from '../../utils/auth';
import { GroupEvents, GroupEvent } from '../../types';
import { AxiosRequestHeaders } from 'axios';

type Props = {
  code?: string;
  id?: number;
  eventStatus?: 'scheduled' | 'complete' | 'cancelled';
  userStatus?: 'registered' | 'invited' | 'other';
  interests?: (string | number)[];
  partnerId?: number | null;
};

const useGetGroupEvents = ({
  code,
  interests,
  eventStatus = 'scheduled',
  userStatus = 'registered',
  partnerId = null,
}: Props) => {
  const loggedIn = !!getAccessTokenCookie();
  const headers: AxiosRequestHeaders = loggedIn
    ? { Authorization: `Bearer ${getAccessTokenCookie()}` }
    : {};

  const userStatusParam =
    !getAccessTokenCookie() || !!code
      ? {}
      : userStatus === 'other'
      ? { organisation_registered: 'False' }
      : userStatus === 'invited'
      ? { organisation_invited: 'True' }
      : { organisation_registered: 'True' }; // default to events user is registered for

  const [{ data, loading, error }, refetch] = useAxios<
    GroupEvents | GroupEvent
  >({
    url: `/api/groupevent/groupevent${code ? `/${code}` : ''}${
      !loggedIn ? `/public` : ''
    }/${partnerId ? `?partner=${partnerId}` : ''}`,
    headers,
    params: {
      limit: 100,
      ...userStatusParam,
      status: eventStatus,
      code,
      interests: interests,
    },
  });

  return {
    groupEventResultsData: (data as GroupEvents)?.results || [], // an Array of GroupEvents (if no 'code' provided)
    groupEventData: (data as GroupEvent) || {}, // a single GroupEvent (if 'code' is provided)
    hasFinishedLoadingGroupEvents: !loading,
    groupEventError: error,
    refetchGroupEvent: refetch,
  };
};

export default useGetGroupEvents;
