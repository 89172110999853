import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import PreferenesInfo from './PreferenesInfo';
import './style/index.css';
import { useNavigate } from 'react-router-dom';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import { CustomHeaderTypography } from 'styling/generalStyling';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import SettingsAccessibilityOutlinedIcon from '@mui/icons-material/SettingsAccessibilityOutlined';
import { Tab, TabsList } from 'styling/tabs';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string;
}
let display = 0;
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  if (value === 'learning') display = 0;
  else if (value === 'language') display = 1;
  else if (value === 'mentor') display = 2;
  return (
    <div
      role="tabpanel"
      hidden={display !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    ></div>
  );
}
const Preferences = ({ tab }: { tab: string }) => {
  let history = useNavigate();

  const tabs = [
    {
      label: 'Learning Topics',
      link: 'learning',
    },
    {
      label: 'Language Preferences',
      link: 'language',
    },
    {
      label: 'Matching Preferences',
      link: 'matching',
    },

  ];

  const [value, setValue] = React.useState(tab);
  const displayIcon = (label: string) => {
    if (label === 'Learning Topics') return <HistoryEduOutlinedIcon />;
    else if (label === 'Language Preferences') return <TranslateOutlinedIcon />;
    else if (label === 'Matching Preferences')
      return <SettingsAccessibilityOutlinedIcon />;
   
    else return null;
  };
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    history(`/preferences/${newValue}`, { replace: true });
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  return (
    <div className="mentoring-preferences-page">
      <div>
        <CustomHeaderTypography sx={{ width: '350px' }}>
          Mentoring Preferences
        </CustomHeaderTypography>
        <TabsUnstyled
          value={value}
          onChange={handleChange}
          className="container__option-tabs"
        >
          <TabsList>
            {tabs.map((tab) => (
              <Tab
                key={tab.link}
                sx={{ fontFamily: 'Gilroy', fontSize: '16px' }}
                value={tab.link}
                {...a11yProps(0)}
              >
                <div className="preferences-tab-icon-label" key={tab.link}>
                  {displayIcon(tab.label)} {tab.label}
                </div>
              </Tab>
            ))}
          </TabsList>
          <TabPanel value={value} index={0} key={0} />
          <TabPanel value={value} index={1} key={1} />
          <TabPanel value={value} index={2} key={2} />
          
        </TabsUnstyled>
      </div>
      <div className="container__info">
        <PreferenesInfo tab={value} />
      </div>
    </div>
  );
};
export default Preferences;
