import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCalendarTimes } from 'react-icons/fa';
import FullScreenLoading from '../../components/FullScreenLoading';
import useGetBoostcallEvent from '../../apiHooks/boostCalls/useGetBoostCallEvent';
import useCancelBoostCallEvent from '../../features/boostCallEvents/hooks/useCancelBoostCallEvent';
import { getUrlParam } from '../../utils/url';
import './cancel-call.scss';
import { DashboardFilledButton } from 'styling/buttons';

const CancelCall = () => {
  const navigate = useNavigate();
  const id = Number(getUrlParam('id') as string);

  // apiHooks
  const { boostCallEvent, isLoadingBoostCallEvent } = useGetBoostcallEvent({
    id,
  });
  const { status: callStatus } = boostCallEvent || {};
  const {
    cancelBoostCall,
    hasCancelledBoostcall,
    cancelBoostCallError,
    hasFinishedLoadingCancellationReasons,
    setReasonText,
    cancellationReasons,
    selectedReasonId,
    setSelectedReasonId,
  } = useCancelBoostCallEvent({ id });

  if (isLoadingBoostCallEvent || !hasFinishedLoadingCancellationReasons) {
    return <FullScreenLoading />;
  }

  if (callStatus === 'cancelled' || callStatus === 'expired') {
    return (
      <div className="cancel-call__outer_container">
        <div className="cancel-call_inner-container">
          <h2 className="cancel-call__title">Thank you</h2>
          <h3 className="cancel-call__subtitle">
            We confirm we have received your cancellation request and that the
            meeting will no longer go ahead.
          </h3>
          <div className="cancel-call__calendar-text-container">
            <FaCalendarTimes size={25} />
            <p className="cancel-call__calendar-text">
              Please make sure you remove the meeting from your calendar.
            </p>
          </div>
          <br />
          <br />
          <div className="options-buttons">
            <DashboardFilledButton
              variant="contained"
              sx={{ width: '250px', height: '50px' }}
              onClick={() => {
                navigate('/dashboard/actions');
              }}
            >
              Back to Dashboard
            </DashboardFilledButton>
          </div>
        </div>
      </div>
    );
  }

  if (hasCancelledBoostcall) {
    return (
      <div className="cancel-call__outer_container">
        <div className="cancel-call_inner-container">
          <h2 className="cancel-call__title">
            You have successfully cancelled the session
          </h2>
          <div className="cancel-call__success-separator" />
          <div className="cancel-call__success-container">
            <div className="thank-you">
              <p className="cancel-call__success-text">
                We have sent an email notifying the volunteer.
              </p>
              <div className="cancel-call__calendar-text-container">
                <FaCalendarTimes size={25} />
                <p className="cancel-call__calendar-text">
                  Please make sure you remove the meeting from your calendar.
                </p>
              </div>
              <br />
              <br />
              <div className="options-buttons">
                <DashboardFilledButton
                  variant="contained"
                  sx={{
                    borderRadius: '38px',
                    width: '195px',
                    alignSelf: 'center',
                  }}
                  onClick={() => {
                    navigate('/dashboard/actions');
                  }}
                >
                  Back to Dashboard
                </DashboardFilledButton>
                <DashboardFilledButton
                  variant="contained"
                  sx={{
                    borderRadius: '38px',
                    width: '195px',
                    alignSelf: 'center',
                  }}
                  onClick={() => {
                    navigate('/account');
                  }}
                >
                  Mentoring Preferences
                </DashboardFilledButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="cancel-call__outer_container">
        <div className="cancel-call_inner-container">
          <h2 className="cancel-call__title">We get that life happens.</h2>
          <div className="cancel-call__container">
            <div className="thank-you">
              <h3 className="cancel-call__subtitle">
                Please let the volunteer know why you're cancelling.
              </h3>
              <br />
              <br />
              <div className="options-buttons">
                {cancellationReasons.map((reason, i) => {
                  return (
                    <label key={`reason-${i}`}>
                      <input
                        type="radio"
                        value={reason.id}
                        checked={selectedReasonId === reason.id}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setSelectedReasonId(reason.id)
                        }
                      />
                      <span>{reason.description}</span>
                    </label>
                  );
                })}
                <p>
                  Feel free to leave a message for the volunteer.{' '}
                  <span className="cancel-call__reason-text">[optional]</span>
                </p>
                <textarea
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setReasonText(e.target.value)
                  }
                />
                {cancelBoostCallError && (
                  <p className="cancel-call__reason-text-error">
                    {cancelBoostCallError}
                  </p>
                )}
                <DashboardFilledButton
                  variant="contained"
                  sx={{
                    width: '150px',
                    marginTop: '20px',
                    alignSelf: 'flex-end',
                  }}
                  onClick={cancelBoostCall}
                >
                  Cancel Session
                </DashboardFilledButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CancelCall;
