import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const CustomTypography = styled(Typography)({
  width: '149px',
  height: '30px',
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontWeight: 'bold',
  textTransform: 'none',
});

export const TimeSlotButton = styled(Button)({
  margin: '4px 8px 0 0',
  border: 'solid 1px #777',
  fontFamily: 'Gilroy',
  padding: ' 12px',
  borderRadius: '8px',
  textTransform: 'none',
  width: '129px',
  height: '38px',
  fontSize: '14px',
  textAlign: 'center',
  color: '#414141',
});
