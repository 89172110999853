import React from 'react';
import { MentorModalWrapperButton } from '../MentorCard/MentorModal';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { displayTime } from '../../utils/time';
import { VolProfile, OrgProfile } from '../../types';
import './message.scss';
import { defaultTimeFormat } from '../../constants';

type Props = {
  text: string;
  created: string;
  user: VolProfile | OrgProfile;
  className?: string;
  modalProfile?: VolProfile;
};

const Message = ({ text, created, user, modalProfile, className }: Props) => {
  const { userLocation } = useSelectProfileData();
  const { timezone } = userLocation || {};
  const { profile_picture, first_name } = user;

  const createdTime =
    timezone &&
    `${displayTime(created, timezone, 'DD MMMM')} at ${displayTime(
      created,
      timezone,
      defaultTimeFormat
    )}`;

  return (
    <div className={`message__container ${className ? className : ''}`}>
      <div className="message__profile-wrapper">
        {modalProfile ? (
          <MentorModalWrapperButton mentor={modalProfile}>
            <img
              src={profile_picture || './avatar.png'}
              className="message__profile-picture message__profile-picture--shadow"
              alt="user"
            />
          </MentorModalWrapperButton>
        ) : (
          <img
            src={profile_picture || './avatar.png'}
            className="message__profile-picture"
            alt="user"
          />
        )}
        <div className="message__profile-text-wrapper">
          {modalProfile ? (
            <MentorModalWrapperButton mentor={modalProfile}>
              <span className="message__name">{first_name}</span>
            </MentorModalWrapperButton>
          ) : (
            <span className="message__name">{first_name}</span>
          )}

          <span className="message__date">{createdTime}</span>
        </div>
      </div>
      <div className="message__body-wrapper">
        <div className="message__text-container">{text}</div>
      </div>
    </div>
  );
};

export default Message;
