import { useEffect, useState } from 'react';
import { useAxios } from '../api/config';

const useGetNews = () => {
  const [news, setNews] = useState<any>([]);

  const [{ data, loading }] = useAxios({
    url: 'api/core/news/get/',
    method: 'GET',
  });

  useEffect(() => {
    if (!loading) {
      const { results = [] } = data ? data : [];
      setNews(results);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return {
    news,
    setNews,
    hasFinishedLoadingNews: loading,
  };
};

export default useGetNews;
