import React from 'react';
import './footer.scss';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import Logo from '../Nav/Logo';

const Footer = () => {
  const d = new Date();
  const year = d.getFullYear();
  const { partner, organisationProfile } = useSelectProfileData();
  const url = new URL(window.location.href);
  const forVolunteerUrl = url.hostname.replace('organisation', 'volunteer');
  const signUpLink = url.hostname + '/signup';
  const partnerInfo = partner ? partner : organisationProfile?.partnerInfo;
  const linkedIn =
    partnerInfo?.white_label &&
    partnerInfo?.use_partner_details &&
    partnerInfo?.linkedin
      ? partnerInfo.linkedin
      : 'https://www.linkedin.com/company/digitalboost-org';

  const twitter =
    partnerInfo?.white_label &&
    partnerInfo?.use_partner_details &&
    partnerInfo?.twitter
      ? partnerInfo.twitter
      : 'https://twitter.com/digitalboost_uk';

  const instagram =
    partnerInfo?.white_label &&
    partnerInfo?.use_partner_details &&
    partnerInfo?.instagram
      ? partnerInfo.instagram
      : 'https://instagram.com/digitalboost.org.uk';
  const facebook =
    partnerInfo?.white_label &&
    partnerInfo?.use_partner_details &&
    partnerInfo?.facebook
      ? partnerInfo.facebook
      : 'https://www.facebook.com/digitalboost.org.uk';
  const contact_us = partnerInfo?.contact_us
    ? partnerInfo.contact_us
    : 'https://digitalboost.org.uk/get-in-touch';
  const about_us = partnerInfo?.about_us
    ? partnerInfo.about_us
    : 'https://www.digitalboost.org.uk/about-us/about-us';
  const faq = partnerInfo?.faq
    ? partnerInfo.faq
    : 'https://digitalboost.org.uk/frequently-asked-questions-mentee-platform/';
  const our_partners = partnerInfo?.our_partners
    ? partnerInfo.our_partners
    : 'https://www.digitalboost.org.uk/partners';
  const our_blog = partnerInfo?.our_blog
    ? partnerInfo.our_blog
    : 'https://digitalboost.org.uk/blog/';
  const company_registration_info = partnerInfo?.company_registration_info ? (
    partnerInfo.company_registration_info
  ) : (
    <div>
      Digital Boost Upskilling, Ltd is a subsidiary of Founders4Schools <br />{' '}
      (registered charity number 1162197)
    </div>
  );
  return (
    <footer
      id="footer"
      className="footer"
      //to be done later if partners require customised whitelabel footer
      // style={{
      //   backgroundColor: `${
      //     partner ? theme.palette.secondary.main : '#166b7a'
      //   }`,
      // }}
    >
      <div className="container-8 w-container">
        <div className="column-footer-main w-row">
          <div className="column-footer w-col w-col-3 w-col-medium-3 w-col-small-small-stack">
            <div className="div-block-6 bottom-logo">
              <Logo footer={true} />
              <div className="text-block-12">
                Copyright {year} - All rights reserved
              </div>
            </div>
          </div>
          <div className="column-footer w-col w-col-3 w-col-medium-3 w-col-small-small-stack">
            <div className="div-block-6">
              <a
                data-gatrack="footer-volunteer-learnmore,clicked"
                href={`https://${forVolunteerUrl}`}
                className="footer-link-1"
                target="_blank"
                rel="noreferrer"
              >
                For Volunteers
              </a>
              <a
                data-gatrack="footer-aboutus,clicked"
                href={about_us}
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                About Us
              </a>
              <a
                data-gatrack="footer-signuppage,clicked"
                href={`https://${signUpLink}`}
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                Sign Up
              </a>
              <a
                data-gatrack="footer-partners,clicked"
                href={our_partners}
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                Our Partners
              </a>
              <a
                data-gatrack="footer-partners,clicked"
                href={faq}
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                Frequently Asked Questions
              </a>
            </div>
          </div>
          <div className="column-footer w-col w-col-3 w-col-medium-3 w-col-small-small-stack">
            <div className="div-block-6">
              <a
                data-gatrack="footer-faqs,clicked"
                href={our_blog}
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                Our Blog
              </a>
              <a
                data-gatrack="footer-contactus,clicked"
                href={contact_us}
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                Contact Us
              </a>
              <a
                data-gatrack="footer-privacypolicy,clicked"
                href="https://www.digitalboost.org.uk/legals/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                Privacy Policy
              </a>
              <a
                data-gatrack="footer-termsofuse,clicked"
                href="https://digitalboost.org.uk/terms-conditions"
                target="_blank"
                rel="noreferrer"
                className="footer-link-1"
              >
                Terms of use
              </a>
            </div>
          </div>
          <div className="column-footer w-col w-col-3 w-col-medium-3 w-col-small-small-stack">
            <div className="div-block-6">
              {(!partnerInfo?.white_label ||
                !partnerInfo?.use_partner_details ||
                (partnerInfo?.white_label &&
                  partnerInfo?.use_partner_details &&
                  partnerInfo.linkedin)) && (
                <a
                  data-gatrack="footer-linkedin,clicked"
                  href={linkedIn}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link-1 nolink"
                >
                  LinkedIn
                </a>
              )}
              {(!partnerInfo?.white_label ||
                !partnerInfo?.use_partner_details ||
                (partnerInfo?.white_label &&
                  partnerInfo?.use_partner_details &&
                  partnerInfo.instagram)) && (
                <a
                  data-gatrack="footer-instagram,clicked"
                  href={instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link-1 nolink"
                >
                  Instagram
                </a>
              )}
              {(!partnerInfo?.white_label ||
                !partnerInfo?.use_partner_details ||
                (partnerInfo?.white_label &&
                  partnerInfo?.use_partner_details &&
                  partnerInfo.facebook)) && (
                <a
                  data-gatrack="footer-facebook,clicked"
                  href={facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link-1 nolink"
                >
                  Facebook
                </a>
              )}
              {(!partnerInfo?.white_label ||
                !partnerInfo?.use_partner_details ||
                (partnerInfo?.white_label &&
                  partnerInfo?.use_partner_details &&
                  partnerInfo.twitter)) && (
                <a
                  data-gatrack="footer-twitter,clicked"
                  href={twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link-1 nolink"
                >
                  Twitter
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="charity">
          <p className="ko">{company_registration_info}</p>
          <p className="ko">{`Version ${process.env.REACT_APP_VERSION}`}</p>
        </div>
        <div className="div-block-27"></div>
      </div>
    </footer>
  );
};

export default Footer;
