import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { logout } from '../auth';
import type { RootState } from '../../store';
import type { UserProfile } from '../../types';

/* --- SLICE --- */

interface UserProfileState {
  status: 'init' | 'pending' | 'fulfilled' | 'rejected';
  profile?: UserProfile | null;
  error?: string | null;
}

const initialState = {
  status: 'init',
  profile: null,
  error: null,
} as UserProfileState;

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    clearUserProfile: (state) => {
      return initialState;
    },
    setUserProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // logout
      .addCase(logout, () => initialState)
      // fetchUserProfile
      .addCase(fetchUserProfileAction.pending, (state, action) => {
        state.status = action.meta.requestStatus;
        state.error = null;
      })
      .addCase(fetchUserProfileAction.fulfilled, (state, action) => {
        state.status = action.meta.requestStatus;
        state.profile = action.payload;
      })
      .addCase(fetchUserProfileAction.rejected, (state, action) => {
        const { error } = action;
        state.error = error.message;
        state.status = action.meta.requestStatus;
      });
  },
});

/* --- SELECTORS --- */

// fetchUserProfile
export const selectUserProfile = (state: RootState) =>
  state.userProfile.profile;
export const selectUserProfileStatus = (state: RootState) =>
  state.userProfile.status;
export const selectHasNotFetchedUserProfile = (state: RootState) =>
  selectUserProfileStatus(state) === 'init';
export const selectHasFinishedLoadingUserProfile = (state: RootState) =>
  selectUserProfileStatus(state) === 'fulfilled' ||
  selectUserProfileStatus(state) === 'rejected';

/* --- ACTIONS --- */

export const { clearUserProfile, setUserProfile } = userProfileSlice.actions;

/* --- THUNKS --- */

// fetchUserProfile
export const fetchUserProfileAction = createAsyncThunk(
  `${userProfileSlice.name}/fetchUserProfile`,
  async (arg, thunkAPI) => {
    const response = await api.getUserProfile();
    return response;
  }
);

export default userProfileSlice.reducer;
