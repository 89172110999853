import apiInstance from './config';
import { AxiosPromise } from 'axios';
import { BoostCallEventWithVolunteerProfile } from '../types';

export const getBoostCallEvents = async () => {
  const response = await apiInstance.get<{
    count: number;
    results: BoostCallEventWithVolunteerProfile[];
    next: string;
    previous: string;
  }>(
    `api/event/boostcallevents/?status=complete&status=scheduled&status=cancelled&limit=100`
  );
  return response;
};

export type CancelBoostCallProps = {
  id: number;
  cancellationReasonId: number;
  message?: string;
};

export const cancelBoostCallEvent = async ({
  id,
  cancellationReasonId,
  message,
}: CancelBoostCallProps) => {
  const response = await apiInstance({
    method: 'DELETE',
    url: `api/event/boostcallevents/${id}/delete/`,
    data: {
      cancellation_reason_id: cancellationReasonId,
      message,
    },
  });

  return response;
};

type CancelRequestParams = {
  type: string;
  id: number;
  setErrorMessage: (arg: string) => void;
  onSuccess: () => void;
};

export const cancelRequest = ({
  type,
  id,
  setErrorMessage,
  onSuccess,
}: CancelRequestParams) => {
  setErrorMessage('');
  apiInstance
    .delete(`api/event/boostcall${type}/${id}/`)
    .then((response) => {
      if (response.data && response.data.non_field_errors) {
        setErrorMessage(response.data[Object.keys(response.data)[0]]);
      } else {
        onSuccess();
      }
    })
    .catch((err) => {
      setErrorMessage(err.response.data[Object.keys(err.response.data)[0]]);
    });
};

export type ProposeRescheduleRequestProps = {
  id: number;
  newStartDateTime: string;
  newCallUrl?: string;
};

export const proposeRescheduleRequest = ({
  id,
  newStartDateTime,
  newCallUrl,
}: ProposeRescheduleRequestProps): AxiosPromise<BoostCallEventWithVolunteerProfile> =>
  apiInstance({
    url: `api/event/boostcallevents/${id}/reschedule/`,
    method: 'POST',
    data: {
      new_start_datetime: newStartDateTime,
      ...(newCallUrl ? { new_call_url: newCallUrl } : {}),
    },
  });

export type AcceptRescheduleRequestProps = {
  id: number;
  newStartDateTime: string;
};

export const acceptRescheduleRequest = ({
  id,
  newStartDateTime,
}: AcceptRescheduleRequestProps): AxiosPromise<BoostCallEventWithVolunteerProfile> =>
  apiInstance({
    url: `api/event/boostcallevents/${id}/accept-reschedule/`,
    method: 'POST',
    data: {
      new_start_datetime: newStartDateTime, // used to verify that users are accepting the correct reschedule time
    },
  });

export type DeclineRescheduleRequestProps = {
  id: number;
  cancel: boolean;
  newStartDateTime: string;
  message?: string;
};

export const declineRescheduleRequest = ({
  id,
  cancel,
  newStartDateTime,
  message,
}: DeclineRescheduleRequestProps): AxiosPromise<BoostCallEventWithVolunteerProfile> =>
  apiInstance({
    url: `api/event/boostcallevents/${id}/decline-reschedule/`,
    method: 'POST',
    data: {
      cancel_event: cancel,
      new_start_datetime: newStartDateTime, // used to verify that users are declining the correct reschedule time
      ...(message ? { message } : {}),
    },
  });
