import { useAxios } from '../api/config';
import { Taxonomy, TaxonomyById } from '../types';

type TaxonomyResponse = {
  results: Taxonomy[];
};

type FormattedTaxonomy = {
  [id: number]: {
    id: number;
    name: string;
    description: string;
    child: Taxonomy[];
    parent: number | null;
  };
};

export const formatTaxonomyResponseData = (
  taxonomyResponseData: any
): { [arg: number]: Taxonomy } =>
  taxonomyResponseData &&
  taxonomyResponseData.results.reduce(
    (acc: FormattedTaxonomy, skill: Taxonomy) => {
      if (skill.parent) {
        if (acc[skill.parent]) {
          if (acc[skill.parent].child) {
            const existingChild = acc[skill.parent].child || [];
            return {
              ...acc,
              [skill.parent]: {
                ...acc[skill.parent],
                child: [...existingChild, skill],
              },
            };
          } else {
            return {
              ...acc,
              [skill.parent]: {
                ...acc[skill.parent],
                child: [skill],
              },
            };
          }
        } else {
          acc[skill.parent] = {
            ...acc[skill.parent],
            child: [skill],
          };
          return acc;
        }
      } else {
        if (!acc[skill.id]) {
          acc[skill.id] = {
            ...skill,
            child: [],
          };
        } else {
          acc[skill.id] = {
            ...skill,
            child: acc[skill.id].child,
          };
        }
        return acc;
      }
    },
    {}
  );

//if formatTaxonomyResponseData consider the following, then it may be
//mcessary to re-wtire places that use this hook
// const formatTaxonomyResponseDataReWritten = (
//   taxonomyResponseData: any
// ) => {
//   if (
//     taxonomyResponseData &&
//     taxonomyResponseData.results)
//     {
//       let parents:FormattedTaxonomy[] = taxonomyResponseData.results?.filter((d:any) => d.parent === null);
//       const childs = taxonomyResponseData.results?.filter((d:any) => d.parent !== null);
//       parents = parents.map((i:FormattedTaxonomy) => { return {...i, child:[]}} );

//       parents.forEach((i:any) => {
//         const localChilds = childs.filter((d:any) => d.parent === i.id);
//         i.child = localChilds;
//       })

//       return parents;
//     }
//   return;
// }

export const useGetTaxonomy = (ids?: any[], partnerId?: number | null) => {
  const searchIdString = ids ? ids.map((id: any) => `&id=${id}`).join('') : '';
  const isWhitelabelPartner = partnerId
    ? `api/taxonomy/interests/partner/?partner=${partnerId}&limit=1000${searchIdString}`
    : `api/taxonomy/interests/?limit=1000${searchIdString}`;
  const [{ data, loading, error }, refetch] =
    useAxios<TaxonomyResponse>(isWhitelabelPartner);

  const taxonomyDataFormatted = formatTaxonomyResponseData(data);
  const taxonomyById: TaxonomyById = data?.results.reduce(
    (acc: any, { id, ...restCur }: any) => {
      acc[id] = { ...restCur };
      return acc;
    },
    {}
  );

  return {
    taxonomyData: data?.results,
    taxonomyDataFormatted,
    taxonomyByParentFlat: Object.values(taxonomyDataFormatted || {}),
    taxonomyById,
    hasFinishedLoadingTaxonomy: !loading,
    taxonomyError: error,
    refetchTaxonomy: refetch,
  };
};
