import React, { useState, useEffect, memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VerificationModal from '../VerificationModal';
import Button from '../Button';
import ReactTooltip from 'react-tooltip';
import './login-signup-modal.scss';
import { Helmet } from 'react-helmet';
import { getUrlParam } from 'utils/url';
import useFetchPartner from 'features/partner/hooks/useFetchPartner';
import { Partner } from 'types';
import SignUpForm from './signupForm';
import Cookie from 'js-cookie';
import useFetchPartnerInfo from 'apiHooks/partner/useFetchPartner';
import FullScreenLoading from 'components/FullScreenLoading';
import useFetchSignupOptions from 'apiHooks/partner/useFetchSignupOptions';

type Props = {
  redirect: undefined | string;
};
const SignupModal = ({ redirect }: Props) => {
  const navigate = useNavigate();
  const [partnerInformation, setPartnerInformation] =
    useState<Partner | null>();
  const { pathname } = useLocation();
  const inRequestFlow = pathname === '/select-availability';

  useEffect(() => {
    const loggedInUser = Cookie.get('loggedIn');
    if (loggedInUser === 'true') navigate('/dashboard/actions');
  });
  const [verificationModalOpen, setVerificationModalOpen] =
    useState<boolean>(false);
  const { partner } = useFetchPartner();

  const getPartnerId = () => {
    if (partner?.id) return partner?.id;
    else if (getUrlParam('id')) return parseInt(getUrlParam('id') as string);
    else if (Cookie.get('partner_id'))
      return parseInt(Cookie.get('partner_id') as string);
    else return null;
  };
  let partnerId = getPartnerId();

  const { partner: fetchPartnerResponseData, hasFinishedLoadingPartner } =
    useFetchPartnerInfo(partnerId);

  useEffect(() => {
    if (fetchPartnerResponseData) {
      setPartnerInformation(fetchPartnerResponseData);
    }
  }, [partnerId, fetchPartnerResponseData]);

  const { signupOptions, hasFinishedLoadingSignupOptions } =
    useFetchSignupOptions(partnerId);

  const welcomeText = `Welcome!`;
  const primaryOptionTitle = `Create an account${
    inRequestFlow ? ' to submit your request' : ''
  }`;

  const secondaryOptionTitle = 'Already have an account?';

  const secondaryOptionButtonText = 'Login';
  let titleTag = '';
  let metaDescription = '';

  titleTag = 'Digital Boost Organisation Portal | Sign Up';
  metaDescription = ' Sign Up to Digital Boost Organisation Portal';
  if (hasFinishedLoadingPartner && hasFinishedLoadingSignupOptions)
    return (
      <div className="login-signup-page-container">
        <Helmet>
          <title>{titleTag}</title>
          <meta name="description" content={metaDescription}></meta>
        </Helmet>
        {verificationModalOpen && (
          <VerificationModal verificationModalOpen={verificationModalOpen} />
        )}
        <ReactTooltip place={'top'} />

        <div className="login-signup__container">
          <div
            className="login-signup__primary-container"
            data-cy="sign-up-container"
          >
            <h1 className="login-signup__welcome-text">{welcomeText}</h1>
            <h2>{primaryOptionTitle}</h2>
            <SignUpForm
              partnerId={partnerId}
              setVerificationModalOpen={setVerificationModalOpen}
              partnerInformation={partnerInformation}
              signupOptions={signupOptions}
            />
          </div>
          <div className="login-signup__secondary-container">
            <h3 onClick={() => navigate('/login')}>{secondaryOptionTitle}</h3>
            <Button
              type={'primary'}
              text={secondaryOptionButtonText}
              onClickFunction={() =>
                redirect && redirect?.length > 1
                  ? navigate(`/login?redirect=${redirect}`)
                  : navigate(`/login`)
              }
              dataCy="login-modal-button"
            />
          </div>
        </div>
      </div>
    );
  else return <FullScreenLoading />;
};

export default memo(SignupModal);
