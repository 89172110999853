import { useAxios } from '../../api/config';
import {
  BoostCallEventsTopInterests,
  BoostCallEventsTopInterest,
} from '../../types';

const useGetBoostCallEventsTopInterests = () => {
  const [{ data, loading, error }, refetch] =
    useAxios<BoostCallEventsTopInterests>({
      url: `api/event/boostcallevents/top-priorities/?limit=4`,
    });

  const topInterests = data?.results.sort(
    (a: BoostCallEventsTopInterest, b: BoostCallEventsTopInterest) =>
      b.total - a.total
  );

  return {
    topInterests: topInterests || [],
    hasFinishedLoadingBoostCallEventsTopInterests: !loading,
    boostCallEventsTopInterestsError: error,
    refetchBoostCallEventsTopInterests: refetch,
  };
};

export default useGetBoostCallEventsTopInterests;
