import { useState, useCallback } from 'react';
import { useAxios } from '../../api/config';
import { getErrorMessageFromResponse } from '../../utils/error';

type Props = {
  attendeeId: number | string | undefined;
};

const useCancelGroupEventRegistration = ({ attendeeId }: Props) => {
  const [hasFinishedCancelling, setHasFinishedCancelling] = useState(false);

  const [{ data, loading, error }, executeCall] = useAxios<any>(
    {
      url: `api/groupevent/attendee/${attendeeId}/`,
      method: 'DELETE',
    },
    { manual: true }
  );

  const typedCancelGroupEventRegistration = useCallback(async () => {
    try {
      await executeCall();
      setHasFinishedCancelling(true);
    } catch (err) {
      throw err;
    }
  }, [executeCall]);

  return {
    cancelGroupEventRegistration: typedCancelGroupEventRegistration,
    cancelGroupEventRegistrationData: data,
    isLoadingCancelGroupEventRegistration: loading,
    hasCancelledGroupEventRegistration: hasFinishedCancelling,
    cancelGroupEventRegistrationError: error
      ? getErrorMessageFromResponse(error)
      : null,
  };
};

export default useCancelGroupEventRegistration;
