import React from 'react';
import './button.scss';
import { TailSpin } from 'react-loader-spinner';
import useSelectProfileData from '../../hooks/useSelectProfileData';

export interface Props {
  text?: string;
  icon?: any;
  type?: string;
  onClickFunction?: any;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  loading?: boolean;
  dataCy?: string;
}

const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      text,
      icon,
      type,
      onClickFunction,
      selected,
      disabled,
      className,
      loading,
      dataCy,
      children,
    },
    ref
  ) => {
    const state = loading ? 'loading' : disabled ? 'disabled' : '';
    const isSelected = selected ? 'selected' : '';
    const addtionalClassName = className ? className : '';
    const disabledProp = { disabled: disabled || loading };
    const { partner } = useSelectProfileData();

    let customOverride = {};

    if (partner?.primary_color && type === 'primary') {
      customOverride = {
        background: partner?.primary_color,
        color: 'white',
        borderColor: partner?.primary_color,
      };
    }

    if (partner?.secondary_color && type === 'tertiary') {
      customOverride = {
        background: partner?.secondary_color,
        color: 'white',
        borderColor: partner?.secondary_color,
      };
    }
    return (
      <button
        onClick={(e) => {
          if (!onClickFunction) return;
          e.preventDefault();
          e.stopPropagation();
          onClickFunction();
        }}
        className={`button button-${type} ${state} ${isSelected} ${addtionalClassName}`}
        data-cy={dataCy}
        style={customOverride}
        ref={ref}
        {...disabledProp}
      >
        {loading && (
          <div className={`button__loading-container`}>
            <TailSpin color="#414141" height={20} width={20} />
          </div>
        )}
        {loading ? (
          <div className={`${loading ? 'button__content-hidden' : ''}`}>
            {icon}
            {text}
            {children}
          </div>
        ) : (
          <>
            {icon}
            {text}
            {children}
          </>
        )}
      </button>
    );
  }
);

export default Button;
