import { BoostCallRequest, TimeSlot } from '../types';

// takes BoostCallRequests[] => ProposedTimeSlots[]
export const getProposedTimeSlots = (boostCallRequests?: BoostCallRequest[]) =>
  boostCallRequests
    ?.reduce((acc: any[], cur: BoostCallRequest) => {
      const topPriority = cur.top_priority_name;
      let showAlertAnotherAlreadyAccepted = false;
      if (!cur.allow_multiple_sessions) {
        const has_accepted_any = cur.time_slots.filter(
          (s) => s.status === 'time_slot_scheduled'
        );
        showAlertAnotherAlreadyAccepted = has_accepted_any.length > 0;
      }
      const timeSlotWithTopPriorities = cur.time_slots.map((ts: TimeSlot) => ({
        ...ts,
        topPriority,
        showAlertAnotherAlreadyAccepted,
      }));
      return [...timeSlotWithTopPriorities, ...acc];
    }, [])
    ?.filter((ts) => ts.status === 'time_slot_proposed') || [];
