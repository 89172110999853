import React, { useState, useEffect, useCallback } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import AccountInfo from './AccountInfo';
import './style/index.css';
import { useNavigate } from 'react-router-dom';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import { CustomHeaderTypography } from 'styling/generalStyling';
import { getProfile } from './functions';
import { CircularProgress } from '@mui/material';
import apiInstance from 'api/config';
import { getUrlParam } from '../../utils/url';
import ProfileCompletion from './Components/ProfileCompletion';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import { Tab, TabsList } from 'styling/tabs';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string;
}

let display = 0;
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  if (value === 'security') display = 1;
  else if (value === 'privacy') display = 2;
  else if (value === 'profile') display = 0;
  return (
    <div
      role="tabpanel"
      hidden={display !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    ></div>
  );
}
const Account = ({ tab }: { tab: string }) => {
  let history = useNavigate();

  const [profile, setProfile] = useState<any>({ first_name: '' });
  const { partner } = useSelectProfileData();
  const code = getUrlParam('code');
  const refreshLinkedIn = useCallback(async () => {
    try {
      let sendObject: { code: any; partner_id?: number; source: string } = {
        code,
        source: 'org-add-details',
      };

      if (partner) {
        sendObject = {
          code,
          partner_id: partner.id,
          source: 'org-add-details',
        };
      }

      const linkedInCallBackResult = await apiInstance.post(
        `api/core/linkedin_callback/`,
        sendObject
      );
      if (linkedInCallBackResult) {
        const liProfile = await apiInstance.get(
          `api/core/linkedinprofiles/me/`
        );
        setProfile(liProfile.data);
      }
    } catch (e) {
      console.error(e);
    }
  }, [code, partner]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await getProfile();
      setProfile(data);
      return data;
    };
    if (code) refreshLinkedIn();
    fetchData().catch(console.error);
  }, [code, refreshLinkedIn]);

  const tabs = [
    {
      label: 'Profile',
      link: 'profile',
    },
    {
      label: 'Login and Security',
      link: 'security',
    },
    {
      label: 'Data, Privacy and Communication',
      link: 'privacy',
    },
  ];
  const displayIcon = (label: string) => {
    if (label === 'Profile') return <AccountBoxOutlinedIcon />;
    else if (label === 'Login and Security') return <HttpsOutlinedIcon />;
    else if (label === 'Data, Privacy and Communication')
      return <PolicyOutlinedIcon />;
    else return null;
  };
  const [value, setValue] = React.useState(tab);

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    history(`/account/${newValue}`, { replace: true });
    setValue(newValue);
  };
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  if (profile.first_name.length > 0)
    return (
      <div className="container__account-page">
        <div>
          <CustomHeaderTypography>Account</CustomHeaderTypography>
          <TabsUnstyled
            value={value}
            onChange={handleChange}
            className="container__option-tabs"
          >
            <TabsList>
              {tabs.map((tab) => (
                <Tab
                  key={tab.link}
                  value={tab.link}
                  sx={{ fontFamily: 'Gilroy', fontSize: '16px' }}
                  {...a11yProps(0)}
                >
                  <div className="account-tab__container" key={tab.link}>
                    {displayIcon(tab.label)} {tab.label}
                  </div>
                </Tab>
              ))}
            </TabsList>
            <TabPanel value={value} index={0} key={0} />
            <TabPanel value={value} index={1} key={1} />
            <TabPanel value={value} index={2} key={2} />
          </TabsUnstyled>

          <ProfileCompletion data={profile} />
        </div>
        <div className="container__info">
          <AccountInfo tab={value} data={profile} />
        </div>
      </div>
    );
  else return <CircularProgress />;
};
export default Account;
