import moment from 'moment-timezone';
import { Availability } from '../../types';
import { defaultTimeFormat } from '../constants';
import { ValueToggle, VolProfile } from '../types';

type GetAvailabilityProps = {
  availabilityObj: Availability;
};

export const getMomentWithTimezone = (
  dateString?: string,
  timeZone?: string
) => {
  // uses the Internationalization API in the browser to determine the user's time zone
  const guessedTimeZone = moment.tz.guess();

  return moment(dateString).tz(timeZone || guessedTimeZone);
};

export const displayTime = (
  dateString: string,
  timeZone: string = moment.tz.guess(),
  format: string
): string => getMomentWithTimezone(dateString, timeZone).format(format);

export const parseTimeZoneCode = (date: string, timeZoneString: string) => {
  if (!date) return '';
  const momentDate = getMomentWithTimezone(date, timeZoneString);
  if (!momentDate.isValid()) {
    console.log('Invalid date formatting attempted');
    return null;
  }

  // Timezonecodes without any letters like '-03' are confusing
  // This filters out any that are only numbers
  const timeZoneCode = momentDate.format('z') || '';
  const hasLettersRegex = new RegExp(/.*([A-Z])/);
  const hasLetters = hasLettersRegex.test(timeZoneCode);

  return hasLetters ? timeZoneCode : '';
};

export const timeZoneAbbreviation = (timeZone: string) => {
  const abbr = moment().tz(timeZone).zoneAbbr();
  const hasLettersRegex = new RegExp(/.*([A-Z])/);
  const hasLetters = hasLettersRegex.test(abbr);
  return hasLetters ? abbr : `GMT${abbr}`;
};

export const formatDate = (
  date?: string,
  timeZoneString?: string,
  duration?: number
) => {
  if (!date) return {};
  const momentStartDate = getMomentWithTimezone(date, timeZoneString);
  if (!momentStartDate.isValid()) {
    console.log('Invalid date formatting attempted');
    return null;
  }

  const momentEndDate = getMomentWithTimezone(date, timeZoneString).add(
    duration,
    'minutes'
  );

  const timeZoneCode = timeZoneString
    ? parseTimeZoneCode(date, timeZoneString)
    : '';

  return {
    date: {
      day: momentStartDate.format('D'),
      month: momentStartDate.format('MMMM'),
      year: momentStartDate.format('YYYY'),
      monthThreeLetterFormat: momentEndDate.format('MMM'),
    },
    time: {
      hour: momentStartDate.format(`${defaultTimeFormat}`),
      day: momentStartDate.format('dddd'),
      timeZone: timeZoneString || '',
      timeZoneCode: timeZoneCode,
    },
    endDate: momentEndDate
      ? {
          date: {
            day: momentEndDate.format('D'),
            month: momentEndDate.format('MMMM'),
          },
          time: {
            hour: momentEndDate.format(`${defaultTimeFormat}`),
            day: momentEndDate.format('dddd'),
            timeZone: timeZoneString || '',
            timeZoneCode: timeZoneCode,
          },
        }
      : null,
  };
};

export const dayMap: { [arg: number]: string } = {
  0: 'Mon',
  1: 'Tue',
  2: 'Wed',
  3: 'Thu',
  4: 'Fri',
  5: 'Sat',
  6: 'Sun',
};

export const getTimezoneOffset = (
  volunteerTimezone: string,
  userTimezone: string
) => {
  const volunteerNow = moment().tz(volunteerTimezone);
  const volunteerOffset = volunteerNow.utcOffset();
  volunteerNow.tz(userTimezone);
  const localOffset = volunteerNow.utcOffset();
  return (volunteerOffset - localOffset) / 60;
};

export const getAvailability = (
  { availabilityObj }: GetAvailabilityProps,
  volunteerTimezone: string,
  userTimezone: string
) => {
  const { days, end_hour, end_minute, start_hour, start_minute } =
    availabilityObj;

  const offset = getTimezoneOffset(volunteerTimezone, userTimezone);
  const startHourWithOffset = Math.floor(start_hour - offset);
  const endHourWithOffset = Math.floor(end_hour - offset);
  const continuesToNextday = endHourWithOffset >= 24;
  const continuesToPreviousday = endHourWithOffset < 0;
  const startsPreviousDay = startHourWithOffset < 0;
  const startsNextDay = startHourWithOffset >= 24;

  const endHourLocal = () => {
    if (continuesToNextday) {
      return endHourWithOffset - 24;
    }
    if (continuesToPreviousday) {
      return endHourWithOffset + 24;
    }

    return endHourWithOffset;
  };

  const startHourLocal = () => {
    if (startsPreviousDay) {
      return startHourWithOffset + 24;
    }
    if (startsNextDay) {
      return startHourWithOffset - 24;
    }

    return startHourWithOffset;
  };

  const sign = offset >= 0 ? 1 : -1;
  let decPart = (offset * sign) % 1;

  if (decPart && decPart > 0) {
    const min = 1 / 60;
    decPart = min * Math.round(decPart / min);
  }

  var minute = parseInt(Math.floor(decPart * 60) + '');

  const getAvailabilityProgress = () => {
    if (startsNextDay) {
      const startHourCalc = startHourWithOffset - 24;
      const end = `${
        startHourCalc < 10 ? `0${startHourCalc}` : startHourCalc
      }:${minute < 10 ? `0${minute}` : minute}`;
      const start = `${
        endHourLocal() < 10 ? `0${endHourLocal()}` : endHourLocal()
      }:${minute < 10 ? `0${minute}` : minute}`;

      if (startHourWithOffset > 24) {
        return `${start} to 00:00 and 00:00 to ${end}`;
      } else {
        return `${start} to ${end}`;
      }
    }

    if (continuesToNextday) {
      const endHourCalc = endHourWithOffset - 24;
      const end = `${endHourCalc < 10 ? `0${endHourCalc}` : endHourCalc}:${
        minute < 10 ? `0${minute}` : minute
      }`;
      const start = `${
        startHourLocal() < 10 ? `0${startHourLocal()}` : startHourLocal()
      }:${minute < 10 ? `0${minute}` : minute}`;

      if (endHourWithOffset > 24) {
        return `${start} to 00:00 and 00:00 to ${end}`;
      } else {
        return `${start} to ${end}`;
      }
    }

    if (!startsNextDay && !continuesToNextday) {
      const start = `${
        startHourLocal() < 10 ? `0${startHourLocal()}` : startHourLocal()
      }:${minute < 10 ? `0${minute}` : minute}`;
      const end = `${
        endHourLocal() < 10 ? `0${endHourLocal()}` : endHourLocal()
      }:${minute < 10 ? `0${minute}` : minute}`;

      return `${start} to ${end}`;
    }
  };

  return {
    availablityDays: days.reduce((acc: { [arg: number]: string }, cur) => {
      acc[cur] = dayMap[cur];
      return acc;
    }, {}),
    availabilityProgress: getAvailabilityProgress(),
    availabilityStartTime: `${
      start_hour < 10 ? `0${start_hour}` : start_hour
    }:${start_minute < 10 ? `0${start_minute}` : start_minute}`,
    availabilityEndTime: `${end_hour < 10 ? `0${end_hour}` : end_hour}:${
      end_minute < 10 ? `0${end_minute}` : end_minute
    }`,
    availabilityStartTimeLocal: `${
      startHourLocal() < 10 ? `0${startHourLocal()}` : startHourLocal()
    }:${minute < 10 ? `0${minute}` : minute}`,
    availabilityEndTimeLocal: `${
      endHourLocal() < 10 ? `0${endHourLocal()}` : endHourLocal()
    }:${end_minute < 10 ? `0${end_minute}` : end_minute}`,

    availabilityLocalTimeObject: {
      start_hour: startHourLocal() < 10 ? startHourLocal() : startHourLocal(),
      start_minute: minute < 10 ? minute : minute,
      end_hour: endHourLocal() < 10 ? endHourLocal() : endHourLocal(),
      end_minute: end_minute < 10 ? end_minute : end_minute,
    },
  };
};

export const getVolunteerAvailabilityTimeRange = (
  volunteers: VolProfile[],
  userTimezone: string
) =>
  volunteers
    .map((volunteer: any) => {
      const offset = getTimezoneOffset(volunteer.timezone, userTimezone);

      return [
        volunteer.availability.start_hour - offset,
        volunteer.availability.end_hour - offset,
      ];
    })
    .flatMap((x: number[]) => x)
    .sort((a, b) => a - b);

export const filterByVolunteerAvailabilityHours = (
  time: Date,
  blockNonBusinessWorkingHours: ValueToggle,
  volunteers: any,
  userTimezone: string,
  date?: Date
) => {
  let selectedDate;
  if (date) {
    selectedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds()
    );
  } else {
    selectedDate = new Date(time);
  }

  if (date) {
    const availableDays = volunteers
      .map((vol: any) => vol.availability.days)
      .flat();
    const availArray = availableDays.filter(
      (el: any, idx: any, self: any) => idx === self.indexOf(el)
    );
    const selectedWeekDay = getDayAndConvertMonToSun(date.toString());

    if (volunteers && volunteers.length > 0) {
      const availabilityArray = getVolunteerAvailabilityTimeRange(
        volunteers,
        userTimezone
      );

      const rangeStartTime = Math.min(...availabilityArray);
      const rangeEndTime = Math.max(...availabilityArray);
      const continuesToNextday = rangeEndTime > 24;
      const startsPreviousDay = rangeStartTime < 0;
      const hoursNextDay = continuesToNextday ? rangeEndTime - 24 : null;
      const hoursPreviousDay = startsPreviousDay ? rangeStartTime + 24 : null;

      const rangeStartDate = new Date(
        date ? date.getFullYear() : time.getFullYear(),
        date ? date.getMonth() : time.getMonth(),
        date ? date.getDate() : time.getDate(),
        rangeStartTime
      );
      const rangeEndDate = new Date(
        date ? date.getFullYear() : time.getFullYear(),
        date ? date.getMonth() : time.getMonth(),
        date ? date.getDate() : time.getDate(),
        rangeEndTime
      );
      const selectedTimeFallsBetweenRangeStartAndRangeEnd =
        rangeStartDate.getTime() <= selectedDate.getTime() &&
        rangeEndDate.getTime() >= selectedDate.getTime();

      const selectedTimeIsEarlierThanHoursNextDay =
        !!hoursNextDay &&
        selectedDate.getTime() <=
          new Date(
            time.getFullYear(),
            time.getMonth(),
            time.getDate(),
            hoursNextDay,
            (hoursNextDay % 2) * 60
          ).getTime();
      const selectedTimeIsLaterThanHoursPreviousDay =
        !!hoursPreviousDay &&
        selectedDate.getTime() >=
          new Date(
            time.getFullYear(),
            time.getMonth(),
            time.getDate(),
            hoursPreviousDay,
            (hoursPreviousDay % 2) * 60
          ).getTime();

      const nextDayWhenPossible = hoursNextDay
        ? availArray[availArray.length - 1] + 1
        : availArray[availArray.length - 1];

      if (
        !availArray.includes(selectedWeekDay) &&
        nextDayWhenPossible !== selectedWeekDay
      ) {
        return false;
      } else if (
        !availArray.includes(selectedWeekDay) &&
        nextDayWhenPossible === selectedWeekDay
      ) {
        rangeEndDate.setDate(rangeEndDate.getDate() - 1);

        return rangeEndDate.getTime() >= selectedDate.getTime();
      }

      return (
        selectedTimeFallsBetweenRangeStartAndRangeEnd ||
        selectedTimeIsEarlierThanHoursNextDay ||
        selectedTimeIsLaterThanHoursPreviousDay
      );
    } else {
      const date8AM = new Date(
        time.getFullYear(),
        time.getMonth(),
        time.getDate(),
        8,
        0
      );
      const date8PM = new Date(
        time.getFullYear(),
        time.getMonth(),
        time.getDate(),
        20,
        0
      );

      // toggle check
      if (blockNonBusinessWorkingHours) {
        return (
          date8AM.getTime() <= selectedDate.getTime() &&
          date8PM.getTime() >= selectedDate.getTime()
        );
      }
    }
  }

  return true;
};

export const getDayAndConvertMonToSun = (dateString: string) => {
  const dateObj = new Date(dateString);
  if (!(dateObj instanceof Date) || isNaN(dateObj?.getTime())) {
    return;
  }
  const dayNumber = dateObj && dateObj?.getDay();
  // this returns day number where 0 = Sunday, 6 = Saturday
  // We store days as 0 = Monday, 6 = Sunday
  // So we need to convert - Sun(0) to Sun(6)
  return dayNumber === 0 ? 6 : dayNumber - 1;
};

export const filterByUKBusinessHours = (
  selectedTime: Date,
  startingHour: number,
  endingHour: number
) => {
  const selectedDate = new Date(selectedTime);
  const now = new Date(Date.now());
  const ukHour = moment(now).tz('Europe/London').format('HH');
  const utcHour = moment(now).tz('UTC').format('HH');
  const ukIsInBST = ukHour !== utcHour; // check if UK is currently in GMT or BST (GMT === UTC)

  const startHour = ukIsInBST ? startingHour - 1 : startingHour;
  const endHour = ukIsInBST ? endingHour - 1 : endingHour;

  const date8AM = new Date(
    selectedTime.getFullYear(),
    selectedTime.getMonth(),
    selectedTime.getDate(),
    8,
    0
  ).setUTCHours(startHour);
  const date6PM = new Date(
    selectedTime.getFullYear(),
    selectedTime.getMonth(),
    selectedTime.getDate(),
    18,
    0
  ).setUTCHours(endHour);

  return date8AM <= selectedDate.getTime() && date6PM >= selectedDate.getTime();
};
