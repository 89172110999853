import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ScheduleIcon from '@mui/icons-material/Schedule';
import './style.scss';
import { formatDate } from '../../../utils/time';
import Paper from '@mui/material/Paper';
import useGetJoinCall from '../../../hooks/useGetJoinCall';
import { GroupEvent, BoostCallEventWithVolunteerProfile } from '../../../types';

import '../components/styling/card-styling.scss';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

import MessagesModal from 'components/MessagesModal';
import MentorModal from 'components/MentorCard/MentorModal';
import api from 'api';
import { Mentor } from '../../../../types';
import { DashboardFilledButton, DashboardEmptyButton } from 'styling/buttons';
import { StyledBadge } from 'styling/generalStyling';
import { useSystemTogglesContext } from 'context/SystemToggles';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from 'features/reduxStore/sessionTitle';

type Props = {
  appointmentData: any;
  timeZone?: string;
  subject: string;
  refetchEvents?: any;
};

const NewCompletedCard = ({
  timeZone,
  appointmentData,
  refetchEvents,
}: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  const navigate = useNavigate();
  const { joinCall } = useGetJoinCall({ callInfo: appointmentData });

  const {
    id: boostCallId,
    volunteer: volunteerData,
    start_time,
    title,
    organisation_new_messages_count,
    organisation_sent_nps,
  } = (appointmentData as BoostCallEventWithVolunteerProfile) || {};

  const [unreadMessages, setUnreadMessages] = useState<number>(
    organisation_new_messages_count
  );
  const [openMessagesModal, setOpenMessagesModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [mentor, setMentor] = useState<Mentor | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const { results } = await api.searchMentors([volunteerData?.id], 1);
      if (results && results.length && results.length > 0)
        setMentor(results[0]);
    })();
  }, [volunteerData?.id]);

  const { interest, code } = (appointmentData as GroupEvent) || {};

  const id = code || boostCallId;

  const profileData = {
    name: volunteerData?.first_name,
    picture: volunteerData?.profile_picture,
    companyName: volunteerData?.employer,
    currentRole: volunteerData?.current_role,
    showPresentedBy: false,
  };

  const dateFormatted = formatDate(start_time, timeZone);

  const time = {
    time: dateFormatted?.time,
    date: dateFormatted?.date,
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const sessionTitle = useSelector(selectSessionTitle);
  if (title === '1-to-1 mentoring call')
    return (
      <div className="dashboard-box">
        <div className="completed-paper-box">
          <Paper key={id} elevation={0}>
            <div className="completed-card__container">
              <div
                onClick={() => mentor && setModalOpen(true)}
                className="profile-photo-card"
                style={{
                  cursor: 'pointer',
                  backgroundImage: profileData.picture
                    ? `url(${profileData.picture})`
                    : 'url(/avatar.png)',
                }}
              ></div>

              <div className="dashboard-card-header-info">
                <div className="dashboard-card-title">
                  {profileData.name ? (
                    <div className="dashboard-card-name">
                      {sessionTitle} with
                      <div className="completed-paper-first-name">
                        {profileData.name}
                      </div>
                    </div>
                  ) : (
                    <div className="dashboard-card-name">Inactive User</div>
                  )}
                </div>
                <div className="completed-paper-organisation">
                  {profileData.companyName}
                </div>
                <div className="completed-paper-topic">
                  {' '}
                  {appointmentData.top_priority_name}
                </div>
                <div className="completed-paper-time">
                  <div className="dashboard-paper-time-day">
                    <CalendarTodayOutlinedIcon
                      sx={{ paddingBottom: '3px' }}
                      fontSize="small"
                    />{' '}
                    {time.date?.day} {time.date?.monthThreeLetterFormat}{' '}
                    {time?.date?.year}
                  </div>
                  <div className="dashboard-paper-time-hour">
                    <ScheduleIcon
                      fontSize="small"
                      sx={{ paddingBottom: '3px' }}
                    />{' '}
                    {time.time?.hour}{' '}
                  </div>
                </div>
                <div className="completed-paper-button-wrapper">
                  <div className="dashboard-button-container">
                    <DashboardFilledButton
                      variant="contained"
                      disabled={
                        organisation_sent_nps || profileData.name === undefined
                      }
                      onClick={() => navigate('/nps-score?id=' + id)}
                      sx={{
                        width: '150px',
                        backgroundColor:
                          organisation_sent_nps ||
                          profileData.name === undefined
                            ? 'white'
                            : 'primary',
                      }}
                    >
                      {organisation_sent_nps
                        ? `Feedback Given`
                        : `Leave Feedback`}
                    </DashboardFilledButton>
                  </div>
                  <div className="dashboard-link-button-container">
                    <DashboardEmptyButton
                      variant="text"
                      onClick={() => {
                        if (showMessagingUI) {
                          navigate(
                            `/messaging-centre?id=${appointmentData?.volunteer?.id}`
                          );
                          return;
                        }
                        refetchEvents();
                        setUnreadMessages(0);
                        setOpenMessagesModal(true);
                      }}
                      sx={{ width: '100%', color: '#333' }}
                    >
                      <StyledBadge badgeContent={unreadMessages} color="info">
                        Messages
                      </StyledBadge>
                    </DashboardEmptyButton>
                  </div>
                  <div className="dashboard-link-button-container">
                    <DashboardEmptyButton
                      variant="text"
                      sx={{ width: '140px' }}
                      disabled={profileData.name === undefined}
                      onClick={() =>
                        navigate(
                          `/volunteer-details?volunteer[]=${appointmentData?.volunteer?.id}&keyword=complete-tab`
                        )
                      }
                    >
                      Rebook Session
                    </DashboardEmptyButton>
                  </div>
                  <div className="dashboard-link-button-container">
                    <DashboardEmptyButton
                      sx={{ color: '#333333' }}
                      onClick={() => {
                        navigate(
                          `/call-details?cid=${appointmentData.id}&type=events&event_update_options=true`
                        );
                      }}
                    >
                      Manage
                    </DashboardEmptyButton>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
        {openMessagesModal ? (
          <MessagesModal
            boostCallEvent={
              appointmentData as BoostCallEventWithVolunteerProfile
            }
            onReadMessages={() => setUnreadMessages(0)}
            onClose={() => setOpenMessagesModal(false)}
            openMessagesModal={openMessagesModal}
          />
        ) : null}
        {mentor && modalOpen && (
          <MentorModal
            mentor={mentor}
            topPriority={appointmentData?.top_priority}
            modalOpen={modalOpen}
            closeModal={closeModal}
            hideSelect
            // allowDirectRequest
          />
        )}
      </div>
    );
  else
    return (
      <div className="dashboard-box">
        <div className="completed-masterclass-paper-box">
          <Paper key={id} elevation={0}>
            <div>
              <div className="completed-card__container">
                <img
                  className="mastercard-photo-card"
                  src={'/masterclass-icon.png'}
                  alt={'masterclass'}
                />
                <div className="dashboard-card-header-info">
                  <div className="dashboard-card-title">
                    <div className="dashboard-card-name">
                      Completed Masterclass
                    </div>
                  </div>
                  <div className="completed-paper-topic">{interest.name}</div>

                  <div className="completed-paper-time">
                    <div className="dashboard-paper-time-day">
                      <CalendarTodayOutlinedIcon
                        fontSize="small"
                        sx={{ paddingBottom: '3px' }}
                      />{' '}
                      {time.date?.day} {time.date?.monthThreeLetterFormat}
                    </div>
                    <div className="dashboard-paper-time-hour">
                      <ScheduleIcon
                        fontSize="small"
                        sx={{ paddingBottom: '3px' }}
                      />{' '}
                      {time.time?.hour}{' '}
                    </div>
                  </div>
                  <div className="completed-paper-button-wrapper">
                    <div className="dashboard-button-container">
                      <DashboardFilledButton
                        sx={{ width: '120px' }}
                        variant="contained"
                        onClick={() => joinCall()}
                      >
                        View Details
                      </DashboardFilledButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    );
};

export default memo(NewCompletedCard);
