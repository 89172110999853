import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { logout } from '../auth';
import type { RootState } from '../../store';
import type { UserLocation } from '../../types';

/* --- SLICE --- */

interface UserLocationState {
  status: 'init' | 'pending' | 'fulfilled' | 'rejected';
  location?: UserLocation | null;
  error?: string | null;
  timezoneUpdateStatus: 'init' | 'pending' | 'fulfilled' | 'rejected';
}

const initialState = {
  status: 'init',
  location: null,
  error: null,
  timezoneUpdateStatus: 'init',
} as UserLocationState;

const userLocationSlice = createSlice({
  name: 'userLocation',
  initialState,
  reducers: {
    clearUserLocation: (state) => {
      return initialState;
    },
    setUserLocation: (state, action) => {
      state.location = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // logout
      .addCase(logout, () => initialState)
      // fetchUserLocation
      .addCase(fetchUserLocationAction.pending, (state, action) => {
        state.status = action.meta.requestStatus;
        state.error = null;
      })
      .addCase(fetchUserLocationAction.fulfilled, (state, action) => {
        state.status = action.meta.requestStatus;
        state.location = action.payload;
      })
      .addCase(fetchUserLocationAction.rejected, (state, action) => {
        const { error } = action;
        state.error = error.message;
        state.status = action.meta.requestStatus;
      })
      // updateTimeZone
      .addCase(updateTimeZoneAction.pending, (state, action) => {
        state.timezoneUpdateStatus = action.meta.requestStatus;
      })
      .addCase(updateTimeZoneAction.fulfilled, (state, action) => {
        state.timezoneUpdateStatus = action.meta.requestStatus;
        state.location = action.payload;
      })
      .addCase(updateTimeZoneAction.rejected, (state, action) => {
        state.timezoneUpdateStatus = action.meta.requestStatus;
      });
  },
});

/* --- SELECTORS --- */

// fetchUserLocation
export const selectUserLocation = (state: RootState) =>
  state.userLocation.location;
export const selectUserLocationStatus = (state: RootState) =>
  state.userLocation.status;
export const selectHasNotFetchedUserLocation = (state: RootState) =>
  selectUserLocationStatus(state) === 'init';
export const selectHasFinishedLoadingUserLocation = (state: RootState) =>
  selectUserLocationStatus(state) === 'fulfilled' ||
  selectUserLocationStatus(state) === 'rejected';

// updateTimeZone
export const selectTimezoneUpdateStatus = (state: RootState) =>
  state.userLocation.timezoneUpdateStatus;
export const selectHasNotUpdatedTimezone = (state: RootState) =>
  selectTimezoneUpdateStatus(state) === 'init';
export const selectHasFinishedUpdatingTimeZone = (state: RootState) =>
  selectTimezoneUpdateStatus(state) === 'fulfilled' ||
  selectTimezoneUpdateStatus(state) === 'rejected';

/* --- ACTIONS --- */

export const { clearUserLocation, setUserLocation } = userLocationSlice.actions;

/* --- THUNKS --- */

// fetchUserLocation
export const fetchUserLocationAction = createAsyncThunk(
  `${userLocationSlice.name}/fetchUserLocation`,
  async (arg, thunkAPI) => {
    const response = await api.getUserLocation();
    return response;
  }
);

export const updateTimeZoneAction = createAsyncThunk(
  `${userLocationSlice.name}/updateTimeZone`,
  async (arg, thunkAPI) => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await api.updateTimeZone(browserTimezone);
    return response.data;
  }
);

export default userLocationSlice.reducer;
