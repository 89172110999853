import React from 'react';
import Modal from 'react-modal';
import { BiBlock } from 'react-icons/bi';
import { IoIosClose } from 'react-icons/io';
import Button from '../Button';

import './block-modal.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirmBlock: () => void;
  blocked: boolean;
  loading: boolean;
  error?: string | null;
  customStyles?: {
    overlay: React.CSSProperties;
    content: React.CSSProperties;
  };
};

const BlockModal = ({
  isOpen,
  onClose,
  onConfirmBlock,
  blocked,
  loading,
  error,
  customStyles,
}: Props) => {
  const blockHeaderText = `Are you sure you want to ${
    blocked ? 'unblock' : 'block'
  } messages for this session?`;
  const blockBodyText =
    'When session messages are blocked, neither users can send or receive messages for this session. Unblocking messages will allow users to send and receive messages again.';

  const blockModalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 25, 0.45)',
      zIndex: 200,
    },
    content: {
      maxWidth: '600px',
      maxHeight: blocked ? '250px' : '300px',
      margin: 'auto',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles || blockModalStyles}
    >
      <div className="block-modal__modal">
        <div className="block-modal__close">
          <button className="block-modal__close-button">
            <IoIosClose size={25} onClick={onClose} />
          </button>
        </div>
        <div className="block-modal__title-container">
          <BiBlock className="block-modal__sent-icon" size={35} />
          <span className="block-modal__title">{blockHeaderText}</span>
        </div>
        <span className="block-modal__text-1">{blockBodyText}</span>
        {!blocked && (
          <span className="block-modal__text-2">
            If you schedule any other sessions with this user, they will still
            be able to send messages as part of those sessions.
          </span>
        )}
        <Button
          text={blocked ? 'Unblock Messages' : 'Block Messages'}
          className="block-modal__confirm-button"
          type="tertiary"
          loading={loading}
          onClickFunction={onConfirmBlock}
        />
        {error && <span className="error">{error}</span>}
      </div>
    </Modal>
  );
};

export default BlockModal;
