import React, { Dispatch, SetStateAction } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { CustomTimeLabel } from 'pages/Dashboard/styling';
import { TimeSelect } from 'styling/generalStyling';
import useWindowSize from 'hooks/useWindowSize';
import { mobileBreakpoint, TabletBreakpoint } from 'styling/screenSizes';
type Props = {
  setNewTime: Dispatch<SetStateAction<string>>;
  selectedDay: Date | undefined;
  newTime: string;
  maxTime: number;
  minTime: number;
  maxMinute: number;
  minMinute: number;
  position: number;
};

const SelectTime = ({
  setNewTime,
  selectedDay,
  newTime,
  maxTime,
  minTime,
  maxMinute,
  minMinute,
  position,
}: Props) => {
  const [selectedTime, setSelectedTime] = React.useState(
    newTime ? newTime : undefined
  );
  const { width } = useWindowSize();
  const isMobile = width < mobileBreakpoint;
  const isTablet = width < TabletBreakpoint && width > mobileBreakpoint;
  const isToday =
    moment(selectedDay).format('MMM DD YY') === moment().format('MMM DD YY');
  var startTime = moment().utc().set({ hour: 1, minute: 0 });
  var endTime = moment().utc().set({ hour: 23, minute: 59 });
  var minimumTime = minMinute;
  var minimumHour = minTime;
  if (isToday) {
    minimumHour = moment().hour();
    minimumTime =
      (Math.round(moment().add(30, 'minute').minute() / 30) * 30) % 60;
    if (minimumTime === 0) minimumHour++;
    if (minimumHour === 24) minimumHour = 1;
  }
  var timeStops = [];
  startTime = moment().utc().set({ hour: minimumHour, minute: minimumTime });
  endTime = moment()
    .utc()
    .set({ hour: maxTime < minTime ? 23 : maxTime, minute: maxMinute });

  while (startTime <= endTime) {
    let newMoment = moment(startTime).format('HH:mm');
    timeStops.push(newMoment);
    startTime.add(30, 'minutes');
  }
  //if end time past midnight
  if (maxTime < minTime) {
    var finalTime = moment().utc().set({ hour: maxTime, minute: maxMinute });
    let newStartTime = moment().utc().set({ hour: 0, minute: 0 });
    while (newStartTime <= finalTime) {
      let newMoment = moment(newStartTime).format('HH:mm');
      timeStops.push(newMoment);
      newStartTime.add(30, 'minutes');
    }
  }
  const handleChange = (event: any) => {
    setSelectedTime(event.target.value as string);
    setNewTime(event.target.value as string);
  };
  return (
    <Box
      sx={{
        minWidth: isTablet ? '320px' : '262px',
        width: isTablet ? '320px' : '262px',
        display: 'flex',
        marginBottom: isMobile ? '5px' : 0,
      }}
    >
      <FormControl
        fullWidth
        sx={{ fontFamily: 'Gilroy', minWidth: isTablet ? '320px' : '262px' }}
      >
        <CustomTimeLabel
          sx={{ fontFamily: 'Gilroy', width: 'auto' }}
          id="demo-simple-select-label"
        >
          {selectedTime ? null : `  ${'#' + position} Click to select a time`}
        </CustomTimeLabel>
        <TimeSelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedTime}
          label={selectedTime ? null : `#{position} Click to select a time`}
          onChange={handleChange}
        >
          {selectedDay ? (
            timeStops.map((time, index) => (
              <MenuItem
                sx={{ fontFamily: 'Gilroy', width: '100%' }}
                key={index}
                value={time}
              >
                {time}
              </MenuItem>
            ))
          ) : (
            <MenuItem
              sx={{ fontFamily: 'Gilroy', fontSize: '13px' }}
              value={undefined}
            >
              Please select a day first
            </MenuItem>
          )}
        </TimeSelect>
      </FormControl>
    </Box>
  );
};

export default SelectTime;
