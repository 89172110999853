// Render Prop
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './styling.scss';
import Cookie from 'js-cookie';
import useAuth from 'features/auth/hooks/useAuth';
import { Partner } from 'types';
import { CustomTextField, CustomFormLabel } from 'styling/generalStyling';
import ReactHtmlParser from 'react-html-parser';
import { Tooltip } from '@mui/material';
import Button from 'components/Button';
import { SignupOption } from 'types';
import { removeWhiteSpace } from 'utils/text';

type Props = {
  partnerId: number | null;
  setVerificationModalOpen: (value: boolean) => void;
  partnerInformation: Partner | null | undefined;
  signupOptions: SignupOption[];
};

const SignUpForm = ({
  partnerId,
  setVerificationModalOpen,
  partnerInformation,
  signupOptions,
}: Props) => {
  const [partner] = useState<number | null>(partnerId);
  const { signup } = useAuth();
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [cookiePolicy, setCookiePolicy] = useState(false);
  const [partnerQuestionCheck, setPartnerQuestionCheck] =
    useState<boolean>(false);
  const [hasConsent, setHasConsent] = useState<boolean>(false);

  useEffect(() => {
    if (
      window.Cookiebot &&
      window.Cookiebot.consent?.necessary &&
      window.Cookiebot.hasResponse
    ) {
      setHasConsent(true);
    }
    const handleConsentReady = () => {
      setHasConsent(true);
    };

    window.addEventListener('CookiebotOnConsentReady', handleConsentReady);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', handleConsentReady);
    };
  }, []);
  const [customTermsConsented, setCustomTermsConsented] =
    useState<boolean>(false);

  const [signupOptionsState, setSignupOptionsState] = useState<number[]>([]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const validationSchema = yup.object({
    email: yup.string().required('Email is required'),
    password: yup.string().min(8).required('Password is required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Please confirm your password'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values: { email: string; password: string }) => {
      setVerificationModalOpen(false);
      const email = removeWhiteSpace(values.email) || '';
      const password = removeWhiteSpace(values.password) || '';
      const signup_options = [...signupOptionsState];

      if (partner && hasConsent)
        Cookie.set('partner_id', partner.toString(), {
          expires: 1,
        });
      const result = await signup({ email, password, partner, signup_options });
      if (result.meta.requestStatus === 'fulfilled')
        setVerificationModalOpen(true);
      else setErrorMessage(result.payload);
    },
  });

  const requiredOptionsMissing = signupOptions.some(
    (item) =>
      item.is_required === true &&
      !signupOptionsState.some((i) => i === item.id)
  );

  const disableButton = partnerId
    ? !cookiePolicy ||
      (partnerInformation?.gdpr_permission_required_org &&
        !partnerQuestionCheck) ||
      (partnerInformation?.custom_terms_text_org !== null &&
        !customTermsConsented)
    : !cookiePolicy;

  const finalDisableButton = disableButton || requiredOptionsMissing;

  return (
    <div>
      {errorMessage ? <div className="error">{errorMessage}</div> : null}
      <form onSubmit={formik.handleSubmit}>
        <div className="sign-in-form-container">
          <CustomTextField
            fullWidth
            id="email"
            name="email"
            label={
              <CustomFormLabel sx={{ fontSize: '16px' }}>Email</CustomFormLabel>
            }
            value={removeWhiteSpace(formik.values.email)}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <CustomTextField
            fullWidth
            id="password"
            name="password"
            label={
              <CustomFormLabel sx={{ fontSize: '16px' }}>
                Password
              </CustomFormLabel>
            }
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <CustomTextField
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            label={
              <CustomFormLabel sx={{ fontSize: '16px' }}>
                Confirm Password
              </CustomFormLabel>
            }
            type="password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <div className="signup-accept-box">
            <div
              className={`login-signup__cookie-checkbox`}
              data-cy="cookie-check-box"
              onClick={() => setCookiePolicy(cookiePolicy ? false : true)}
            >
              <label>
                <input name="cookie" type="checkbox" checked={cookiePolicy} />
                <label>
                  I agree to the Digital Boost{' '}
                  <a
                    href="https://www.digitalboost.org.uk/legals/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    User Agreement
                  </a>
                  ,{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.digitalboost.org.uk/legals/privacy-policy"
                  >
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.digitalboost.org.uk/legals/cookiepolicy"
                  >
                    Cookie Policy
                  </a>
                </label>
              </label>
            </div>
            {partner && partnerInformation?.gdpr_permission_required_org ? (
              <div
                className={`login-signup__cookie-checkbox partner-terms`}
                data-cy="cookie-check-box"
                onClick={() => setPartnerQuestionCheck(!partnerQuestionCheck)}
              >
                <input
                  name="cookie"
                  type="checkbox"
                  checked={partnerQuestionCheck}
                />
                <label className="partner-checkbox-help-text">
                  {partnerInformation?.custom_gdpr_text_org ? (
                    <>
                      {ReactHtmlParser(
                        partnerInformation?.custom_gdpr_text_org
                      )}
                    </>
                  ) : (
                    <>
                      I agree to share my information with{' '}
                      <b>{partnerInformation.name}</b>
                    </>
                  )}
                  <Tooltip
                    open={showTooltip1}
                    onMouseEnter={() => setShowTooltip1(true)}
                    onMouseLeave={() => setShowTooltip1(false)}
                    title={`If you click this button, you agree that we can share with ${partnerInformation?.name} your contact information and how many mentoring requests you have made / completed. This is for reporting purposes and programme administration only, and never used for marketing.`}
                  >
                    <span className="login-signup__data-tip">
                      {' '}
                      What is shared and why?
                    </span>
                  </Tooltip>
                </label>
              </div>
            ) : null}
            {partner && partnerInformation?.custom_terms_text_org ? (
              <div
                onClick={() => setCustomTermsConsented(!customTermsConsented)}
                className="login-signup__terms-container"
              >
                <input
                  checked={customTermsConsented}
                  type={'checkbox'}
                  readOnly={true}
                  className="login-signup__terms-checkbox"
                />
                <label>
                  {' '}
                  {ReactHtmlParser(
                    partnerInformation?.custom_terms_text_org
                  )}{' '}
                  {partnerInformation?.custom_terms_explainer_org && '('}
                  {partnerInformation?.custom_terms_explainer_org && (
                    <span
                      className="login-signup__data-tip"
                      data-tip={partnerInformation?.custom_terms_explainer_org}
                    >
                      Read more
                    </span>
                  )}
                  {partnerInformation?.custom_terms_explainer_org && ')'}
                </label>
              </div>
            ) : null}
            {signupOptions.map((item: SignupOption) => {
              const checked = signupOptionsState.some((i) => i === item.id);

              const addOptionId = (optionId: number) => {
                let newOptionList: number[] = [...signupOptionsState];

                if (signupOptionsState.includes(optionId)) {
                  const index = newOptionList.indexOf(optionId);
                  newOptionList.splice(index, 1);
                } else {
                  newOptionList.push(optionId);
                }

                setSignupOptionsState(newOptionList);
              };

              return (
                <div
                  onClick={() => addOptionId(item.id)}
                  className="login-signup__terms-container"
                >
                  <input
                    checked={checked}
                    type={'checkbox'}
                    readOnly={true}
                    className="login-signup__terms-checkbox"
                  />
                  <label>
                    {' '}
                    {ReactHtmlParser(item?.text)} {item?.explainer && '('}
                    {item?.explainer && (
                      <span
                        className="login-signup__data-tip"
                        data-tip={item?.explainer}
                      >
                        Read more
                      </span>
                    )}
                    {item?.explainer && ')'}
                  </label>
                </div>
              );
            })}
          </div>
          <Button
            type={'primary'}
            disabled={finalDisableButton}
            text={'Create my account'}
            onClickFunction={onsubmit}
            className="submit-button"
          />
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
