import React, { useState, useEffect } from 'react';
import './style.scss';
import '../components/styling/card-styling.scss';
import { formatDate } from 'utils/time';
import { TailSpin } from 'react-loader-spinner';
import api from 'api';
import {
  acceptFollowUpRequest,
  declineFollowUpRequest,
} from 'api/callRequests';
import { useNavigate } from 'react-router-dom';
import { CustomCallPagePaper } from 'styling/backgrounds';
import { DashboardFilledButton } from 'styling/buttons';
import { Button } from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
interface Props {
  details?: any;
  timeZone?: string;
  setFollowUpDetails: any;
}

const FollowupDetails = ({ details, timeZone, setFollowUpDetails }: Props) => {
  const navigate = useNavigate();

  const [loading, isLoading] = useState(false);
  const [interest, setInterest] = useState<any>(null);

  const { id, score, follow_up_text, interests } = details;

  useEffect(() => {
    api.getTaxonomy(interests).then((taxonomy: any) => {
      const interest = taxonomy?.filter((item: any) => item.child);
      setInterest(interest);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interests]);

  const dateFormatted = formatDate(score?.event?.start_time, timeZone);
  const time = {
    time: dateFormatted?.time,
    date: dateFormatted?.date,
  };

  return (
    <div className="upcoming-details-container">
      <div
        className="header-text-button"
        onClick={() => setFollowUpDetails(null)}
      >
        <ArrowCircleLeftIcon sx={{ color: '#333' }} />
        <Button
          variant="text"
          sx={{
            fontFamily: 'Gilroy',
            fontSize: '14px',
            fontWeight: 'bold',
            textAlign: 'center',
            textTransform: 'none',
            color: '#333',
            paddingLeft: '2px',
          }}
          onClick={() => setFollowUpDetails(null)}
        >
          Go back
        </Button>
      </div>
      <div className="upcoming-title-wrapper">
        <div className="upcoming-details-title">
          <span>
            {score?.volunteer?.first_name} {score?.volunteer?.last_name}
          </span>{' '}
          has requested a follow-up session
        </div>
      </div>

      <CustomCallPagePaper elevation={0} className="upcoming-box">
        <div className="upcoming-date-info">
          <div className="upcoming-follow-proposed-time">
            Your last call was on:
          </div>
          <div className="upcoming-time-slot">
            <div className="upcoming-time-slot-txt">
              {time?.date?.day} {time?.date?.month} {time?.date?.year}
            </div>
            <div className="upcoming-time-slot-txt">
              <span>{time?.time?.day}</span>
            </div>
            <div className="upcoming-time-slot-txt">{time?.time?.hour}</div>
          </div>

          <div className="upcoming-box-buttons-wrapper">
            <DashboardFilledButton
              className={
                loading
                  ? 'upcoming-accept-btn upcoming-accept-btn__loading'
                  : 'upcoming-accept-btn'
              }
              onClick={() =>
                acceptFollowUpRequest(interests, [score.volunteer.id], () =>
                  navigate(
                    `/book-session?volunteer=${score.volunteer.id}&interest=${interests?.[0]}&rebook=true&follow_up=${id}&keyword=follow-up`
                  )
                )
              }
            >
              {loading && <TailSpin color="#a6a6a6" height={20} width={20} />}
              Schedule
            </DashboardFilledButton>
            <div
              className={
                loading
                  ? 'upcoming-decline-btn upcoming-decline-btn__loading'
                  : 'upcoming-decline-btn'
              }
              onClick={() => {
                isLoading(true);
                declineFollowUpRequest(String(id), () =>
                  window.location.reload()
                );
              }}
            >
              {loading ? (
                <TailSpin color="#a6a6a6" height={20} width={20} />
              ) : (
                <img src="/decline-icon.svg" alt="Decline new time" />
              )}
              Decline
            </div>
          </div>
        </div>
      </CustomCallPagePaper>

      <div className="upcoming-info-box">
        <div className="upcoming-info-label">Help requested</div>
        <div className="upcoming-info-text">
          {interest?.[0]?.child?.[0]?.name || ''}
        </div>

        <div className="upcoming-info-label">Follow-up volunteer message</div>
        <div className="upcoming-info-text">{follow_up_text}</div>

        <div className="upcoming-info-label">Volunteer details</div>
        <div className="upcoming-info-text">
          <div className="upcoming-vol-info">
            <img
              src={score?.volunteer?.profile_picture}
              alt={score?.volunteer?.first_name}
            />
            <div className="upcoming-vol-info-column">
              <div className="followup-vol-name">
                {score?.volunteer?.first_name} {score?.volunteer?.last_name}
              </div>
              <div className="upcoming-vol-role">
                {score?.volunteer?.current_role}
              </div>
            </div>
          </div>
        </div>

        <div className="upcoming-info-label">Volunteer time zone</div>
        <div className="upcoming-info-text">{score?.volunteer?.timezone}</div>
      </div>
    </div>
  );
};

export default FollowupDetails;
