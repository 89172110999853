import React from 'react';
import ToolTip from '../ToolTip';
import { replaceUnderscoreWithSpaces } from '../../utils/text';
import useSelectProfileData from '../../hooks/useSelectProfileData';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

import styles from './timezone-indicator.module.scss';

type Props = {
  className?: string;
  newDashboard?: boolean;
};

const TimezoneIndicator = ({ className, newDashboard }: Props) => {
  const { userLocation } = useSelectProfileData();
  const { timezone = Intl.DateTimeFormat().resolvedOptions().timeZone } =
    userLocation || {};

  return (
    <div
      className={`${newDashboard ? styles.newContainer : styles.container} ${
        className ? className : ''
      }`}
    >
      {newDashboard ? (
        <AccessTimeIcon />
      ) : (
        <img className={styles.icon} src="./timezone.svg" alt="timezone" />
      )}
      <div className={styles.text_container}>
        <span className={styles.text}>{`Your timezone is detected as`}</span>
        <div className={styles.timezone_text_container}>
          <span className={styles.timezone_text}>
            {replaceUnderscoreWithSpaces(timezone)}{' '}
          </span>
          <ToolTip
            text="Timezone is automatically detected based on your device settings. Times will be displayed according to this timezone."
            id="nav-timezone-tooltip"
            place="left"
            className="preferences__timezone-tooltip"
          />
        </div>
      </div>
      <div className={styles.pointer} />
    </div>
  );
};

export default TimezoneIndicator;
