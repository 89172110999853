import { useAxios } from '../../api/config';
import { BoostCallEventReviews } from '../../types';

const useGetBoostCallEventReviews = (next?: string) => {
  let URL = next
    ? `api/netpromoterscore/boostcallscores/myscores` +
      next.split('myscores/')[1]
    : `api/netpromoterscore/boostcallscores/myscores?limit=4&offset=0`;

  const [{ data, loading, error }, refetch] = useAxios<BoostCallEventReviews>(
    {
      url: URL,
    },
    { manual: false }
  );

  return {
    next: data?.next,
    count: data?.count,
    reviews: data?.results,
    hasFinishedLoadingReviews: !loading,
    reviewsError: error,
    refetchReviews: refetch,
  };
};

export default useGetBoostCallEventReviews;
