import { styled } from '@mui/system';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { Button, InputLabel, Typography } from '@mui/material';

export const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

export const CustomRequestsLabel = styled(Typography)({
  fontSize: '17px',
  marginBottom: '5px',
  fontWeight: 'bold',
  lineHeight: '1',
  color: '#333',
  fontFamily: 'Gilroy',
  textTransform: 'none',
});

export const CustomMobileRequestsLabel = styled(Typography)({
  fontSize: '14px',
  marginBottom: '5px',
  fontWeight: 'bold',
  lineHeight: '1',
  color: '#333',
  fontFamily: 'Gilroy',
  textTransform: 'none',
});

export const CustomTimeLabel = styled(InputLabel)({
  fontSize: '14px',
  color: '#333',
  fontFamily: 'Gilroy',
  textTransform: 'none',
  textAlign: 'left',
});

export const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: black;
  orientation: vertical;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[100]};
  }
`;

export const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

export const TabsList = styled(TabsListUnstyled)`
  min-width: 280px;
  border-radius: 8px;
  orientation: vertical;
`;

export const LoadMoreButton = styled(Button)({
  backgroundColor: '#ffffff',
  width: '100%',
  '&:hover': {
    backgroundColor: '#fff',
    color: 'black',
  },
  height: '60px',
  color: '#2f80ed',
  textTransform: 'none',
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
});
