import { useAxios } from '../../api/config';
import { BoostcallCancellationReasons } from '../../types';

const useGetCancellationReasons = () => {
  const [{ data, loading, error }, refetch] =
    useAxios<BoostcallCancellationReasons>({
      url: `api/event/cancellationreasons/?display_reason_to=organisation`,
    });

  const reasons = data?.results;

  return {
    cancellationReasons: reasons || [],
    hasFinishedLoadingCancellationReasons: !loading,
    cancellationReasonsError: error,
    refetchCancellationReasonsError: refetch,
  };
};

export default useGetCancellationReasons;
