import React, { useEffect } from 'react';
import { Slider, Slide } from 'pure-react-carousel';

const scores = [
  '#D8405B',
  '#D05C5C',
  '#E48F74',
  '#EDA665',
  '#E9BD4E',
  '#EADD69',
  '#DFE163',
  '#D5E478',
  '#B2D765',
  '#9DCF75',
  '#77CF75',
];

interface Props {
  score: number | null;
  onScoreSet?: Function;
  mobile?: boolean;
}

const GroupEventScoringGrid = ({
  score,
  mobile,
  onScoreSet = (index: number) => {
    onScoreSet(index);
  },
}: Props) => {
  // Used to allow scrolling of mobile slide
  useEffect(() => {
    document.body.style.setProperty('overscroll-behavior-x', 'none');

    return () => {
      document.body.style.removeProperty('overscroll-behavior-x');
    };
  }, []);
  if (mobile) {
    return (
      <div className="scoring-grid__mobile-view-container">
        <span className="scoring-grid__slider-explainer">
          Please slide or tap to select a score
        </span>

        <div className="scoring-grid__slider-container">
          <Slider
            className="scoring-grid__slider"
            classNameTray="scoring-grid__slider-tray"
          >
            {scores.map((color, index) => {
              let setStyle;
              if (index === score) {
                setStyle = { background: color, opacity: 1 };
              } else {
                setStyle = { background: color, opacity: 0.5 };
              }

              return (
                <Slide
                  index={index}
                  onClick={() => {
                    onScoreSet(index);
                  }}
                  style={setStyle}
                  innerClassName="scoring-grid__slide-inner"
                  className={'scoring-grid__score-slide'}
                  key={`scoring-slider-${index}`}
                  tag="button"
                >
                  {<div>{index}</div>}
                </Slide>
              );
            })}
          </Slider>
        </div>
      </div>
    );
  } else
    return (
      <div className="scoring-grid">
        {scores.map((color, index) => {
          let setStyle;

          if (index === score) {
            setStyle = { background: color, opacity: 1 };
          } else {
            setStyle = { background: color };
          }

          return (
            <div
              key={`scoring-grid-${index}`}
              onClick={() => {
                onScoreSet(index);
              }}
              style={setStyle}
              className="scoring-grid__grid-square"
            >
              {index}
            </div>
          );
        })}
      </div>
    );
};

export default GroupEventScoringGrid;
