import React, { memo, useEffect, useState } from 'react';
import './style.scss';
import ScheduledEvent from './scheduledEvent';
import MentorModal from 'components/MentorCard/MentorModal';
import api from 'api';
import { Mentor } from '../../../types';
import RescheduleUpcomingCard from 'components/RescheduleUpcomingCard';
import { formatDate } from 'utils/time';
import RescheduleRequestByOrganisation from './rescheduleRequestByOrganisation';
import RescheduleRequestByVolunteer from './rescheduleRequestByVolunteer';
import CancelledEvent from './cancelledEvent';
import { BoostCallEventWithVolunteerProfile } from 'types';
import MessagesModal from 'components/MessagesModal';
import CompletedEvent from './completedEvent';
import { getUrlParam } from 'utils/url';
import { useSystemTogglesContext } from 'context/SystemToggles';
import { useNavigate } from 'react-router-dom';
import useSelectProfileData from 'hooks/useSelectProfileData';
import { selectSessionTitle } from 'features/reduxStore/sessionTitle';
import { useSelector } from 'react-redux';

type Props = {
  event: any;
  setDisplayUpcomingEventDetails?: any;
};

const ManageCallPage = ({ event }: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  let navigate = useNavigate();

  let eventType = undefined;
  const [mentor, setMentor] = useState<Mentor | undefined>(undefined);
  const [rescheduleModal, setRescheduleModal] = useState(false);

  const openModal = async () => {
    const { results } = await api.searchMentors([event.volunteer?.id], 1);
    if (results && results.length && results.length > 0) {
      setMentor(results[0]);
    }
  };
  const { organisationProfile, hasFinishedLoadingOrganisationProfile } =
    useSelectProfileData();
  if (
    hasFinishedLoadingOrganisationProfile &&
    organisationProfile?.partnerInfo?.name !== undefined &&
    organisationProfile?.partnerInfo?.white_label
  )
    event.partner_name = organisationProfile?.partnerInfo?.name;
  const session_title = useSelector(selectSessionTitle);
  event.session_title = session_title;
  const dateFormatted = formatDate(event.start_time, event.timeZone);
  const formattedTime = {
    time: dateFormatted?.time,
    date: dateFormatted?.date,
  };
  const profileData = {
    name: event.volunteer?.first_name,
    picture: event.organisation?.profile_picture,
    companyName: event.organisation?.name,
    currentRole: event.organisation?.current_role,
    showPresentedBy: false,
  };
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const closeModal = () => {
    setMentor(undefined);
  };
  const openMessagePopup = getUrlParam('messages') as string;
  const [messagesRead, setMessagesRead] = useState(false);
  const [openMessagesModal, setOpenMessagesModal] = useState(false);

  useEffect(() => {
    if (toggles['NEW_MESSAGING_UI'] && !showMessagingUI && openMessagePopup) {
      setMessagesRead(true);
      setOpenMessagesModal(true);
    } else if (
      toggles['NEW_MESSAGING_UI'] &&
      showMessagingUI &&
      openMessagePopup
    ) {
      navigate(`/messaging-centre?id=${event?.volunteer?.id}`);
    }
  }, [toggles, showMessagingUI, openMessagePopup, navigate, event]);

  if (event.status === 'cancelled') eventType = 'cancelled';
  else if (event.status === 'complete') eventType = 'completed';
  else if (
    event.reschedule_status === 'pending' &&
    event.reschedule_requested_by === 'organisation'
  )
    eventType = 'rescheduleRequestByOrganisation';
  else if (
    event.reschedule_status === 'pending' &&
    event.reschedule_requested_by === 'volunteer'
  )
    eventType = 'rescheduleRequestByVolunteer';
  else if (event.status === 'scheduled') eventType = 'scheduledEvent';
  var skillLevel = 'I know nothing';
  if (event.interest_expertise === 'i_know_a_lot')
    skillLevel = 'I know a lot, just need a help with some specifics';
  else if (event.interest_expertise === 'i_know_some')
    skillLevel = 'I know some';
  if (openMessagePopup) return null;
  return (
    <div className="organisation-information-container">
      {eventType === 'scheduledEvent' ? (
        <ScheduledEvent
          event={event}
          messagesRead={messagesRead}
          setOpenVolunteerModal={openModal}
          setRescheduleModal={setRescheduleModal}
          setOpenMessagesModal={setOpenMessagesModal}
        />
      ) : null}
      {eventType === 'rescheduleRequestByOrganisation' ? (
        <RescheduleRequestByOrganisation
          event={event}
          messagesRead={messagesRead}
          setOpenVolunteerModal={openModal}
          setRescheduleModal={setRescheduleModal}
          setOpenMessagesModal={setOpenMessagesModal}
        />
      ) : null}
      {eventType === 'rescheduleRequestByVolunteer' ? (
        <RescheduleRequestByVolunteer
          event={event}
          messagesRead={messagesRead}
          setOpenVolunteerModal={openModal}
          setRescheduleModal={setRescheduleModal}
          setOpenMessagesModal={setOpenMessagesModal}
        />
      ) : null}
      {eventType === 'cancelled' ? (
        <CancelledEvent
          setOpenVolunteerModal={openModal}
          event={event}
          setOpenMessagesModal={setOpenMessagesModal}
        />
      ) : null}
      {eventType === 'completed' ? (
        <CompletedEvent
          event={event}
          setOpenVolunteerModal={openModal}
          setOpenMessagesModal={setOpenMessagesModal}
        />
      ) : null}
      <div className="upcoming-info-box">
        <div className="upcoming-info-label">You requested help with</div>
        <div className="upcoming-info-text">{event.top_priority_name}</div>

        <div className="upcoming-info-label">Your request</div>
        <div className="upcoming-info-text">{event.help_needed}</div>
        {event.accessibility_req ? (
          <>
            {' '}
            <div className="upcoming-info-label">Your accessibility request</div>
            <div className="upcoming-info-text">{event.accessibility_req}</div>
          </>
        ) : null}

        <div className="upcoming-info-label">Current experience</div>
        <div className="upcoming-info-text">{skillLevel}</div>
        <div className="upcoming-info-label">Volunteer details</div>
        <div className="upcoming-info-text">
          <div className="upcoming-vol-info">
            <div
              onClick={openModal}
              className="profile-photo-card"
              style={{
                marginRight: '10px',
                cursor: 'pointer',
                backgroundImage: event.volunteer?.profile_picture
                  ? `url(${event.volunteer?.profile_picture})`
                  : 'url(/avatar.png)',
              }}
            ></div>
            {isModalOpen && mentor ? (
              <MentorModal
                mentor={mentor}
                modalOpen={Boolean(isModalOpen)}
                closeModal={() => setIsModalOpen(false)}
                hideSelect
              />
            ) : null}

            <div className="upcoming-vol-info-column">
              <div className="upcoming-vol-name">
                {event.volunteer?.first_name} {event.volunteer?.last_name}
              </div>
              <div className="upcoming-vol-role">
                {event.volunteer?.current_role}
              </div>
            </div>
          </div>
        </div>
      </div>
      {mentor && (
        <MentorModal
          mentor={mentor}
          modalOpen={Boolean(mentor)}
          closeModal={closeModal}
          hideSelect
        />
      )}
      {openMessagesModal && (
        <MessagesModal
          boostCallEvent={event as BoostCallEventWithVolunteerProfile}
          onClose={() => setOpenMessagesModal(false)}
          onReadMessages={() => setMessagesRead(true)}
          openMessagesModal={openMessagesModal}
        />
      )}
      <RescheduleUpcomingCard
        profileData={profileData}
        timeZone={event.timeZone}
        id={event.id}
        time={formattedTime}
        setRescheduleModal={setRescheduleModal}
        rescheduleModal={rescheduleModal}
      />
    </div>
  );
};

export default memo(ManageCallPage);
