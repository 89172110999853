import { useAxios } from 'api/config';
import { StatsByMonth } from '../../types';

export const useGetTotalSessions = () => {
  const [{ data, loading, error }, refetch] = useAxios<StatsByMonth[]>(
    {
      url: `api/organisation/organisation-metrics/stats-by-month/`,
    },
    { manual: false }
  );
  return {
    totalSessions: data,
    hasFinishedLoadingTotal: !loading,
    totalOrgsError: error,
    refetchTotal: refetch,
  };
};

export default useGetTotalSessions;
