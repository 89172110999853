import { useEffect } from 'react';
import moment from 'moment-timezone';
import { useAxios } from '../../api/config';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { getMomentWithTimezone } from '../../utils/time';
import { BoostCallEventWithVolunteerProfile } from '../../types';

type Props = {
  id: number | string | null;
};

const useGetBoostCallEvent = ({ id }: Props) => {
  const { userLocation } = useSelectProfileData();
  const { timezone } = userLocation || {};

  const [{ data, loading, error }, executeCall] =
    useAxios<BoostCallEventWithVolunteerProfile>(
      {
        url: `api/event/boostcallevents/${id}/`,
        method: 'GET',
      },
      {
        manual: true,
      }
    );
  useEffect(() => {
    if (!!id) executeCall();
  }, [executeCall, id]);

  const callHasNotYetStarted =
    data && timezone && !!moment().isBefore(moment(data.start_time));
  const callHasEnded =
    data &&
    timezone &&
    !moment().isBefore(
      getMomentWithTimezone(data.start_time, timezone).add(
        data.duration,
        'minutes'
      )
    );
  const callIsHappening = !callHasNotYetStarted && !callHasEnded;

  // text to be used on Join Call button - changes depending on when call start time is
  const buttonText =
    data &&
    (callHasNotYetStarted
      ? `Starts ${moment().utc().to(moment(data.start_time))} - Join Call`
      : callIsHappening
      ? `Started ${moment()
          .utc()
          .to(getMomentWithTimezone(data.start_time, timezone))} - Join Call`
      : `Call finished ${moment()
          .utc()
          .to(
            getMomentWithTimezone(data.start_time, timezone).add(
              data.duration,
              'minutes'
            )
          )}`);

  return {
    boostCallEvent: data,
    isLoadingBoostCallEvent: loading,
    hasFinishedLoadingBoostCallEvent: !loading,
    boostCallEventError: error,
    refetchBoostCallEvent: executeCall,
    callHasNotYetStarted,
    callIsHappening,
    callHasEnded,
    buttonText,
  };
};

export default useGetBoostCallEvent;
