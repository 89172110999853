import { useAxios } from '../../api/config';
import {
  BoostCallEvents,
  BoostCallEventWithVolunteerProfile,
} from '../../types';

const useGetBoostCallEventsScheduled = () => {
  const [{ data, loading, error }, refetch] = useAxios<BoostCallEvents>({
    url: `api/event/boostcallevents/search/?status=scheduled`,
  });

  return {
    boostCallEventsScheduled:
      (data?.results as BoostCallEventWithVolunteerProfile[]) || [],
    boostCallEventsScheduledCount: data?.count || 0,
    hasFinishedLoadingBoostCallEventsScheduled: !loading,
    boostCallEventsScheduledError: error,
    refetchBoostCallEventsScheduled: refetch,
  };
};

export default useGetBoostCallEventsScheduled;
