import { useEffect } from 'react';
import { useAxios } from '../../api/config';
import Cookie from 'js-cookie';
import { BoostCallRequest, TimeSlot } from '../../types';

type Props = {
  id: number | string | null;
};

const useGetBoostCallEventRequest = ({ id }: Props) => {
  const headers = { Authorization: `Bearer ${Cookie.get('accessOrgTokenDB')}` };

  const [{ data, loading, error }, executeCall] = useAxios<BoostCallRequest>(
    {
      url: `api/event/boostcallrequests/${id}/`,
      method: 'GET',
      headers: headers,
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (!!id) executeCall();
  }, [executeCall, id]);

  const volunteerProposedTimeSlots =
    data?.time_slots.filter(
      (ts: TimeSlot) => ts.status === 'time_slot_proposed'
    ) || [];

  return {
    boostCallEventRequest: data,
    volunteerProposedTimeSlots,
    isLoadingBoostCallEventRequest: loading,
    hasFinishedLoadingBoostCallEventRequest: !loading,
    boostCallEventRequestError: error,
    refetchBoostCallEventRequest: executeCall,
  };
};

export default useGetBoostCallEventRequest;
