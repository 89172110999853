import { TextField } from '@mui/material';
import React from 'react';
import './input-with-label.scss';

interface Props extends React.HTMLProps<HTMLInputElement> {
  label?: string | undefined;
  placeHolder?: string | undefined;
  onInput?: any;
  type?: string;
  isMobile?: any;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  defaultValue?: any;
  readOnly?: boolean;
  mandatory?: boolean;
  maxLength?: number;
  containerClassName?: string;
}

const InputWithLabel = ({
  type,
  label,
  placeHolder,
  defaultValue,
  maxLength,
  mandatory,
  onChange,
}: Props) => {
  const labelForTesting =
    label &&
    label
      .split(' ')
      .map((s) => s.toLowerCase())
      .join('-');

  return (
    <div
      style={{
        display: 'inline-flex',
        flexDirection: 'column',
        width: '100%',
        fontFamily: 'Gilroy',
      }}
    >
      {label && (
        <label data-cy={`input-label-${labelForTesting}`}>
          {label}{' '}
          {mandatory && (
            <span className="input-with-label__mandatory-asterisk">
              {'\u002A '}
            </span>
          )}
        </label>
      )}
      <TextField
        type={type}
        onChange={onChange}
        placeholder={placeHolder}
        inputProps={{ maxLength: maxLength }}
        sx={{

          fontFamily: 'Gilroy',
          fontWeight: '16px',
        }}
        fullWidth
        variant="outlined"
      />
    </div>
  );
};

export default InputWithLabel;
