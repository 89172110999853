import React from 'react';
import RadioButton from '../../../../components/RadioButton';

import './style.scss';

interface NewMessageCardProps {
  id: string;
  profilePicture?: string;
  name: string;
  onClick: () => void;
  selectedUser: any;
}

const NewMessageCard = ({
  id,
  profilePicture,
  name,
  onClick,
  selectedUser,
}: NewMessageCardProps) => (
  <div className="new-message-card__container" onClick={onClick}>
    <RadioButton selected={selectedUser?.volunteer?.id === id} bigger />
    <img
      src={profilePicture || 'avatar.png'}
      alt="User avatar"
      className="new-message-card__photo"
    />
    <div className="new-message-card__name">{name}</div>
  </div>
);

export default NewMessageCard;
