import React, { Dispatch, SetStateAction, useState } from 'react';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import './reschedule-upcoming-card.scss';
import SelectAvailability from './selectAvailability';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import SelectTime from './selectTime';
import { formatDate } from '../../utils/time';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import useRescheduleBoostCallRequest from 'features/boostCallEvents/hooks/useRescheduleBoostCallRequest';
import { DashboardFilledButton } from 'styling/buttons';

type Props = {
  id: any;
  timeZone?: string;
  profileData: {
    name: string;
    picture: string;
    companyName: string;
    currentRole: string | undefined;
    showPresentedBy: boolean;
  };
  time?: {
    time?: {
      hour: string;
      day: string;
      timeZone: string;
      timeZoneCode: string | null;
    };
    date?: {
      day: string;
      month: string;
      monthThreeLetterFormat: string;
      year: string;
    };
  };
  rescheduleModal: boolean;
  setRescheduleModal: Dispatch<SetStateAction<boolean>>;
};

const RescheduleUpcomingCard = ({
  profileData,
  rescheduleModal,
  setRescheduleModal,
  time,
  id,
  timeZone,
}: Props) => {
  const { proposeRescheduleRequest } = useRescheduleBoostCallRequest();

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [newDate, setNewDate] = useState('');
  const [newTime, setNewTime] = useState('');
  const [newDateFormat, setNewDateFormat] = useState('');

  const closeConfirmation = () => {
    setConfirmationModal(false);
    window.location.reload();
  };
  const closeReschedule = () => {
    setRescheduleModal(false);
  };
  const getHourAndMinute = (time: string) => {
    const hourMinute = time.split(':');
    return hourMinute;
  };
  const rescheduleMeeting = async () => {
    const newDateFormatted = formatDate(newDate, timeZone);
    setNewDateFormat(
      newDateFormatted?.date?.day +
        ' ' +
        newDateFormatted?.date?.monthThreeLetterFormat
    );
    const updatedDate = moment(newDate);
    const updatedTime = getHourAndMinute(newTime);
    updatedDate.add(updatedTime[0], 'hours');
    updatedDate.add(updatedTime[1], 'minutes');
    await proposeRescheduleRequest({
      id,
      newStartDateTime: updatedDate.format(),
    });
    setRescheduleModal(false);
    setConfirmationModal(true);
  };

  return (
    <div>
      <Modal
        open={rescheduleModal}
        onClose={closeReschedule}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="reschedule-box-container">
          <div className="reschedule-close-dialog-icon">
            <IconButton
              color="primary"
              onClick={closeReschedule}
              sx={{ padding: '0px' }}
            >
              <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
            </IconButton>
          </div>
          <div className="reschedule-card-container">
            <div className="reschedule-card-title">Reschedule session</div>
            <div className="reschedule-card-info">
              <div className="reschedule-card-name">
                Current session with
                <div className="reschedule-card-first-name">
                  {profileData.name}
                </div>
              </div>
              <div className="reschedule-card-time">
                <div className="reschedule-card-time-day">
                  <CalendarTodayOutlinedIcon
                    fontSize="small"
                    sx={{ paddingBottom: '3px' }}
                  />{' '}
                  {time?.date?.day} {time?.date?.monthThreeLetterFormat}{' '}
                  {time?.date?.year}
                </div>
                <div className="reschedule-card-time-hour">
                  <ScheduleIcon
                    fontSize="small"
                    sx={{ paddingBottom: '3px' }}
                  />{' '}
                  {time?.time?.hour}{' '}
                </div>
              </div>
            </div>
            <div className="reschedule-card-info">
              We recommend you send a message to {profileData.name} to agree a
              new time before rescheduling.
            </div>
            <Divider light />

            <div className="reschedule-card-text"> Select a date and time</div>
            <div className="reschedule-card-date-time-reason">
              <div className="reschedule-card-date-time">
                <SelectAvailability setNewDate={setNewDate} />
                <SelectTime
                  setNewTime={setNewTime}
                  newTime={newTime}
                  selectedDay={newDate}
                />
              </div>
            </div>
            <DashboardFilledButton
              variant="contained"
              sx={{
                width: ' 235px',
              }}
              disabled={newDate.length < 1 || newTime.length < 1}
              onClick={rescheduleMeeting}
            >
              Ask to reschedule
            </DashboardFilledButton>
          </div>
        </div>
      </Modal>
      <Modal
        open={confirmationModal}
        onClose={closeConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="reschedule-box-container">
          <div className="reschedule-close-dialog-icon ">
            <IconButton
              color="primary"
              onClick={closeConfirmation}
              sx={{ padding: '0px' }}
            >
              <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
            </IconButton>
          </div>
          <div className="reschedule-card-container">
            <div className="reschedule-card-confirmation-title">
              Reschedule requested
            </div>
            <div className="reschedule-card-confirmation-message">
              We will ask {profileData.name} if this new time is ok. If yes, the
              new time will be automatically confirmed. If they do not accept,
              the session will remain at
              <b>
                {' '}
                {time?.time?.hour} on {time?.date?.day}{' '}
                {time?.date?.monthThreeLetterFormat}
              </b>{' '}
              unless you cancel it.
            </div>
            <Divider light />
            <div className="reschedule-card-confirmation-info">
              <div className="reschedule-card-name">
                Session with
                <div className="reschedule-card-first-name">
                  {profileData.name}
                </div>
              </div>
              <div className="reschedule-card-time">
                <div className="reschedule-card-time-day">
                  <CalendarTodayOutlinedIcon
                    fontSize="small"
                    sx={{ paddingBottom: '3px' }}
                  />{' '}
                  {newDateFormat}
                </div>
                <div className="reschedule-card-time-hour">
                  <ScheduleIcon
                    fontSize="small"
                    sx={{ paddingBottom: '3px' }}
                  />{' '}
                  {newTime}{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RescheduleUpcomingCard;
