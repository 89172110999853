import useRedux from '../../../hooks/useRedux';
import {
  proposeRescheduleRequestAction,
  selectProposeRescheduleError,
  selectIsLoadingProposeReschedule,
  selectHasFinishedProposeReschedule,
  acceptRescheduleRequestAction,
  selectAcceptRescheduleError,
  selectIsLoadingAcceptReschedule,
  selectHasFinishedAcceptReschedule,
  declineRescheduleRequestAction,
  selectDeclineRescheduleError,
  selectIsLoadingDeclineReschedule,
  selectHasFinishedDeclineReschedule,
  ProposeRescheduleRequestActionProps,
} from '../index';
import {
  AcceptRescheduleRequestProps,
  DeclineRescheduleRequestProps,
} from '../../../api/boostCallEvents';

const useRescheduleBoostCallRequest = () => {
  // HOOKS
  const { useSelector, dispatch } = useRedux();

  // ACTIONS
  // accept
  const proposeRescheduleRequest = ({
    id,
    newStartDateTime,
    newCallUrl,
  }: ProposeRescheduleRequestActionProps) =>
    dispatch(
      proposeRescheduleRequestAction({ id, newStartDateTime, newCallUrl })
    );

  // accept
  const acceptRescheduleRequest = ({
    id,
    newStartDateTime,
  }: AcceptRescheduleRequestProps) =>
    dispatch(acceptRescheduleRequestAction({ id, newStartDateTime }));

  // decline
  const declineRescheduleRequest = ({
    id,
    cancel,
    newStartDateTime,
    message,
  }: DeclineRescheduleRequestProps) =>
    dispatch(
      declineRescheduleRequestAction({ id, cancel, newStartDateTime, message })
    );

  // SELECTORS
  // propose
  const isLoadingProposeReschedule = useSelector(
    selectIsLoadingProposeReschedule
  );
  const hasFinishedProposeReschedule = useSelector(
    selectHasFinishedProposeReschedule
  );
  const proposeRescheduleError = useSelector(selectProposeRescheduleError);

  // accept
  const isLoadingAcceptReschedule = useSelector(
    selectIsLoadingAcceptReschedule
  );
  const hasFinishedAcceptReschedule = useSelector(
    selectHasFinishedAcceptReschedule
  );
  const acceptRescheduleError = useSelector(selectAcceptRescheduleError);

  // decline
  const isLoadingDeclineReschedule = useSelector(
    selectIsLoadingDeclineReschedule
  );
  const hasFinishedDeclineReschedule = useSelector(
    selectHasFinishedDeclineReschedule
  );
  const declineRescheduleError = useSelector(selectDeclineRescheduleError);

  return {
    // propose
    proposeRescheduleRequest,
    isLoadingProposeReschedule,
    hasFinishedProposeReschedule,
    proposeRescheduleError,
    // accept
    acceptRescheduleRequest,
    isLoadingAcceptReschedule,
    hasFinishedAcceptReschedule,
    acceptRescheduleError,
    // decline
    declineRescheduleRequest,
    isLoadingDeclineReschedule,
    hasFinishedDeclineReschedule,
    declineRescheduleError,
  };
};

export default useRescheduleBoostCallRequest;
