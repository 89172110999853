/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import './styling/bookings.scss';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import NewUpcomingCard from '../NewUpcomingCard';
import NewRecordingCard from '../NewRecordingCard';
import { styled } from '@mui/material/styles';
import {
  CustomRequestsLabel,
  LoadMoreButton,
  CustomMobileRequestsLabel,
} from '../styling';
import BookingsEmptyPage from './bookingsEmptyPage';
import useWindowSize from 'hooks/useWindowSize';
import NewCompletedCard from '../NewCompletedCard';
import NewRescheduleCard from '../NewRescheduleCard';
import UpcomingDetails from '../UpcomingDetails';
import useGetBoostCallEventsCompleted from 'apiHooks/boostCalls/useGetBoostCallEventsCompleted';
import { BoostCallEventWithVolunteerProfile } from 'types';
import FullScreenLoading from 'components/FullScreenLoading';
import NewRequestedCard from '../NewRequestedCard';
import RequestsEmptyPage from './requestsEmptyPage';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface Props {
  upcoming: Array<any>;
  groupEventsCompleted: any[];
  timeZoneString?: string;
  refetchEvents: any;
  requested: Array<any>;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const { width = window.innerWidth } = useWindowSize();
  const isMobile = width && width < 500;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: isMobile ? 0 : 3 }}>{children}</Box>}
    </div>
  );
}

const BookingsTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'primary',
  },
});

const Bookings = ({
  upcoming,
  requested,
  groupEventsCompleted,
  timeZoneString = '',
  refetchEvents,
}: Props) => {
  const [nextURL, setNextURL] = useState<string | undefined>(undefined);
  const [displayCompleteEvents, setDisplayCompleteEvents] = useState<
    BoostCallEventWithVolunteerProfile[]
  >([]);
  const {
    count,
    next,
    boostCallEventsCompleted,
    hasFinishedLoadingBoostCallEventsCompleted,
  } = useGetBoostCallEventsCompleted(nextURL);

  useEffect(() => {
    if (boostCallEventsCompleted && boostCallEventsCompleted.length > 0) {
      let completedEvents = [
        ...displayCompleteEvents,
        ...boostCallEventsCompleted,
      ];
      completedEvents = completedEvents?.sort(
        (event1: any, event2: any) =>
          new Date(event2.start_time).valueOf() -
          new Date(event1.start_time).valueOf()
      );
      setDisplayCompleteEvents(completedEvents);
    }
  }, [boostCallEventsCompleted]);
  const [requestedCounter, setRequestedCounter] = useState(4);
  const displayRequestedEvents = () =>
    requested.length > 0 ? (
      requested
        .slice(0, requestedCounter)
        .map((event, index) => (
          <NewRequestedCard
            key={index}
            timeZoneString={timeZoneString}
            appointment={event}
          />
        ))
    ) : (
      <RequestsEmptyPage />
    );
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;

  const [upcomingCounter, setUpcomingCounter] = useState(4);
  const [completedCounter, setCompletedCounter] = useState(4);

  const [details, setDetails] = useState(null);

  const [valueTab, setValueTab] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  const addArraytoArray = (arr1: any[], arr2: any[]) => {
    let i = 0;
    while (i < arr2.length) {
      arr1.push(arr2[i]);
      i++;
    }
    return arr1;
  };
  const sortByPriority = (events: any[]) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    var topPriorityRequests = events.filter(
      (event) =>
        event.status === 'scheduled' && new Date(event.start_time) <= tomorrow
    );
    var secondPriorityRequests = events.filter(
      (event) => event.reschedule_status === 'pending'
    );
    var thirdPriority = events.filter(
      (event) =>
        event.status === 'scheduled' &&
        event.reschedule_status !== 'pending' &&
        new Date(event.start_time) > tomorrow
    );
    thirdPriority = thirdPriority.sort(
      (event1: { start_time: string }, event2: { start_time: string }) =>
        new Date(event1.start_time).valueOf() -
        new Date(event2.start_time).valueOf()
    );
    const top3Requests = [
      ...topPriorityRequests,
      ...secondPriorityRequests,
      ...thirdPriority,
    ];
    const remainingEvents = events.filter(
      ({ id: id1 }) => !top3Requests.some(({ id: id2 }) => id2 === id1)
    );

    var finalEventsArray: any[] = [];
    addArraytoArray(finalEventsArray, topPriorityRequests);
    addArraytoArray(finalEventsArray, secondPriorityRequests);
    addArraytoArray(finalEventsArray, thirdPriority);
    addArraytoArray(finalEventsArray, remainingEvents);

    //remove duplicates
    finalEventsArray = finalEventsArray.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );
    return finalEventsArray;
  };

  const displayEvents = (value: any) => {
    if (value === 0) return displayRequestedEvents();
    if (value === 1) return displayScheduledEvents();
    else return displayMasterclassRecordings();
  };

  const bookingsBadge = () => <span> ({upcoming.length})</span>;
  const displayScheduledEvents = () => {
    //remove duplicates
    //remove events where volunteers deleted account
    const finalEvents = upcoming.filter((event) => event.volunteer !== null);

    return sortByPriority(finalEvents)
      .slice(0, upcomingCounter)
      .map((event, index) => {
        if (event.reschedule_status === 'pending')
          return (
            <NewRescheduleCard
              key={index}
              timeZone={timeZoneString}
              appointmentData={event}
              subject="Upcoming"
              clickManage={(e) => setDetails(e)}
              refetchEvents={refetchEvents}
            />
          );
        else if (event.status === 'scheduled')
          return (
            <NewUpcomingCard
              key={index}
              timeZone={timeZoneString}
              appointmentData={event}
              subject="Upcoming"
              refetchEvents={refetchEvents}
              clickManage={(e) => setDetails(e)}
            />
          );
        else return null;
      });
  };

  const displayMasterclassRecordings = () =>
    groupEventsCompleted.map((event) =>
      event.recording_url ? (
        <NewRecordingCard
          timeZone={timeZoneString}
          appointmentData={event}
          subject="Upcoming"
        />
      ) : null
    );

  if (details) {
    return (
      <UpcomingDetails
        details={details}
        timeZone={timeZoneString}
        refetchEvents={refetchEvents}
      />
    );
  } else {
    return (
      <div className="dashboard-bookings-container">
        <div className="dashboard-title-component">Mentoring</div>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <BookingsTabs
              value={valueTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{ width: '25%' }}
                value={0}
                label={
                  !isMobile ? (
                    <CustomRequestsLabel>Requests</CustomRequestsLabel>
                  ) : (
                    <CustomMobileRequestsLabel>
                      Requests
                    </CustomMobileRequestsLabel>
                  )
                }
              />
              <Tab
                sx={{ width: '25%' }}
                value={1}
                label={
                  !isMobile ? (
                    <CustomRequestsLabel>
                      Upcoming {bookingsBadge()}
                    </CustomRequestsLabel>
                  ) : (
                    <CustomMobileRequestsLabel>
                      Upcoming {bookingsBadge()}
                    </CustomMobileRequestsLabel>
                  )
                }
              />
              <Tab
                sx={{ width: '25%' }}
                value={2}
                label={
                  !isMobile ? (
                    <CustomRequestsLabel>Completed</CustomRequestsLabel>
                  ) : (
                    <CustomMobileRequestsLabel>
                      Completed
                    </CustomMobileRequestsLabel>
                  )
                }
              />
            </BookingsTabs>
          </Box>
          <TabPanel value={valueTab} index={0} key={0}>
            {displayEvents(valueTab)}
            <Box>
              {requested.length > 4 && requestedCounter < requested.length ? (
                <LoadMoreButton
                  disabled={requestedCounter >= requested.length}
                  variant="contained"
                  onClick={() => setRequestedCounter(requestedCounter + 4)}
                >
                  Load more
                </LoadMoreButton>
              ) : null}
            </Box>
          </TabPanel>
          <TabPanel value={valueTab} index={1} key={1}>
            {upcoming.length > 0 ? (
              <div className="tab-panel-bookings-container">
                {displayEvents(valueTab)}
                <div className="loading-button-container">
                  {upcomingCounter < upcoming.length ? (
                    <LoadMoreButton
                      disabled={upcomingCounter >= upcoming.length}
                      variant="contained"
                      onClick={() => setUpcomingCounter(upcomingCounter + 4)}
                    >
                      Load more
                    </LoadMoreButton>
                  ) : null}
                </div>
              </div>
            ) : (
              <BookingsEmptyPage text="You have no upcoming events" />
            )}
          </TabPanel>
          {hasFinishedLoadingBoostCallEventsCompleted ||
          displayCompleteEvents.length > 0 ? (
            <TabPanel value={valueTab} index={2} key={2}>
              {count && count > 0 ? (
                <div className="tab-panel-bookings-container">
                  {displayCompleteEvents.map((event, index) => (
                    <NewCompletedCard
                      key={index}
                      timeZone={timeZoneString}
                      appointmentData={event}
                      subject="Upcoming"
                      refetchEvents={refetchEvents}
                    />
                  ))}
                  <div className="loading-button-container">
                    {completedCounter < count ? (
                      <LoadMoreButton
                        disabled={completedCounter >= count}
                        variant="contained"
                        onClick={() => {
                          setNextURL(next);
                          if (completedCounter + 4 > count)
                            setCompletedCounter(count);
                          else setCompletedCounter(completedCounter + 4);
                        }}
                      >
                        Load more
                      </LoadMoreButton>
                    ) : null}
                  </div>
                </div>
              ) : (
                <BookingsEmptyPage text="You have no completed events" />
              )}
            </TabPanel>
          ) : (
            <FullScreenLoading />
          )}
        </Box>
      </div>
    );
  }
};

export default memo(Bookings);
