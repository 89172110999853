import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Taxonomy, ValueToggle } from '../../../types';
import { useSystemTogglesContext } from '../../../context/SystemToggles';
import { CustomTypography } from 'styling/generalStyling';

type Props = {
  value: Taxonomy;
  skillsList: Taxonomy[];
  setCurrentSkills: React.Dispatch<React.SetStateAction<Taxonomy[]>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  currentSkills: Taxonomy[];
};

export default function SkillsForm({
  skillsList,
  value,
  setCurrentSkills,
  currentSkills,
  setErrorMessage,
}: Props) {
  const [skills] = useState(skillsList);
  const { toggles } = useSystemTogglesContext();
  const maxSkills: ValueToggle = toggles.MAX_SKILLS as ValueToggle;

  return (
    <FormControl>
      <FormGroup
        sx={{ display: 'inline-flex', flexDirection: 'column', rowGap: '0px' }}
      >
        {skills
          .filter((skill) => skill.parent === value.id)
          .map((subcat) => (
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    height: '30px',
                  }}
                  checked={currentSkills.indexOf(subcat) !== -1}
                  onChange={() => {
                    if (currentSkills.indexOf(subcat) !== -1) {
                      setCurrentSkills(
                        currentSkills.filter((skill) => skill !== subcat)
                      );
                    } else if (currentSkills.length < maxSkills.value)
                      setCurrentSkills((currentSkills) => [
                        ...currentSkills,
                        subcat,
                      ]);
                    else
                      setErrorMessage(
                        `You cannot select more than ${maxSkills.value} skills`
                      );
                  }}
                  name={subcat.name}
                  color="primary"
                />
              }
              label={<CustomTypography> {subcat.name}</CustomTypography>}
            />
          ))}
      </FormGroup>
    </FormControl>
  );
}
