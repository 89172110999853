import React, { useState } from 'react';
import './delete-mentor-pop-up.scss';
import Button from '../Button';

interface Props {
  onClick: any;
}

const DeleteMentorPopUp = ({ onClick }: Props) => {
  const [selected, setSelected] = useState(false);

  return (
    <div className="delete-mentor-pop-up">
      {selected && (
        <div className={`remove ${selected}`}>
          <div>
            Are you sure you want to remove this mentor from your selected list?
          </div>

          <Button
            text={'Yes, remove'}
            type={'red'}
            onClickFunction={() => {
              onClick();
              setSelected(false);
            }}
          />
        </div>
      )}

      <button
        onClick={() => setSelected(!selected)}
        className={`select-button ${selected}`}
      >
        Remove
      </button>
    </div>
  );
};

export default DeleteMentorPopUp;
