import { useAxios } from '../../api/config';
import {
  BoostCallEvents,
  BoostCallEventWithVolunteerProfile,
} from '../../types';

const useGetBoostCallEventsPending = () => {
  const [{ data, loading, error }, refetch] = useAxios<BoostCallEvents>({
    url: `api/event/boostcallrequests/?status=pending`,
  });
  return {
    boostCallEventsPending:
      (data?.results as BoostCallEventWithVolunteerProfile[]) || [],
    boostCallEventsPendingCount: data?.count || 0,
    hasFinishedLoadingBoostCallEventsPending: !loading,
    boostCallEventsPendingError: error,
    refetchBoostCallEventsPending: refetch,
  };
};

export default useGetBoostCallEventsPending;
