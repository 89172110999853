import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import '../components/styling/card-styling.scss';
import { declineGroupEvent } from 'api/groupEvents';
import { DashboardFilledButton, DashboardEmptyButton } from 'styling/buttons';

type Props = {
  inviteData: any;
  className?: string;
};

const NewInviteCard = ({ inviteData }: Props) => {
  const navigate = useNavigate();
  const { id, code, title } = inviteData;
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };
  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '8px',
    transform: 'translate(-50%, -50%)',
    width: ' 554px',
    height: '200px',
    margin: '9px 29px 217px 76px',
    bgcolor: ' #fff',
    boxShadow: 24,
    p: 4,
  };
  return (
    <div className="dashboard-box">
      <Box>
        <Paper
          key={id}
          sx={{
            borderRadius: '8px',
            boxShadow: '8px 5px 30px 0 rgba(0, 0, 0, 0.06)',
          }}
        >
          <div>
            <div className="rectangle-icon-container">
              <div className="invitation-rectangle-icon">
                <div className="rectangle-icon-text"> Invitation</div>
              </div>
            </div>

            <div className="dashboard-card__container">
              <img
                className="mastercard-photo-card"
                src={'/masterclass-icon.png'}
                alt={'masterclass'}
              />
              <div className="dashboard-card-header-info">
                <div className="dashboard-card-title">
                  <div className="dashboard-card-name">
                    Invitation to join a{' '}
                    {capitalizeFirstLetter(inviteData.type)}
                  </div>
                </div>
                <div className="invite-card-interest">{title}</div>

                <div className="invitation-paper-button-wrapper">
                  <div className="dashboard-button-container">
                    <DashboardFilledButton
                      sx={{ width: '150px' }}
                      variant="contained"
                      onClick={() =>
                        navigate(`/group-event-details?code=${code}`)
                      }
                    >
                      View Details
                    </DashboardFilledButton>
                  </div>
                  <div className="dashboard-link-button-container">
                    <DashboardEmptyButton
                      sx={{
                        color: '#ff5f5f',
                      }}
                      onClick={handleOpen}
                    >
                      Decline
                    </DashboardEmptyButton>
                  </div>
                </div>
              </div>
              <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                  <div className="decline-invite-card-name">
                    Decline Masterclass invite
                  </div>
                  <div className="decline-invite-container">
                    <div>
                      Are you sure you want to decline this masterclass invite?
                    </div>
                    <div className="cancel-invitation-button-wrapper">
                      <DashboardEmptyButton
                        sx={{ color: '#ff5f5f' }}
                        onClick={async () => {
                          await declineGroupEvent({ groupEventCode: code });
                          window.location.reload();
                        }}
                      >
                        Confirm decline
                      </DashboardEmptyButton>
                      <DashboardEmptyButton onClick={handleClose}>
                        Cancel
                      </DashboardEmptyButton>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default NewInviteCard;
