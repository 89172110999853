import { useAxios } from 'api/config';
import { getErrorMessageFromResponse } from 'utils/error';
import { Partner } from 'types';

const useFetchPartner = (id: number | null) => {
  const [{ data, loading, error }, executeCall] = useAxios<Partner>({
    url: `api/partner/partners/${id}/`,
    method: 'GET',
  });

  return {
    partner: data,
    isLoadingPartner: loading,
    hasFinishedLoadingPartner: !loading,
    partnerError: getErrorMessageFromResponse(error),
    refetchPArtner: executeCall,
  };
};

export default useFetchPartner;
