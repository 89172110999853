import React from 'react';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import styles from './faq-indicator.module.scss';

type Props = {
  className?: string;
  partner: any;
};

const FaqIndicator = ({ className, partner }: Props) => {
  const faq = partner?.faq
    ? partner.faq
    : 'https://digitalboost.org.uk/frequently-asked-questions-mentee-platform/';

  return (
    <div className={`${styles.container} ${className ? className : ''}`}>
      <a href={faq} target="_blank" rel="noreferrer" className={styles.icon}>
        <HelpOutlineOutlinedIcon fontSize="medium" />
      </a>
      <div className={styles.text_container}>
        <span className={styles.text}>
          Check out our{' '}
          <a href={faq} target="_blank" rel="noreferrer">
            FAQs
          </a>{' '}
          and handy resources
        </span>
        <div className={styles.pointer} />
      </div>
    </div>
  );
};

export default FaqIndicator;
