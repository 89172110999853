import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ToolTip from '../../components/ToolTip';
import apiInstance from '../../api/config';
import Button from '../../components/Button';
import InputWithLabel from '../../components/InputWithLabel';
import TextAreadWithLabel from '../../components/TextAreaWithLabel';
import ReactTooltip from 'react-tooltip';
import { getUrlParam } from '../../utils/url';
import { replaceUnderscoreWithSpaces } from '../../utils/text';
import ImageUploader from 'react-images-upload';
import { UserProfile, Industry } from '../../types';
import api from '../../api';
import useGetIndustries from '../../apiHooks/useGetIndustries';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { useSystemTogglesContext } from '../../context/SystemToggles';
import ReactHtmlParser from 'react-html-parser';
import { Tooltip } from '@mui/material';
import Cookie from 'js-cookie';

import { capitalCase } from 'change-case';
import SelectOrgType from '../../components/SelectOrgType';

import 'react-datepicker/dist/react-datepicker.css';
import './partner-integrated-signup.scss';
import '../OldSelectAvailability/select-availability.scss';

const genderData = [
  { id: 0, label: 'Woman', value: 'female' },
  { id: 1, label: 'Man', value: 'male' },
  { id: 2, label: 'Non Binary', value: 'non-binary' },
  {
    id: 3,
    label: "I don't identify with any of these genders",
    value: 'dont-identify',
  },
  { id: 4, label: 'Prefer not to say', value: 'other' },
];

const PartnerIntegratedSignUp = () => {
  const navigate = useNavigate();
  const code = getUrlParam('code');

  const { toggles } = useSystemTogglesContext();
  const autoDetectTimezone = toggles['TIMEZONE_AUTO_DETECT'];

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions()?.timeZone;

  const { setOrganisationProfile, setUserLocation, partner } =
    useSelectProfileData();
  const { industries: industryList } = useGetIndustries();

  const [isLocationSet, setIsLocationSet] = useState(false);

  const [countryList, setCountryList] = useState<any[]>([]);
  const [ethnicityList, setEthnicityList] = useState<any[]>([]);
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [cookiePolicy, setCookiePolicy] = useState(false);
  const [customTermsConsented, setCustomTermsConsented] =
    useState<boolean>(false);
  const [partnerQuestionCheck, setPartnerQuestionCheck] =
    useState<boolean>(false);

  const [profilePhoto, setProfilePhoto] = useState<string>('/avatar.png');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [gender, setGender] = useState<string | null>('');
  const [ethnicity, setEthnicity] = useState<number>();
  const [customEthnicity, setCustomEthnicity] = useState<string>('');
  const [country, setCountry] = useState<string>('GBR');
  const [timezone, setTimezone] = useState<string>(
    browserTimezone || 'Europe/London'
  );
  const [postCode, setPostCode] = useState<string>('');
  const [bio, setBio] = useState<string>('');
  const [businessType, setBusinessType] = useState<string>('business');
  const [orgName, setOrgName] = useState<string>('');
  const [industry, setIndustry] = useState<number>();
  const [website, setWebsite] = useState<string>('');
  const [newsletterOptIn, setNewsletterOptIn] = useState<boolean>(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [nameError, setNameError] = useState<boolean>(false);
  const [nameLengthError, setNameLengthError] = useState<boolean>(false);
  const [genderError, setGenderError] = useState<boolean>(false);
  const [countryError, setCountryError] = useState<boolean>(false);
  const [postCodeError, setPostCodeError] = useState<boolean>(false);
  const [outsidePostCodeError, setOutsidePostCodeError] =
    useState<boolean>(false);
  const [bioError, setBioError] = useState<boolean>(false);
  const [orgNameError, setOrgNameError] = useState<boolean>(false);
  const [industryError, setIndustryError] = useState<boolean>(false);
  const [websiteError, setWebsiteError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<any>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);
  const [locationErrorMessage, setLocationErrorMessage] = useState<
    string | boolean
  >(false);
  const [orgErrorMessage, setOrgErrorMessage] = useState<string | boolean>(
    false
  );

  const firstNameEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const genderEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const countryEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const postCodeEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const bioEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const orgNameEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const passwordEl = useRef() as React.MutableRefObject<HTMLInputElement>;
  const confirmPasswordEl =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  let timeZoneListToBeSet = moment.tz.names();
  timeZoneListToBeSet.push('');
  const [timezoneList] = useState<any>(timeZoneListToBeSet);

  const handleNext = async () => {
    let error = false;
    setNameError(false);
    setNameLengthError(false);
    setGenderError(false);
    setCountryError(false);
    setPostCodeError(false);
    setBioError(false);
    setOrgNameError(false);
    setIndustryError(false);
    setOrgErrorMessage(false);
    setLocationErrorMessage(false);

    if (
      !firstName ||
      !lastName ||
      !gender ||
      !country ||
      !postCode ||
      !bio ||
      !orgName ||
      !password ||
      !confirmPassword ||
      confirmPassword !== password ||
      password.length < 8
    ) {
      let elToFocus;

      if (!password) {
        setPasswordError('Password is a required fields');
        elToFocus = passwordEl;
      }
      if (password && password.length < 8) {
        setPasswordError(
          'This password is too short. It must contain at least 8 characters'
        );
        elToFocus = confirmPasswordEl;
      }
      if (!confirmPassword) {
        setConfirmPasswordError('Confirm password is a required field');
        elToFocus = confirmPasswordEl;
      }
      if (password !== confirmPassword) {
        setConfirmPasswordError('Password must match');
        elToFocus = passwordEl;
      }
      if (!orgName) {
        setOrgNameError(true);
        elToFocus = orgNameEl;
      }
      if (!bio) {
        setBioError(true);
        elToFocus = bioEl;
      }
      if (!postCode && country === 'GBR') {
        setPostCodeError(true);
        elToFocus = postCodeEl;
      }
      if (!postCode && country !== 'GBR') {
        setOutsidePostCodeError(true);
        elToFocus = postCodeEl;
      }
      if (!country) {
        setCountryError(true);
        elToFocus = countryEl;
      }
      if (!gender) {
        setGenderError(true);
        elToFocus = genderEl;
      }
      if (!firstName || !lastName) {
        setNameError(true);
        elToFocus = firstNameEl;
      } else {
        if (firstName.length <= 2 || lastName.length <= 2) {
          setNameLengthError(true);
          elToFocus = firstNameEl;
        }
      }

      elToFocus?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
      return;
    }

    try {
      await apiInstance.post(`api/core/changeintegratedpassword/`, {
        new_password: password,
      });

      let promiseArray: any[] = [];

      if (isLocationSet) {
        promiseArray.push(updateLocation());
      } else {
        promiseArray.push(createLocation());
      }
      promiseArray.push(createOrganisation());
      promiseArray.push(updateDetails());

      Promise.all(promiseArray)
        .then((results) => {
          if (results[1].status === 200 || results[1].status === 201) {
            setOrganisationProfile(results[1].data); // make sure to update context
          } else {
            setOrgErrorMessage(
              results[1].response.data[Object.keys(results[1].response.data)[0]]
            );
            error = true;
          }

          if (results[0].status === 200 || results[0].status === 201) {
            setUserLocation(results[0].data); // make sure to update context
          } else {
            setLocationErrorMessage(
              results[0].response.data[Object.keys(results[0].response.data)[0]]
            );
            error = true;
          }

          if (!error) {
            Cookie.set('loggedIn', 'true', { expires: 1 });
            navigate(`/mentor-select`);
          }
        })
        .catch((err: any) => console.log('Promise ALL:', err));
    } catch (err: any) {
      let elToFocus;

      setPasswordError(err.response?.data?.new_password);
      elToFocus = passwordEl;

      elToFocus?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  };

  const createOrganisation = async () => {
    const pattern = /^((http|https|ftp):\/\/)/;

    let newWebsite = website;

    if (!pattern.test(newWebsite) && newWebsite !== '') {
      newWebsite = 'http://' + newWebsite;
    }

    return apiInstance
      .post(`api/organisation/organisations/`, {
        first_name: firstName,
        last_name: lastName,
        name: orgName,
        registration_number: '?',
        website: newWebsite,
        partner: partner?.id,
        org_type: businessType.toLowerCase(),
        ethnicity: ethnicity,
        custom_ethnicity: customEthnicity,
        industry: industry,
        privacy_preferences: {
          newsletter: newsletterOptIn,
        },
      })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };

  const updateDetails = async () => {
    return apiInstance
      .patch(`api/core/users/me/`, {
        first_name: firstName,
        last_name: lastName,
        gender: gender,
        summary: bio,
      })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };

  const updateProfilePhoto = useCallback(
    async (files: File[]) => {
      const formData = new FormData();

      formData.append('profile_picture', files[0]);
      try {
        await apiInstance.patch<UserProfile>(`api/core/users/me/`, formData);
        api.getUserProfile().then((response) => {
          if (response.profile_picture) {
            setProfilePhoto(response.profile_picture || profilePhoto);
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    [profilePhoto]
  );

  const onDrop = (files: File[]) => {
    updateProfilePhoto(files);
  };

  const updateLocation = async () => {
    return apiInstance
      .patch(`api/location/locations/me/`, {
        postcode: postCode,
        country: country,
        timezone: timezone,
      })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };

  const createLocation = async () => {
    return apiInstance
      .post(`api/location/locations/`, {
        postcode: postCode,
        country: country,
        timezone: timezone,
      })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        return err;
      });
  };

  const downloadFileFromURL = async (url: string, filename: string) => {
    try {
      await fetch(url)
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          const file = new File([blob], filename, { type: blob.type });
          updateProfilePhoto([file]);
        });
    } catch (error) {
      console.error('There was a problem:', error);
      return [];
    }
  };

  useEffect(() => {
    (async () => {
      window.scrollTo(0, 0);

      const { data: countryData } = await api.getCountryList();
      setCountryList(countryData.results);

      const { data: ethnicityData } = await api.getEthnicityList();
      setEthnicityList(ethnicityData.results);

      const locationResponse = await api.getUserLocation();
      if (locationResponse) {
        setIsLocationSet(true);
        setCountry(locationResponse.country);
        setPostCode(locationResponse.postcode);
        setTimezone(locationResponse.timezone);
      }

      if (code) {
        const partnerIntegratedDataResponse =
          await api.getPartnerIntegratedData(code as string);

        if (partnerIntegratedDataResponse) {
          setProfilePhoto(
            partnerIntegratedDataResponse?.picture || '/avatar.png'
          );

          if (partnerIntegratedDataResponse?.picture) {
            var filename = partnerIntegratedDataResponse?.picture
              .split('/')
              .pop();
            downloadFileFromURL(
              partnerIntegratedDataResponse?.picture,
              filename
            );
          }

          setFirstName(partnerIntegratedDataResponse?.first_name || '');
          setLastName(partnerIntegratedDataResponse?.last_name || '');
          setGender(partnerIntegratedDataResponse?.gender || '');
          setEthnicity(partnerIntegratedDataResponse?.ethnicity);
          setCountry(
            countryData?.results?.find(
              (item: any) =>
                item.code === partnerIntegratedDataResponse?.country
            )?.code || 'GBR'
          );
          setPostCode(partnerIntegratedDataResponse?.postcode || '');
          setBio(partnerIntegratedDataResponse?.summary || '');
          setBusinessType(
            partnerIntegratedDataResponse?.org_type || 'business'
          );
          setOrgName(partnerIntegratedDataResponse?.business_name || '');
          setIndustry(partnerIntegratedDataResponse?.industry);
          setWebsite(partnerIntegratedDataResponse?.website || '');
        }
      }
    })();
    // eslint-disable-next-line
  }, [code]);

  const ethnicityGroups = ethnicityList.filter((a) => a.parent === null);
  const ethnicityOptgroup = ethnicityGroups.map((item) => {
    const ethnicitySubGroup = ethnicityList.filter((a) => a.parent === item.id);
    const ethnicityOptions = ethnicitySubGroup.map((data) => {
      return (
        <option
          key={`ethnicity-${data.id}`}
          defaultValue={ethnicity}
          value={data.id}
        >
          {data.name}
        </option>
      );
    });

    return (
      <optgroup key={`ethnicity-group-${item.id}`} label={item.name}>
        {ethnicityOptions}
      </optgroup>
    );
  });

  const disableButton = partner
    ? !cookiePolicy ||
      (partner?.gdpr_permission_required_org && !partnerQuestionCheck) ||
      (partner?.custom_terms_text_org !== null && !customTermsConsented)
    : !cookiePolicy;

  return (
    <div className="partner-integrated select-availability">
      <ReactTooltip place={'top'} arrowColor={'#333333'} />

      <ReactTooltip place="top" id="bio-tooltip" aria-haspopup="true">
        <ul>
          <li>
            a sentence or two about who you are, your background/experience
          </li>
          <li>
            a brief overview of your organisation and its mission (or your
            business idea - if you haven’t started yet)
          </li>
          <li>
            a fun fact! Stuck for inspo? Tell your mentors what song gets you on
            the dance floor, what tv series you binge on repeat or the person
            that inspires you the most
          </li>
        </ul>
      </ReactTooltip>

      <div className="border" />
      <div>
        <div className="org-details" data-cy="org-details">
          {locationErrorMessage && (
            <div className="error">{locationErrorMessage}</div>
          )}
          <h2>
            <span className="title-number">1</span>Details: Please can you let
            us know more about you?
          </h2>

          <div className="sub-border" />

          <div className="profile-picture-wrapper">
            <div
              className="profile-picture"
              style={{ backgroundImage: `url(${profilePhoto})` }}
            />
            <ImageUploader
              withIcon={false}
              withPreview={false}
              label=""
              singleImage={true}
              buttonText={`${profilePhoto ? 'Change' : 'Upload'} Profile Image`}
              onChange={onDrop}
              imgExtension={[
                '.jpg',
                '.gif',
                '.png',
                '.gif',
                '.svg',
                '.peg',
                '.jpeg',
              ]}
              maxFileSize={2248576}
              fileSizeError="File size is too big, please keep it below 2 MB"
            />
          </div>
          <div className="names" ref={firstNameEl}>
            <InputWithLabel
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFirstName(e.target.value);
                if (!!lastName && !!nameError) setNameError(false);
                if (lastName.length > 2 && !!nameLengthError)
                  setNameLengthError(false);
              }}
              type={'text'}
              maxLength={30}
              label={'First Name'}
              defaultValue={firstName}
              mandatory
            />
            <InputWithLabel
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLastName(e.target.value);
                if (!!firstName && !!nameError) setNameError(false);
                if (firstName.length > 2 && !!nameLengthError)
                  setNameLengthError(false);
              }}
              type={'text'}
              label={'Last Name'}
              maxLength={30}
              defaultValue={lastName}
              mandatory
            />
          </div>
          {nameError && (
            <span className="add-details__validation-error">
              {'First Name and Last Name are required fields'}
            </span>
          )}
          {nameLengthError && (
            <span className="add-details__validation-error">
              {'First Name and/or Last Name are too short'}
            </span>
          )}

          <div className="edit-gender" ref={genderEl}>
            <fieldset
              className="radio-wrapper"
              data-cy="radio-wrapper-gender"
              aria-required="true"
            >
              <div>
                <label>
                  Gender
                  {
                    <span className="add-details__mandatory-asterisk">
                      {'\u002A '}
                    </span>
                  }
                </label>
                <p>
                  Please state which gender identity you most identify with.
                 
                </p>
              </div>

              {genderData?.map((genderItem) => (
                <span
                  key={genderItem.id}
                  onClick={() => {
                    setGender(genderItem.value);
                    setGenderError(false);
                  }}
                >
                  <input
                    type="radio"
                    value={genderItem.value}
                    checked={gender === genderItem.value}
                    required
                  />
                  <span>{genderItem.label}</span>
                </span>
              ))}
              <span
                className="question"
                data-tip={
                  'Providing your gender data is optional but it will help us make more suitable recommendations for you. We will report this data anonymously to our partners to improve diversity of our services.'
                }
              >
                <img
                  width={'5px'}
                  height="8px"
                  src="/question.png"
                  alt="question"
                />
              </span>
            </fieldset>
            {genderError && (
              <span className="add-details__validation-error">
                {'Please choose a value'}
              </span>
            )}

            <div className="select-ethnicity">
              <label>Ethnicity</label>

              <select
                value={ethnicity}
                onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                  setEthnicity(parseInt(e.currentTarget.value));
                  if (
                    ethnicityList.filter((a) => a.id === ethnicity).length >
                      0 &&
                    !ethnicityList.filter((a) => a.id === ethnicity)[0]
                      .allow_custom
                  ) {
                    setCustomEthnicity('');
                  }
                }}
              >
                <option
                  key="ethnicity-empty"
                  defaultValue={ethnicity}
                  value=""
                ></option>
                {ethnicityOptgroup}
              </select>
              <span
                style={{ position: 'absolute', marginTop: '19px' }}
                className="question"
                data-tip={
                  'Providing your ethnicity data is optional but it will help us make more suitable recommendations for you. We will report this data anonymously to our partners to improve diversity of our services.'
                }
              >
                <img
                  width={'5px'}
                  height="8px"
                  src="/question.png"
                  alt="question"
                />
              </span>

              {ethnicityList.filter((a) => a.id === ethnicity).length > 0 &&
                ethnicityList.filter((a) => a.id === ethnicity)[0]
                  .allow_custom && (
                  <InputWithLabel
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setCustomEthnicity(e.target.value)
                    }
                    type="text"
                    maxLength={255}
                    placeHolder="Enter the ethnicity you indentify with"
                    defaultValue={customEthnicity}
                  />
                )}
            </div>
          </div>

          <div className="select-country" ref={countryEl}>
            <label>
              Country
              {
                <span className="add-details__mandatory-asterisk">
                  {'\u002A '}
                </span>
              }{' '}
            </label>
            <select
              value={country}
              onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                setCountry(e.currentTarget.value);
                setCountryError(false);
              }}
              required
            >
              {countryList.map((displayCountry, i) => {
                return (
                  <option
                    key={`country-${i}`}
                    defaultValue={country}
                    value={displayCountry.code}
                  >
                    {displayCountry.name}{' '}
                  </option>
                );
              })}
            </select>
          </div>
          {countryError && (
            <span className="add-details__validation-error">
              {'Please specify your country'}
            </span>
          )}
          {autoDetectTimezone ? (
            <div className="add-details__timezone-container">
              <label>Timezone</label>
              <div className="add-details__timezone-inner-container">
                <span>{replaceUnderscoreWithSpaces(timezone)}</span>
                <ToolTip
                  text="Timezone is automatically detected based on your device settings. Times will be displayed according to this timezone."
                  id="add-details-timezone-tooltip"
                  className="add-details__timezone-tooltip"
                />
              </div>
            </div>
          ) : (
            <div className="select-timezone">
              <label>
                Timezone
                {
                  <span className="add-details__mandatory-asterisk">
                    {'\u002A '}
                  </span>
                }{' '}
              </label>
              <select
                value={timezone}
                onChange={(e: React.FormEvent<HTMLSelectElement>) =>
                  setTimezone(e.currentTarget.value)
                }
              >
                {timezoneList.map((displayTimezone: any, i: number) => {
                  return (
                    <option
                      key={`timezone-${i}`}
                      defaultValue={timezone}
                      value={displayTimezone}
                    >
                      {replaceUnderscoreWithSpaces(displayTimezone)}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <div className="input-container" ref={postCodeEl}>
            <InputWithLabel
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPostCode(e.target.value);
                if (postCodeError) setPostCodeError(false);
                if (outsidePostCodeError) setOutsidePostCodeError(false);
              }}
              type={'text'}
              label={'Post Code'}
              defaultValue={postCode}
              mandatory
            />
            <span
              className="question"
              data-tip={
                'You only need to provide the first part of your postcode. Digital Boost receives funding by the UK government. We need this for our reporting purposes.'
              }
            >
              <img
                width={'5px'}
                height="8px"
                src="/question.png"
                alt="question"
              />
            </span>
          </div>

          <div>
            {postCodeError && (
              <span className="add-details__validation-error">
                {
                  'Please provide at least the first part of your postcode (UK only)'
                }
              </span>
            )}
            {outsidePostCodeError && (
              <span className="add-details__validation-error">
                {'Postcode is a required field'}
              </span>
            )}

            <div className="input-container" ref={bioEl}>
              <TextAreadWithLabel
                defaultValue={bio}
                onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setBio(e.target.value);
                  if (bioError) setBioError(false);
                }}
                label="Type in a bio of yourself"
                cols={60}
                rows={11}
                mandatory
                placeHolder="We're not looking for an autobiography (although you can if you want!) - this is a chance for your mentors to get to know you and decide whether you'll be a good match. Check the tooltip on the side for inspiration"
              />
              <span className="question" data-tip data-for="bio-tooltip">
                <img
                  width={'5px'}
                  height="8px"
                  src="/question.png"
                  alt="question"
                />
              </span>
            </div>
            {bioError && (
              <span className="add-details__validation-error">
                {'A short bio is required'}
              </span>
            )}
            <div className="org-type" ref={orgNameEl}>
              <SelectOrgType
                orgType={businessType}
                setOrgType={setBusinessType}
              />

              {orgErrorMessage && (
                <div className="error">{orgErrorMessage}</div>
              )}

              <InputWithLabel
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setOrgName(e.target.value);
                  if (orgNameError) setOrgNameError(false);
                }}
                type={'text'}
                label={`Name of ${capitalCase(businessType)}`}
                defaultValue={orgName}
                mandatory
              />
              {orgNameError && (
                <span className="add-details__validation-error">
                  {'A value is required for this field'}
                </span>
              )}
            </div>

            <div className="select-sector" data-cy="select-sector">
              <label>Sector {} </label>
              <select
                value={industry}
                onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                  setIndustry(+e.currentTarget.value);
                  if (industryError) setIndustryError(false);
                }}
              >
                <option value="" disabled selected>
                  Select your Sector
                </option>
                {industryList.map((displayIndustry: Industry, i: number) => {
                  return (
                    <option
                      key={`industry-${i}`}
                      defaultValue={''}
                      value={displayIndustry.id}
                    >
                      {displayIndustry.name}{' '}
                    </option>
                  );
                })}
              </select>
              {industryError && (
                <span className="add-details__validation-error">
                  {'Please choose which sector you are in '}
                </span>
              )}
            </div>
            <InputWithLabel
              defaultValue={website}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (
                  e.target.value !== undefined &&
                  !(
                    e.target.value.toLowerCase().startsWith('http') ||
                    e.target.value.toLowerCase().startsWith('www')
                  )
                )
                  setWebsiteError('Please introduce a valid website');
                else {
                  setWebsiteError(null);
                  setWebsite(e.target.value.toLowerCase());
                }
              }}
              type={'text'}
              label={`Website`}
            />
            {websiteError ? (
              <span className="add-details__validation-error">
                {websiteError}
              </span>
            ) : null}

            <div ref={passwordEl}>
              <InputWithLabel
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value);
                  if (!!password) setPasswordError(null);
                  if (password && password.length > 2) setPasswordError(null);
                }}
                type={'password'}
                label="Password"
                defaultValue={password}
                mandatory
              />
            </div>
            {passwordError && (
              <div className="partner-integrated__validation-error-container">
                {passwordError && Array.isArray(passwordError) ? (
                  passwordError.map((error) => (
                    <div className="partner-integrated__validation-error">
                      {error}
                    </div>
                  ))
                ) : (
                  <span className="partner-integrated__validation-error">
                    {passwordError}
                  </span>
                )}
              </div>
            )}

            <div ref={confirmPasswordEl}>
              <InputWithLabel
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setConfirmPassword(e.target.value);
                  if (!!confirmPassword) setConfirmPasswordError(null);
                  if (confirmPassword && confirmPassword.length > 2)
                    setConfirmPasswordError(null);
                }}
                type={'password'}
                label="Confirm Password"
                defaultValue={confirmPassword}
                mandatory
              />
            </div>
            {confirmPasswordError && confirmPasswordError.length > 0 && (
              <span className="partner-integrated__validation-error">
                {confirmPasswordError}
              </span>
            )}

            <div
              className="partner-integrated__cookie-checkbox"
              data-cy="cookie-check-box"
              onClick={() => setCookiePolicy(cookiePolicy ? false : true)}
            >
              <label>
                <input name="cookie" type="checkbox" checked={cookiePolicy} />
                <label>
                  I agree to the Digital Boost{' '}
                  <a
                    href="https://www.digitalboost.org.uk/legals/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    User Agreement
                  </a>
                  ,{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.digitalboost.org.uk/legals/privacy-policy"
                  >
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.digitalboost.org.uk/legals/cookiepolicy"
                  >
                    Cookie Policy
                  </a>
                </label>
              </label>
            </div>
            {partner && partner?.gdpr_permission_required_org ? (
              <div
                className="partner-integrated__cookie-checkbox partner-integrated__partner-terms"
                data-cy="cookie-check-box"
                onClick={() => setPartnerQuestionCheck(!partnerQuestionCheck)}
              >
                <input
                  name="cookie"
                  type="checkbox"
                  checked={partnerQuestionCheck}
                />
                <label className="partner-checkbox-help-text">
                  {partner?.custom_gdpr_text_org ? (
                    <>{ReactHtmlParser(partner?.custom_gdpr_text_org)}</>
                  ) : (
                    <>
                      I agree to share my information with <b>{partner.name}</b>
                    </>
                  )}
                  <Tooltip
                    open={showTooltip1}
                    onMouseEnter={() => setShowTooltip1(true)}
                    onMouseLeave={() => setShowTooltip1(false)}
                    title={`If you click this button, you agree that we can share with ${partner?.name} your contact information and how many mentoring requests you have made / completed. This is for reporting purposes and programme administration only, and never used for marketing.`}
                  >
                    <span className="login-signup__data-tip">
                      {' '}
                      What is shared and why?
                    </span>
                  </Tooltip>
                </label>
              </div>
            ) : null}
            {partner && partner?.custom_terms_text_org ? (
              <div
                onClick={() => setCustomTermsConsented(!customTermsConsented)}
                className="partner-integrated__cookie-checkbox partner-integrated__partner-terms"
              >
                <input
                  checked={customTermsConsented}
                  type={'checkbox'}
                  readOnly={true}
                  className="login-signup__terms-checkbox"
                />
                <label>
                  {' '}
                  {ReactHtmlParser(partner?.custom_terms_text_org)}{' '}
                  {partner?.custom_terms_explainer_org && '('}
                  {partner?.custom_terms_explainer_org && (
                    <span
                      className="login-signup__data-tip"
                      data-tip={partner?.custom_terms_explainer_org}
                    >
                      Read more
                    </span>
                  )}
                  {partner?.custom_terms_explainer_org && ')'}
                </label>
              </div>
            ) : null}

            <div className="partner-integrated__newsletter">
              <div className="newsletter-title">
                Would you like to join our newsletter to keep updated and
                connected? (We won't be annoying)
              </div>
              <input
                type="checkbox"
                checked={newsletterOptIn}
                onChange={() => setNewsletterOptIn(!newsletterOptIn)}
              />
              <label>I would like to receive newsletters</label>
            </div>

            <span className="add-details__mandatory-text">
              <span className="add-details__mandatory-asterisk">
                {'\u002A '}
              </span>{' '}
              Mandatory fields
            </span>
          </div>
        </div>
      </div>
      <div className="add-details__button-container">
        <Button
          disabled={disableButton}
          type={'primary'}
          dataCy={'NextButton'}
          text={'Next'}
          onClickFunction={() => {
            handleNext();
          }}
        />
      </div>
    </div>
  );
};

export default PartnerIntegratedSignUp;
