import React, { useState, useEffect } from 'react';
import './jitsi-container.scss';

// NOTE: This implimentation is from:
// https://dev.to/metamodal/add-jitsi-meet-to-your-react-app-33j9

// Extend Window type to allow JitsiMeetExternalAPI to exist on it
// This API is added by the https://meet.jit.si/external_api.js script
declare global {
  interface Window {
    JitsiMeetExternalAPI: any;
  }
}

type InitialiseProps = {
  jitsiContainerId: string;
  setJitsi: (arg: any) => void;
  jitsiOptions: {
    roomName: string;
  };
};

type ComponentProps = {
  jitsiOptions: {
    roomName: string;
  };
};

const loadJitsiScript = () => {
  let resolveLoadJitsiScriptPromise = null;

  const loadJitsiScriptPromise = new Promise((resolve) => {
    resolveLoadJitsiScriptPromise = resolve;
  });

  // Loading this script is crucial to add the Jitsi API to the window
  // see: https://dev.to/metamodal/add-jitsi-meet-to-your-react-app-33j9
  // see: https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe
  const script = document.createElement('script');
  script.src = 'https://meet.jit.si/external_api.js';
  script.async = true;
  script.onload = resolveLoadJitsiScriptPromise;
  document.body.appendChild(script);

  return loadJitsiScriptPromise;
};

const initialiseJitsi = async ({
  jitsiContainerId,
  setJitsi,
  jitsiOptions,
}: InitialiseProps) => {
  console.log('jitsiOptions:', jitsiOptions);
  if (!window.JitsiMeetExternalAPI) {
    await loadJitsiScript();
  }
  const config = {
    parentNode: document.getElementById(jitsiContainerId),
    requireDisplayName: true,
    ...jitsiOptions,
  };

  const _jitsi = new window.JitsiMeetExternalAPI('meet.jit.si', config);

  setJitsi(_jitsi);
};

const JitsiContainer = ({ jitsiOptions }: ComponentProps) => {
  const jitsiContainerId = 'jitsi-container-id';
  const [jitsi, setJitsi] = useState<any>({});

  useEffect(() => {
    initialiseJitsi({ jitsiContainerId, setJitsi, jitsiOptions });

    return () => jitsi?.dispose?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id={jitsiContainerId} className="jitsi-container">
      <img
        src="/main-logo.png"
        alt="digital boost"
        className="jitsi-container__logo"
      />
    </div>
  );
};

export default JitsiContainer;
