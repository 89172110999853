import React from 'react';
import Box from '@mui/material/Box';
import { CustomTextField, CustomFormLabel } from 'styling/generalStyling';

type Props = {
  setValue: (value: any, input: string) => void;
};

export default function EditPasswordForm({ setValue }: Props) {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '35ch' },
        display: 'inline-flex',
        'flex-direction': 'column',
      }}
      noValidate
      autoComplete="off"
    >
      <CustomTextField
        fullWidth
        type="password"
        onChange={(event) => setValue(event.target.value, 'old_password')}
        id="outlined-basic"
        label={<CustomFormLabel>Old Password</CustomFormLabel>}
        variant="outlined"
      />
      <CustomTextField
        type="password"
        id="outlined-basic"
        onChange={(event) => setValue(event.target.value, 'new_password')}
        label={<CustomFormLabel>New Password</CustomFormLabel>}
        variant="outlined"
      />
      <CustomTextField
        type="password"
        onChange={(event) =>
          setValue(event.target.value, 'confirm_new_password')
        }
        id="outlined-basic"
        label={<CustomFormLabel>Confirm New Password</CustomFormLabel>}
        variant="outlined"
      />
    </Box>
  );
}
