import { RadioGroup, Radio } from '@mui/material';
import React from 'react';
import {
  CustomFormControlLabel,
  CustomTypography,
} from 'styling/generalStyling';


interface Props {
  orgType: any;
  setOrgType: any;
}
const orgOptions = [
  { value: 'business', label: 'Business' },
  { value: 'charity', label: 'Charity' },
  { value: 'social_enterprise', label: 'Social Enterprise' },
  {
    value: 'deducational_institute',
    label: 'School / College / University',
  },
  { value: 'freelancer', label: 'Freelancer' },
  { value: 'student', label: 'Student' },
  { value: 'health_service', label: 'Health Services' },
  { value: 'unemployed_furloughed', label: 'Unemployed / Furloughed' },
];
const SelectOrgType = ({ orgType, setOrgType }: Props) => {
  return (
    <div className="select-org-type">
      <label>What type of organisation do you work for?</label>

      <RadioGroup
        aria-label="orgType"
        sx={{ display: 'inline-flex', flexDirection: 'row', flexWrap: 'wrap' }}
        value={orgType}
        onChange={(e) => setOrgType(e.target.value)}
      >
        {orgOptions.map(({ value, label }) => (
          <CustomFormControlLabel
            key={value}
            value={value}
            control={<Radio />}
            label={<CustomTypography>{label}</CustomTypography>}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default SelectOrgType;
