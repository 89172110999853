import { useAxios } from '../../api/config';
import { getAccessTokenCookie } from '../../utils/auth';
import { SpeakerBriefing } from '../../types';
import { AxiosRequestHeaders } from 'axios';

type Props = {
  code?: string;
};

interface SpeakerBriefingResults {
  results: SpeakerBriefing[];
}

const useGetSpeakerBriefing = ({ code }: Props) => {
  const loggedIn = !!getAccessTokenCookie();
  const headers: AxiosRequestHeaders = loggedIn
    ? { Authorization: `Bearer ${getAccessTokenCookie()}` }
    : {};

  const [{ data, loading, error }, refetch] = useAxios<SpeakerBriefingResults>({
    url: `api/groupevent/speakerbriefing?group_event__code=${
      code ? `${code}` : ''
    }`,
    headers,
  });

  return {
    speakerBriefingData: (data?.results as SpeakerBriefing[]) || {},
    hasFinishedLoadingSpeakerBriefing: !loading,
    speakerBriefingError: error,
    refetchSpeakerBriefing: refetch,
  };
};

export default useGetSpeakerBriefing;
