import useRedux from '../../../hooks/useRedux';
import useAutoFetch from '../../../hooks/useAutoFetch';
import {
  updateTimeZoneAction,
  selectHasFinishedUpdatingTimeZone,
  selectHasNotUpdatedTimezone,
} from '../index';

type Props = { autoFetch: boolean };

const useUpdateTimezone = (
  { autoFetch = true }: Props = { autoFetch: true }
) => {
  // HOOKS
  const { useSelector, dispatch } = useRedux();
  useAutoFetch(updateTimeZoneAction, selectHasNotUpdatedTimezone, {
    autoFetch,
  });

  // ACTIONS
  const updateTimeZone = () => dispatch(updateTimeZoneAction());

  // SELECTORS
  const hasFinishedUpdatingTimezone = useSelector(
    selectHasFinishedUpdatingTimeZone
  );

  return {
    updateTimeZone,
    hasFinishedUpdatingTimezone,
  };
};

export default useUpdateTimezone;
