import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CustomFormLabel, CustomTextField } from 'styling/generalStyling';
import apiInstance from '../../../api/config';

type Props = {
  setValue: (value: any, input: string) => void;
  value?: { country: string; postcode: string };
};

type Country = {
  code: string;
  name: string;
};
export default function EditLocationForm({ value, setValue }: Props) {
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [country, setCountry] = useState(value ? value.country : '');
  const [postcode] = useState(value ? value.postcode : null);

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCountry(event.target.value);
    setValue(event.target.value, 'country');
  };
  const getCountriesList = () => {
    return apiInstance
      .get(`api/location/countries/?limit=1000`, {})
      .then((response) => {
        return response.data.results;
      })
      .catch((err) => err);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCountriesList();
      setCountryList(data);
      return data;
    };
    fetchData().catch(console.error);
  }, []);

  return (
    <Box sx={{ width: 240 }}>
      <FormControl fullWidth>
        <Select
          MenuProps={{ transitionDuration: 0 }}
          labelId="location-simple-select-label"
          id="location-simple-select"
          value={country}
          onChange={handleChange}
          sx={{ fontFamily: 'Gilroy' }}
        >
          {countryList.map((country) => (
            <MenuItem
              sx={{
                '&.MuiMenuItem-root': {
                  fontFamily: 'Gilroy',
                },
              }}
              key={country.code}
              value={country.code}
            >
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <CustomTextField
        id="outlined-basic"
        sx={{ width: 240 }}
        onChange={(event) => setValue(event.target.value, 'postcode')}
        label={<CustomFormLabel> Postcode</CustomFormLabel>}
        defaultValue={postcode}
        variant="outlined"
      />
    </Box>
  );
}
