import React, { memo, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { formatDate } from '../../../utils/time';
import './style.scss';
import { GroupEvent, BoostCallEventWithVolunteerProfile } from '../../../types';
import MessagesModal from 'components/MessagesModal';

import '../components/styling/card-styling.scss';
import { IconButton } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';

import {
  acceptRescheduleRequest,
  declineRescheduleRequest,
} from 'api/boostCallEvents';
import RescheduleModal from './rescheduleModal';
import useGetJoinCall from 'hooks/useGetJoinCall';
import { useNavigate } from 'react-router-dom';
import RescheduleDialog from './rescheduleDialog';
import ConfirmRescheduleDialog from './confirmRescheduleModal';
import MentorModal from 'components/MentorCard/MentorModal';
import api from 'api';
import { Mentor } from '../../../../types';
import { DashboardFilledButton, DashboardEmptyButton } from 'styling/buttons';
import { StyledBadge } from 'styling/generalStyling';
import { useSystemTogglesContext } from 'context/SystemToggles';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from 'features/reduxStore/sessionTitle';

type Props = {
  appointmentData: any;
  timeZone?: string;
  subject: string;
  clickManage?: (e: any) => void;
  refetchEvents?: any;
};
const NewRescheduleCard = ({
  timeZone,
  appointmentData,
  refetchEvents,
}: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  const {
    id: boostCallId,
    volunteer: volunteerData,
    organisation_new_messages_count,
    reschedule_request_time,
    start_time,
  } = (appointmentData as BoostCallEventWithVolunteerProfile) || {};

  const { code } = (appointmentData as GroupEvent) || {};
  const id = code || boostCallId.toString();
  const profileData = {
    name: volunteerData?.first_name,
    picture: volunteerData?.profile_picture,
    companyName: volunteerData?.employer,
    currentRole: volunteerData?.current_role,
    showPresentedBy: false,
  };

  const currentDateFormatted = formatDate(start_time, timeZone);
  const rescheduleDateFormatted = formatDate(
    reschedule_request_time ? reschedule_request_time : undefined,
    timeZone
  );
  const closeModal = () => {
    setMentor(undefined);
    setOpenMentorModal(false);
  };
  const currentTime = {
    time: currentDateFormatted?.time,
    date: currentDateFormatted?.date,
  };

  const rescheduleTime = {
    time: rescheduleDateFormatted?.time,
    date: rescheduleDateFormatted?.date,
  };
  const [unreadMessages, setUnreadMessages] = useState<number>(
    organisation_new_messages_count
  );
  const [openMessagesModal, setOpenMessagesModal] = useState(false);
  const [rescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [confirmRescheduleModalOpen, setConfirmRescheduleModalOpen] =
    useState(false);

  const { joinCall } = useGetJoinCall({ callInfo: appointmentData });
  const navigate = useNavigate();

  const acceptAndRefresh = async () => {
    if (!reschedule_request_time) return;
    try {
      await acceptRescheduleRequest({
        id: boostCallId,
        newStartDateTime: reschedule_request_time,
      });
      setConfirmRescheduleModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  };
  const [mentor, setMentor] = useState<Mentor | undefined>(undefined);
  const [openMentorModal, setOpenMentorModal] = useState<boolean>(false);

  const openModal = async () => {
    const { results } = await api.searchMentors([volunteerData?.id], 1);
    if (results && results.length && results.length > 0) {
      setMentor(results[0]);
      setOpenMentorModal(true);
    }
  };

  const declineConfirm = async () => {
    if (!reschedule_request_time) return;
    try {
      await declineRescheduleRequest({
        id: boostCallId,
        newStartDateTime: reschedule_request_time,
        cancel: true,
        message: 'Cancel the session.',
      });
      setTimeout(() => window.location.reload(), 3000);
    } catch (err) {
      console.log(err);
    }
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    (async () => {
      const { results } = await api.searchMentors([volunteerData?.id], 1);
      if (results && results.length && results.length > 0)
        setMentor(results[0]);
    })();
  }, [volunteerData?.id]);

  const handleClose = () => {
    setOpen(false);
  };
  const sessionTitle = useSelector(selectSessionTitle);
  return (
    <div className="dashboard-box">
      <Box
        sx={{
          '& > :not(style)': {
            width: '100%',
            height: '100%',
          },
        }}
      >
        <Paper
          key={id}
          sx={{
            borderRadius: '8px',
            boxShadow: '8px 5px 30px 0 rgba(0, 0, 0, 0.06)',
          }}
        >
          <div>
            {appointmentData.reschedule_requested_by === 'volunteer' ? (
              <div className="rectangle-icon-container">
                <div className="sent-reschedule-rectangle-icon">
                  <div className="rectangle-icon-text">Pending</div>
                </div>
              </div>
            ) : (
              <div className="rectangle-icon-container">
                <div className="reschedule-rectangle-icon">
                  <div className="rectangle-icon-text">Upcoming</div>
                </div>
              </div>
            )}

            <div className="dashboard-card__container">
              <div
                onClick={() => openModal()}
                className="profile-photo-card"
                style={{
                  cursor: 'pointer',
                  backgroundImage: profileData.picture
                    ? `url(${profileData.picture})`
                    : 'url(/avatar.png)',
                }}
              ></div>
              {mentor && openMentorModal ? (
                <MentorModal
                  mentor={mentor}
                  modalOpen={Boolean(mentor)}
                  closeModal={closeModal}
                  hideSelect
                />
              ) : null}

              <div className="dashboard-card-header-info">
                <div className="dashboard-card-title">
                  <div className="dashboard-card-name">
                    {appointmentData.reschedule_requested_by !== 'volunteer'
                      ? `${sessionTitle} with`
                      : 'Reschedule request by'}
                    <div className="request-paper-first-name">
                      {profileData.name}
                    </div>
                  </div>
                </div>
                <div className="reschedule-paper-organisation">
                  {profileData.companyName}
                </div>
                <div className="reschedule-paper-topic">
                  {appointmentData.top_priority_name}
                </div>
                {appointmentData.reschedule_requested_by === 'volunteer' ? (
                  <div>
                    <div className="reschedule-help-text">
                      The volunteer has asked to reschedule for the following
                      new time
                    </div>
                    <div className="reschedule-paper-time">
                      <div className="dashboard-paper-time-hour">
                        <ScheduleIcon
                          fontSize="small"
                          sx={{ paddingBottom: '3px' }}
                        />{' '}
                        {rescheduleTime.date?.day}{' '}
                        {rescheduleTime.date?.monthThreeLetterFormat}{' '}
                        {rescheduleTime.date?.year}
                      </div>
                      <div className="dashboard-paper-time-day">
                        <CalendarTodayOutlinedIcon
                          fontSize="small"
                          sx={{ paddingBottom: '3px' }}
                        />{' '}
                        {rescheduleTime.time?.hour}{' '}
                      </div>
                      <IconButton
                        color="primary"
                        onClick={handleClickOpen}
                        sx={{ backgroundColor: '#ff5f5f', padding: '3px' }}
                      >
                        <HistoryIcon
                          sx={{ color: 'white' }}
                          fontSize="medium"
                        />
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="reschedule-help-text">
                      You asked to reschedule. If the volunteer doesn’t accept,
                      the session will remain at the original time unless you
                      cancel it.
                    </div>
                    <div className="reschedule-paper-time">
                      <div className="dashboard-paper-time-day">
                        <CalendarTodayOutlinedIcon
                          fontSize="small"
                          sx={{ paddingBottom: '3px' }}
                        />{' '}
                        {currentTime.date?.day}{' '}
                        {currentTime.date?.monthThreeLetterFormat}{' '}
                        {currentTime.date?.year}
                      </div>
                      <div className="dashboard-paper-time-hour">
                        <ScheduleIcon
                          fontSize="small"
                          sx={{ paddingBottom: '3px' }}
                        />{' '}
                        {currentTime.time?.hour}{' '}
                      </div>
                      <IconButton
                        color="primary"
                        onClick={handleClickOpen}
                        sx={{ backgroundColor: '#ff5f5f', padding: '3px' }}
                      >
                        <HistoryIcon
                          sx={{ color: 'white' }}
                          fontSize="medium"
                        />
                      </IconButton>
                    </div>
                  </div>
                )}

                {appointmentData.reschedule_requested_by === 'volunteer' ? (
                  <div className="reschedule-paper-button-wrapper">
                    <div className="dashboard-button-container">
                      <DashboardFilledButton
                        sx={{ width: '190px' }}
                        variant="contained"
                        onClick={acceptAndRefresh}
                      >
                        Accept New Time
                      </DashboardFilledButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#ff5f5f', width: '190px' }}
                        onClick={() => setIsRescheduleModalOpen(true)}
                      >
                        Decline New Time
                      </DashboardEmptyButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#333333' }}
                        onClick={() => {
                          navigate(
                            `/call-details?cid=${appointmentData.id}&type=events&event_update_options=true`
                          );
                        }}
                      >
                        Manage
                      </DashboardEmptyButton>
                    </div>
                  </div>
                ) : (
                  <div className="upcoming-paper-button-wrapper">
                    <div className="dashboard-button-container">
                      <DashboardFilledButton
                        variant="contained"
                        sx={{ width: '150px' }}
                        onClick={() => joinCall()}
                      >
                        Join Session
                      </DashboardFilledButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#333' }}
                        onClick={() => {
                          if (showMessagingUI) {
                            navigate(
                              `/messaging-centre?id=${appointmentData?.volunteer?.id}`
                            );
                            return;
                          }
                          refetchEvents();
                          setUnreadMessages(0);
                          setOpenMessagesModal(true);
                        }}
                      >
                        <StyledBadge badgeContent={unreadMessages} color="info">
                          Messages
                        </StyledBadge>
                      </DashboardEmptyButton>
                    </div>
                    <div className="dashboard-link-button-container">
                      <DashboardEmptyButton
                        sx={{ color: '#333333' }}
                        onClick={() => {
                          navigate(
                            `/call-details?cid=${appointmentData.id}&type=events&event_update_options=true`
                          );
                        }}
                      >
                        Manage
                      </DashboardEmptyButton>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Paper>
      </Box>
      {openMessagesModal ? (
        <MessagesModal
          boostCallEvent={appointmentData as BoostCallEventWithVolunteerProfile}
          onClose={() => setOpenMessagesModal(false)}
          openMessagesModal={openMessagesModal}
          onReadMessages={() => setUnreadMessages(0)}
        />
      ) : null}
      <RescheduleModal
        onClose={handleClose}
        open={open}
        appointmentData={appointmentData}
        timeZone={timeZone}
        declineConfirm={declineConfirm}
        acceptAndRefresh={acceptAndRefresh}
      />
      {rescheduleModalOpen ? (
        <RescheduleDialog
          isOpen={rescheduleModalOpen}
          event={appointmentData}
          setIsRescheduleModalOpen={setIsRescheduleModalOpen}
        />
      ) : null}
      {confirmRescheduleModalOpen ? (
        <ConfirmRescheduleDialog
          isOpen={confirmRescheduleModalOpen}
          event={appointmentData}
          setConfirmRescheduleModalOpen={setConfirmRescheduleModalOpen}
        />
      ) : null}
    </div>
  );
};

export default memo(NewRescheduleCard);
