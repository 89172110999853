import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { FormControlLabel } from '@mui/material';


type Props = {
  setValue: (value: boolean, input: string, key: string) => void;
  value: any;
};

export default function EditShowDisabilityForm({ value, setValue }: Props) {
  const [disabilityOption, setDisabilityOption] = useState<string>(
    value.show_disabilities ? 'yes' : 'no'
  );


  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDisabilityOption(e.target.value);
    if (e.target.value === 'no') setValue(false, 'show_disabilities', '');
    if (e.target.value === 'yes') setValue(true, 'show_disabilities', '');
  };

  return (
    <FormControl>
      <label className="profile-input_text">
      Do you want to share your disability / health condition with mentors? This is optional but may allow them to better tailor their support for you.
      </label>
      <div>
        <RadioGroup row value={disabilityOption} onChange={handleChange}>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </div>
    </FormControl>
  );
}
