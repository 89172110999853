import React from 'react';

const LockIcon = () => {
  return (
    <svg
      width="49"
      height="63"
      viewBox="0 0 49 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.875 21H39.8125V15C39.8125 6.72 32.9525 0 24.5 0C16.0475 0 9.1875 6.72 9.1875 15V21H6.125C2.75625 21 0 23.7 0 27V57C0 60.3 2.75625 63 6.125 63H42.875C46.2437 63 49 60.3 49 57V27C49 23.7 46.2437 21 42.875 21ZM24.5 48C21.1313 48 18.375 45.3 18.375 42C18.375 38.7 21.1313 36 24.5 36C27.8687 36 30.625 38.7 30.625 42C30.625 45.3 27.8687 48 24.5 48ZM33.9937 21H15.0062V15C15.0062 9.87 19.2631 5.7 24.5 5.7C29.7369 5.7 33.9937 9.87 33.9937 15V21Z"
        fill="#181F39"
      />
    </svg>
  );
};

export default LockIcon;
