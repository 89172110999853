import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardFilledButton } from 'styling/buttons';
import './styling/requests.scss';

type Props = {
  text?: string;
};
const RequestsEmptyPage = ({ text }: Props) => {
  const navigate = useNavigate();

  if (text)
    return (
      <div className="empty-page-container">
        <div className="not-found-text-container">{text}</div>
        <img
          className="not-found-image"
          src={'/no-bookings.png'}
          alt={'not-bookings-found'}
        />
        <DashboardFilledButton
          sx={{ width: '250px', marginTop: '20px' }}
          variant="contained"
          onClick={() => navigate('/mentor-select')}
        >
          Find a mentor
        </DashboardFilledButton>
      </div>
    );
  else
    return (
      <div className="empty-page-container">
        <img
          className="not-found-image"
          src={'/no-requests-found.png'}
          alt={'not-found'}
        />
        <div className="not-found-text"> Our mentors are ready to help.</div>
        <div className="not-found-text">
          {' '}
          Request a session and find the help you need.
        </div>

        <DashboardFilledButton
          sx={{ width: '250px', marginTop: '20px' }}
          variant="contained"
          onClick={() => navigate('/mentor-select')}
        >
          Find a mentor
        </DashboardFilledButton>
      </div>
    );
};

export default RequestsEmptyPage;
