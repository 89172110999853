import React from 'react';
import LockIcon from './lock-icon';
import './locked-component.scss';

interface Props {
  label: string;
  content: string;
}

const LockedMetric = ({ label, content }: Props) => {
  return (
    <div className="locked-metric">
      <div className="metric-text">
        <div className="metric-label">{label}</div>
        <div className="metric-content">{content}</div>
      </div>
      <div className="image-button">
        <LockIcon />
        {/* TODO implement what the button should do */}
        <button
          data-cy="upgrade-button"
          onClick={() => alert('Contact Sales Team')}
        >
          Upgrade now
        </button>
      </div>
    </div>
  );
};

export default LockedMetric;
