import React, { memo, useState } from 'react';
import './style.scss';
import moment from 'moment';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import { useNavigate } from 'react-router-dom';
import useGetJoinCall from '../../hooks/useGetJoinCall';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';

import { acceptRescheduleRequest } from 'api/boostCallEvents';
import RescheduleDialog from 'pages/Dashboard/NewRescheduleCard/rescheduleDialog';
import { DashboardEmptyButton, DashboardFilledButton } from 'styling/buttons';
import { CustomCallPagePaper } from 'styling/backgrounds';
import { StyledBadge } from 'styling/generalStyling';
import { useSystemTogglesContext } from 'context/SystemToggles';

type Props = {
  event: any;
  setOpenVolunteerModal?: any;
  setOpenMessagesModal?: any;
  messagesRead?: any;
  setRescheduleModal?: any;
};
const RescheduleRequestByVolunteer = ({
  event,
  setOpenVolunteerModal,
  messagesRead,
  setOpenMessagesModal,
  setRescheduleModal,
}: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  const { joinCall } = useGetJoinCall({ callInfo: event });
  const startTime = moment(event.start_time);
  const today = moment();
  const navigate = useNavigate();

  const [rescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);

  const handleDeclineRescheduleOpen = () => {
    setIsRescheduleModalOpen(true);
  };

  const acceptAndRefresh = async () => {
    try {
      const acceptParams = {
        newStartDateTime: event.reschedule_request_time,
        id: event.id,
        call_url_v2: event.call_url_v2,
      };
      await acceptRescheduleRequest(acceptParams);
      navigate(`/call-confirmation?id=${event.id}`);
    } catch (err) {
      console.log(err);
    }
  };

  window.onpopstate = function () {
    window.location.reload();
  };

  return (
    <div>
      <div className="organisation-information-container">
        <div className="header-container">
          <div className="header-text">
            {event.volunteer.first_name} has asked to reschedule
          </div>
          <DashboardEmptyButton
            variant="text"
            onClick={() => setOpenVolunteerModal(true)}
          >
            View volunteer profile
          </DashboardEmptyButton>
        </div>
        <CustomCallPagePaper>
          <div>
            <div className="header-box-proposed-time">
              <div>
                <div className="time-container-text">New proposed time</div>
                <div className="time-container">
                  <div className="time-container-text">
                    {moment(event.reschedule_request_time).format(
                      'DD MMMM YYYY'
                    )}
                  </div>
                  <div className="time-container-text">
                    {moment(event.reschedule_request_time).format('dddd')}
                  </div>
                  <div className="time-container-text">
                    {moment(event.reschedule_request_time).format('HH:mm')}
                  </div>
                </div>
              </div>
              <div>
                <div className="time-container-text">Original time</div>
                <div className="time-container-reschedule-requested">
                  <div className="time-container-box">
                    <div className="time-container-text">
                      {moment(event.start_time).format('DD MMMM YYYY')}
                    </div>
                    <div className="time-container-text">
                      {moment(event.start_time).format('dddd')}
                    </div>
                    <div className="time-container-text">
                      {moment(event.start_time).format('HH:mm')}
                    </div>
                  </div>
                  <DashboardEmptyButton
                    variant="text"
                    sx={{
                      fontFamily: 'Gilroy',
                      fontSize: '14px',

                      paddingLeft: '2px',
                      width: '190px',
                    }}
                    onClick={() => joinCall()}
                  >
                    Join Session
                  </DashboardEmptyButton>
                </div>
              </div>
            </div>
          </div>

          <div className="header-box-time-left-container">
            Starts in
            <div className="time-left">
              {moment(startTime.diff(today)).format('D')}
            </div>{' '}
            days
          </div>
          <div className="header-box-buttons">
            <DashboardFilledButton
              variant="contained"
              sx={{ width: '190px', maxWidth: '100%' }}
              onClick={() => acceptAndRefresh()}
            >
              Accept new time
            </DashboardFilledButton>

            <div className="header-box-button">
              <DashboardEmptyButton
                variant="text"
                sx={{
                  fontSize: '14px',
                  color: '#f26d37',
                  paddingLeft: '2px',
                }}
                onClick={() => handleDeclineRescheduleOpen()}
              >
                <DangerousOutlinedIcon sx={{ marginRight: '5px' }} />
                Decline new time
              </DashboardEmptyButton>
            </div>

            <div className="header-box-button">
              <DashboardEmptyButton
                variant="text"
                sx={{
                  fontSize: '14px',
                  paddingLeft: '2px',
                }}
                onClick={() => {
                  if (showMessagingUI) {
                    navigate(`/messaging-centre?id=${event?.volunteer?.id}`);
                    return;
                  }
                  setOpenMessagesModal(true);
                }}
              >
                <EmailOutlinedIcon sx={{ marginRight: '5px' }} />
                <StyledBadge
                  badgeContent={
                    !messagesRead ? event.organisation_new_messages_count : 0
                  }
                  color="info"
                >
                  Messages
                </StyledBadge>
              </DashboardEmptyButton>
            </div>
            <div className="header-box-button">
              <DashboardEmptyButton
                variant="text"
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',

                  paddingLeft: '2px',
                }}
                onClick={() => setRescheduleModal(true)}
              >
                <HistoryOutlinedIcon sx={{ marginRight: '5px' }} />
                Reschedule
              </DashboardEmptyButton>
            </div>
            <div className="header-box-button">
              <DashboardEmptyButton
                variant="text"
                sx={{
                  fontFamily: 'Gilroy',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  textTransform: 'none',
                  color: '#f23737',
                  paddingLeft: '2px',
                }}
                onClick={() => navigate(`/cancel-call?id=${event.id}`)}
              >
                <DangerousOutlinedIcon sx={{ marginRight: '5px' }} />
                Cancel session
              </DashboardEmptyButton>
            </div>
          </div>
        </CustomCallPagePaper>

        {rescheduleModalOpen ? (
          <RescheduleDialog
            isOpen={rescheduleModalOpen}
            event={event}
            setIsRescheduleModalOpen={setIsRescheduleModalOpen}
          />
        ) : null}
      </div>
    </div>
  );
};
export default memo(RescheduleRequestByVolunteer);
