import { createContext, useContext } from 'react';
import { FormattedToggles } from '../types';

export type SystemTogglesContextType = {
  toggles: FormattedToggles;
  hasFinishedLoadingToggles: boolean;
};

export const SystemToggleContext = createContext<SystemTogglesContextType>({
  toggles: {},
  hasFinishedLoadingToggles: false,
});
export const useSystemTogglesContext = () => useContext(SystemToggleContext);
