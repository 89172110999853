import React from 'react';
import './text-area-with-label.scss';

interface Props {
  label?: string | undefined;
  placeHolder?: string | undefined;
  onInput?: any;
  rows?: number;
  cols?: number;
  defaultValue?: any;
  readOnly?: boolean;
  mandatory?: boolean;
  className?: string;
}

const TextAreadWithLabel = ({
  label,
  placeHolder,
  defaultValue,
  rows,
  cols,
  onInput = () => {
    console.log('onInput');
  },
  mandatory,
  className,
}: Props) => {
  return (
    <div className={`text-area-with-label ${className ? className : ''}`}>
      {label && (
        <label>
          {label}{' '}
          {mandatory && (
            <span className="text-area-with-label__mandatory-asterisk">
              {'\u002A '}
            </span>
          )}
        </label>
      )}

      <textarea
        onInput={onInput}
        placeholder={placeHolder}
        defaultValue={defaultValue}
        rows={rows}
        cols={cols}
        required={mandatory}
      />
    </div>
  );
};

export default TextAreadWithLabel;
