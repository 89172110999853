import React, { memo } from 'react';

import './style.scss';
import moment from 'moment';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import { useNavigate } from 'react-router-dom';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import useGetJoinCall from '../../hooks/useGetJoinCall';
import useSelectProfileData from 'hooks/useSelectProfileData';
import NewAddToCalendarButton from 'atoms/NewAddToCalendarButton';
import { DashboardEmptyButton, DashboardFilledButton } from 'styling/buttons';
import { CustomCallPagePaper } from 'styling/backgrounds';
import { StyledBadge } from 'styling/generalStyling';
import { useSystemTogglesContext } from 'context/SystemToggles';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from 'features/reduxStore/sessionTitle';

type Props = {
  event: any;
  setOpenVolunteerModal?: any;
  setOpenMessagesModal?: any;
  messagesRead?: boolean;
  setRescheduleModal?: any;
};

const ScheduledEvent = ({
  event,
  setOpenVolunteerModal,
  setOpenMessagesModal,
  messagesRead,
  setRescheduleModal,
}: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  const { userLocation } = useSelectProfileData();
  const timeZoneString =
    userLocation && userLocation.timezone ? userLocation.timezone : '';
  const { joinCall } = useGetJoinCall({ callInfo: event });
  const startTime = moment(event.start_time);
  const today = moment();
  const navigate = useNavigate();
  window.onpopstate = function () {
    window.location.reload();
  };
  const sessionTitle = useSelector(selectSessionTitle);
  return (
    <div>
      <div className="organisation-information-container">
        <div className="header-container">
          <div className="header-text">
            {sessionTitle} with {event.volunteer?.first_name}
          </div>
          <DashboardEmptyButton
            variant="text"
            onClick={() => setOpenVolunteerModal()}
          >
            View volunteer profile
          </DashboardEmptyButton>
        </div>
      </div>

      <CustomCallPagePaper>
        <div className="header-box-date-container">
          <div className="header-box-date-time">
            {moment(event.start_time).format('DD MMMM YYYY')}
          </div>
          <div className="header-box-day-name">
            {moment(event.start_time).format('dddd')}
          </div>
          <div className="header-box-date-time">
            {moment(event.start_time).format('HH:mm')}
          </div>
        </div>
        {!moment(startTime).isSame(today, 'day') ? (
          <div className="header-box-time-left-container">
            Starts in
            <div className="time-left">
              {moment(startTime.diff(today)).format('D')}
            </div>{' '}
            days
          </div>
        ) : (
          <div className="header-box-time-left-container">
            {!moment().isAfter(moment(event.start_time)) ? (
              <div className="starts-today-text">Starts today</div>
            ) : (
              <div className="starts-today-text">Event started</div>
            )}
          </div>
        )}
        <div className="header-box-buttons">
          <DashboardFilledButton
            variant="contained"
            sx={{ width: '220px' }}
            onClick={() => joinCall()}
          >
            Join session
          </DashboardFilledButton>
          <div className="header-box-button">
            <DashboardEmptyButton
              variant="text"
              onClick={() => {
                if (showMessagingUI) {
                  navigate(`/messaging-centre?id=${event?.volunteer?.id}`);
                  return;
                }
                setOpenMessagesModal(true);
              }}
            >
              <EmailOutlinedIcon sx={{ marginRight: '5px' }} />
              <StyledBadge
                badgeContent={
                  !messagesRead ? event.organisation_new_messages_count : 0
                }
                color="info"
              >
                Messages
              </StyledBadge>
            </DashboardEmptyButton>
          </div>
          <div className="header-box-button">
            <DashboardEmptyButton
              variant="text"
              disabled={moment().isAfter(moment(event.start_time))}
              onClick={() => setRescheduleModal(true)}
            >
              <HistoryOutlinedIcon sx={{ marginRight: '5px' }} />
              Reschedule
            </DashboardEmptyButton>
          </div>

          <div className="header-box-button">
            <DashboardEmptyButton
              variant="text"
              sx={{
                color: '#f23737',
              }}
              onClick={() => navigate(`/cancel-call?id=${event.id}`)}
            >
              <DangerousOutlinedIcon sx={{ marginRight: '5px' }} />
              Cancel session
            </DashboardEmptyButton>
          </div>
          <div className="header-box-button">
            <div className="header-box-button">
              <NewAddToCalendarButton
                callInfo={event}
                timeZoneString={timeZoneString}
              />
            </div>
          </div>
        </div>
      </CustomCallPagePaper>
    </div>
  );
};

export default memo(ScheduledEvent);
