import React, { memo } from 'react';
import './thank-you-registration.scss';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

const ThankYouRegistration = () => {
  return (
    <Grid
      container
      xs={12}
      justifyContent="center"
      className="thank-you-registration"
    >
      <Grid item container xs={12} sm={12} md={6} lg={5}>
        <Stack direction="column" alignItems="center">
          <h1>Thank you for registering!</h1>
          <img src="/congratulations.png" alt="congratulations" />

          <h6>
            We will send you an email when your account is ready so keep an eye
            on your inbox for updates. If you have any questions,
            <a href="mailto:contact@digitalboost.org.uk"> please let us know</a>
            !
          </h6>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default memo(ThankYouRegistration);
