import { useAxios } from '../api/config';
import { FormattedToggles, ToggleFromResponse } from '../types';

type Props = {
  isBetaUser?: boolean;
};

const formatToggles = (
  togglesFromResponse: ToggleFromResponse[],
  isBetaUser: boolean
) =>
  togglesFromResponse.reduce(
    (acc: FormattedToggles, cur: ToggleFromResponse) => {
      const { toggle_name, enabled, enabled_to_beta_users, value, ...rest } =
        cur;
      const enabledForUser = enabled || (enabled_to_beta_users && isBetaUser);
      if (!!value) {
        acc[toggle_name] = { enabled: enabledForUser, value, ...rest };
      } else {
        acc[toggle_name] = enabledForUser;
      }
      return acc;
    },
    {}
  );

const useGetSystemToggles = ({ isBetaUser = false }: Props) => {
  const [{ data, loading, error }, refetch] = useAxios({
    url: 'api/systemtoggle/systemtoggle/?limit=100',
    method: 'GET',
  });

  return {
    toggles: data ? formatToggles(data?.results, isBetaUser) : {},
    togglesFetchError: error,
    refretchToggles: refetch,
    hasFinishedLoadingToggles: !loading,
  };
};

export default useGetSystemToggles;
