import React from 'react';
import Box from '@mui/material/Box';
import { CustomTextField, CustomFormLabel } from 'styling/generalStyling';

type Props = {
  setValue: (value: any, input: string) => void;
  value: string;
};
export default function EditEmailForm({ value, setValue }: Props) {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '35ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <CustomTextField
        id="outlined-basic"
        label={<CustomFormLabel>Email</CustomFormLabel>}
        defaultValue={value}
        onChange={(event) => setValue(event.target.value, 'email')}
        variant="outlined"
      />
    </Box>
  );
}
