import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import './filePopupStyle.scss';
import { getAttachments } from 'api/attachments';
import InfiniteScroll from 'react-infinite-scroll-component';
import useWindowSize from 'hooks/useWindowSize';
import { Attachment } from 'types';
import { formatDate, truncateFileName } from './utils';
import { DashboardFilledButton } from 'styling/buttons';

interface MiniLibraryProps {
  open: boolean;
  setOpenLibrary: (arg1: boolean) => void;
  sendFilesFromLibrary: (...args: any[]) => any;
}

const MiniLibrary: React.FC<MiniLibraryProps> = ({
  open,
  setOpenLibrary,
  sendFilesFromLibrary,
}) => {
  const { width } = useWindowSize();
  const isMobile = width < 750;
  const [counter, setCounter] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [displayAttachments, setDisplayAttachments] = useState<Attachment[]>(
    []
  );

  const [selectedFiles, setSelectedFiles] = useState<Record<number, boolean>>(
    {}
  );

  const [errorMessage, setErrorMessage] = useState('');

  const handleFileClick = (file: { id: number; name: any }) => {
    const selectedCount = Object.values(selectedFiles).filter(
      (val) => val
    ).length;

    if (selectedCount >= 5 && !selectedFiles[file.id]) {
      setErrorMessage('You cannot select more than 5 files.');
    } else {
      setErrorMessage('');
      setSelectedFiles({
        ...selectedFiles,
        [file.id]: !selectedFiles[file.id],
      });
    }
  };
  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        const response = await getAttachments(counter, counter - 10);
        if (response.error) {
          console.log(response.error);
        } else {
          setTotal(response.count);

          setDisplayAttachments((prevAttachments) => [
            ...prevAttachments,
            ...response.attachments,
          ]);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchAttachments();
  }, [counter]); // Depend on counter and offset for pagination

  const sendAllSelectedFiles = () => {
    Object.entries(selectedFiles).forEach(([fileId, isSelected]) => {
      if (isSelected) {
        const fileDetails = displayAttachments.find(
          (file) => file.id === Number(fileId)
        );

        if (fileDetails)
          sendFilesFromLibrary({ fileId, fileName: fileDetails.name });
      }
    });
    setSelectedFiles({});
    setErrorMessage('');
    setOpenLibrary(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpenLibrary(false)}
      sx={{
        '& .MuiDialog-paper': {
          border: '1px solid #A3A3A3',
          borderRadius: '20px',
          padding: '0',
          maxWidth: '642px',
        },
        '& .MuiDialogContent-root': {
          paddingLeft: '27px',
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={() => setOpenLibrary(false)}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          paddingTop: '20px',
          minWidth: !isMobile ? '642px' : undefined,
          height: !isMobile ? '393px' : '563px',
          borderRadius: '10px',
        }}
      >
        <div className="library-attachment-popup-title">My library</div>
        <div>
          {' '}
          {errorMessage && <div className="error">{errorMessage}</div>}
          {Object.values(selectedFiles).filter((val) => val).length > 0 ? (
            <div className="submit-container">
              {' '}
              <DashboardFilledButton
                sx={{ width: '200px' }}
                onClick={sendAllSelectedFiles}
              >
                Add selected files
              </DashboardFilledButton>{' '}
            </div>
          ) : null}
        </div>
        {total === 0 ? (
          <div
            className="mini-library-scroll"
            id="scrollAttachmentsDiv"
            style={{ fontFamily: 'Gilroy', fontSize: '15px' }}
          >
            There’s no files in your library yet!
            <br /> Add files to your library first and we’ll show them here
          </div>
        ) : (
          <div className="mini-library-scroll" id="scrollAttachmentsDiv">
            <InfiniteScroll
              dataLength={displayAttachments.length}
              next={() => {
                setCounter(counter + 10);
              }}
              hasMore={counter < total}
              loader={<p className="mini-date-file-format">Loading...</p>}
              endMessage={
                <p className="mini-date-file-format">No more data to load.</p>
              }
              scrollableTarget="scrollAttachmentsDiv"
            >
              <div className="mini-files-container">
                {displayAttachments &&
                  displayAttachments.length > 0 &&
                  displayAttachments.map((file) => (
                    <div
                      key={file.id}
                      className={`mini-file-container ${
                        selectedFiles[file.id] ? 'selected' : ''
                      }`}
                      onClick={() => handleFileClick(file)}
                    >
                      <div className="mini-text-file-format">
                        {truncateFileName(file.name)}
                      </div>
                      <div className="mini-text-file-format">
                        {' '}
                        {file.size < 1
                          ? `${file.size * 1000} Kb`
                          : `${file.size} MB`}{' '}
                      </div>
                      <div className="mini-date-file-format">
                        {file.created_by === file.owner
                          ? 'uploaded on'
                          : 'received on'}{' '}
                        {formatDate(file.created)}
                      </div>
                    </div>
                  ))}
              </div>
            </InfiniteScroll>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MiniLibrary;
