import Cookie from 'js-cookie';
import apiInstance from './config';
import { BoostCallRequest } from '../types';

type SubmitDraftBoostCallProps = {
  selectedSkillList: any[];
  selectedMentorsList: any[];
  helpNeeded?: string;
  topPrioritySkill?: number;
  partner?: null | number;
  timeSlots?: any[];
  allowMultipleSessions?: boolean;
  chooseForMe?: boolean;
  interestExpertise?: string;
  mentoringKind?: 'one_off_mentoring' | 'ongoing_mentoring';
};

export const getDraftBoostCall = async () => {
  const draftBoostCallUUID = Cookie.get('draftBoostCallUUID');

  return apiInstance.get<BoostCallRequest>(
    `api/event/draftboostcallrequests/${draftBoostCallUUID}/`,
    {}
  );
};

export const submitDraftBoostCall = ({
  selectedSkillList,
  selectedMentorsList,
  helpNeeded,
  topPrioritySkill,
  timeSlots,
  partner = null,
  allowMultipleSessions,
  interestExpertise,
  mentoringKind,
  chooseForMe,
}: SubmitDraftBoostCallProps) => {
  helpNeeded = helpNeeded === undefined ? '' : helpNeeded;
  timeSlots = timeSlots === undefined ? [] : timeSlots;
  allowMultipleSessions = allowMultipleSessions || false;

  return apiInstance
    .post(
      `api/event/draftboostcallrequests/`,
      {
        help_needed: helpNeeded,
        interests: selectedSkillList,
        invited_volunteers: selectedMentorsList,
        time_slots: timeSlots,
        partner,
        top_priority: topPrioritySkill,
        allow_multiple_sessions: allowMultipleSessions,
        interest_expertise: interestExpertise,
        mentoring_kind: mentoringKind,
        choose_for_me: chooseForMe,
      },
      {}
    )
    .then((response: any) => {
      // set the uuid of the draft boost call that expires in a day
      Cookie.set('draftBoostCallUUID', response.data.uuid, {
        expires: 1,
      });
      return response;
    })
    .catch((err: any) => err);
};
