import React, { memo, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  CustomMobileRequestsLabel,
  CustomRequestsLabel,
  LoadMoreButton,
} from '../styling';
import './styling/requests.scss';
import './styling/impact.scss';
import NumberOfSessionsChart from '../charts/NumberOfSessionsChart';
import NumberOfMinutesMentorshipChart from '../charts/NumberOfMinutesMentorshipChart';
import StatisticsChart from '../charts/StatisticsChart';
import ImpactEmptyPage from './impactEmptyPage';
import useWindowSize from 'hooks/useWindowSize';
import NewFeedbackCard from '../NewFeedbackCard';
import useGetBoostCallEventReviews from 'apiHooks/boostCalls/useGetBoostCallEventReviews';
import { BoostCallEventReview } from 'types';
import FullScreenLoading from 'components/FullScreenLoading';
import { useNavigate } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string | undefined;
}

interface Props {
  totalSessions: [
    {
      duration: number;
      year: number;
      month: number;
    }
  ];
  masterclasses: number;
  topInterests: any[];
  subTab?: string;
}

let display = 0;
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  if (value === 'feedback') display = 1;
  else if (value === 'activity') display = 0;

  return (
    <div
      role="tabpanel"
      hidden={display !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {display === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const RequestsTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'primary',
  },
});

const Impact = ({
  totalSessions,
  masterclasses,
  topInterests,
  subTab,
}: Props) => {
  let history = useNavigate();

  const [displayResults, setDisplayResults] = useState<BoostCallEventReview[]>(
    []
  );
  const [nextURL, setNextURL] = useState<string | undefined>(undefined);

  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;
  const handleChange = (event: any, newValue: number) => {
    history(`/dashboard/your-progress/${newValue}`);
  };
  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setNextURL(next);
  };

  const { reviews, hasFinishedLoadingReviews, count, next } =
    useGetBoostCallEventReviews(nextURL);

  useEffect(() => {
    if (reviews && reviews.length > 0) {
      setDisplayResults((prevResults) => [...prevResults, ...reviews]);
    }
  }, [reviews]);

  const displayFeedbackBadge = () => <span>({count})</span>;

  if (hasFinishedLoadingReviews || displayResults)
    return (
      <div className="dashboard-requests-container">
        <div className="dashboard-title-component">My Progress</div>

        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <RequestsTabs
              value={subTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                value="activity"
                label={
                  !isMobile ? (
                    <CustomRequestsLabel>Activity</CustomRequestsLabel>
                  ) : (
                    <CustomMobileRequestsLabel>
                      Activity
                    </CustomMobileRequestsLabel>
                  )
                }
              />
              <Tab
                value="feedback"
                label={
                  !isMobile ? (
                    <CustomRequestsLabel>
                      Personal Feedback {displayFeedbackBadge()}
                    </CustomRequestsLabel>
                  ) : (
                    <CustomMobileRequestsLabel>
                      Personal Feedback {displayFeedbackBadge()}
                    </CustomMobileRequestsLabel>
                  )
                }
              />
            </RequestsTabs>
          </Box>
          <TabPanel value={subTab} index={0} key={0}>
            {totalSessions && totalSessions.length > 0 ? (
              <div className="impact-charts-container">
                <div className="align-charts">
                  <NumberOfSessionsChart totalSessions={totalSessions} />
                  <NumberOfMinutesMentorshipChart
                    totalSessions={totalSessions}
                  />
                </div>
                <StatisticsChart
                  masterclasses={masterclasses}
                  topInterests={topInterests}
                />
              </div>
            ) : (
              <ImpactEmptyPage tab={subTab} />
            )}
          </TabPanel>
          <TabPanel value={subTab} index={1} key={1}>
            {count && count > 0 ? (
              <div className="impact-charts-container">
                {displayResults.map((review, index) => (
                  <NewFeedbackCard review={review} />
                ))}
              </div>
            ) : (
              <ImpactEmptyPage tab={subTab} />
            )}
            {count && displayResults.length < count ? (
              <div className="loading-button-reviews">
                <LoadMoreButton
                  sx={{ marginTop: '20px', width: '100%' }}
                  variant="contained"
                  onClick={handleLoadMore}
                >
                  Load more
                </LoadMoreButton>
              </div>
            ) : null}
          </TabPanel>
        </Box>
      </div>
    );
  else return <FullScreenLoading />;
};

export default memo(Impact);
