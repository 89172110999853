import React, { memo } from 'react';
import { formatDate } from '../../../utils/time';
import './style.scss';
import { BoostCallEventWithVolunteerProfile } from '../../../types';
import '../components/styling/card-styling.scss';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  appointmentData: any;
  timeZone?: string;
  onClose: any;
  open: boolean;
  acceptAndRefresh: any;
  declineConfirm: any;
};
const RescheduleModal = ({
  timeZone,
  appointmentData,
  onClose,
  open,
}: Props) => {
  const { reschedule_request_time, start_time } =
    (appointmentData as BoostCallEventWithVolunteerProfile) || {};

  const rescheduleDateFormatted = formatDate(
    reschedule_request_time as string,
    timeZone
  );
  const currentDateFormatted = formatDate(start_time, timeZone);
  const rescheduledTime = {
    time: rescheduleDateFormatted?.time,
    date: rescheduleDateFormatted?.date,
  };
  const currentTime = {
    time: currentDateFormatted?.time,
    date: currentDateFormatted?.date,
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <div className="close-dialog-icon">
        <IconButton color="primary" onClick={onClose} sx={{ padding: '0px' }}>
          <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
        </IconButton>
      </div>

      <div className="reschedule-dialog-title"> Reschedule requested</div>
      <div className="reschedule-dialog-info">
        {appointmentData.reschedule_requested_by === 'volunteer' ? (
          <div>You have received a new reschedule request</div>
        ) : (
          <div> You asked to reschedule the session</div>
        )}
        <div className="reschedule-dialog-date">
          Original Time:
          <div className="reschedule-dialog-date-text">
            {currentTime.date?.day} {currentTime.date?.monthThreeLetterFormat}{' '}
            {currentTime.date?.year} {currentTime.time?.hour}{' '}
          </div>
        </div>
        <div className="reschedule-dialog-date">
          Proposed new time:{' '}
          <div className="reschedule-dialog-date-text">
            {' '}
            {rescheduledTime.date?.day}{' '}
            {rescheduledTime.date?.monthThreeLetterFormat}{' '}
            {rescheduledTime.date?.year} {rescheduledTime.time?.hour}{' '}
          </div>
        </div>
        {appointmentData.reschedule_requested_by === 'volunteer' ? (
          <div>
            <div className="reschedule-help-text">
              {' '}
              If you don't accept, then the session will happen at the original
              time, unless you cancel it.
            </div>
          </div>
        ) : (
          <div>
            If {appointmentData.volunteer.first_name} does not accept, the
            session will remain at the original time unless you or{' '}
            {appointmentData.volunteer.first_name} cancel it.
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default memo(RescheduleModal);
