import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';
import useRedux from '../../../hooks/useRedux';
import useSelectProfileData from '../../../hooks/useSelectProfileData';
import {
  resendVerificationAction,
  verifyAccountAction,
  selectSignupEmail,
  selectIsLoadingResendVerification,
  selectHasResentVerification,
  selectAuthError,
  selectHasVerifiedAccount,
} from '../index';
import { invalidTokenError } from '../utils';
import { getUrlParam } from '../../../utils/url';

type Props = { id?: number; autoFetch: boolean };

const useVerifyAccount = (
  { id, autoFetch = true }: Props = { autoFetch: true }
) => {
  // CONST
  const organisationId = getUrlParam('uid') as string;
  const confirmationToken = getUrlParam('ct') as string;
  const partnerId = getUrlParam('id') as string;
  const redirectTime = 5000;

  // HOOKS
  const navigate = useNavigate();
  const { useSelector, dispatch } = useRedux();
  const {
    userProfile,
    organisationProfile,
    fetchAllProfileData,
    hasFinishedFetchingProfileAndLocation,
  } = useSelectProfileData();
  const { username } = userProfile || {};
  const hasProfile = !!organisationProfile;

  // SELECTORS
  const signupEmail = useSelector(selectSignupEmail);
  const hasResentVerification = useSelector(selectHasResentVerification);
  const isLoadingResendVerification = useSelector(
    selectIsLoadingResendVerification
  );
  const hasVerifiedAccount = useSelector(selectHasVerifiedAccount);

  // // error selectors
  const authError = useSelector(selectAuthError);
  const invalidToken = invalidTokenError(authError);

  // ACTIONS
  const resendVerification = async () => {
    if (!signupEmail && !username) return;
    await dispatch(
      resendVerificationAction({ email: username || signupEmail })
    );
  };

  const verifyAccount = useCallback(async () => {
    await dispatch(
      verifyAccountAction({ id: organisationId, token: confirmationToken })
    );
    fetchAllProfileData();
  }, [confirmationToken, dispatch, fetchAllProfileData, organisationId]);

  // HANDLE VERIFICATION
  useEffect(() => {
    (async () => {
      if (
        organisationId &&
        confirmationToken &&
        !hasVerifiedAccount &&
        !authError
      ) {
        await verifyAccount();
      }
    })();
  }, [
    authError,
    confirmationToken,
    hasVerifiedAccount,
    organisationId,
    verifyAccount,
  ]);

  const getUrlParams = (nonBooking: any, id: any) => {
    const params = new URLSearchParams({
      ...(nonBooking && { non_booking: 1 }),
      ...(id && { id }),
    });

    return params.toString();
  };

  // HANDLE REDIRECTION
  useEffect(() => {
    const nonBooking = !Cookie.get('draftBoostCallUUID');
    let redirectTimeout: ReturnType<typeof setTimeout>;
    if (hasFinishedFetchingProfileAndLocation && hasVerifiedAccount) {
      redirectTimeout = setTimeout(
        () =>
          navigate(
            nonBooking || partnerId
              ? `/add-details?${getUrlParams(nonBooking, partnerId)}`
              : '/add-details'
          ),
        redirectTime
      );
      return;
    }

    return () => !!redirectTimeout && clearTimeout(redirectTimeout);
  }, [
    hasFinishedFetchingProfileAndLocation,
    hasVerifiedAccount,
    hasProfile,
    navigate,
    invalidToken,
    authError,
    partnerId,
  ]);

  return {
    resendVerification,
    hasResentVerification,
    isLoadingResendVerification,
    resendVerificationError: authError,
    verifyAccount,
    hasVerifiedAccount,
    verifyAccountError: authError,
    invalidToken,
  };
};

export default useVerifyAccount;
