import React, { useEffect, useState, useRef } from 'react';
import useGetJoinCall from '../../hooks/useGetJoinCall';
import { BoostCallEventWithVolunteerProfile } from '../../types';
import { displayTime, getMomentWithTimezone } from '../../utils/time';
import { toTitleCase } from '../../utils/text';
import { getUrlParam } from '../../utils/url';
import './addToCalendarButton.scss';
import { defaultDateFormat, defaultTimeFormat } from '../../constants';
import { DashboardEmptyButton } from 'styling/buttons';
import { Helmet } from 'react-helmet';
import DateRangeIcon from '@mui/icons-material/DateRange';
const baseUrl = process.env.REACT_APP_BASE_URL;

type Props = {
  callInfo: BoostCallEventWithVolunteerProfile;
  timeZoneString: string;
  disableStyling?: boolean;
};
const currentURL = window.location.href;

// Find the index of "org.uk" in the URL
const extractedURL =
  currentURL.indexOf('org.uk/') !== -1
    ? currentURL.substring(0, currentURL.indexOf('org.uk/') + 7)
    : baseUrl;

// Note: This component relies on the 'addevent' third party <script> being loaded
const AddToCalendarButton = ({
  callInfo,
  timeZoneString,
  disableStyling = false,
}: Props) => {
  const {
    id: callId,
    call_url: originalCallUrl,
    start_time: startTime,
    duration,
    title,
    session_title,
    partner_name,
    volunteer,
    top_priority_name: topPriority,
  } = callInfo;

  const { url: dbCallUrl } = useGetJoinCall({ callInfo });

  const calendarButtonRef = useRef<HTMLDivElement>(null);
  const addToCalendarParam = getUrlParam('add_to_calendar') as string;
  const [shouldOpenCalendar, setShouldOpenCalendar] = useState(
    !!addToCalendarParam
  );

  // Fixes a bug requiring a reload of the <script>
  useEffect(() => {
    if (callInfo) {
      // @ts-ignore
      setTimeout(() => window.addeventatc.refresh(), 1000);
    }
  }, [callInfo]);

  useEffect(() => {
    if (shouldOpenCalendar) {
      (async () => {
        // 2 second delay needed to load calendar script
        await new Promise((resolve) => setTimeout(resolve, 2000));
        calendarButtonRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
        // @ts-ignore
        await window.addeventatc?.refresh();
        calendarButtonRef?.current?.click();
        setShouldOpenCalendar(false);
      })();
    }
  }, [shouldOpenCalendar, setShouldOpenCalendar, calendarButtonRef]);

  if (!volunteer || !timeZoneString) return null;

  const detailsLink = `${extractedURL}call-details?type=events&cid=${callId}`;
  const rescheduleCancelLink = `${extractedURL}call-details?type=events&event_update_options=true&cid=${callId}`;

  return (
    <div className="addToCalendarWrapper">
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.addevent.com/libs/atc/themes/fff-theme-6/theme.css"
          type="text/css"
          media="all"
        />
        <script
          type="text/javascript"
          src="https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js"
        ></script>
      </Helmet>
      <div
        title="Add to calendar"
        className="addeventatc"
        data-styling="none"
        ref={calendarButtonRef}
        onClick={() => {
          setShouldOpenCalendar(!shouldOpenCalendar);
        }}
      >
        <DashboardEmptyButton
          variant="text"
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
            paddingLeft: '2px',
          }}
        >
          <DateRangeIcon sx={{ marginRight: '5px' }} />
          <div>Add to calendar</div>
        </DashboardEmptyButton>

        <span className="start atc-hidden-text">
          {displayTime(
            startTime,
            timeZoneString,
            `${defaultDateFormat} ${defaultTimeFormat}`
          )}
        </span>
        <span className="end atc-hidden-text">
          {getMomentWithTimezone(startTime, timeZoneString)
            .add(duration, 'minutes')
            .format(`${defaultDateFormat} ${defaultTimeFormat}`)}
        </span>
        <span className="timezone atc-hidden-text">{timeZoneString}</span>
        <span className="title atc-hidden-text">{`${
          partner_name ? partner_name : 'Digital Boost'
        } ${toTitleCase(session_title ? session_title : title)} with ${
          volunteer.first_name
        } ${volunteer.last_initial}`}</span>
        <span className="description atc-hidden-text">
          {`${partner_name ? partner_name : 'Digital Boost'} ${toTitleCase(
            session_title ? session_title : title
          )} with ${volunteer.first_name} ${volunteer.last_initial} from ${
            volunteer.employer
          } ${
            topPriority ? `about ${toTitleCase(topPriority)}` : ''
          }.\n \n For more details about this call, visit\n${detailsLink}\n\nIf you need to reschedule or cancel your session, click here:\n${rescheduleCancelLink}`}
        </span>
        <span className="location atc-hidden-text">
          {!!dbCallUrl ? dbCallUrl : originalCallUrl}
        </span>
      </div>
    </div>
  );
};

export default AddToCalendarButton;
