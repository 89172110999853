import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardFilledButton } from 'styling/buttons';

import './style.scss';
const VolunteerNotAvailable = () => {
  const navigate = useNavigate();
  return (
    <div className="no-volunteer-wrapper">
      <img
        src="no-messages.svg"
        alt="No messages"
        className="no-volunteer-image"
      />
      <div className="no-volunteer-title">
        The mentor you are looking for is not available
      </div>

      <DashboardFilledButton
        sx={{ width: '200px', marginTop: '15px' }}
        variant="contained"
        onClick={() => {
          navigate('/mentor-select');
        }}
      >
        See Available Mentors
      </DashboardFilledButton>
    </div>
  );
};

export default VolunteerNotAvailable;
