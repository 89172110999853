import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ReactTooltip from 'react-tooltip';
import SelectButton from '../SelectButton';
import Button from '../Button';
import DeleteMentoPopUp from '../DeleteMentoPopUp';
import { Mentor } from '../../../types';
import MentorModal from './MentorModal';

import './mentor-card.scss';
import { SkillFilledButton } from 'styling/buttons';
import { CustomTypography } from 'styling/generalStyling';

type Props = {
  mentor: Mentor;
  onCardClick?: () => void;
  onAddRemoveClick?: () => void;
  selected?: boolean;
  hideSelect?: boolean;
  hideBio?: boolean;
  disabled?: boolean;
  disabledReason?: 'skills' | 'availability';
  disableButton?: boolean;
  showRemoveButton?: boolean;
  compact?: boolean;
  key?: string | number;
};

const MentorCard = ({
  mentor,
  onCardClick,
  onAddRemoveClick,
  selected,
  hideSelect,
  disabled,
  disabledReason,
  disableButton,
  showRemoveButton,
  compact,
}: Props) => {
  const { id, first_name, last_initial, interests, current_role, employer } =
    mentor;
  const [modalOpen, setModalOpen] = useState<boolean>();
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>();
  const [skillsExpanded, setSkillsExpanded] = useState<boolean>();
  const skillsToDisplay = skillsExpanded ? interests : interests.slice(0, 2);
  const numberOfOtherInterests = interests?.length - 3;
  const showMoreSkillsTag =
    !skillsExpanded && skillsToDisplay && interests?.length > 3;
  const showReactTooltip = !!disabled;

  const closeModal = () => {
    setModalOpen(false);
  };

  const onSelect = () => {
    onAddRemoveClick && onAddRemoveClick();
    closeModal();
  };

  const openModal = () => {
    setModalOpen(true);
    setSkillsExpanded(false);
  };

  useEffect(() => {
    if (disabled && disabledReason) ReactTooltip.rebuild();
  }, [disabled, disabledReason]);

  return (
    <>
      {showReactTooltip && (
        <ReactTooltip place={'top'} arrowColor={'#333333'} />
      )}
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <Grid
          direction="column"
          className={`mentor-card__frame ${
            compact ? 'mentor-card__frame--compact' : ''
          }`}
          data-cy="mentor-frame"
        >
          {disabled && disabledReason && (
            <Box className="mentor-card__disabled-reason" onClick={openModal}>
              <span>
                {disabledReason === 'skills'
                  ? "This mentor's skills do not match your top priority"
                  : disabledReason === 'availability'
                  ? 'This mentor is not available for any of your selected times'
                  : ''}
                <span
                  className="mentor-card__tooltip-wrapper"
                  data-tip={
                    "You can view each mentor's available days & times by clicking on their card."
                  }
                >
                  <img
                    width={'5px'}
                    height="8px"
                    src="/question.png"
                    alt="question"
                  />
                </span>
              </span>
            </Box>
          )}
          <Box
            className={`mentor-card__inner-container ${
              disabled ? 'mentor-card__disabled' : ''
            }`}
          >
            <Stack
              direction={{ xs: 'row', sm: 'row', md: 'column', lg: 'column' }}
              className="mentor-card__img-container"
            >
              <div
                className="profile-picture-volunteer"
                style={{
                  backgroundImage: mentor?.profile_picture
                    ? `url(${mentor?.profile_picture})`
                    : 'url(/avatar.png)',
                }}
                onClick={openModal}
              ></div>

              {compact && (
                <span className="mentor-card__img-text">{first_name}</span>
              )}

              <div className="mentor-top-information" onClick={openModal}>
                <div>
                  <CustomTypography
                    sx={{ fontSize: '18px', fontWeight: 'bold' }}
                  >
                    {' '}
                    {!compact && (
                      <Box
                        className={`name ${
                          compact && 'mentor-card__name--compact'
                        }`}
                      >
                        {first_name} {last_initial}
                      </Box>
                    )}
                  </CustomTypography>
                  <CustomTypography>
                    {current_role && current_role?.length > 15
                      ? `${current_role?.slice(0, 15)}...`
                      : current_role}
                  </CustomTypography>
                  <CustomTypography>
                    {employer && employer?.length > 15
                      ? `${employer?.slice(0, 15)}...`
                      : employer}
                  </CustomTypography>
                </div>
                <Box className="skills-container">
                  {skillsToDisplay.map((interest: string, j: number) => {
                    return (
                      <SkillFilledButton
                        variant="outlined"
                        sx={{ width: 'fit-content', height: 'fit-content' }}
                        key={`skill-${id}-${j}`}
                        data-cy="mentor-skill"
                      >
                        {interest}
                      </SkillFilledButton>
                    );
                  })}
                  {showMoreSkillsTag && (
                    <CustomTypography sx={{ fontSize: '12px' }}>
                      +{numberOfOtherInterests} skills
                    </CustomTypography>
                  )}
                </Box>
              </div>
            </Stack>

            {showRemoveButton ? (
              compact ? (
                <Button
                  text="&#x00D7;"
                  type="ghost"
                  onClickFunction={() => setRemoveModalOpen(!removeModalOpen)}
                  className="mentor-card__remove-button-compact"
                >
                  {removeModalOpen && (
                    <Box className="mentor-card__remove-modal">
                      <span>
                        Are you sure you want to remove this mentor from your
                        selected list?
                      </span>
                      <Button
                        text={'Yes, remove'}
                        type={'red'}
                        onClickFunction={onAddRemoveClick}
                      />
                    </Box>
                  )}
                </Button>
              ) : (
                <DeleteMentoPopUp onClick={onAddRemoveClick} />
              )
            ) : !hideSelect ? (
              <div className="mentor-card__select-button">
                <SelectButton
                  onClick={onSelect}
                  selected={selected}
                  disabled={disableButton}
                />
              </div>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      {modalOpen && (
        <MentorModal
          mentor={mentor}
          modalOpen={modalOpen}
          closeModal={closeModal}
          onSelect={onSelect}
          selected={selected}
          disabledSelect={disableButton}
          hideSelect={hideSelect}
        />
      )}
    </>
  );
};

export default MentorCard;
