import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import '../style/profile.css';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditNameForm from '../Forms/EditNameForm';
import EditGenderForm from '../Forms/EditGenderForm';
import EditEthnicityForm from '../Forms/EditEthnicityForm';
import EditLocationForm from '../Forms/EditLocationForm';
import EditCompanyRoleForm from '../Forms/EditCompanyRoleForm';
import EditNumberOfEmployeeesForm from '../Forms/EditNumberOfEmployeeesForm';
import EditStageOfBusinessForm from '../Forms/EditStageOfBusinessForm';
import EditIntroductionForm from '../Forms/EditIntroductionForm';
import EditWebsiteForm from '../Forms/EditWebsiteForm';
import EditSectorForm from '../Forms/EditSectorForm';
import EditBusinessHealthForm from '../Forms/EditBusinessHealthForm';
import EditEmailForm from '../Forms/EditEmailForm';
import EditPasswordForm from '../Forms/EditPasswordForm';
import EditEmailSettingsForm from '../Forms/EditEmailSettingsForm';
import EditOrganisationTypeForm from '../Forms/EditOrganisationTypeForm';
import EditDisabilityForm from '../Forms/EditDisabilityForm';
import EditShowDisabilityForm from '../Forms/EditShowDisabilityForm';

type Props = {
  inputField: string;
  name: string;
  input: any;
  setValue: (value: any, input: any, key?: any) => void;
  deleteValue?: (input: any) => void;
  value: any;
};

const EditInput = ({
  inputField,
  name,
  input,
  value,
  setValue,
}: Props) => {
  const [edit, setEdit] = useState(false);
  const [field, setInputField] = useState('');
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [showTooltip3, setShowTooltip3] = useState(false);
  const [showTooltip4, setShowTooltip4] = useState(false);

  return (
    <div className="profile__container">
      <div className="profile-input__container">
        <div className="profile-input_name">{name}</div>
        {input && inputField !== 'timezone' ? (
          <EditIcon
            sx={{ fontSize: 'small', marginTop: '24px' }}
            onClick={() => {
              setEdit(true);
              setInputField(inputField);
            }}
          />
        ) : null}

        {name === 'Ethnicity' ? (
          <Tooltip
            open={showTooltip1}
            onClick={() => setShowTooltip1(true)}
            onClose={() => setShowTooltip1(false)}
            placement="top-end"
            title="Your ethnicity helps us improve the quality of our matches. We also use this data to report usage of the service anonymously to our partners."
          >
            <IconButton sx={{ fontSize: 'small', marginTop: '24px' }}>
              <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
            </IconButton>
          </Tooltip>
        ) : null}
         {name === 'Disability / Health Condition' ? (
          <Tooltip
            open={showTooltip1}
            onClick={() => setShowTooltip1(true)}
            onClose={() => setShowTooltip1(false)}
            placement="top-end"
            title="This information helps us improve the quality of our matches and experience. We also use this data to report usage of our service anonymously to our partners."
          >
            <IconButton sx={{ fontSize: 'small', marginTop: '24px' }}>
              <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
            </IconButton>
          </Tooltip>
        ) : null}
        {name === 'Gender' ? (
          <Tooltip
            open={showTooltip2}
            onClick={() => setShowTooltip2(true)}
            onClose={() => setShowTooltip2(false)}
            placement="top-end"
            title="Your gender helps us improve the quality of our matches. We also use this data to report usage of the service anonymously to our partners."
          >
            <IconButton sx={{ fontSize: 'small', marginTop: '24px' }}>
              <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
            </IconButton>
          </Tooltip>
        ) : null}
        {name === 'Location' ? (
          <Tooltip
            open={showTooltip3}
            onClick={() => setShowTooltip3(true)}
            onClose={() => setShowTooltip3(false)}
            placement="top-end"
            title="Only the first three digits are required. This information will help us make more relevant matches and is used for reporting purposes."
          >
            <IconButton sx={{ fontSize: 'small', marginTop: '24px' }}>
              <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
            </IconButton>
          </Tooltip>
        ) : null}
        {name === 'Timezone' ? (
          <Tooltip
            open={showTooltip4}
            onClick={() => setShowTooltip4(true)}
            onClose={() => setShowTooltip4(false)}
            placement="top-end"
            title="Timezone is automatically detected based on your device settings. Times will be displayed according to this timezone."
          >
            <IconButton sx={{ fontSize: 'small', marginTop: '24px' }}>
              <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
      {!edit && (
        <div className="profile-input_text">
          <div>
            {input ? (
              input
            ) : (
              <Button
                className="add_entry_profile"
                sx={{ textTransform: 'none' }}
                startIcon={<AddIcon />}
                onClick={() => {
                  setEdit(true);
                  setInputField(inputField);
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      )}
      {field === 'name' ? (
        <EditNameForm value={value} setValue={setValue} />
      ) : null}
      {field === 'gender' ? (
        <EditGenderForm value={value} setValue={setValue} />
      ) : null}
      {field === 'ethnicity' ? (
        <EditEthnicityForm value={value} setValue={setValue} />
      ) : null}
       {field === 'disability' ? (
        <EditDisabilityForm value={value} setValue={setValue}/>
      ) : null}
       {field === 'showDisability' ? (
        <EditShowDisabilityForm value={value} setValue={setValue}/>
      ) : null}
      {field === 'location' ? (
        <EditLocationForm value={value} setValue={setValue} />
      ) : null}
      {field === 'companyRole' ? (
        <EditCompanyRoleForm value={value} setValue={setValue} />
      ) : null}
      {field === 'organisationType' ? (
        <EditOrganisationTypeForm value={value} setValue={setValue} />
      ) : null}
      {field === 'numberEmployeer' ? (
        <EditNumberOfEmployeeesForm value={value} setValue={setValue} />
      ) : null}
      {field === 'businessStage' ? (
        <EditStageOfBusinessForm value={value} setValue={setValue} />
      ) : null}
      {field === 'introduction' ? (
        <EditIntroductionForm value={value} setValue={setValue} />
      ) : null}
      {field === 'companyWebsite' ? (
        <EditWebsiteForm value={value} setValue={setValue} />
      ) : null}
      {field === 'sector' ? (
        <EditSectorForm value={value} setValue={setValue} />
      ) : null}
      {field === 'businessHealth' ? (
        <EditBusinessHealthForm value={value} setValue={setValue} />
      ) : null}
      {field === 'email' ? (
        <EditEmailForm value={value} setValue={setValue} />
      ) : null}
      {field === 'password' ? <EditPasswordForm setValue={setValue} /> : null}
      {field === 'privacy' ? (
        <EditEmailSettingsForm value={value} setValue={setValue} />
      ) : null}
    </div>
  );
};

export default EditInput;
