import React, { memo, useCallback, useEffect, useState } from 'react';

import {
  CustomSelectAvailabilityTypography,
  CustomTypography,
  CustomTypographyPrimaryColor,
} from 'styling/generalStyling';
import NeededSupport from './neededSupport';
import './select-availability.scss';
import api from 'api';
import { FormControl, OutlinedInput, Paper, Switch } from '@mui/material';
import Cookie from 'js-cookie';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ThankYouRegistration from 'pages/ThankYouRegistration';
import {
  DashboardEmptyButton,
  SelectAvailabilityButton,
} from 'styling/buttons';
import MentorAvailability from './mentorAvailavility';
import { submitRequest } from 'api/callRequests';
import { useNavigate } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import { useGetTaxonomy } from 'apiHooks/useGetTaxonomy';
import useRedux from 'hooks/useRedux';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { updateTaxonomy } from 'features/reduxStore/taxonomy/taxonomy';
import SelectDate from './selectDate';
import SelectTime from './selectTime';
import { getUrlParam } from 'utils/url';
import moment, { Moment } from 'moment';
import { useGetPreferences } from 'pages/Preferences/functions';
import { mobileBreakpoint, TabletBreakpoint } from 'styling/screenSizes';
import { getAvailability } from 'utils/time';
import useFetchPartner from 'features/partner/hooks/useFetchPartner';
import TimeSlotPopupAlert from './TimeSlotPopupAlert';

const SelectAvailabilityPage = () => {
  const preferencesData = useGetPreferences();
  const [hasTimeSlotConflict, setHasTimeSlotConflict] =
    useState<boolean>(false);
  const [accessibilityRequest, setAccessibilityRequest] = useState<string>('');
  const [volunteers, setVolunteers] = useState<any>();
  const volunteerParam = getUrlParam('volunteer') as string;
  const keyword = getUrlParam('keyword') as string;
  const interestParam = getUrlParam('interest')?.toString();

  const followUpId = getUrlParam('follow_up');
  const isFollowUp = !!followUpId;
  const ignoreAvailabilityParam = getUrlParam('ia') as string;
  const recommendedId = getUrlParam('recommended') as string;
  const rebook = getUrlParam('rebook') as string;
  const isRebook = !!rebook;
  const isLinkedRequest =
    !!ignoreAvailabilityParam &&
    ignoreAvailabilityParam === volunteers?.[0]?.toString(); // we should ignore Volunteer availability if request is follow-up or a directly linked request
  const ignoreAvailabilityLimitations =
    isFollowUp || isLinkedRequest || isRebook;

  const volunteer = volunteerParam ? parseInt(volunteerParam) : -1;

  const [interest, setInterest] = useState<number>(
    interestParam ? parseInt(interestParam) : -1
  );
  const [partnerId, setPartnerId] = useState<number | null>(null);
  const [knowledge, setKnowledge] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<string>('');
  const [allowMultipleSessions, seAllowMultipleSessions] =
    useState<boolean>(false);
  const [timeSlotPopupOpen, setTimeSlotPopupOpen] = useState<boolean>(false);
  const [popupCounter, setPopupCounter] = useState<number>(0);
  const { partner } = useFetchPartner();
  const {
    taxonomyByParentFlat: taxonomy,
    taxonomyData,
    hasFinishedLoadingTaxonomy,
  } = useGetTaxonomy([], partnerId);
  const { dispatch } = useRedux();
  useEffect(() => {
    if (hasFinishedLoadingTaxonomy) {
      dispatch(updateTaxonomy(taxonomyData));
    }
  }, [hasFinishedLoadingTaxonomy, dispatch, taxonomyData]);

  let completeDaysArray: number[] = [];
  const daysOfTheWeek = (days: (string | number)[]) => {
    if (days.includes(0)) completeDaysArray.push(0);
    if (days.includes(1)) completeDaysArray.push(1);
    if (days.includes(2)) completeDaysArray.push(2);
    if (days.includes(3)) completeDaysArray.push(3);
    if (days.includes(4)) completeDaysArray.push(4);
    if (days.includes(5)) completeDaysArray.push(5);
    if (days.includes(6)) completeDaysArray.push(6);
  };

  const [newTime1, setNewTime1] = useState<any>(null);
  const [newTime2, setNewTime2] = useState<any>(null);
  const [newTime3, setNewTime3] = useState<any>(null);
  const [newDate1, setNewDate1] = useState<Date | undefined>(undefined);
  const [newDate2, setNewDate2] = useState<Date | undefined>(undefined);
  const [newDate3, setNewDate3] = useState<Date | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const [timeError, setTimeError] = useState<string | null>(null);
  const [shortMessageError, setShortMessageError] = useState<string | null>(
    null
  );
  const [knowledgeError, setKnowledgeError] = useState<string | null>(null);
  const chooseForMe = Cookie.get('system_pick') ? true : false;
  const { width } = useWindowSize();
  const findTimeSlotConflict = useCallback(
    (timeSlots: Array<Moment>) =>
      timeSlots.some((date1, index1) =>
        timeSlots.some((date2, index2) => {
          const momentDate1Plus1Hour = moment(date1).add(59, 'minute'); // to allow for exactly 1 hour ahead
          const momentDate1Minus1Hour = moment(date1).subtract(1, 'hour');

          return (
            index2 !== index1 &&
            (date2.isSame(date1) ||
              date2.isBetween(date1, momentDate1Plus1Hour) ||
              date2.isBetween(date1, momentDate1Minus1Hour))
          );
        })
      ),
    []
  );
  let helpNeeded = undefined;
  const taxArray = taxonomy ? taxonomy : undefined;
  if (taxArray)
    for (let i = 0; i < taxArray.length; i++) {
      let foundEntry = taxArray[i].child?.filter(
        (entry) => entry.id === interest
      );
      if (foundEntry && foundEntry.length > 0) {
        helpNeeded = foundEntry[0];
        break;
      }
    }
  const [showMentors, setShowMentors] = useState(false);
  const isMobile = width < mobileBreakpoint;
  const isTablet = width < TabletBreakpoint && width > mobileBreakpoint;
  const isDesktop = width >= TabletBreakpoint;
  const navigate = useNavigate();
  const messagePopup = isRebook
    ? "We recommend using all the date/time slots on this request so that your mentor has more flexibility to meet with you. If you've already agreed a slot via message with your mentor, please ignore this!"
    : 'Requests with 3 times slots are far more likely to get matched than those that have less! Click okay to add more slots to your request';
  const titlePopup = isRebook
    ? 'Do you want to add more time slots?'
    : 'Increase your chances of being matched 🚀';
  const onSubmit = useCallback(() => {
    setTimeError(null);
    setShortMessageError(null);
    setError(null);
    setKnowledgeError(null);
    const updateTimeslots = () => {
      const getHourAndMinute = (time: string) => {
        const hourMinute = time.split(':');
        return hourMinute;
      };
      let timeSlotArray = [];
      if (newDate1 && newTime1) {
        let updatedDate = moment(newDate1);
        let updatedTime = getHourAndMinute(newTime1);
        updatedDate.add(updatedTime[0], 'hours');
        updatedDate.add(updatedTime[1], 'minutes');
        timeSlotArray.push(updatedDate);
      }
      if (newDate2 && newTime2) {
        let updatedDate = moment(newDate2);
        let updatedTime = getHourAndMinute(newTime2);
        updatedDate.add(updatedTime[0], 'hours');
        updatedDate.add(updatedTime[1], 'minutes');
        timeSlotArray.push(updatedDate);
      }
      if (newDate3 && newTime3) {
        let updatedDate = moment(newDate3);
        let updatedTime = getHourAndMinute(newTime3);
        updatedDate.add(updatedTime[0], 'hours');
        updatedDate.add(updatedTime[1], 'minutes');
        timeSlotArray.push(updatedDate);
      }
      return timeSlotArray;
    };
    const timeSlots = updateTimeslots();

    if (findTimeSlotConflict(timeSlots) === true) {
      setHasTimeSlotConflict(true);
      return null;
    }
    let formattedTimeSlots = timeSlots.map((d) => ({
      start_time: d.toDate(),
    }));
    if (timeSlots.length === 0)
      setTimeError('Please select at least one time slot');
    else if (timeSlots.length < 3 && popupCounter === 0) {
      setTimeSlotPopupOpen(true);
      setPopupCounter(1);
    } else if (message.length < 20)
      setShortMessageError(
        'Oops! It looks like your request is a little short, try adding a few more details to help us match you with the perfect mentor'
      );
    else if (knowledge === undefined)
      setKnowledgeError('Please select how much you know about this topic');
    else
      submitRequest({
        params: {
          skillIds: [interest],
          volunteerIds: volunteers?.map(
            (volunteer: { id: any }) => (volunteer = volunteer.id)
          ),
          descriptionText: message,
          topPrioritySkill: interest,
          followUpId: Number(followUpId),
          partner: !isFollowUp ? partnerId : volunteers?.[0]?.partner_id,
          timeSlots: formattedTimeSlots,
          ignoreVolunteersAvailability: ignoreAvailabilityLimitations,
          allowMultipleSessions: allowMultipleSessions,
          interestExpertise: knowledge,
          mentoringKind: 'one_off_mentoring',
          accessibility_req: accessibilityRequest,
          recommendedId: recommendedId || undefined,
          acceptSubstitutes:
            preferencesData?.privacy_preferences?.accept_substitutes &&
            !isRebook,
          keyword: keyword || 'availability',
          chooseForMe,
        },
        onSuccess: () => navigate(`/request-complete`),
        onError: (errMessage) => {
          setError(errMessage);
          window.scrollTo(0, 0);
        },
        onAccessPaused: () => navigate('/access-paused'),
      });
  }, [
    findTimeSlotConflict,
    allowMultipleSessions,
    interest,
    knowledge,
    message,
    partnerId,
    navigate,
    newTime1,
    newTime2,
    newTime3,
    newDate1,
    newDate2,
    newDate3,
    volunteers,
    keyword,
    chooseForMe,
    ignoreAvailabilityLimitations,
    recommendedId,
    isRebook,
    preferencesData,
    followUpId,
    isFollowUp,
    popupCounter,
    accessibilityRequest
  ]);
  let minTime = 25;
  let maxTime = 0;

  let minMinute = 0;
  let maxMinute = 0;
  const { userLocation } = useSelectProfileData();
  const { timezone = Intl.DateTimeFormat().resolvedOptions().timeZone } =
    userLocation || {};
  if (volunteers?.length > 0) {
    for (const volunteer of volunteers) {
      const { availabilityLocalTimeObject } = getAvailability(
        { availabilityObj: volunteer?.availability },
        volunteer?.timezone,
        timezone
      );
      if (availabilityLocalTimeObject?.start_hour < minTime)
        minTime = availabilityLocalTimeObject?.start_hour;
      minMinute = availabilityLocalTimeObject?.start_minute;
      if (availabilityLocalTimeObject?.end_hour > maxTime)
        maxTime = availabilityLocalTimeObject?.end_hour;
      maxMinute = availabilityLocalTimeObject?.end_minute;
      daysOfTheWeek(volunteer.availability.days);
    }
  } else {
    completeDaysArray = [0, 1, 2, 3, 4, 5, 6];
    minTime = 0;
    maxTime = 23;
  }

  useEffect(() => {
    const getDraftBootsCall = async () => {
      const { data } = await api.getDraftBoostCall();
      setPartnerId(data?.partner);
      setInterest(data.interests[0]);
      const { invited_volunteers } = data;

      if (chooseForMe === true) {
        setVolunteers([]);
      } else {
        api.searchMentors(invited_volunteers, 20).then((data: any) => {
          setVolunteers(data.results);
        });
      }
    };
    const getVolunteer = async () => {
      api.searchMentors([volunteer], 1).then((data: any) => {
        setVolunteers(data.results);
      });
    };

    if (volunteer === -1) getDraftBootsCall();
    else getVolunteer();
  }, [chooseForMe, interest, volunteer]);

  return (
    <div className="page-container">
      <TimeSlotPopupAlert
        open={timeSlotPopupOpen}
        handleClose={() => setTimeSlotPopupOpen(false)}
        message={messagePopup}
        titlePopup={titlePopup}
      />
      <div className="select-availability-container">
        <CustomTypographyPrimaryColor
          sx={{
            display: 'flex',
            fontSize: isMobile ? '24px' : '36px',
            fontWeight: '800',
            marginTop: '20px',
            lineHeight: '30px',
            width: isMobile ? '299px' : 'auto',
            letterSpacing: 'normal',
            justifyContent: isMobile || isTablet ? 'center' : 'left',
          }}
        >
          Your {partner?.request_title || '1:1 Mentoring'} Request
        </CustomTypographyPrimaryColor>
        <DashboardEmptyButton
          variant="text"
          sx={{
            textTransform: 'none',
            fontFamily: 'Gilroy',
            fontSize: isMobile ? '13px' : '16px',
            fontWeight: '800',
            textDecoration: 'underline',
            textAlign: 'left',
            color: '#000000',
            padding: 0,
            height: 'auto',
            marginLeft: isMobile ? '25px' : '0',
            marginTop: '12px',
            width: 'fit-content',
            marginBottom: isMobile ? '10px' : '0',
          }}
          onClick={() =>
            window.open(
              partner?.top_tips_link ||
                'https://digitalboost.org.uk/how-to-write-the-perfect-mentoring-request/',
              '_blank'
            )
          }
        >
          Top tips for making a request
        </DashboardEmptyButton>
        <div className="action-instruction-container">
          <Paper
            elevation={0}
            sx={{
              borderRadius: '15px',
              filter: 'drop-shadow(0.5px 0px 1px rgba(0, 0, 0, 0.15))',
              maxWidth: isTablet ? '720px' : isMobile ? '99vw' : '640px',
              height: 'inherit',
              paddingLeft: '18px',
              paddingTop: '18px',
              paddingRight: '12px',
              display: 'inline-flex',
              flexDirection: 'column',
            }}
          >
            <CustomTypographyPrimaryColor
              sx={{
                justifyContent: isMobile || isTablet ? 'center' : 'left',
                display: 'flex',
                fontSize: isMobile ? '18px' : isTablet ? '28px' : '32px',
                fontWeight: '800',
              }}
            >
              {isMobile || isTablet ? '' : '1.'} Tell us what support you need
            </CustomTypographyPrimaryColor>
            <CustomTypography
              sx={{
                color: '#000000',
                fontWeight: '500',
                fontSize: isMobile ? '13px' : 'undefined',
                wordBreak: 'break-word',
                marginTop: '10px',
                lineHeight: isMobile ? '15.3px' : '18.83px',
              }}
            >
              Be specific about the help you need and don’t include personal
              info such as email or phone.
            </CustomTypography>
            {knowledgeError ? (
              <CustomSelectAvailabilityTypography
                sx={{
                  fontSize: isMobile ? '9px' : '12px',
                  lineHeight: '20px',
                  color: '#FF0000',
                  fontWeight: '800',
                  marginTop: '10px',
                  marginBottom: '-5px',
                  alignSelf: isMobile ? 'center' : 'left',
                }}
              >
                {knowledgeError}
              </CustomSelectAvailabilityTypography>
            ) : null}
            {helpNeeded ? (
              <NeededSupport
                setKnowledge={setKnowledge}
                knowledge={knowledge}
                shortMessageError={shortMessageError}
                setMessage={setMessage}
              />
            ) : null}
          </Paper>

          <Paper
            elevation={0}
            sx={{
              width: '100%',
              borderRadius: '15px',
              filter: 'drop-shadow(0.5px 0px 1px rgba(0, 0, 0, 0.15))',
              height: 'inherit',
              maxWidth: isTablet ? '720px' : isMobile ? '99vw' : '640px',
              paddingLeft: isMobile ? '0' : '18px',
              paddingTop: '18px',
              paddingRight: isMobile ? '0' : '5px',
              display: 'inline-flex',
              rowGap: '10px',

              flexDirection: 'column',
            }}
          >
            <CustomTypographyPrimaryColor
              sx={{
                fontSize: isMobile ? '18px' : isTablet ? '28px' : '32px',
                fontWeight: '800',
                width: isMobile ? '298px' : 'auto',
                letterSpacing: 'normal',
                justifyContent: isMobile || isTablet ? 'center' : 'left',
                display: 'flex',
              }}
            >
              {isMobile || isTablet ? '' : '2.'} Suggest available dates and
              times
            </CustomTypographyPrimaryColor>

            <div className="support-date-container">
              <CustomSelectAvailabilityTypography
                sx={{
                  width: isMobile ? '268px' : 'auto',
                  fontWeight: '500',
                  fontSize: isMobile ? '13px' : 'undefined',
                  wordBreak: 'break-word',
                  letterSpacing: 'normal',

                  lineHeight: isMobile ? '15.3px' : '18.83px',
                  marginBottom: chooseForMe ? '20px' : '0px',
                }}
              >
                For the best chance of your request being accepted, propose up
                to three days {'& '}times to meet. Volunteers may propose
                different times if they can’t make the ones you’ve suggested.
              </CustomSelectAvailabilityTypography>

              {!chooseForMe && volunteers ? (
                <div>
                  <DashboardEmptyButton
                    sx={{
                      fontSize: isMobile ? '13px' : '16px',
                      fontWeight: '800',
                      textDecoration: 'underline',
                      width: '100%',
                      lineHeight: isMobile ? '16.25px' : '20px',
                      textAlign: 'center',
                      padding: 0,
                      marginBottom: '20px',
                      paddingTop: '10px',
                      justifyContent:
                        isMobile || isTablet ? 'center' : 'flex-start',
                    }}
                    variant="text"
                    onClick={() => setShowMentors(!showMentors)}
                  >
                    View mentor availability
                  </DashboardEmptyButton>
                  <div>
                    {volunteers && showMentors ? (
                      <MentorAvailability volunteers={volunteers} />
                    ) : null}
                  </div>
                </div>
              ) : null}
              {timeError ? (
                <CustomSelectAvailabilityTypography
                  sx={{
                    fontSize: isMobile ? '9px' : '12px',
                    lineHeight: '20px',
                    color: '#FF0000',
                    fontWeight: '800',
                    marginTop: '-10px',
                    marginBottom: '10px',
                    alignSelf: isMobile ? 'center' : 'left',
                  }}
                >
                  {timeError}
                </CustomSelectAvailabilityTypography>
              ) : null}
              <div className="select-availability-card-date-time">
                <div>
                  <SelectDate
                    setNewDate={setNewDate1}
                    position={1}
                    completeDaysArray={completeDaysArray}
                  />
                </div>

                <SelectTime
                  position={1}
                  setNewTime={setNewTime1}
                  newTime={newTime1}
                  selectedDay={newDate1}
                  minTime={minTime}
                  maxTime={maxTime}
                  maxMinute={maxMinute}
                  minMinute={minMinute}
                />
              </div>
              <div className="select-availability-card-date-time">
                <SelectDate
                  position={2}
                  setNewDate={setNewDate2}
                  completeDaysArray={completeDaysArray}
                />

                <SelectTime
                  position={2}
                  setNewTime={setNewTime2}
                  newTime={newTime2}
                  selectedDay={newDate2}
                  minTime={minTime}
                  maxTime={maxTime}
                  maxMinute={maxMinute}
                  minMinute={minMinute}
                />
              </div>
              <div className="select-availability-card-date-time">
                <SelectDate
                  setNewDate={setNewDate3}
                  completeDaysArray={completeDaysArray}
                  position={3}
                />

                <SelectTime
                  position={3}
                  setNewTime={setNewTime3}
                  newTime={newTime3}
                  selectedDay={newDate3}
                  minTime={minTime}
                  maxTime={maxTime}
                  maxMinute={maxMinute}
                  minMinute={minMinute}
                />
              </div>
            </div>
          </Paper>
        </div>

        <Paper
          elevation={0}
          sx={{
            filter:
              isMobile || isTablet
                ? 'undefined'
                : 'drop-shadow(0.5px 0px 1px rgba(0, 0, 0, 0.15))',

            marginBottom: isMobile ? '60px' : '0',
            borderRadius: '15px',
            paddingLeft: isMobile ? '0' : '20px',
            maxWidth: isTablet ? '720px' : isMobile ? '320px' : '1280px',
            height: isMobile ? 'auto' : 'auto',
            paddingTop: '18px',
            display: 'inline-flex',
            paddingRight: isMobile ? '0' : '20px',
            rowGap: '20px',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <CustomTypographyPrimaryColor
            sx={{
              fontSize: isMobile ? '18px' : isTablet ? '28px' : '32px',
              fontWeight: '800',
              lineHeight: '22.5px',
              display: 'inline-flex',
              alignSelf: 'center',
              letterSpacing: 'normal',
              width: isMobile ? '311px' : 'auto',
            }}
          >
            {isMobile || isTablet ? '' : '3.'} Confirm your mentoring
            preferences
          </CustomTypographyPrimaryColor>

          <div className="mentoring-preferences-container">
            {!keyword ? (
              <div className="mentoring-preferences-options">
                <CustomSelectAvailabilityTypography
                  sx={{
                    fontWeight: '500',
                    fontSize: isMobile ? '12px' : '16px',
                    width: isDesktop ? '620px' : '100%',
                    lineHeight: isMobile ? '14.12px' : '18.83px',
                  }}
                >
                  If more than one mentor accepts your request, should we set up
                  multiple sessions?
                </CustomSelectAvailabilityTypography>
                <FormGroup
                  sx={{
                    display: 'inline-flex',
                    width: '120px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <CustomSelectAvailabilityTypography
                    sx={{
                      marginRight: '10px',
                      fontWeight: '500',
                      fontSize: isMobile ? '12px' : '16px',
                    }}
                  >
                    {' '}
                    No
                  </CustomSelectAvailabilityTypography>
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    checked={allowMultipleSessions}
                    onChange={() =>
                      seAllowMultipleSessions(!allowMultipleSessions)
                    }
                    label={null}
                  />
                  <CustomSelectAvailabilityTypography
                    sx={{
                      marginLeft: '-15px',
                      marginRight: '5px',
                      fontWeight: '500',
                      fontSize: isMobile ? '12px' : '16px',
                    }}
                  >
                    {' '}
                    Yes
                  </CustomSelectAvailabilityTypography>
                </FormGroup>
              </div>
            ) : null}
            {keyword ? (
              <div className="select-availability-help-text-keyword">
                <b>We'll ask your chosen mentor if they can help.</b> Keep an
                eye on your emails as we'll be in touch once the mentor accepts
                the request 😊
              </div>
            ) : chooseForMe ? (
              <div className="select-availability-help-text">
                <b>We'll connect you with the best mentor(s) to help you.</b>{' '}
                Keep an eye on your emails as we'll let you know as soon as
                we've found your perfect match 😊
              </div>
            ) : (
              <div className="select-availability-help-text">
                <b>
                  We’ll ask your chosen mentor(s) if they can help.
                  {preferencesData?.privacy_preferences?.accept_substitutes
                    ? ' If they can’t, we’ll connect you to another great mentor with similar skills.'
                    : null}
                </b>{' '}
                <div>
                  Keep on eye on your emails as we’ll be in touch once we’ve
                  been able to match you with a mentor 😊
                </div>
              </div>
            )}
          </div>
          <CustomSelectAvailabilityTypography
            sx={{
              fontWeight: '800',
              fontSize: isMobile ? '13px' : '16px',
              display: 'inline-flex',
              columnGap: '5px',
              flexDirection: 'row',
              rowGap: '10px',
              width: 'fit-content',
              lineHeight: isMobile ? '16.25px' : '20px',
              whiteSpace: 'nowrap',
              flexWrap: isMobile ? 'wrap' : 'nowrap',
              alignItems: 'center',
            }}
          >
            If you have any accessibility requests for this call, please let our mentor(s) know below:
          </CustomSelectAvailabilityTypography>
          <FormControl
            sx={{
              width: '100%',
              minHeight: 'fit-content',
              marginBottom: '18px',
            }}
          >
            <OutlinedInput
              sx={{
                fontFamily: 'Gilroy',
                width: '100%',
                maxHeight: '100%',
                minHeight: '130px',
                borderRadius: '15px',
                fontSize: isMobile ? '13px' : '16px',
                lineHeight: isMobile ? '15.3px' : '18px',
              }}
              multiline={true}
              onChange={(event) => setAccessibilityRequest(event.target.value)}
            />
          </FormControl>
        </Paper>
        {error ? (
          <CustomSelectAvailabilityTypography
            sx={{
              fontSize: isMobile ? '9px' : '16px',
              lineHeight: '20px',
              color: '#FF0000',
              fontWeight: '800',
              alignSelf: isMobile ? 'center' : 'left',
            }}
          >
            {error}
          </CustomSelectAvailabilityTypography>
        ) : null}
        {hasTimeSlotConflict ? (
          <CustomSelectAvailabilityTypography
            sx={{
              fontSize: isMobile ? '9px' : '16px',
              lineHeight: '20px',
              color: '#FF0000',
              fontWeight: '800',
            }}
          >
            All our sessions are 60 minutes long, so please choose another time
            that doesn't overlap
          </CustomSelectAvailabilityTypography>
        ) : null}

        <SelectAvailabilityButton
          variant={
            message.length === 0 || knowledge === undefined || newDate1 === null
              ? 'outlined'
              : 'contained'
          }
          sx={{
            width: isMobile ? '300px' : 'undefined',
            border: '10px',
          }}
          onClick={onSubmit}
        >
          Submit
        </SelectAvailabilityButton>
      </div>
    </div>
  );
};

const SelectAvailability = () => {
  const { organisationProfile } = useSelectProfileData({ autoFetch: true });
  if (
    organisationProfile?.partner !== null &&
    organisationProfile?.is_partner_approved === false
  )
    return <ThankYouRegistration />;
  else return <SelectAvailabilityPage />;
};

export default memo(SelectAvailability);
