import { useState, useEffect } from 'react';
import { getProposedTimeSlots } from '../../utils/boostCallData';
import { BoostCallRequest, TimeSlotWithTopPriority } from '../../types';

type Props = { boostCallEventRequests: BoostCallRequest[] | undefined };

const useGetProposeTimeSlots = ({ boostCallEventRequests }: Props) => {
  const [timeSlotToDisplay, setTimeSlotToDisplay] =
    useState<TimeSlotWithTopPriority | null>();
  const [hasDisplayed, setHasDispalyed] = useState<boolean>(false);

  // Filter BoostCallEventRequests and return TimeSlots with status === 'proposed'
  const proposedTimeSlots = getProposedTimeSlots(boostCallEventRequests);

  useEffect(() => {
    if (
      proposedTimeSlots &&
      proposedTimeSlots.length > 0 &&
      !timeSlotToDisplay &&
      !hasDisplayed
    ) {
      setTimeSlotToDisplay(proposedTimeSlots[0]);
      setHasDispalyed(true);
    }
  }, [hasDisplayed, proposedTimeSlots, timeSlotToDisplay]);

  return {
    proposedTimeSlots: (proposedTimeSlots as TimeSlotWithTopPriority[]) || [],
    timeSlotToDisplay,
    setTimeSlotToDisplay,
  };
};

export default useGetProposeTimeSlots;
