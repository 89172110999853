import React, { useState } from 'react';
import './style.scss';
import '../components/styling/card-styling.scss';
import MentorModal from 'components/MentorCard/MentorModal';
import { useNavigate } from 'react-router-dom';
import { DashboardEmptyButton, SkillFilledButton } from 'styling/buttons';
import { IoIosClose } from 'react-icons/io';

type Props = {
  mentorData: any;
  clickHide: (id: number) => void;
};

const NewRecommendationMentorCard = ({ mentorData, clickHide }: Props) => {
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const { volunteer, interest, id } = mentorData || {};

  return (
    <div className="recommended-mentor__container">
      <div
        className="recommended-mentor__delete"
        onClick={() => clickHide(mentorData?.id)}
      >
        <IoIosClose size={35} />
      </div>

      <div className="recommended-mentor__row">
        <div>
          <div className="profile-photo-container">
            <img
              src={volunteer?.profile_picture}
              alt={`${volunteer?.first_name}`}
            />
          </div>
          <DashboardEmptyButton
            variant="outlined"
            sx={{
              borderRadius: '100px',
              color: '#333',
              marginTop: '20px',
              borderWidth: '3px',
            }}
            onClick={() =>
              navigate(
                `/volunteer-details?volunteer[]=${mentorData?.volunteer?.id}&keyword=recommended-mentor`
              )
            }
            className="recommended-mentor__button"
          >
            View
          </DashboardEmptyButton>
        </div>
        <div className="recommended-mentor__info">
          <div className="recommended-mentor__name">
            {volunteer?.first_name} {volunteer?.last_name}
          </div>
          <div className="recommended-mentor__profession">{interest?.name}</div>
          {volunteer?.employer && (
            <div className="recommended-mentor__company">
              {volunteer?.employer}
            </div>
          )}

          <div className="recommended-mentor__tag-wrap">
            {volunteer?.interests.slice(0, 2).map((interest: string) => (
              <SkillFilledButton
                variant="outlined"
                sx={{ width: 'fit-content', height: 'fit-content' }}
              >
                {interest}
              </SkillFilledButton>
            ))}
            {volunteer?.interests?.length > 3 && (
              <div className="recommended-mentor__tag">
                +{volunteer?.interests?.length - 3} other skills
              </div>
            )}
          </div>
        </div>
      </div>

      {modalOpen && (
        <MentorModal
          mentor={volunteer}
          modalOpen={modalOpen}
          recommendedId={id}
          allowDirectRequest
          hideSelect
          closeModal={() => setModalOpen(false)}
        />
      )}
    </div>
  );
};

export default NewRecommendationMentorCard;
