import React, { useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import './filePopupStyle.scss';
import { DashboardFilledButton } from 'styling/buttons';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '@mui/material';
import { allowedFileExtensions } from 'pages/Library/fileExtensions';

interface AttachmentPopupProps {
  open: boolean;
  setOpenAttachmentPopup: (arg0: boolean) => void;
  setOpenLibrary: (arg1: boolean) => void;
  refetchMessage: () => void;
  uploadFileToSend: (files: FileList) => void;
  volunteerId: number | undefined;
  count: number;
}

const AttachmentPopup: React.FC<AttachmentPopupProps> = ({
  open,
  count,
  setOpenLibrary,
  setOpenAttachmentPopup,
  uploadFileToSend,
}) => {
  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined
  );
  const handleOpenLibrary = () => {
    setOpenLibrary(true);
    setOpenAttachmentPopup(false);
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };
  const handleClose = () => {
    setErrorMessage(undefined);
    setOpenAttachmentPopup(false);
  };
  const verifyFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;
    let totalSizeMB = 0;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const selectedFile = files[i];

        // Get the file extension
        const fileExtension = selectedFile.name.split('.').pop()?.toLowerCase();

        if (fileExtension === undefined) return;
        if (parseFloat((selectedFile.size / (1000 * 1000)).toFixed(2)) > 10) {
          setErrorMessage(
            'Oops, it looks like that file is too big! All uploads must be under 10MB. Choose another one'
          );
          setOpenAttachmentPopup(true);
          event.target.value = '';
          return;
        }
        totalSizeMB = totalSizeMB + selectedFile.size / (1024 * 1024);

        if (!allowedFileExtensions.includes(`.${fileExtension}`)) {
          setErrorMessage(
            'Oops! That file format is not supported. Please try uploading another file type'
          );
          setOpenAttachmentPopup(true);
          event.target.value = ''; // Clear the file input
          return;
        }
      }
      setOpenAttachmentPopup(false);
      uploadFileToSend(files);
    }
  };

  const [showTooltip, setShowTooltip] = useState(false);
  // if (isLoading)
  //   return (
  //     <LoadingSplashPopup
  //       open={isLoading}
  //       message="Your file is being uploaded"
  //     />
  //   );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          border: '1px solid #A3A3A3',
          boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.15)',
          borderRadius: '20px',
          backgroundColor: 'transparent',
        },
      }}
    >
      <Tooltip
        sx={{
          position: 'absolute',
          top: '18px',
          left: '14px',
          fontFamily: 'Gilroy',
        }}
        open={showTooltip}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        title={
          <span style={{ fontFamily: 'Gilroy' }}>
            Each file must not exceed 10 Mb
          </span>
        }
      >
        <HelpOutlineIcon />
      </Tooltip>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          paddingTop: '20px',
          width: '324px',
          height: errorMessage ? '250px' : '207px',
          backgroundColor: '#F2F2F2',
          borderRadius: '20px',
        }}
      >
        <div className="attachment-popup-title">Attach file</div>
        {count > 4 ? (
          <div className="error">
            You cannot add more than 5 files in each message. Please, send first
            the ones you have already selected.
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <DashboardFilledButton
              variant="contained"
              sx={{ height: '52px', cursor: 'pointer', marginBottom: '10px' }}
              onClick={handleButtonClick}
            >
              From your device
            </DashboardFilledButton>

            <DashboardFilledButton
              variant="contained"
              sx={{ height: '52px', cursor: 'pointer', marginBottom: '5px' }}
              onClick={handleOpenLibrary}
            >
              From your library
            </DashboardFilledButton>
            <input
              ref={fileInputRef}
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              onChange={verifyFileUpload}
            />
          </div>
        )}

        {errorMessage ? (
          <div className="error-message-attachment">{errorMessage}</div>
        ) : undefined}
      </DialogContent>
    </Dialog>
  );
};

export default AttachmentPopup;
