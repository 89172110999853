// ExistingCard.js

import React, { memo, useState } from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { deleteActionTask, updateActionTask } from 'api/actionPlan';
import { DashboardEmptyButton, StyledTrashButton } from 'styling/buttons';
import './cardPlan.scss';
import { CardsType } from '../../../types';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { formatDate } from 'pages/MessagingCentre/MessageCenter/MessageCenterComposer/utils';
import { NoBorderTextField } from 'styling/generalStyling';
interface ExistingCardProps {
  isOpen: boolean;
  onClose: () => void;
  setCounterChange: () => void;
  setCardEdit: (value: boolean) => void;
  permission: string;
  cardDetails: CardsType;
}

const ExistingCard = ({
  onClose,
  permission,
  isOpen,
  setCardEdit,
  setCounterChange,
  cardDetails,
}: ExistingCardProps) => {
  const allowEditorActions =
    permission === 'owner' || permission === 'editor' ? true : false;
  const color =
    cardDetails.status === 'todo'
      ? '#FCEBEB'
      : cardDetails.status === 'in_progress'
      ? '#FFF9E4'
      : '#ecfbf0';

  const deleteCard = async () => {
    if (cardDetails)
      await deleteActionTask(cardDetails?.id.toString())
        .then((response) => {
          if (response.success) {
            setCounterChange();
            onClose();
          } else {
            console.error('Error deleting task:', response.error);
          }
        })
        .catch((error) => {
          console.error('Error in task:', error);
        });
  };
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const handleUpdateCard = async (status: string) => {
    if (cardDetails) {
      try {
        const result = await updateActionTask(cardDetails, status);
        if (result && result.success) {
          setCounterChange();
          onClose();
        } else {
          console.error('Task update failed:', result.error);
        }
      } catch (error) {
        console.error('Error updating task:', error);
      }
    } else {
      console.error('Error: Card details are required to update a task');
    }
  };
  const firstName =
    cardDetails?.organisation?.first_name || cardDetails?.volunteer?.first_name;
  const displayCreatorName =
    firstName && firstName.length > 10
      ? firstName.substring(0, 10) + '...'
      : firstName;

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
        sx={{
          padding: 0,
          '.MuiPaper-root': {
            padding: 0,
            height: showButtons ? '426px' : 'auto',
            borderRadius: '15px',
            background: ' #FFF',
            width: '840px',
            minHeight: '372px',
            maxHeight: '90vh',
            backgroundColor: `${color}`,
            maxWidth: '100vw',
            flexShrink: 0,
          },
        }}
      >
        <div
          className="close-dialog-icon"
          style={{ justifyContent: 'flex-end', paddingRight: '30px' }}
        >
          <IconButton
            color="primary"
            onClick={onClose}
            sx={{ padding: '0px', alignSelf: 'flex-end' }}
          >
            <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
          </IconButton>
        </div>

        <DialogContent
          sx={{
            marginRight: '70px',
            marginLeft: '70px',
            width: '700px',
            minHeight: '280px',
            padding: 0,
            paddingBottom: '30px',
          }}
        >
          <div className="new-card-management-details">
            <div>
              <div className="row-card-management">
                <div className="table-text" style={{ marginLeft: '20px' }}>
                  Task name
                </div>
                <div className="table-text" style={{ marginLeft: '20px' }}>
                  {' '}
                  Topic
                </div>
              </div>
              <div className="row-card-management">
                <NoBorderTextField
                  disabled
                  value={cardDetails?.title}
                  variant="standard"
                  inputProps={{
                    maxLength: 50,
                  }}
                />{' '}
                <div className="new-card-management-text">
                  {' '}
                  {cardDetails.interest?.name
                    ? cardDetails.interest.name
                    : 'Not selected'}
                </div>
              </div>
              <div className="divider" style={{ width: '700px' }} />
            </div>
            <div>
              <div className="row-card-management-2">
                <div className="table-text" style={{ marginLeft: '20px' }}>
                  Task Creator
                </div>
                <div className="table-text" style={{ marginLeft: '20px' }}>
                  {' '}
                  Creation Date
                </div>
                <div className="table-text" style={{ marginLeft: '20px' }}>
                  {' '}
                  Deadline
                </div>
              </div>
              <div className="row-card-management-2">
                <div className="new-card-management-text">
                  {displayCreatorName}
                </div>
                <div className="new-card-management-text">
                  {' '}
                  {formatDate(cardDetails?.created)}
                </div>
                <div className="new-card-management-text">
                  {' '}
                  {cardDetails?.deadline
                    ? formatDate(cardDetails?.deadline)
                    : 'No deadline set'}
                </div>
              </div>
              <div className="divider" style={{ width: '700px' }} />
            </div>

            <div>
              <div className="row-card-management-2">
                <div className="table-text" style={{ marginLeft: '20px' }}>
                  Description
                </div>
              </div>
              <div className="new-card-management-text">
                {cardDetails?.description
                  ? cardDetails.description
                  : 'No description available'}
              </div>
              <div className="divider" style={{ width: '700px' }} />
            </div>
            {allowEditorActions ? (
              <div className="new-card-management-buttons">
                <DashboardEmptyButton
                  variant="text"
                  sx={{
                    justifyContent: 'flex-end',
                    color: '#333',
                    fontFamily: 'Gilroy',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '120%',
                    letterSpacing: '0.14px',
                    padding: 0,
                    minWidth: '30px',
                  }}
                  onClick={() => {
                    setCardEdit(true);
                    onClose();
                  }}
                >
                  <CreateOutlinedIcon /> Edit card
                </DashboardEmptyButton>
                <DashboardEmptyButton
                  onClick={() => setShowButtons(true)}
                  variant="text"
                  sx={{
                    justifyContent: 'flex-end',
                    color: '#333',
                    fontFamily: 'Gilroy',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '120%',
                    letterSpacing: '0.14px',
                    padding: 0,
                    minWidth: '30px',
                  }}
                >
                  <ArrowForwardIcon />
                  Move to
                </DashboardEmptyButton>
                <DashboardEmptyButton
                  variant="text"
                  onClick={() => deleteCard()}
                  sx={{
                    justifyContent: 'flex-end',
                    color: '#333',
                    fontFamily: 'Gilroy',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '120%',
                    letterSpacing: '0.14px',
                    padding: 0,
                    minWidth: '30px',
                  }}
                >
                  <StyledTrashButton sx={{ backgroundColor: '#3a3a3a' }} />
                  Delete card
                </DashboardEmptyButton>
              </div>
            ) : null}
            {showButtons ? (
              <div className="ticket-status-buttons">
                {cardDetails?.status !== 'todo' ? (
                  <DashboardEmptyButton
                    variant="text"
                    onClick={() => handleUpdateCard('todo')}
                    sx={{
                      justifyContent: 'center',
                      color: '#333',
                      backgroundColor: '#fcebeb',
                      fontFamily: 'Gilroy',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      letterSpacing: '0.14px',
                      padding: 0,
                      width: '140px',
                      height: '28px',
                      borderRadius: '5px',
                    }}
                  >
                    To do
                  </DashboardEmptyButton>
                ) : null}

                {cardDetails?.status !== 'in_progress' ? (
                  <DashboardEmptyButton
                    variant="text"
                    onClick={() => handleUpdateCard('in_progress')}
                    sx={{
                      justifyContent: 'center',
                      color: '#333',
                      backgroundColor: '#FFF9E4',
                      fontFamily: 'Gilroy',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      letterSpacing: '0.14px',
                      padding: 0,
                      width: '140px',
                      height: '28px',
                      borderRadius: '5px',
                    }}
                  >
                    In progress
                  </DashboardEmptyButton>
                ) : null}
                {cardDetails?.status !== 'completed' ? (
                  <DashboardEmptyButton
                    variant="text"
                    onClick={() => handleUpdateCard('completed')}
                    sx={{
                      justifyContent: 'center',
                      color: '#333',
                      backgroundColor: '#ECFBF0',
                      fontFamily: 'Gilroy',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      letterSpacing: '0.14px',
                      padding: 0,
                      width: '140px',
                      height: '28px',
                      borderRadius: '5px',
                    }}
                  >
                    Completed
                  </DashboardEmptyButton>
                ) : null}
              </div>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default memo(ExistingCard);
