import { createSlice } from '@reduxjs/toolkit';
import { RESET_STORE, RootState } from 'store';
import { Attachment } from 'types';

const initialState = {
  count: 0,
  next: null,
  attachments: [] as Attachment[],
};

export const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState,
  reducers: {
    updateAttachments: (state, action) => {
      return { ...state, ...action.payload };
    },
    deleteAttachmentFromRedux: (state, action) => {
      const attachmentIdToDelete = action.payload;
      state.attachments = state.attachments.filter(
        (attachment) => attachment.id !== attachmentIdToDelete
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STORE, () => {
      return initialState;
    });
  },
});

export const { updateAttachments, deleteAttachmentFromRedux } =
  attachmentsSlice.actions;
/* --- SELECTORS --- */
export const selectAttachments = (state: RootState) => state.attachments;

export default attachmentsSlice.reducer;
