import { useState, useEffect } from 'react';
import { logRocketIdentify } from '../utils/auth';
import useSelectProfileData from './useSelectProfileData';

const useLogRocket = () => {
  const [hasInitialisedLogRocket, setHasInitialisedLogRocket] = useState(false);
  const {
    userProfile,
    organisationProfile,
    hasFinishedFetchingProfileAndLocation,
  } = useSelectProfileData();
  useEffect(() => {
    (async () => {
      if (
        hasFinishedFetchingProfileAndLocation &&
        !hasInitialisedLogRocket &&
        userProfile &&
        organisationProfile
      ) {
        try {
          await logRocketIdentify({
            userProfile: userProfile,
            orgProfile: organisationProfile,
          });
          setHasInitialisedLogRocket(true);
        } catch (err) {
          console.log('err:', err);
        }
      }
    })();
  }, [
    hasFinishedFetchingProfileAndLocation,
    hasInitialisedLogRocket,
    organisationProfile,
    userProfile,
  ]);
};

export default useLogRocket;
