import React, { useState } from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import useVerifyAccount from '../../features/auth/hooks/useVerifyAccount';
import CloseIcon from '@mui/icons-material/Close';
import './verification-modal.scss';
import { Box, IconButton, Modal } from '@mui/material';
import { DashboardFilledButton } from 'styling/buttons';
type Props = {
  verificationModalOpen: boolean;
};
const VerificationModal = ({ verificationModalOpen }: Props) => {
  const { resendVerification, hasResentVerification, resendVerificationError } =
    useVerifyAccount();
  const notificationTextVisible =
    hasResentVerification || resendVerificationError;
  const [modalOpen, setModalOpen] = useState<boolean>(verificationModalOpen);
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    width: '600px',
    left: '50%',
    fontFamily: 'Gilroy',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <Modal open={modalOpen} onClose={closeModal} className="modal-container">
      <Box sx={style}>
        <div className="reschedule-close-dialog-icon ">
          <IconButton
            color="primary"
            onClick={closeModal}
            sx={{ padding: '0px' }}
          >
            <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
          </IconButton>
        </div>
        <div className="sign-up-modal-container">
          <div className="titleContainer">
            <IoIosCheckmarkCircle className="icon" size={32} fill={'#00AB6B'} />
            <h3 className="title">
              A verification link has been sent to your email address
            </h3>
          </div>
          <div className="sign-up-modal-description">
            If you haven't received it, double-check that the email you entered
            is correct and/or check your spam folder.
          </div>
          <div className="sign-up-modal-description">
            If you're still having problems,{' '}
            <a
              className="link"
              href={'https://www.digitalboost.org.uk/contact'}
            >
              {'contact us!'}
            </a>
          </div>

          <div>
            <div className="sign-up-modal-description">
              Need a new verification code?
            </div>
          </div>
          <div className="buttonContainer">
            <DashboardFilledButton
              variant="contained"
              sx={{
                backgroundColor: '#181f39',
                maxWidth: '300px',
                '&:hover': {
                  backgroundColor: '#181f39',
                },
              }}
              onClick={resendVerification}
            >
              Resend Verification Email
            </DashboardFilledButton>
          </div>
          {notificationTextVisible && (
            <span
              className={`notificationText ${
                resendVerificationError ? 'errorText' : ''
              }`}
            >
              {resendVerificationError
                ? resendVerificationError
                : 'Verification resent!'}
            </span>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default VerificationModal;
