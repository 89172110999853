import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import {
  CustomFormLabel,
  CustomFormControlLabel,
  CustomTypography,
} from 'styling/generalStyling';
import '../style/profile.css';

type Props = {
  setValue: (value: any, input: string) => void;
  value: string;
};
export default function EditBusinessHealthForm({ value, setValue }: Props) {
  return (
    <FormControl>
      <CustomFormLabel id="gender-radio-buttons-group-label">
        How would you rate your business’s current health?
      </CustomFormLabel>
      <RadioGroup
        aria-labelledby="gender-radio-buttons-group-label"
        defaultValue={value}
        name="radio-buttons-group"
        sx={{
          display: 'inline-flex',
          flexDirection: 'row',
         
        }}
      >
        <CustomFormControlLabel
          sx={{ backgroundColor: '#f44336', padding: '10px' }}
          label={<CustomTypography>1</CustomTypography>}
          onClick={() => setValue(1, 'business_health')}
          control={<Radio />}
          value="1"
        />
        <CustomFormControlLabel
          sx={{ backgroundColor: '#ff9800', padding: '10px' }}
          label={<CustomTypography>2</CustomTypography>}
          onClick={() => setValue(2, 'business_health')}
          control={<Radio />}
          value="2"
        />
        <CustomFormControlLabel
          sx={{ backgroundColor: '#ffef62', padding: '10px' }}
          label={<CustomTypography>3</CustomTypography>}
          onClick={() => setValue(3, 'business_health')}
          control={<Radio />}
          value="3"
        />
        <CustomFormControlLabel
          sx={{ backgroundColor: '#9ad29c', padding: '10px' }}
          label={<CustomTypography>4</CustomTypography>}
          onClick={() => setValue(4, 'business_health')}
          control={<Radio />}
          value="4"
        />
        <CustomFormControlLabel
          sx={{ backgroundColor: '#00e676', padding: '10px' }}
          label={<CustomTypography>5</CustomTypography>}
          onClick={() => setValue(5, 'business_health')}
          control={<Radio />}
          value="5"
        />
      </RadioGroup>
      <CustomFormLabel
        sx={{ marginTop: '10px' }}
        id="gender-radio-buttons-group-label"
      >
        1 = very poor, 3 = neutral, 5 = very strong
      </CustomFormLabel>
    </FormControl>
  );
}
