import React, { memo, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { getUrlParam } from '../../utils/url';

import 'react-toastify/dist/ReactToastify.css';
import './login-signup.scss';
import LoginPage from 'components/LoginSignupModal/loginPage';
import SignupPage from 'components/LoginSignupModal/signupPage';
import useSelectProfileData from 'hooks/useSelectProfileData';
import Cookie from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import useRedux from 'hooks/useRedux';
import { setPartner } from 'features/partner';

type Props = {
  mode: 'signup' | 'login';
};

const LoginSignupContainer = ({ mode }: Props) => {
  const deleteNotification = getUrlParam('delete');
  const redirectPathEncoded = getUrlParam('redirect') as string;
  const redirectPath = redirectPathEncoded && decodeURI(redirectPathEncoded);
  const { organisationProfile } = useSelectProfileData();
  let navigate = useNavigate();
  const { dispatch } = useRedux();
  useEffect(() => {
    if (deleteNotification) {
      setTimeout(() => {
        toast.error('You have successfully deleted your account', {
          autoClose: 9000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }, 500);
    }
  }, [deleteNotification]);
  useEffect(() => {
    const draftRequest = Cookie.get('draftBoostCallUUID');
    if (
      Cookie.get('accessOrgTokenDB') !== undefined &&
      organisationProfile?.first_name
    ) {
      if (!!redirectPath) {
        navigate(
          `${redirectPath}${
            redirectPath.includes('group-event-details?code')
              ? '&register=true'
              : ''
          }`
        );
      } else if (draftRequest) navigate('/mentor-select');
      else {
        dispatch(setPartner(organisationProfile.partnerInfo));
        navigate('/dashboard/actions');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationProfile]);

  return (
    <div className="login-signup">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {mode === 'login' ? (
        <LoginPage redirect={redirectPathEncoded} />
      ) : (
        <SignupPage redirect={redirectPathEncoded} />
      )}
    </div>
  );
};

export default memo(LoginSignupContainer);
