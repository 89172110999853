// Render Prop
import React, { memo } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './styling.scss';
import useAuth from 'features/auth/hooks/useAuth';
import Button from 'components/Button';
import { CustomFormLabel, CustomTextField } from 'styling/generalStyling';
import useFetchPartner from 'features/partner/hooks/useFetchPartner';
import { removeWhiteSpace } from 'utils/text';

const volBaseUrl = process.env.REACT_APP_VOL_URL;

const SignInForm = () => {
  const { partner } = useFetchPartner();

  const { login, authError } = useAuth();
  const validationSchema = yup.object({
    email: yup.string().required('Email is required'),
    password: yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: { email: string; password: string }) => {
      const email = removeWhiteSpace(values.email) || '';
      const password = values.password || '';
      login({ email, password });
    },
  });

  return (
    <div className="signin-page-container">
      <form onSubmit={formik.handleSubmit}>
        <div className="sign-in-form-container">
          <CustomTextField
            fullWidth
            id="email"
            name="email"
            label={
              <CustomFormLabel sx={{ fontSize: '16px' }}>Email</CustomFormLabel>
            }
            value={removeWhiteSpace(formik.values.email)}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <CustomTextField
            fullWidth
            id="password"
            name="password"
            label={
              <CustomFormLabel sx={{ fontSize: '16px' }}>
                Password
              </CustomFormLabel>
            }
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />

          {authError ? <div className="error">{authError}</div> : null}
          <div className="login-signup__forgot-password-container">
            <a href="/resetpassword" className="login-signup__forgot-password">
              <CustomFormLabel sx={{ fontSize: '16px', fontColor: 'black' }}>
                {' '}
                Forgotten your password?
              </CustomFormLabel>
            </a>
          </div>
          <Button
            type={'primary'}
            text={'Login'}
            onClickFunction={onsubmit}
            className="submit-button"
          />
          <div className="login-signup__volunteer-login-container">
            <a
              href={
                partner && partner?.subdomain && volBaseUrl
                  ? `https://${partner?.subdomain}.${volBaseUrl.replace(
                      'https://',
                      ''
                    )}login`
                  : `${volBaseUrl}login`
              }
              target="_blank"
              rel="noopener noreferrer"
              className="login-signup__volunteer-login"
            >
              {partner && partner?.volunteer_title
                ? `Switch to ${partner?.volunteer_title} Log In`
                : 'Switch to volunteer Log In'}
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default memo(SignInForm);
