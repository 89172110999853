import Cookie from 'js-cookie';
import { useAxios } from '../api/config';
import { getErrorMessageFromResponse } from '../utils/error';
import { Industry } from '../types';

type IndustriesById = {
  [arg: number]: Industry;
};

const useGetIndustries = () => {
  const headers = { Authorization: `Bearer ${Cookie.get('accessOrgTokenDB')}` };

  const [{ data, loading, error }, executeCall] = useAxios<{
    results: Industry[];
  }>({
    url: `api/unifiedindustries/unifiedindustries/`,
    method: 'GET',
    headers: headers,
    params: {
      limit: 200,
    },
  });

  const industriesById: IndustriesById | undefined = data?.results?.reduce(
    (acc, cur: Industry) => ({ ...acc, [cur.id]: cur }),
    {}
  );

  return {
    industries: data?.results || [],
    industriesById: industriesById,
    isLoadingIndustries: loading,
    hasFinishedLoadingIndustries: !loading,
    industriesError: getErrorMessageFromResponse(error),
    refetchIndustries: executeCall,
  };
};

export default useGetIndustries;
