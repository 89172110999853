import useRedux from '../../../hooks/useRedux';
import useAutoFetch from '../../../hooks/useAutoFetch';
import {
  fetchPartnerAction,
  setPartner as setPartnerAction,
  clearPartner as clearPartnerAction,
  selectPartner,
  selectHasFinishedLoadingPartner,
  selectHasNotFetchedPartner,
} from '../index';
import type { Partner } from '../../../types';

type Props = { autoFetch: boolean };

const useFetchPartner = ({ autoFetch = true }: Props = { autoFetch: true }) => {
  // HOOKS
  const { useSelector, dispatch } = useRedux();
  useAutoFetch(fetchPartnerAction, selectHasNotFetchedPartner, { autoFetch });

  // ACTIONS
  const fetchPartner = () => dispatch(fetchPartnerAction());
  const setPartner = (profile: Partner) => dispatch(setPartnerAction(profile));
  const clearPartner = () => dispatch(clearPartnerAction());

  // SELECTORS
  const partner = useSelector(selectPartner);
  const hasFinishedLoadingPartner = useSelector(
    selectHasFinishedLoadingPartner
  );
  const needsToFinishSignup = hasFinishedLoadingPartner && !partner;

  return {
    partner,
    fetchPartner,
    setPartner,
    clearPartner,
    needsToFinishSignup,
    hasFinishedLoadingPartner,
  };
};

export default useFetchPartner;
