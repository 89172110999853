import apiInstance from '../api/config';
import { getAccessTokenCookie, getRefreshTokenCookie } from '../utils/auth';
import { UserProfile, OrgProfile, UserLocation, Partner } from '../types';

// Note: We are gradually moving away from individual axios calls toward calls made with axios-hooks
// axios-hooks allows for automatica management fo call-state (e.g. loading, error etc.), as well as grouping related business logic together
// As such new api calls should be created using axios-hooks - not in the /apiCalls/ directory
// See /apiHooks/ directory for examples

export const getUserProfile = async () => {
  const response = await apiInstance.get<UserProfile>(`api/core/users/me/`);
  if (response.status === 404) throw new Error('User Details not found');
  return response.data;
};

export const getOrgProfile = async () => {
  if (!!getAccessTokenCookie() || !!getRefreshTokenCookie()) {
    const response = await apiInstance.get<OrgProfile>(
      `api/organisation/organisations/me/`
    );
    if (response?.data?.partner) {
      const partnerResponse = await apiInstance.get<Partner>(
        `api/partner/partners/${response.data.partner}/`
      );
      response.data.partnerInfo = partnerResponse.data;
    }
    return response.data;
  }
};

export const getUserLocation = async () => {
  if (!!getAccessTokenCookie() || !!getRefreshTokenCookie()) {
    const response = await apiInstance.get<UserLocation>(
      `api/location/locations/me/`
    );
    return response.data;
  }
};

export type FetchAllProfileData = () => Promise<{
  userProfileResult: PromiseSettledResult<UserProfile | undefined>;
  orgProfileResult: PromiseSettledResult<OrgProfile | undefined>;
  userLocationResult: PromiseSettledResult<UserLocation | undefined>;
}>;

export const fetchAllProfileData: FetchAllProfileData = async () => {
  // Promise.allSettled will return either:
  //   { status: "fulfilled", value: value },
  //   { status: "rejected",  reason: Error: an error }
  const [userProfileResult, orgProfileResult, userLocationResult] =
    await Promise.allSettled([
      getUserProfile(),
      getOrgProfile(),
      getUserLocation(),
    ]);

  return {
    userProfileResult,
    orgProfileResult,
    userLocationResult,
  };
};

export const getPartnerIntegratedData = async (code: string) => {
  const response = await apiInstance.get<any>(
    `api/core/partner-integrated-signup/?code=${code}`
  );
  if (response.status === 404) throw new Error('User Details not found');
  return response.data;
};
