import React from 'react';

import './style.scss';
import { MessagingCentreButton } from 'styling/buttons';

interface MessageCenterBlockProps {
  blockedByVol: boolean;
  blockedByMentee: boolean;
  clickUnblock?: () => void;
}

const MessageCenterBlock = ({
  blockedByVol,
  blockedByMentee,
  clickUnblock,
}: MessageCenterBlockProps) => {
  return (
    <div className="message-center-block__container">
      <div className="message-center-block__wrapper">
        <img src="block.svg" width="16px" height="16px" alt="block icon" />
        {blockedByVol && (
          <div className="message-center-block__text">
            Messaging function is disabled
          </div>
        )}
        {blockedByMentee && !blockedByVol && (
          <div className="message-center-block__text">
            You have blocked this person.
          </div>
        )}
      </div>

      {blockedByMentee ? (
        <MessagingCentreButton onClick={clickUnblock && clickUnblock}>
          Unblock
        </MessagingCentreButton>
      ) : null}
    </div>
  );
};

export default MessageCenterBlock;
