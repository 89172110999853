import React, { memo } from 'react';
import './booking-status-bar.scss';

import Grid from '@mui/material/Grid';

import useWindowScrollPosition from '../../hooks/useWindowScrollPosition';
import { DashboardFilledButton } from 'styling/buttons';

interface Props {
  statusNames: string[];
  title: string;
  subTitle?: string;
  selectedIndex: number;
  hideProgressBar?: boolean;
  showButton?: boolean;
  buttonDisabled?: boolean;
  clickNext?: () => void;
  buttonText?: string;
  isRebook?: boolean;
}

const BookingStatusBar = ({
  statusNames,
  title,
  subTitle,
  selectedIndex,
  hideProgressBar = false,
  showButton = false,
  buttonDisabled,
  clickNext,
  buttonText,
  isRebook,
}: Props) => {
  const { y = window.pageYOffset } = useWindowScrollPosition();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
      className={`booking-status-bar ${y > 0 ? 'shadow' : ''}`}
    >
      <Grid direction="column" className="titles-text">
        <h1>{title}</h1>
        {subTitle && (
          <h2 className="booking-status-bar__subtitle">{subTitle}</h2>
        )}
      </Grid>

      {!hideProgressBar && (
        <div className="status-tracker">
          <div className={`line line-${selectedIndex}`} />
          {statusNames.map((statusName, i) => {
            const selected = i + 1 <= selectedIndex;
            return (
              <div className="status" key={i}>
                <div className={`index ${selected ? 'selected' : ''}`}>
                  <span className="text">{i + 1}</span>
                </div>
                <div>{statusName}</div>
              </div>
            );
          })}
        </div>
      )}

      {showButton && (
        <Grid className={`button-container `}>
          <DashboardFilledButton
            disabled={buttonDisabled}
            onClick={clickNext}
            variant="contained"
          >
            {buttonText}
          </DashboardFilledButton>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(BookingStatusBar);
