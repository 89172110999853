import Cookie from 'js-cookie';
import apiInstance from '../api/config';
import { GroupEvent } from '../types';

type RegisterForGroupEventProps = {
  groupEventCode: string;
};

type RegisterQuestionGroupEventProps = {
  groupEventId: number | undefined;
  orgQuestion: string;
  interestExpertise: string | undefined;
};

type UpdateForGroupEventProps = {
  groupEventId: number | undefined;
  orgQuestion: string;
  interestExpertise: string | undefined;
  speakerBriefingId: number;
};

type GetGroupEventProps = {
  code: string;
  limit?: number;
};

// Note: We are gradually moving away from individual axios calls toward calls made with axios-hooks
// axios-hooks allows for automatica management fo call-state (e.g. loading, error etc.), as well as grouping related business logic together
// As such new api calls should be created using axios-hooks - not in the /apiCalls/ directory
// See /apiHooks/ directory for examples

export const registerForGroupEvent = async ({
  groupEventCode,
}: RegisterForGroupEventProps) => {
  const response = await apiInstance.post(
    `api/groupevent/groupevent/${groupEventCode}/acceptinvite/`,
    {}
  );

  return response;
};

export const registerQuestionGroupEvent = async ({
  groupEventId,
  orgQuestion,
  interestExpertise,
}: RegisterQuestionGroupEventProps) => {
  const response = await apiInstance.post(
    `api/groupevent/speakerbriefing/create/`,
    {
      group_event: groupEventId,
      help_needed: orgQuestion,
      interest_expertise: interestExpertise,
    }
  );

  return response;
};

export const updateQuestionGroupEvent = async ({
  groupEventId,
  orgQuestion,
  interestExpertise,
  speakerBriefingId,
}: UpdateForGroupEventProps) => {
  const response = await apiInstance.patch(
    `api/groupevent/speakerbriefing/${speakerBriefingId}/`,
    {
      group_event: groupEventId,
      help_needed: orgQuestion,
      interest_expertise: interestExpertise,
    }
  );

  return response;
};

export const declineGroupEvent = async ({
  groupEventCode,
}: RegisterForGroupEventProps) => {
  const response = await apiInstance.post(
    `api/groupevent/groupevent/${groupEventCode}/declineinvite/`,
    {}
  );

  return response;
};

export const getGroupEvent = async ({ code }: GetGroupEventProps) => {
  const loggedIn = !!Cookie.get('accessOrgTokenDB');

  const groupEventResponse = await apiInstance.get<GroupEvent>(
    `/api/groupevent/groupevent${code ? `/${code}` : ''}${
      !loggedIn ? `/public` : ''
    }/`
  );
  if (groupEventResponse.status < 200 || groupEventResponse.status >= 400)
    throw groupEventResponse;
  return groupEventResponse.data;
};

export const updateAttendeeJoinCallClicked = async (
  attendeeId: number,
  groupEvent: number,
  organisation: number
) => {
  await apiInstance.patch(`api/groupevent/attendee/${attendeeId}/`, {
    group_event: groupEvent,
    organisation: organisation,
    is_join_call_clicked: true,
  });
};
