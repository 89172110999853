import { styled } from '@mui/system';
import { Box, Button } from '@mui/material';

function isMobile() {
  const { innerWidth: width } = window;
  if (width < 900) return true;
  else return false;
}
const breakpoints = {
  mobile: '750px',
  tablet: '900px',
  screen: '1200px',
};

export const CustomProfileSaveButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  backgroundColor: 'primary',
  padding: ' 12px 24px',
  marginTop: '20px',
  marginRight: '30px',
  borderRadius: '32px',
  textTransform: 'none',
  width: '149px',
  height: '44px',
  fontSize: '15px',
  fontWeight: 'bold',
  lineHeight: '1.2',
  letterSpacing: '0.17px',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '&:disabled': {
    color: `#c0c0c0`,
  },
}));

export const NoStyleButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  backgroundColor: 'primary',
  borderRadius: '32px',
  textTransform: 'none',
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: 'bold',
  '&:disabled': {
    backgroundColor: 'transparent',
    color: `#c0c0c0`,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export const CustomProfileCancelButton = styled(Button)({
  fontFamily: 'Gilroy',
  backgroundColor: 'primary',
  width: '149px',
  height: '44px',
  marginTop: '20px',
  marginRight: '10px',
  borderColor: 'primary',
  border: 'solid 2px primary',
  padding: '8px 50px',
  borderRadius: '110px',
  textTransform: 'none',
  fontSize: '15px',
  fontWeight: 'bold',
  lineHeight: '1',
  letterSpacing: '0.51px',
  textAlign: 'center',
  color: '#191e39',
});

export const CustomLoginSignUpButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  backgroundColor: 'primary',
  padding: ' 12px 24px',
  marginRight: '30px',
  textTransform: 'none',
  height: '44px',
  fontSize: '15px',
  fontWeight: 'bold',
  lineHeight: '1.2',
  letterSpacing: '0.17px',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '&:disabled': {
    backgroundColor: 'transparent',
    color: `#c0c0c0`,
  },
}));

export const StyledDownloadButton = styled(Box)(({ theme }) => ({
  width: '20px',
  height: '20px',
  WebkitMask: 'url("download-icon.svg") no-repeat center',
  mask: 'url("download-icon.svg") no-repeat center',
  backgroundColor: '#999999',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const StyledTrashButton = styled(Box)(({ theme }) => ({
  width: '20px',
  height: '20px',
  WebkitMask: 'url("trash-icon.svg") no-repeat center',
  mask: 'url("trash-icon.svg") no-repeat center',
  backgroundColor: '#999999',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  [`@media (max-width: ${breakpoints.mobile})`]: {
    width: '20px',
    height: '20px',
  },
}));

export const AttachmentButton = styled(Box)(({ theme }) => ({
  width: '20px',
  height: '20px',
  marginRight: '15px',
  WebkitMask: 'url("attachment.svg") no-repeat center',
  mask: 'url("attachment.svg") no-repeat center',
  backgroundColor: '#999999',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const SkillButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: '1px',
  color: theme.palette.primary.contrastText,
  marginRight: '5px',
  paddingLeft: '10px',
  paddingRight: '10px',
  textTransform: 'none',
  fontFamily: 'Gilroy',
  fontSize: '14px',
  textAlign: 'left',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));
export const QuickMatchEmptyButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  textTransform: 'none',
  width: '160px',
  borderWidth: '3px',
  height: '44px',
  color: 'black',
  fontSize: '16px',
  borderRadius: '32px',
  lineHeight: '1',
  letterSpacing: '0.51px',
  fontWeight: 'bold',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '&:disabled': {
    color: `#c0c0c0`,
  },
}));
export const DashboardFilledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: '32px',
  textTransform: 'none',
  width: '100%',
  height: '44px',
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '0',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '&:disabled': {
    backgroundColor: 'transparent',
    color: `#c0c0c0`,
  },
}));
export const SelectAvailabilityButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  textTransform: 'none',
  height: '60px',
  width: '399px',
  borderRadius: '32px',
  padding: '12px 36px 12px 36px',
  maxWidth: '80vw',
  alignSelf: 'center',
  fontSize: '20px',
  fontWeight: '700',

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  '&:disabled': {
    opacity: ' 0.5',
    color: theme.palette.primary.contrastText,
  },
}));
export const SkillFilledButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  backgroundColor: 'transparent',
  borderRadius: '32px',
  textTransform: 'none',
  width: '100%',
  height: `${isMobile() ? '40px' : '30px'}`,
  fontSize: `${isMobile() ? '12px' : '14px'}`,
  paddingLeft: '10px',
  paddingRight: '10px',
  fontWeight: 'normal',
  textAlign: 'center',
  color: '#333',
  '&:hover': {
    backgroundColor: 'transparent',
    color: '#333',
  },
  '&:disabled': {
    color: `#c0c0c0`,
  },
}));
export const DashboardEmptyButton = styled(Button)(({ theme }) => ({
  fontFamily: 'Gilroy',
  textTransform: 'none',
  minWidth: '100px',
  height: '44px',
  color: 'black',
  fontSize: '16px',
  borderRadius: '32px',
  lineHeight: '1',
  letterSpacing: '0.51px',
  fontWeight: 'bold',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: 'unset',
    color: theme.palette.primary.main,
  },
  '&:disabled': {
    color: `#c0c0c0`,
  },
}));

export const DatePopperButton = styled(Button)({
  width: '240px',
  height: '46px',
  margin: '4px 0 0',
  borderRadius: ' 8px',
  border: 'solid 1.2px #333',
  backgroundColor: '#fff',
  fontFamily: 'Gilroy',
  textTransform: 'none',
  fontSize: '14px',
  color: '#414141',
  textAlign: 'left',
});

export const TimeSlotButton = styled(Button)(({ theme }) => ({
  margin: '4px 8px 0 0',
  border: 'solid 1px #777',
  fontFamily: 'Gilroy',
  padding: ' 12px',
  borderRadius: '8px',
  textTransform: 'none',
  width: '129px',
  height: '38px',
  fontSize: '14px',
  textAlign: 'center',
  color: '#414141',
  '&:hover': {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  },
}));

export const KnowledgeButton = styled(Button)(({ theme }) => ({
  width: 'fit-content',
  borderColor: 'rgba(0, 0, 0, 0.15)',
  textTransform: 'none',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  fontWeight: '500',
  padding: '10px',
  fontFamily: 'Gilroy',
  borderRadius: '30px',
  boxShadow: ' 0px 0.7px rgba(0, 0, 0, 0.15)',
  color: '#414141',
  '&:hover': {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  },
}));
export const MessagingCentreButton = styled(Button)(({ theme }) => ({
  padding: '12px 24px',
  borderRadius: '110px',
  border: 'none',
  backgroundColor: theme.palette.primary.main,

  fontFamily: 'Gilroy',
  fontSize: '17px',
  fontWeight: 'bold',
  lineHeight: '1.2',
  letterSpacing: '0.17px',
  color: theme.palette.primary.contrastText,
  textTransform: 'unset',

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  '&:disabled': {
    opacity: '0.4',
    cursor: 'not-allowed',
    color: theme.palette.primary.contrastText,
  },
}));
export const MessagingSendButton = styled(Button)(({ theme }) => ({
  width: '110px',
  height: '46px',
  borderRadius: '60px',
  backgroundColor: theme.palette.primary.main,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },

  '&:disabled': {
    backgroundColor: '#e0e0e0',
  },
}));
