import React, { useState } from 'react';
import Tag from '../Tag';
import Button from '../Button';
import { VolProfile } from '../../types';

import './volunteer-profile.scss';

type Props = {
  volunteerProfile: VolProfile;
  compact?: boolean;
  hideSkills?: boolean;
  className?: string;
};

type ProfileProps = {
  volunteerProfile: VolProfile;
  compact?: boolean;
  hideSkills?: boolean;
};

export const Profile = ({
  volunteerProfile,
  compact,
  hideSkills,
}: ProfileProps) => {
  const [skillsExpanded, setSkillsExpanded] = useState(false);

  const hasSkills =
    !!volunteerProfile.interests && !!volunteerProfile.interests.length;
  const skillsToDisplay = !hasSkills
    ? []
    : skillsExpanded
    ? volunteerProfile.interests
    : volunteerProfile.interests.slice(0, 4);
  const showExpandSkillsButton =
    !skillsExpanded &&
    hasSkills &&
    skillsToDisplay &&
    volunteerProfile.interests.length > 4;
  const showMoreSkillsText = showExpandSkillsButton
    ? `+${
        volunteerProfile.interests.length - skillsToDisplay.length
      } more skills`
    : '';

  return (
    <div className="volunteer-profile__profile-column">
      <div>
        <img
          width={compact ? '175' : '300'}
          height={compact ? '175' : '300'}
          alt="profile"
          src={volunteerProfile.profile_picture || '/avatar.png'}
          className="volunteer-profile__profile-picture"
        />
        {!hideSkills && (
          <>
            <h3>Skills</h3>
            <div className="volunteer-profile__skills-container">
              {skillsToDisplay &&
                skillsToDisplay.map((subject: string, i: number) => (
                  <Tag
                    text={subject}
                    type="primary"
                    key={subject}
                    className="volunteer-profile__skill-tag"
                  />
                ))}
            </div>
            {showExpandSkillsButton && (
              <Tag
                text={showMoreSkillsText}
                onClick={() => setSkillsExpanded(true)}
                type="tertiary"
                className="volunteer-profile__skill-tag"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const Details = ({ volunteerProfile, compact }: Props) => {
  const [aboutMeExpanded, setAboutMeExpanded] = useState<boolean>(false);
  const [experiencesExpanded, setExperiencesExpanded] = useState(false);
  const hasEmploymentHistory =
    !!volunteerProfile.employment_history &&
    !!volunteerProfile.employment_history.length;
  const experiencesToDisplay = !hasEmploymentHistory
    ? []
    : experiencesExpanded
    ? volunteerProfile.employment_history
    : volunteerProfile.employment_history.slice(0, 2);
  const showExpandExperiencesButton =
    hasEmploymentHistory && volunteerProfile.employment_history.length > 2;

  return (
    <div
      className={`volunteer-profile__details-column ${
        compact ? 'volunteer-profile__details-column--compact' : ''
      }`}
    >
      <h2 className="volunteer-profile__volunteer-name">
        {volunteerProfile.first_name} {volunteerProfile.last_initial}
      </h2>
      <span>
        {volunteerProfile.current_role || ''}{' '}
        {volunteerProfile.current_role && volunteerProfile.employer
          ? ' | '
          : ''}{' '}
        {volunteerProfile.employer || ''}
      </span>
      {volunteerProfile.summary && (
        <div className="volunteer-profile__about-me-container">
          <h3 className="volunteer-profile__body-title">About Me</h3>
          <p className="volunteer-profile__about-me">
            {aboutMeExpanded
              ? volunteerProfile.summary
              : `${volunteerProfile.summary.substring(0, 180)}...`}
          </p>
          <Button
            type="link"
            text={aboutMeExpanded ? 'Read less' : 'Read more'}
            onClickFunction={() => setAboutMeExpanded(!aboutMeExpanded)}
            className="volunteer-profile__read-more-button"
          />
        </div>
      )}

      {experiencesToDisplay.length > 1 && (
        <>
          <h3 className="volunteer-profile__body-title">Experience</h3>
          <div className="experience">
            {experiencesToDisplay.map((employment: any, i: number) => {
              return (
                <div
                  key={i}
                  className="volunteer-profile__experience-text-container"
                >
                  <div>
                    {employment.role} | {employment.employer}
                  </div>
                  <div>
                    {employment.from_year} - {employment.to_year || 'Current'}
                  </div>
                </div>
              );
            })}
            {showExpandExperiencesButton && (
              <Button
                type="link"
                text={
                  experiencesExpanded
                    ? 'Hide experiences'
                    : 'Show more experiences'
                }
                onClickFunction={() =>
                  setExperiencesExpanded(!experiencesExpanded)
                }
                className="volunteer-profile__read-more-button"
              />
            )}
          </div>
        </>
      )}
      {volunteerProfile.languages && volunteerProfile.languages.length > 0 ? (
        <>
          <h3 className="volunteer-profile__body-title">Languages</h3>
          <div className="volunteer-profile__languages-container">
            {volunteerProfile.languages.map((l: string, index: number) => (
              <span
                key={`language-${index}`}
                className="volunteer-profile__language"
              >{`${l}${
                volunteerProfile.languages &&
                index < volunteerProfile.languages.length - 1
                  ? ', '
                  : ''
              }`}</span>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

const VolunteerProfile = ({
  volunteerProfile,
  compact,
  hideSkills,
  className,
}: Props) => {
  return (
    <div
      className={`volunteer-profile__upcoming-column-container ${
        compact ? 'volunteer-profile__single-column' : ''
      } ${className ? className : ''}`}
    >
      <Profile
        volunteerProfile={volunteerProfile}
        hideSkills={hideSkills}
        compact={compact}
      />
      <Details volunteerProfile={volunteerProfile} compact={compact} />
    </div>
  );
};

export default VolunteerProfile;
