import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardFilledButton } from 'styling/buttons';
import './styling/requests.scss';

type Props = {
  text: string;
};
const BookingsEmptyPage = ({ text }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="empty-page-container">
      <div className="not-found-text-container">{text}</div>

      <img
        className="not-found-image"
        src={'/no-bookings.png'}
        alt={'not-found'}
      />

      <DashboardFilledButton
        sx={{ width: '250px', marginTop: '20px' }}
        variant="contained"
        onClick={() => navigate('/mentor-select')}
      >
        Find a mentor
      </DashboardFilledButton>
    </div>
  );
};

export default BookingsEmptyPage;
