import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { logout } from '../auth';
import type { RootState } from '../../store';
import type { OrgProfile } from '../../types';
/* --- SLICE --- */

interface OrganisationProfileState {
  status: 'init' | 'pending' | 'fulfilled' | 'rejected';
  profile?: OrgProfile | null;
  error?: string | null;
}

const initialState = {
  status: 'init',
  profile: null,
  error: null,
} as OrganisationProfileState;

const organisationProfileSlice = createSlice({
  name: 'organisationProfile',
  initialState,
  reducers: {
    clearOrganisationProfile: (state) => {
      return initialState;
    },
    setOrganisationProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // logout
      .addCase(logout, () => initialState)
      // fetchOrganisationProfile
      .addCase(fetchOrganisationProfileAction.pending, (state, action) => {
        state.status = action.meta.requestStatus;
        state.error = null;
      })
      .addCase(fetchOrganisationProfileAction.fulfilled, (state, action) => {
        state.status = action.meta.requestStatus;
        state.profile = action.payload;
      })
      .addCase(fetchOrganisationProfileAction.rejected, (state, action) => {
        const { error } = action;
        state.error = error.message;
        state.status = action.meta.requestStatus;
      });
  },
});

/* --- SELECTORS --- */

// fetchOrganisationProfile
export const selectOrganisationProfile = (state: RootState) =>
  state.organisationProfile.profile;
export const selectOrganisationProfileStatus = (state: RootState) =>
  state.organisationProfile.status;
export const selectHasNotFetchedOrganisationProfile = (state: RootState) =>
  selectOrganisationProfileStatus(state) === 'init';
export const selectHasFinishedLoadingOrganisationProfile = (state: RootState) =>
  selectOrganisationProfileStatus(state) === 'fulfilled' ||
  selectOrganisationProfileStatus(state) === 'rejected';

/* --- ACTIONS --- */

export const { clearOrganisationProfile, setOrganisationProfile } =
  organisationProfileSlice.actions;

/* --- THUNKS --- */

// fetchOrganisationProfile
export const fetchOrganisationProfileAction = createAsyncThunk(
  `${organisationProfileSlice.name}/fetchOrganisationProfile`,
  async (arg, thunkAPI) => {
    const response = await api.getOrgProfile();
    return response;
  }
);

export default organisationProfileSlice.reducer;
