import React, { memo } from 'react';

import './style.scss';
import moment from 'moment';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import { useNavigate } from 'react-router-dom';
import useGetJoinCall from '../../hooks/useGetJoinCall';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import NewAddToCalendarButton from 'atoms/NewAddToCalendarButton';
import useSelectProfileData from 'hooks/useSelectProfileData';
import { DashboardEmptyButton, DashboardFilledButton } from 'styling/buttons';
import { CustomCallPagePaper } from 'styling/backgrounds';
import { StyledBadge } from 'styling/generalStyling';
import { useSystemTogglesContext } from 'context/SystemToggles';

type Props = {
  event: any;
  setOpenVolunteerModal?: any;
  setOpenMessagesModal?: any;
  messagesRead?: boolean;
  setRescheduleModal?: any;
};
const RescheduleRequestByOrganisation = ({
  event,
  setOpenVolunteerModal,
  messagesRead,
  setOpenMessagesModal,
  setRescheduleModal,
}: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  const { userLocation } = useSelectProfileData();
  const timeZoneString =
    userLocation && userLocation.timezone ? userLocation.timezone : '';
  const { joinCall } = useGetJoinCall({ callInfo: event });
  const startTime = moment(event.start_time);
  const today = moment();
  const navigate = useNavigate();

  window.onpopstate = function () {
    window.location.reload();
  };

  return (
    <div>
      <div className="organisation-information-container">
        <div className="header-container">
          <div className="header-text">You have sent a reschedule request</div>
          <DashboardEmptyButton
            variant="text"
            sx={{
              color: '#333',
            }}
            onClick={() => setOpenVolunteerModal(true)}
          >
            View volunteer profile
          </DashboardEmptyButton>
        </div>
        <CustomCallPagePaper>
          <div className="header-box-wrapper">
            <div>
              <div className="header-box-proposed-time">
                <div>
                  <div className="time-container-text">New proposed time</div>
                  <div className="time-container">
                    <div className="time-container-text">
                      {moment(event.reschedule_request_time).format(
                        'DD MMMM YYYY'
                      )}
                    </div>
                    <div className="time-container-text">
                      {moment(event.reschedule_request_time).format('dddd')}
                    </div>
                    <div className="time-container-text">
                      {moment(event.reschedule_request_time).format('HH:mm ')}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="time-container-text">Original time</div>
                  <div className="time-container">
                    <div className="time-container-text">
                      {moment(event.start_time).format('DD MMMM YYYY')}
                    </div>
                    <div className="time-container-text">
                      {moment(event.start_time).format('dddd')}
                    </div>
                    <div className="time-container-text">
                      {moment(event.start_time).format('HH:mm')}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="helper-text"
                style={{ marginBottom: '20px', marginTop: '30px' }}
              >
                Waiting for {event.volunteer.first_name} to accept your request.
                If the volunteer does not accept, the session will go ahead at
                the original time.
              </div>
            </div>

            <div className="header-box-time-left-container header-box-time-left-container-margin">
              Starts in
              <div className="time-left">
                {moment(startTime.diff(today)).format('D')}
              </div>{' '}
              days
            </div>
          </div>
          <div className="header-box-buttons">
            <DashboardFilledButton
              variant="contained"
              sx={{ width: '150px' }}
              onClick={() => joinCall()}
            >
              Join session
            </DashboardFilledButton>

            <div className="header-box-button">
              <DashboardEmptyButton
                variant="text"
                onClick={() => {
                  if (showMessagingUI) {
                    navigate(`/messaging-centre?id=${event?.volunteer?.id}`);
                    return;
                  }
                  setOpenMessagesModal(true);
                }}
              >
                <EmailOutlinedIcon sx={{ marginRight: '5px' }} />
                <StyledBadge
                  badgeContent={
                    !messagesRead ? event.organisation_new_messages_count : 0
                  }
                  color="info"
                >
                  Messages
                </StyledBadge>
              </DashboardEmptyButton>
            </div>
            <div className="header-box-button">
              <DashboardEmptyButton
                variant="text"
                onClick={() => setRescheduleModal(true)}
              >
                <HistoryOutlinedIcon sx={{ marginRight: '5px' }} />
                Reschedule
              </DashboardEmptyButton>
            </div>

            <div className="header-box-button">
              <DashboardEmptyButton
                variant="text"
                sx={{
                  color: '#f23737',
                }}
                onClick={() => navigate(`/cancel-call?id=${event.id}`)}
              >
                <DangerousOutlinedIcon sx={{ marginRight: '5px' }} />
                Cancel session
              </DashboardEmptyButton>
            </div>
            <div className="header-box-button">
              <div className="header-box-button">
                <NewAddToCalendarButton
                  callInfo={event}
                  timeZoneString={timeZoneString}
                />
              </div>
            </div>
          </div>
        </CustomCallPagePaper>
      </div>
    </div>
  );
};

export default memo(RescheduleRequestByOrganisation);
