import apiInstance from './config';

export const getResources = async ({
  contentType,
  textDebouncedValue,
  selectedSkillList,
}: any) => {
  const filters = new URLSearchParams({
    ...(contentType && { content_type: contentType }),
    ...(textDebouncedValue.length > 0 && { text: textDebouncedValue }),
    ...(selectedSkillList.length > 0 && { interests: selectedSkillList[0] }),
  });
  const filtersString = filters.toString();

  return apiInstance.get<any>(
    filtersString.length > 0
      ? `api/resourcecenter/resource/searchresource/?${filtersString}`
      : `api/resourcecenter/resource/searchresource/`,
    {}
  );
};

export const resourceClick = async (body: any) => {
  return apiInstance.post<any>(
    `api/resourcecenter/organisationresourceclick/`,
    body
  );
};
