import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import {
  FormControlLabel,
  Checkbox,
  TextField,
} from '@mui/material';
import {
  CustomFormControlLabel,
  CustomTypography,
} from 'styling/generalStyling';
import { selectDisabilities } from 'features/reduxStore/disabilityList/disabilityList';
import { useSelector } from 'react-redux';
type Props = {
  setValue: (value: number, input: string, key: string) => void;
  value: any;
};

export default function EditDisabilityForm({ value, setValue }: Props) {
  const disabilitiesList = useSelector(selectDisabilities);


  const [selectedOptions, setSelectedOptions] = useState<number[]>(
    value.disabilities
  );
  const [otherDisability, setOtherDisability] = useState<string>(
    value.disability_info || ''
  );
  const [disabilityOption, setDisabilityOption] = useState<string>(
    value.disabilities.length > 0 ? 'yes' : 'no'
  );

  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDisabilityOption(e.target.value);
    if (e.target.value === 'no') setValue(-1, 'disability', '');
  };
  const handleInputChange = (event: { target: { value: any } }) => {
    setOtherDisability(event.target.value);
    setValue(8, 'disability', event.target.value);
  };

  const handleCheckboxChange = (event: { target: { value: any } }) => {
    const option = parseInt(event.target.value);

    setSelectedOptions((prevOptions) =>
      prevOptions.includes(option)
        ? prevOptions.filter((o) => o !== option)
        : [...prevOptions, option]
    );
    setValue(option, 'disability', otherDisability);
 
  };

  return (
    <FormControl>
      <label className="disability-label">
      Do you have a disability or long-term health condition (mental and / or physical)?
        
      </label>
      <div>
        <RadioGroup row value={disabilityOption} onChange={handleChange}>
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
      </div>
      {disabilityOption === 'yes' && (
        <div className="disability-options">
          {disabilitiesList?.map((option) => (
            <CustomFormControlLabel
              key={option.id}
              control={
                <Checkbox
                  checked={selectedOptions.includes(option.id)}
                  onChange={handleCheckboxChange}
                  value={option.id}
                  name={option.name}
                />
              }
              label={<CustomTypography>{option.name}</CustomTypography>}
            />
          ))}
          <TextField
            label={
              <CustomTypography>
                If other, please specify (if desired)
              </CustomTypography>
            }
            variant="outlined"
            fullWidth
            margin="normal"
            value={otherDisability}
            onChange={handleInputChange}
          />
        </div>
      )}
    </FormControl>
  );
}
