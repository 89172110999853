import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import moment from 'moment';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { DashboardEmptyButton, DashboardFilledButton } from 'styling/buttons';
import { CustomCallPagePaper } from 'styling/backgrounds';
import { useSystemTogglesContext } from 'context/SystemToggles';

type Props = {
  event: any;
  setOpenVolunteerModal?: any;
  setOpenMessagesModal?: any;
};

const CompletedEvent = ({
  event,
  setOpenVolunteerModal,
  setOpenMessagesModal,
}: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  const navigate = useNavigate();

  window.onpopstate = function () {
    window.location.reload();
  };

  return (
    <div>
      <div className="organisation-information-container">
        <div className="header-container">
          <div className="header-text"> This session was completed</div>
          <DashboardEmptyButton
            variant="text"
            sx={{
              color: '#333',
            }}
            onClick={() => setOpenVolunteerModal(true)}
          >
            View volunteer profile
          </DashboardEmptyButton>
        </div>
      </div>

      <CustomCallPagePaper>
        <div>
          <div className="time-container-text">Original time</div>
          <div className="time-container">
            <div className="time-container-text">
              {moment(event.start_time).format('DD MMMM YYYY')}
            </div>
            <div className="time-container-text">
              {moment(event.start_time).format('dddd')}
            </div>
            <div className="time-container-text">
              {moment(event.start_time).format('HH:mm')}
            </div>
          </div>
        </div>
        <div className="header-box-time-left-container">
          This session was completed
        </div>
        <div className="header-box-buttons">
          <DashboardFilledButton
            sx={{ width: '250px' }}
            variant="contained"
            onClick={() => navigate('/nps-score?id=' + event?.id)}
            disabled={
              event?.organisation_sent_nps ||
              event?.volunteer?.first_name === undefined
            }
          >
            {event?.organisation_sent_nps ? `Feedback Given` : `Leave Feedback`}
          </DashboardFilledButton>
          <div className="header-box-button">
            <EmailOutlinedIcon sx={{ color: '#333' }} />
            <DashboardEmptyButton
              sx={{ color: '#333' }}
              variant="text"
              onClick={() => {
                if (showMessagingUI) {
                  navigate(`/messaging-centre?id=${event?.volunteer?.id}`);
                  return;
                }
                setOpenMessagesModal(true);
              }}
            >
              Message
            </DashboardEmptyButton>
          </div>
          <div className="header-box-button">
            <DashboardEmptyButton
              sx={{ color: '#333' }}
              variant="text"
              onClick={() => navigate(`/nps-score?id=${event?.id}&no_show=1`)}
              disabled={event?.volunteer?.first_name === undefined}
            >
              Call didn't happen?
            </DashboardEmptyButton>
          </div>
          <div className="header-box-button">
            <DashboardEmptyButton
              sx={{ color: '#333' }}
              variant="text"
              onClick={() =>
                navigate(
                  `/volunteer-details?volunteer[]=${event?.volunteer?.id}&keyword=complete-tab`
                )
              }
              disabled={event?.volunteer?.first_name === undefined}
            >
              Rebook session
            </DashboardEmptyButton>
          </div>
        </div>
      </CustomCallPagePaper>
    </div>
  );
};

export default memo(CompletedEvent);
