import { useTracking } from 'react-tracking';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useSystemTogglesContext } from '../context/SystemToggles';

const TRACKING_URL = process.env.REACT_APP_TRACKING_URL || '';

type Pages = {
  [key: string]: string;
};

const pages: Pages = {
  '/dashboard': 'Dashboard',
  '/call-details': 'CallDetails',
};

const useCustomTracking = () => {
  const { pathname } = useLocation();
  const { toggles, hasFinishedLoadingToggles } = useSystemTogglesContext();

  const pageName = pages[pathname] || pathname;
  const { Type, trackEvent } = useTracking(
    { page: pageName, app: 'organisation' },
    {
      dispatch: async (data: any) => {
        try {
          await axios.post(TRACKING_URL, data);
        } catch (err) {
          console.log('Error sending analytics data: ', err);
        }
      },
    }
  );

  const trackFunction = async (props: any) => {
    if (!toggles['FRONTEND_TRACKING']) return; // don't track if toggle switched off
    return trackEvent(props);
  };

  return {
    Type,
    trackEvent: trackFunction,
    readyToTrack: hasFinishedLoadingToggles,
  };
};

export default useCustomTracking;
