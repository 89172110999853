import React, { useEffect } from 'react';
import { DashboardFilledButton } from 'styling/buttons';
import useCustomTracking from '../../hooks/useCustomTracking';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import { getUrlParam } from '../../utils/url';

import './group-event-call.scss';

const GroupEventCall = () => {
  const customCallUrl = getUrlParam('custom_call_url') as string;
  const groupEventId = getUrlParam('id') as string;
  const { trackEvent, readyToTrack } = useCustomTracking();
  const { organisationProfile } = useSelectProfileData();

  useEffect(() => {
    if (readyToTrack) {
      const now = new Date(Date.now()).toISOString();

      (async () => {
        try {
          await trackEvent({
            action: 'page-load',
            time: now,
            label: 'join-group-event-call',
            group_event_id: groupEventId,
            organisation_id: organisationProfile?.id,
          });
        } catch (err) {}
        if (customCallUrl) {
          window.location.href = customCallUrl;
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToTrack]);

  return (
    <div className="group-event-call__container">
      <div className="group-event-call__custom-call-container">
        <h3>Now redirecting you to your call</h3>
        <DashboardFilledButton
          variant="contained"
          sx={{ width: '240px' }}
          onClick={() => window.open(customCallUrl, 'custom_call')}
        >
          Or click here to continue
        </DashboardFilledButton>
      </div>
    </div>
  );
};

export default GroupEventCall;
