import useRedux from '../../../hooks/useRedux';
import useAutoFetch from '../../../hooks/useAutoFetch';
import {
  fetchOrganisationProfileAction,
  setOrganisationProfile as setOrganisationProfileAction,
  clearOrganisationProfile as clearOrganisationProfileAction,
  selectOrganisationProfile,
  selectHasFinishedLoadingOrganisationProfile,
  selectHasNotFetchedOrganisationProfile,
} from '../index';
import type { OrgProfile } from '../../../types';

type Props = { autoFetch: boolean };

const useFetchOrganisationProfile = (
  { autoFetch = true }: Props = { autoFetch: true }
) => {
  // HOOKS
  const { useSelector, dispatch } = useRedux();
  useAutoFetch(
    fetchOrganisationProfileAction,
    selectHasNotFetchedOrganisationProfile,
    { autoFetch }
  );

  // ACTIONS
  const fetchOrganisationProfile = () =>
    dispatch(fetchOrganisationProfileAction());
  const setOrganisationProfile = (profile: OrgProfile) =>
    dispatch(setOrganisationProfileAction(profile));
  const clearOrganisationProfile = () =>
    dispatch(clearOrganisationProfileAction());

  // SELECTORS
  const organisationProfile = useSelector(selectOrganisationProfile);
  const hasFinishedLoadingOrganisationProfile = useSelector(
    selectHasFinishedLoadingOrganisationProfile
  );
  const needsToFinishSignup =
    hasFinishedLoadingOrganisationProfile && !organisationProfile;

  return {
    organisationProfile,
    fetchOrganisationProfile,
    setOrganisationProfile,
    clearOrganisationProfile,
    needsToFinishSignup,
    hasFinishedLoadingOrganisationProfile,
  };
};

export default useFetchOrganisationProfile;
