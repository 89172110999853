export const statusNames = ['Select Preferences', 'Availability', 'Confirm'];

export const domainGroupId = '38ffa6da-45fe-4054-9bb9-cf29b6cbb450'; // this is for Cookie Bot

export const defaultTimeFormat = 'HH:mm';

export const defaultDateFormat = 'YYYY-MM-DD';

export const genderMap = {
  male: 'Man',
  female: 'Woman',
  other: 'Other',
  'non-binary': 'Non Binary',
  'dont-identify': "I don't identify with any gender",
};
