import React from 'react';
import './radio-button.scss';
import { useSelector } from 'react-redux';
import { selectTheme } from 'features/auth';

interface Props {
  selected: boolean;
  rebook?: boolean;
  bigger?: boolean;
}

const RadioButton = ({ bigger, selected, rebook, ...rest }: Props) => {
  var themeStored = useSelector(selectTheme);

  return !bigger ? (
    <div
      className={
        selected
          ? rebook
            ? 'radio-btn__selected rebook'
            : 'radio-btn__selected'
          : 'radio-btn__unseleted'
      }
    ></div>
  ) : (
    <div
      className={
        selected ? 'radio-btn-bigger__selected' : 'radio-btn-bigger__unseleted'
      }
      style={{
        border: selected
          ? `7px solid ${themeStored?.palette?.primary?.main}`
          : '',
      }}
    ></div>
  );
};

export default RadioButton;
