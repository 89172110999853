import React, { useEffect, useState } from 'react';
import apiInstance from '../../api/config';
import qs from 'qs';
import Button from '../../components/Button';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import AddToCalendarButton from '../../atoms/AddToCalendarButton';
import { displayTime } from '../../utils/time';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import {
  TimeSlot,
  BoostCallInvite,
  BoostCallEventWithVolunteerProfile,
} from '../../types';
import { defaultTimeFormat } from '../../constants';
import './confirm-call.scss';
import useGetBoostCallEventRequests from 'apiHooks/boostCallRequests/useGetBoostCallEventRequests';
import { declineProposedTime } from '../../api/callRequests';

const ConfirmCall = () => {
  const { userLocation } = useSelectProfileData();
  const { timezone } = userLocation || {};
  const [selectedTimeSlot, setSelectedTimeslot] = useState<any>();
  const [startTime, setStartTime] = useState<any>();
  const [availableTimeslots, setAvailableTimeslots] = useState<TimeSlot[]>([]);
  const [code, setCode] = useState<any>();
  const [volunteerName, setVolunteerName] = useState('');
  const [callURL, setCallURL] = useState<any>('');
  const [callProvider, setCallProvider] = useState<string | undefined>();
  const [callInfo, setCallInfo] =
    useState<BoostCallEventWithVolunteerProfile>();
  const [cancelCall, setCancelCall] = useState(false);
  const [requesterName, setRequesterName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [timeZoneString, setTimeZoneString] = useState<any>('');
  const [isProcessing, setIsProcessing] = useState(true);
  const navigate = useNavigate();

  const { timeSlotToDisplay } = useGetBoostCallEventRequests();

  const getDataFromCode = async () => {
    let code = qs.parse(
      window.location.search.slice(window.location.search.lastIndexOf('?') + 1)
    ).code;
    let selectedTimeSlotId = qs.parse(
      window.location.search.slice(window.location.search.lastIndexOf('?') + 1)
    ).time_slot;
    let volunteerId = qs.parse(
      window.location.search.slice(window.location.search.lastIndexOf('?') + 1)
    ).id;

    let timeSlotResult: TimeSlot | undefined;
    let callUrlResult: string | undefined;
    let callProviderResult: string | undefined;

    setCode(code);
    setSelectedTimeslot(selectedTimeSlotId);

    try {
      const boostCallInviteResponse = await apiInstance.get<BoostCallInvite>(
        `api/event/boostcallinvites/${code}/`,
        {}
      );

      const nonExpiredPendingSlots: TimeSlot[] =
        boostCallInviteResponse.data.time_slots.filter(
          ({ start_time, status, volunteer }: TimeSlot) => {
            return (
              moment(start_time).isAfter(moment()) &&
              status === 'time_slot_proposed' &&
              volunteer === Number(volunteerId)
            );
          }
        );

      setAvailableTimeslots(nonExpiredPendingSlots);

      setStartTime(
        //@ts-ignore
        boostCallInviteResponse.data.time_slots.find(
          (timeSlot: TimeSlot) => timeSlot.id.toString() === selectedTimeSlotId
        ).start_time
      );
      if (nonExpiredPendingSlots && nonExpiredPendingSlots.length > 0) {
        timeSlotResult = nonExpiredPendingSlots[0];
        if (timeSlotResult.call_provider) {
          callUrlResult = timeSlotResult.call_url;
          callProviderResult = timeSlotResult.call_provider?.id.toString();
          setCallURL(callUrlResult);
          setCallProvider(timeSlotResult.call_provider?.id.toString());
        }
      }
      setVolunteerName(boostCallInviteResponse.data.volunteer_name);
      setRequesterName(boostCallInviteResponse.data.requester_name);
      if (
        timeSlotResult &&
        timeSlotResult.status === 'time_slot_proposed' &&
        timeSlotToDisplay?.showAlertAnotherAlreadyAccepted
      ) {
        await confirmCall(
          code,
          timeSlotResult.id.toString(),
          callUrlResult,
          timeSlotResult.start_time,
          callProviderResult
        );
      }
      setIsProcessing(false);
    } catch (err) {
      console.log(err);
      setIsProcessing(false);
    }
  };

  const confirmCall = async (
    code: any,
    selectedTimeSlot: any,
    callURL: string | undefined,
    startTime: any,
    callProvider: string | undefined
  ) => {
    const callProviderParams = callProvider
      ? { call_provider: +callProvider }
      : {};

    await apiInstance
      .post(
        `api/event/boostcallinvites/${code}/acceptinvite/`,
        {
          time_slot: selectedTimeSlot,
          call_url: callURL,
          ...callProviderParams,
        },
        {}
      )
      .then((response: any) => {
        let callInfoData = response.data;
        const timeZone = /\((.*)\)/.exec(new Date(startTime).toString());
        if (timeZone && timeZone[1]) {
          setTimeZoneString(timeZone[1]);
        }
        setCallInfo(callInfoData);
      })
      .catch((err: any) => {
        setErrorMessage(err.response.data[Object.keys(err.response.data)[0]]);
      });
  };

  const declineCall = async (code: any) => {
    await declineProposedTime({ code: String(code) });
    setCancelCall(true);
  };

  useEffect(() => {
    getDataFromCode();
    // @ts-ignore
    setTimeout(() => window.addeventatc?.refresh(), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (callInfo) {
      // @ts-ignore
      setTimeout(() => window.addeventatc?.refresh(), 1000);
    }
  }, [callInfo]);

  if (isProcessing) {
    return (
      <div className="confirm-call__thank-you-container">Processing ...</div>
    );
  }

  if (volunteerName) {
    if (callInfo) {
      return (
        <div className="confirm-call__thank-you-container">
          <div className="confirm-call__thank-you">
            <h3>
              Thank you {requesterName}, <br />
              your session is confirmed!
            </h3>
            <p>
              We have sent your mentor {volunteerName} and yourself a
              confirmation email with the date, time and a link to the call.
              <br />
              <br />
              Thank you for using our platform.
              <br />
              The Digital Boost Team
            </p>
            <div className="confirm-call__button-wrapper">
              <Button
                text={'Back To Dashboard'}
                type="secondary"
                onClickFunction={() => {
                  navigate('/dashboard/actions');
                }}
              />
              {callInfo && timezone && (
                <AddToCalendarButton
                  callInfo={callInfo}
                  timeZoneString={timezone}
                />
              )}
            </div>
          </div>
        </div>
      );
    } else {
      if (!availableTimeslots.length) {
        return (
          <div className="appointments-expired-container">
            <div className="appointments-expired">
              <h3>Thank you, but this mentoring request has expired.</h3>
              <p>
                This can happen when all the timeslots selected have expired or
                the request has been accepted by someone else.
              </p>
              <div className="confirm-call__button-wrapper">
                <Button
                  text="Change Mentoring Preferences"
                  type="secondary"
                  onClickFunction={() => navigate('/account')}
                />
                <Button
                  text="Back to Dashboard"
                  type="primary"
                  onClickFunction={() => navigate('/dashboard/actions')}
                />
              </div>
            </div>
          </div>
        );
      }

      if (cancelCall) {
        return (
          <div className="confirm-call__thank-you-container">
            <div className="confirm-call__thank-you">
              <h3>You have successfully declined your request</h3>
              <p>
                Thank you for using our platform.
                <br />
                The Digital Boost Team
              </p>
              <div className="confirm-call__button-wrapper">
                <Button
                  text={'Back To Dashboard'}
                  type="secondary"
                  onClickFunction={() => {
                    navigate('/dashboard/actions');
                  }}
                />
              </div>
            </div>
          </div>
        );
      }
      // This piece of could/can probably be removed due to the auto confirm logic
      return (
        <div className="confirm-call">
          <div className="confirm-call__container">
            <h1> Confirm your availability </h1>

            {errorMessage && <h3 className="error">{errorMessage}</h3>}

            <h3>
              Select one of the below options. Your selected time is highlighted
            </h3>

            <div className="time-slots">
              {availableTimeslots.map((availableTimeslot: any, i: number) => {
                return (
                  <div className={'time-slot'} key={`time-slot-${i}`}>
                    <input
                      type="radio"
                      value={availableTimeslot.id}
                      checked={
                        availableTimeslot.id === parseInt(selectedTimeSlot)
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setStartTime(availableTimeslot.start_time);
                        setSelectedTimeslot(e.target.value);
                      }}
                    />
                    <label
                      className={
                        availableTimeslot.id === parseInt(selectedTimeSlot)
                          ? 'selected'
                          : ''
                      }
                    >
                      {displayTime(
                        availableTimeslot.start_time,
                        timeZoneString,
                        `MMMM Do YYYY, ${defaultTimeFormat}`
                      )}{' '}
                    </label>
                  </div>
                );
              })}
            </div>

            <br />

            <Button
              type={'primary'}
              text="Confirm Call"
              onClickFunction={() =>
                confirmCall(
                  code,
                  selectedTimeSlot,
                  callURL,
                  startTime,
                  callProvider
                )
              }
            />
            {timeSlotToDisplay?.showAlertAnotherAlreadyAccepted ? (
              <>
                <Button
                  type={'primary'}
                  text="Decline Call"
                  className="decline-call__btn"
                  onClickFunction={() => declineCall(code)}
                />
                <div className="mentor-modal__alert">
                  You already have a call with a different volunteer scheduled
                  from this request - if you accept this proposed time, both
                  calls will be scheduled.
                </div>
              </>
            ) : null}
          </div>
        </div>
      );
    }
  } else {
    return null;
  }
};

export default ConfirmCall;
