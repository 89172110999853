import { getOrgProfile, getUserLocation, getUserProfile } from 'api/user';

export async function getProfile() {
  const organisationProfile = await getOrgProfile().then((results) => results);
  const userLocation = await getUserLocation().then((results) => results);
  const userProfile = await getUserProfile().then((results) => results);
  let profile = { ...organisationProfile, ...userProfile, ...userLocation };

  const {
    availability,
    industries,
    interests,
    languages,
    id,
    user,
    is_beta_user,
    region,
    ...updatedProfile
  } = profile;
  return updatedProfile;
}
