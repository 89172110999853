import useSelectProfileData from 'hooks/useSelectProfileData';
import useWindowSize from 'hooks/useWindowSize';
import React, { memo } from 'react';
import { CustomSelectAvailabilityTypography } from 'styling/generalStyling';
import { mobileBreakpoint } from 'styling/screenSizes';
import { getAvailability } from 'utils/time';

type Props = {
  volunteers: [any];
};

const MentorAvailability = ({ volunteers }: Props) => {
  const { userLocation } = useSelectProfileData();
  const { timezone = Intl.DateTimeFormat().resolvedOptions().timeZone } =
    userLocation || {};
  const { width } = useWindowSize();
  const isMobile = width < mobileBreakpoint;
  const daysOfTheWeek = (days: (string | number)[]) => {
    let newDaysArray = [];

    if (days.includes(6)) newDaysArray.push('Su');
    if (days.includes(0)) newDaysArray.push('Mo');
    if (days.includes(1)) newDaysArray.push('Tu');
    if (days.includes(2)) newDaysArray.push('We');
    if (days.includes(3)) newDaysArray.push('Th');
    if (days.includes(4)) newDaysArray.push('Fr');
    if (days.includes(5)) newDaysArray.push('Sa');

    return newDaysArray;
  };
  return (
    <div className="support-mentors-container">
      {volunteers.map((volunteer) => {
        const { availabilityStartTimeLocal, availabilityEndTimeLocal } =
          getAvailability(
            { availabilityObj: volunteer?.availability },
            volunteer?.timezone,
            timezone
          );

        return (
          <div className="mentors-info">
            <CustomSelectAvailabilityTypography
              sx={{
                fontSize: isMobile ? '9px' : '13px',
                color: '#000000',
                textAlign: 'left',
                fontWeight: '600',
                lineHeight: isMobile ? '10.74px' : '15.51px',
              }}
            >
              {volunteer.first_name} {volunteer.last_name} {'/'}
            </CustomSelectAvailabilityTypography>

            <div className="days-of-week-contained">
              {daysOfTheWeek(volunteer.availability.days).map((day) => (
                <CustomSelectAvailabilityTypography
                  sx={{
                    fontSize: isMobile ? '9px' : '13px',
                    color: '#000000',
                    textAlign: 'left',
                    fontWeight: '600',
                    lineHeight: isMobile ? '10.74px' : '15.51px',
                  }}
                >
                  {day}
                </CustomSelectAvailabilityTypography>
              ))}
            </div>
            <CustomSelectAvailabilityTypography
              sx={{
                fontSize: isMobile ? '9px' : '13px',
                color: '#000000',
                textAlign: 'left',
                display: 'flex',
                alignContent: 'center',
                fontWeight: '600',
                lineHeight: isMobile ? '10.74px' : '15.51px',
              }}
            >
              {'/'} {availabilityStartTimeLocal} - {availabilityEndTimeLocal}
            </CustomSelectAvailabilityTypography>
          </div>
        );
      })}
    </div>
  );
};

export default memo(MentorAvailability);
