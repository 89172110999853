import React from 'react';
import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import './filePopupStyle.scss';
import whitelabelLoadingGif from './whitelabel-spinner.gif';
import loadingGif from './db-spinner.gif';
import { selectPartner } from 'features/partner';
import useRedux from 'hooks/useRedux';
interface LoadingSplashPopupProps {
  open: boolean;
  message?: string;
}

const LoadingSplashPopup: React.FC<LoadingSplashPopupProps> = ({
  open,
  message,
}) => {
  const { useSelector } = useRedux();
  const partner = useSelector(selectPartner);

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-container': {
          height: '80vh',
          background: 'white',
          marginTop: '10vh',
          border: 'none',
        },

        '& .MuiDialog-paper': {
          boxShadow: 'none',
          outline: 'none',
          border: 'none',
        },
        borderRadius: '15px',
        background: 'transparent',
      }}
    >
      <DialogContent sx={{ marginTop: '20px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '20px',
          }}
        >
          <img
            height="50%"
            width="50%"
            src={
              partner && partner.white_label === true
                ? whitelabelLoadingGif
                : loadingGif
            }
            alt="Uploading document loading spinner"
          />
          <div className="popup-text">
            {message ? message : 'Loading, please wait...'}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingSplashPopup;
