// NewCard.js

import React, { memo, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createActionTask } from 'api/actionPlan';
import { DashboardFilledButton } from 'styling/buttons';
import { CardTextField } from 'styling/generalStyling';
import './cardPlan.scss';
import { CardsType } from '../../../types';
import TaxonomyPopup from './taxonomyPopup';
import SelectAvailabilityTask from './selectAvailabilityTask';
interface NewCardProps {
  planId: string;
  isOpen: boolean;
  onClose: () => void;
  status: string;
  cardDetails: CardsType | undefined;
  setCounterChange: () => void;
}

const NewCard = ({
  planId,
  onClose,
  isOpen,
  status,
  cardDetails,
  setCounterChange,
}: NewCardProps) => {
  const [newCardName, setNewCardName] = useState<string | undefined>(undefined);
  const color = status === 'todo' ? '#FCEBEB' : '#FFF9E4';
  const [newDate, setNewDate] = useState<string | undefined>(undefined);

  const [description, setDescription] = useState<string>('');
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<any>(undefined);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (isOpen) {
      setNewCardName(undefined);
      setNewDate(undefined);
      setDescription('');
      setSelectedTaxonomy(undefined);
      setErrorMessage(null);
      setLoading(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (cardDetails?.interest?.id)
      setSelectedTaxonomy(cardDetails?.interest?.id.toString());
  }, [cardDetails?.interest?.id]);
  const handleSaveCard = async () => {
    if (!newCardName || newCardName.trim().length < 1) {
      setErrorMessage('Please give the card a title');
      return;
    } else if (newCardName) {
      setLoading(true);
      try {
        const result = await createActionTask(
          newCardName,
          parseInt(planId),
          newDate ? new Date(newDate) : undefined,
          description,
          parseInt(selectedTaxonomy),
          status
        );
        if (result.success) {
          setCounterChange();
          onClose();
        }
      } catch (error) {
        console.error('Error creating task:', error);
      }
    } else {
      setErrorMessage(' Name is required to create a task');
    }
    setLoading(false);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
        sx={{
          padding: 0,
          '.MuiPaper-root': {
            padding: 0,
            borderRadius: '15px',
            background: ' #FFF',
            width: '840px',
            minHeight: '372px',
            maxHeight: '90vh',
            display: 'inline-flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: `${color}`,
            maxWidth: '100vw',
            flexShrink: 0,
          },
        }}
      >
        <IconButton
          color="primary"
          onClick={async () => {
            onClose();
          }}
          sx={{
            padding: '0px',
            marginRight: '36px',
            alignSelf: 'flex-end',
            marginTop: '36px',
          }}
        >
          <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
        </IconButton>

        <div className="card-title" style={{ marginTop: '-20px' }}>
          Add a new task
        </div>

        <DialogContent sx={{ padding: 0, width: '750px' }}>
          <div className="empty-card-container">
            <div className="row-card">
              <div className="table-text">Task name*</div>
              <div className="table-text"> Deadline</div>
              <div className="table-text"> Topic</div>
            </div>
            <div className="row-card">
              <div>
                <CardTextField
                  sx={{
                    width: '240px',
                    margin: 0,
                  }}
                  inputProps={{ maxLength: 50 }}
                  placeholder="Type the task name"
                  type="text"
                  onChange={(e) => setNewCardName(e.target.value)}
                />
                {errorMessage ? (
                  <div className="error-message">{errorMessage}</div>
                ) : null}
              </div>
              <SelectAvailabilityTask setNewDate={setNewDate} />

              <TaxonomyPopup
                setSelectedTaxonomy={setSelectedTaxonomy}
                selectedTaxonomy={selectedTaxonomy}
              />
            </div>
            <div className="row-card">
              <CardTextField
                multiline
                inputProps={{ maxLength: 800 }}
                sx={{
                  width: '700px',
                  marginTop: '32px',
                }}
                placeholder="Add a description"
                type="text"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            alignSelf: 'center',
            paddingBottom: '31px',
            width: ' 100%',
            justifyContent: 'center',
          }}
        >
          <DashboardFilledButton
            variant="contained"
            onClick={handleSaveCard}
            sx={{
              width: '290px',
              height: '52px',
              padding: '12px 36px',
            }}
          >
            {loading ? (
              <img
                width="25px"
                height="25px"
                src={'/loading-icon.svg'}
                alt="loading-icon"
              />
            ) : (
              'Save'
            )}
          </DashboardFilledButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default memo(NewCard);
