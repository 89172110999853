import { useAxios } from '../../api/config';
import useGetProposeTimeSlots from './useGetProposeTimeSlots';
import { BoostCallRequests, BoostCallRequest } from '../../types';

const useGetBoostCallEventRequests = () => {
  const [{ data, loading, error }, executeCall] = useAxios<BoostCallRequests>({
    url: `api/event/boostcallrequests/`,
    method: 'GET',
  });

  const { proposedTimeSlots, timeSlotToDisplay, setTimeSlotToDisplay } =
    useGetProposeTimeSlots({ boostCallEventRequests: data?.results });

  const pendingBoostCallEventRequests = data?.results?.filter(
    (request: BoostCallRequest) => request.status === 'pending'
  );

  return {
    boostCallEventRequests: data || [],
    pendingBoostCallEventRequests: pendingBoostCallEventRequests || [],
    hasFinishedLoadingBoostCallEventRequests: !loading,
    boostCallEventRequestsError: error,
    refetchBoostCallEventRequests: executeCall,
    proposedTimeSlots,
    timeSlotToDisplay,
    setTimeSlotToDisplay,
  };
};

export default useGetBoostCallEventRequests;
