import React from 'react';
import Box from '@mui/material/Box';
import { CustomFormLabel, CustomTextField } from 'styling/generalStyling';

type Props = {
  setValue: (value: any, input: string) => void;
  value: {
    company: string;
    role: string;
    partner: number | null;
  };
};
export default function EditCompanyRoleForm({ value, setValue }: Props) {
  return (
    <div>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <CustomTextField
          inputProps={{ maxLength: 50 }}
          id="outlined-basic"
          label={<CustomFormLabel>Company</CustomFormLabel>}
          defaultValue={value.company}
          onChange={(event) => setValue(event.target.value, 'name')}
          variant="outlined"
        />
        <CustomTextField
          inputProps={{ maxLength: 50 }}
          id="outlined-basic"
          label={<CustomFormLabel>Role</CustomFormLabel>}
          defaultValue={value.role}
          onChange={(event) => setValue(event.target.value, 'current_role')}
          variant="outlined"
        />
      </Box>
    </div>
  );
}
