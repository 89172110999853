import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ActionPlanType, Card } from 'types';

interface ActionPlansState {
  actionPlans: ActionPlanType[];
}
const initialState: ActionPlansState = {
  actionPlans: [],
};

const actionPlansSlice = createSlice({
  name: 'actionPlans',
  initialState,
  reducers: {
    addPlan: (state, action: PayloadAction<ActionPlanType>) => {
      state.actionPlans.push(action.payload);
    },
    addCardToPlan: (
      state,
      action: PayloadAction<{ planIndex: number; card: Card }>
    ) => {
      state.actionPlans[action.payload.planIndex].cards.push(
        action.payload.card
      );
    },
    updateCardStatus: (
      state,
      action: PayloadAction<{
        planName: string;
        cardName: string;
        newStatus: string;
      }>
    ) => {
      const plan = state.actionPlans.find(
        (plan) => plan.name === action.payload.planName
      );
      const card = plan?.cards.find(
        (card) => card.name === action.payload.cardName
      );
      if (card) {
        card.status = action.payload.newStatus;
      }
    },
  },
});

export const { addPlan, addCardToPlan, updateCardStatus } =
  actionPlansSlice.actions;
export const selectActionPlans = (state: RootState) => state.action.actionPlans;
export const selectPlanByName = (state: RootState, planName: string) =>
  state.action.actionPlans.find(
    (plan: { name: string }) => plan.name === planName
  );

export default actionPlansSlice.reducer;
