import React, { useState, useEffect } from 'react';
import apiInstance from '../../../api/config';
import '../style/skills.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { VolunteerPreferences } from '../types';
import { Taxonomies, Taxonomy } from '../../../types';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@mui/icons-material/Close';

import '../style/general.css';
import SkillsForm from '../Forms/SkillsForm';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {
  CustomProfileCancelButton,
  CustomProfileSaveButton,
  SkillButton,
} from 'styling/buttons';
import { CustomPaper } from 'styling/backgrounds';

type Props = {
  data: VolunteerPreferences;
};

const LearningTab = ({ data }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [volunteerPreferences] = useState(data);
  const [skillsList, setSkillsList] = useState<Taxonomy[]>([]);
  const [categoriesList1, setCategoriesList1] = useState<Taxonomy[]>([]);
  const [categoriesList2, setCategoriesList2] = useState<Taxonomy[]>([]);
  const [currentSkills, setCurrentSkills] = useState<Taxonomy[]>([]);
  const handleSaveClick = async () => {
    const interests = currentSkills.map((skill) => skill.id);
    try {
      await apiInstance.patch(`api/organisation/organisations/me/`, {
        interests: interests,
      });
    } catch (e) {
      console.error(e);
    }
    window.location.reload();
  };
  useEffect(() => {
    var categories: Taxonomy[] = [];
    var subCategories: Taxonomy[] = [];
    var currentSkillsList: Taxonomy[] = [];
    const getSkillsList = async () => {
      try {
        const response = await apiInstance.get<Taxonomies>(
          `api/taxonomy/interests/?limit=1000`,
          {}
        );
        categories = response.data.results.filter(
          (value) => value.parent === null
        );
        subCategories = response.data.results.filter(
          (value) => value.parent !== null
        );

        if (volunteerPreferences.interests)
          volunteerPreferences.interests.forEach((skillId) => {
            const skill = subCategories.filter(
              (category) => category.id === skillId
            )[0];
            if (skill) currentSkillsList.push(skill);
          });
      } catch (e) {
        console.error(e);
      }
    };
    getSkillsList().then(() => {
      const categoriesList = categories.splice(0, 6);
      setCategoriesList1(categoriesList);
      setCategoriesList2(categories);
      setSkillsList(subCategories);
      setCurrentSkills(currentSkillsList);
    });
  }, [volunteerPreferences.interests]);

  const [edit, setEdit] = useState(false);
  const [fieldsOpen, setFieldOpen] = useState<number[]>([]);
  return (
    <div className="mentoring-preferences__container">
      <div className="mentoring-preferences-tooltip__container">
        <div className="mentoring-preferences-title">
          What you want to learn about
        </div>

        <Tooltip
          open={showTooltip}
          onClick={() => setShowTooltip(true)}
          onClose={() => setShowTooltip(false)}
          placement="top-end"
          title="We will recommend the most relevant mentors, Masterclasses and learning resources for the topics / skills you want to learn about."
        >
          <IconButton sx={{ fontSize: 'small', marginTop: '24px' }}>
            <HelpIcon sx={{ fontSize: 'small', color: '#2f80ed' }} />
          </IconButton>
        </Tooltip>
      </div>
      <div>
        {currentSkills
          ? currentSkills.map((value) => (
              <div className="mentoring-preferences-button">
                <SkillButton
                  onClick={() => {
                    if (currentSkills.length > 1)
                      setCurrentSkills(
                        currentSkills.filter(
                          (skill) => skill.name !== value.name
                        )
                      );
                    else setErrorMessage('You need at least 1 skill selected');
                  }}
                  endIcon={<CloseIcon />}
                >
                  {value.name}
                </SkillButton>
              </div>
            ))
          : null}
      </div>
      {errorMessage}
      <CustomPaper elevation={0} sx={{ paddingTop: 0 }}>
        <div className="skills__container">
          <div
            className="mentoring-preferences-title"
            style={{ marginLeft: '12px' }}
          >
            {' '}
            Add more skills
          </div>
          <div className="categories__container">
            <div className="categories__column-container">
              {categoriesList1.length
                ? categoriesList1.map((value) => (
                    <div className="category-input">
                      <div className="category-input_name">
                        {fieldsOpen.indexOf(value.id) === -1 ? (
                          <AddCircleOutlineIcon
                            sx={{ fontSize: 'medium' }}
                            onClick={() => {
                              setEdit(true);
                              setFieldOpen((fieldsOpen) => [
                                ...fieldsOpen,
                                value.id,
                              ]);
                            }}
                          />
                        ) : (
                          <RemoveCircleOutlineIcon
                            sx={{ fontSize: 'medium' }}
                            onClick={() => {
                              setFieldOpen(
                                fieldsOpen.filter((item) => item !== value.id)
                              );
                            }}
                          />
                        )}
                        {value.name}
                      </div>
                      {edit && fieldsOpen.indexOf(value.id) !== -1 ? (
                        <SkillsForm
                          skillsList={skillsList}
                          value={value}
                          setCurrentSkills={setCurrentSkills}
                          currentSkills={currentSkills}
                          setErrorMessage={setErrorMessage}
                        />
                      ) : null}
                    </div>
                  ))
                : null}
            </div>
            <div className="categories__column-container">
              {categoriesList2.map((value) => (
                <div className="category-input">
                  <div className="category-input_name">
                    {fieldsOpen.indexOf(value.id) === -1 ? (
                      <AddCircleOutlineIcon
                        sx={{ fontSize: 'medium' }}
                        onClick={() => {
                          setEdit(true);
                          setFieldOpen((fieldsOpen) => [
                            ...fieldsOpen,
                            value.id,
                          ]);
                        }}
                      />
                    ) : (
                      <RemoveCircleOutlineIcon
                        sx={{ fontSize: 'medium' }}
                        onClick={() => {
                          setFieldOpen(
                            fieldsOpen.filter((item) => item !== value.id)
                          );
                        }}
                      />
                    )}
                    {value.name}
                  </div>
                  {edit && fieldsOpen.indexOf(value.id) !== -1 ? (
                    <SkillsForm
                      skillsList={skillsList}
                      value={value}
                      setCurrentSkills={setCurrentSkills}
                      currentSkills={currentSkills}
                      setErrorMessage={setErrorMessage}
                    />
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      </CustomPaper>
      <div className="update-preferences-buttons">
        <CustomProfileSaveButton
          variant="contained"
          onClick={() => handleSaveClick()}
        >
          Save
        </CustomProfileSaveButton>
        <CustomProfileCancelButton
          variant="outlined"
          onClick={() => window.location.reload()}
        >
          Cancel
        </CustomProfileCancelButton>
      </div>
    </div>
  );
};

export default LearningTab;
