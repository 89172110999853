import React, { useState, useRef } from 'react';
import { ReactComponent as ShareLogo } from './share.svg';
import moment from 'moment-timezone';
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from 'react-share';
import Button from '../Button';
import { useSystemTogglesContext } from '../../context/SystemToggles';
import { toTitleCase } from '../../utils/text';
import { getMasterclassSocialText } from '../../utils/social';
import './social-share-button.scss';
import { defaultDateFormat, defaultTimeFormat } from '../../constants';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { DashboardEmptyButton, DashboardFilledButton } from 'styling/buttons';
import useSelectProfileData from 'hooks/useSelectProfileData';
import { selectGroupEventMasterclassName, selectGroupEventWebinarName, selectGroupEventWorkshopName } from 'features/reduxStore/groupEventsName';
import { useSelector } from 'react-redux';

type Props = {
  text?: string;
  skill: string;
  link: string;
  hostName: string;
  eventType: string;
  isNPS?: boolean;
  hostCompanyName?: string;
  eventStartTime: string;
  type?: string;
  className?: string;
  containerClassName?: string;
  buttonClassName?: string;
};

type SocialButtonProps = {
  platform: 'linkedin' | 'facebook' | 'twitter';
  icon?: React.ReactElement;
  text?: string;
  isNPS?: boolean;
  className?: string;
};

type SocialShareModalProps = {
  link?: string;
  isNPS?: boolean;
  eventType: string;
  subject: string;
  name: string;
  companyName?: string;
  startTime: string;
  className?: string;
  onCloseClick?: () => void;
};

const platforms = {
  linkedin: 'Linkedin',
  twitter: 'Twitter',
  facebook: 'Facebook',
};

const icons = {
  linkedin: <img src="/linkedin-logo.svg" alt="linkedin" />,
  twitter: <img src="/twitter-logo.png" alt="twitter" />,
  facebook: <img src="/facebook-logo.png" alt="facebook" />,
};

const SocialButton = ({ platform, className }: SocialButtonProps) => {
  return (
    <div
      className={`social-share-button__social-button social-share-button__${platform} ${
        className ? className : ''
      }`}
    >
      {icons[platform]}
      <span className="social-share-button__social-button-text">
        {platforms[platform]}
      </span>
    </div>
  );
};

export const SocialButtons = ({
  skill,
  link,
  hostName,
  hostCompanyName,
  eventStartTime,
  eventType,
  isNPS,
}: Props) => {
  const formattedTime = moment(eventStartTime).format(
    `${defaultTimeFormat} ${defaultDateFormat}`
  );

  const { facebookText, twitterText, linkedinText } = getMasterclassSocialText({
    skill,
    link,
    eventType,
    hostName,
    hostCompanyName,
    eventStartTime: formattedTime,
  });
  const masterclassName =
    useSelector(selectGroupEventMasterclassName) || 'Masterclass';
  const webinarName = useSelector(selectGroupEventWebinarName) || 'Webinar';
  const workshopName = useSelector(selectGroupEventWorkshopName) || 'Workshop';
  const shareEventType =
  eventType === 'masterclass'
      ? masterclassName
      : eventType === 'webinar'
      ? webinarName
      : workshopName;

  return (
    <div className="social-share-button__modal-inner-container">
      <div className="masterclass-share-title">
        Share this {toTitleCase(shareEventType)}
      </div>
      <div className="social-share-button__buttons-wrapper">
        <LinkedinShareButton
          url={link}
          title={
            !isNPS
              ? linkedinText
              : `I just had a great free 1-2-1 mentoring session on ${skill} through Digital Boost. Thank you to my mentor. Highly recommended for other small businesses and charities!`
          }
          summary={
            !isNPS
              ? linkedinText
              : `I just had a great free 1-2-1 mentoring session on ${skill} through Digital Boost. Thank you to my mentor. Highly recommended for other small businesses and charities!`
          }
        >
          <SocialButton platform="linkedin" />
        </LinkedinShareButton>
        <TwitterShareButton
          url={link}
          hashtags={
            !isNPS ? [toTitleCase(skill).replace(/[\s+]|[/+]/g, '')] : []
          }
          title={
            !isNPS
              ? twitterText
              : `I just had a great free 1-2-1 mentoring session on ${skill} through Digital Boost. Thank you to my mentor. Highly recommended for other small businesses and charities!`
          }
        >
          <SocialButton platform="twitter" />
        </TwitterShareButton>
        <FacebookShareButton
          url={link}
          quote={
            !isNPS
              ? facebookText
              : `I just had a great free 1-2-1 mentoring session on ${skill} through Digital Boost. Thank you to my mentor. Highly recommended for other small businesses and charities!`
          }
          title={
            !isNPS
              ? facebookText
              : `I just had a great free 1-2-1 mentoring session on ${skill} through Digital Boost. Thank you to my mentor. Highly recommended for other small businesses and charities!`
          }
        >
          <SocialButton platform="facebook" />
        </FacebookShareButton>
      </div>
    </div>
  );
};

export const NewSocialShareModal = ({
  link,
  eventType,
  subject,
  name,
  companyName,
  startTime,
  className,
  onCloseClick,
}: SocialShareModalProps) => {
  let linkToUse = link ? link : window.location.href;
  const { organisationProfile } = useSelectProfileData();
  const partnerId = organisationProfile?.partner;
  if (partnerId) linkToUse = linkToUse + `&id=${partnerId}`;
  const { toggles } = useSystemTogglesContext();
  const showSocialShare = !!toggles['GROUP_EVENT_SOCIAL_SHARE'];
  const inputRef = useRef<HTMLInputElement>(null);

  // Check if copying is possible
  if (!document.queryCommandSupported('copy')) return null;

  const copyToClipboard = (e: any) => {
    inputRef?.current?.select();
    document.execCommand('copy');
    e?.target?.focus();
  };
  return (
    <div className={`social-share__modal ${className}`}>
      <div className="close-share-modal">
        {onCloseClick && (
          <IconButton sx={{ color: '#000' }} onClick={onCloseClick}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </div>
      <div className="share-event-container">
        {showSocialShare && (
          <SocialButtons
            skill={subject}
            link={linkToUse}
            hostName={name}
            hostCompanyName={companyName}
            eventStartTime={startTime}
            eventType={eventType}
            type={'white'}
          />
        )}
        <div className="share-event-prompt-text">Or copy the link</div>
        <div className={'social-share__social-share-modal-copy-container'}>
          <input
            ref={inputRef}
            value={linkToUse}
            className={'social-share__social-share-modal-copy-input'}
          />
          <DashboardFilledButton
            variant="contained"
            sx={{ width: '100px' }}
            onClick={copyToClipboard}
          >
            Copy
          </DashboardFilledButton>
        </div>
      </div>
    </div>
  );
};

export const SocialShareModal = ({
  link,
  eventType,
  subject,
  name,
  companyName,
  startTime,
  className,
  onCloseClick,
  isNPS,
}: SocialShareModalProps) => {
  const { organisationProfile } = useSelectProfileData();
  let linkToUse = link ? link : window.location.href;
  const partnerId = organisationProfile?.partner;

  if (partnerId) linkToUse = linkToUse + `&id=${partnerId}`;
  const { toggles } = useSystemTogglesContext();
  const showSocialShare = !!toggles['GROUP_EVENT_SOCIAL_SHARE'];
  const [copyText, setCopyText] = useState<string>('Copy');
  const inputRef = useRef<HTMLInputElement>(null);

  // Check if copying is possible
  if (!document.queryCommandSupported('copy')) return null;

  const copyToClipboard = (e: any) => {
    inputRef?.current?.select();
    document.execCommand('copy');
    e?.target?.focus();
    setCopyText('Copied!');
  };
  return (
    <div className={`social-share__modal ${className}`}>
      {onCloseClick && (
        <Button
          text="&#x00D7;"
          className="social-share__modal-close"
          type="ghost"
          onClickFunction={onCloseClick}
        />
      )}
      {showSocialShare && (
        <SocialButtons
          skill={subject}
          isNPS={isNPS}
          link={linkToUse}
          hostName={name}
          hostCompanyName={companyName}
          eventStartTime={startTime}
          eventType={eventType}
          type={'white'}
        />
      )}
      {!isNPS && (
        <>
          <p className="social-share__social-share-modal-copy-text">{`${
            showSocialShare ? 'Or c' : 'C'
          }opy link`}</p>
          <div className={'social-share__social-share-modal-copy-container'}>
            <input
              ref={inputRef}
              value={linkToUse}
              className={'social-share__social-share-modal-copy-input'}
            />
            <Button
              text={copyText}
              type="primary"
              onClickFunction={copyToClipboard}
              className={'social-share__social-share-modal-copy-button'}
            />
          </div>
        </>
      )}
    </div>
  );
};

const SocialShareButton = ({
  text = 'Share',
  skill,
  link,
  hostName,
  isNPS,
  eventType,
  hostCompanyName,
  eventStartTime,
  className,
  containerClassName,
  type = 'white',
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div
      className={`social-share-button-container ${
        containerClassName ? containerClassName : ''
      }`}
    >
      <DashboardEmptyButton
        variant="outlined"
        sx={{ width: '350px' }}
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        <ShareLogo className="social-share-button__share-logo" />
        <span>{text}</span>
      </DashboardEmptyButton>
      {isModalOpen && (
        <div className="social-share-button__modal">
          <SocialButtons
            skill={skill}
            isNPS={isNPS}
            link={link}
            hostName={hostName}
            eventType={eventType}
            hostCompanyName={hostCompanyName}
            eventStartTime={eventStartTime}
            className={className}
            type={type}
          />
          <div className={'social-share-button__pointer'} />
        </div>
      )}
    </div>
  );
};

export default SocialShareButton;
