import { AxiosPromise } from 'axios';
import apiInstance from './config';

export type SearchMessagesResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: any;
};

export interface MessageUsersProps {
  name?: string;
  limit?: number;
}
export const getMessageUsers = ({
  name,
  limit,
}: MessageUsersProps): AxiosPromise<SearchMessagesResponse> =>
  apiInstance(
    name && name.length > 0
      ? `api/messagecentre/messageusers/search?name=${name}&has_messages=Y`
      : `api/messagecentre/messageusers/search?has_messages=Y&limit=${
          limit ? 10 * limit : 10
        }`,
    {
      method: 'GET',
    }
  );

export const getAvailableUsers = ({
  name,
  limit,
}: MessageUsersProps): AxiosPromise<SearchMessagesResponse> =>
  apiInstance(
    name && name.length > 0
      ? `api/messagecentre/messageusers/search?name=${name}`
      : `api/messagecentre/messageusers/search?has_messages=A&limit=${
          limit ? 10 * limit : 10
        }`,
    {
      method: 'GET',
    }
  );

export interface SearchMessageUsers {
  name: string;
}
export const searchMessageUsers = ({
  name,
}: SearchMessageUsers): AxiosPromise<SearchMessagesResponse> =>
  apiInstance(`api/messagecentre/messageusers/search?name=${name}`, {
    method: 'GET',
  });

export interface BlockUserProps {
  volunteerId: number;
  isBlocked: boolean;
}
export const blockUser = ({
  volunteerId,
  isBlocked,
}: BlockUserProps): AxiosPromise<any> =>
  apiInstance('api/messagecentre/blockvolunteers/manage/', {
    method: 'POST',
    data: {
      volunteer_id: volunteerId,
      is_blocked: isBlocked,
    },
  });

export interface SearchMessagesProps {
  volunteerId: number;
  limit?: number;
}

export const searchMessages = ({
  volunteerId,
  limit,
}: SearchMessagesProps): AxiosPromise<SearchMessagesResponse> =>
  apiInstance(
    limit
      ? `api/messagecentre/messages/search?volunteer_id=${volunteerId}&limit=${
          limit * 10
        }`
      : `api/messagecentre/messages/search?volunteer_id=${volunteerId}&limit=6`,
    {
      method: 'GET',
    }
  );

export interface SendMessageProps {
  volunteerId: number;
  text: string;
}

export const sendMessage = ({
  text,
  volunteerId,
}: SendMessageProps): AxiosPromise<any> =>
  apiInstance('api/messagecentre/messages/sendmessage/', {
    method: 'POST',
    data: {
      volunteer_id: volunteerId,
      message: text,
    },
  });

export const getUnreadMessagesCount = (): AxiosPromise<any> =>
  apiInstance('api/messagecentre/unreadmessages', {
    method: 'GET',
  });

export interface ReadMessagesProps {
  volunteerId: number;
}

export const readMessages = ({
  volunteerId,
}: ReadMessagesProps): AxiosPromise<any> =>
  apiInstance('api/messagecentre/messages/readmessages/', {
    method: 'POST',
    data: {
      volunteer_id: volunteerId,
    },
  });
