import React, { useState } from 'react';
import './login-page.scss';
import InputWithLabel from '../../components/InputWithLabel';
import Button from '../../components/Button';
import apiInstance from '../../api/config';
import Cookie from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const [orgEmail, setOrgEmail] = useState('');
  const [orgPassword, setOrgPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState<boolean | string>(false);

  const navigate = useNavigate();

  let redirect = '';

  if (window.location.search) {
    redirect = window.location.search.split('?redirect=')[1];
  }

  const orgLogin = () => {
    setErrorMessage(false);
    apiInstance
      .post(
        `api/token/`,
        {
          username: orgEmail,
          password: orgPassword,
          user_type: 'partner',
        },
        {
          headers: {},
        }
      )
      .then((response: any) => {
        // set cookie that expires in a day
        Cookie.set('refreshPartnerTokenDB', response.data.refresh, {
          expires: 90,
        });

        Cookie.set('accessPartnerTokenDB', response.data.access, {
          expires: 1,
        });

        if (redirect !== '') {
          navigate(redirect);
        } else {
          navigate('/premium-partner');
        }
      })
      .catch((err: any) => {
        setErrorMessage(err.response.data[Object.keys(err.response.data)[0]]);
      });
  };

  return (
    <div className="login-page login-page-partner">
      <h1>Welcome Back! Sign in to Your Partner Account</h1>

      <div className="signup-text">
        {' '}
        Want to join as a partner?{' '}
        <a href="/https://www.digitalboost.org.uk/contact">
          Click here to join us?{' '}
        </a>
      </div>

      {errorMessage && <div className="error">{errorMessage}</div>}

      <div className="container">
        <div className="inputs">
          <h3>Login with Email</h3>

          <InputWithLabel
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOrgEmail(e.target.value);
            }}
            label={'Email'}
            type={'text'}
            placeHolder={'Enter your email address'}
          />
          <InputWithLabel
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOrgPassword(e.target.value);
            }}
            label={'Password'}
            type={'password'}
          />

          <Button
            disabled={!!!orgEmail || !!!orgPassword}
            onClickFunction={() => orgLogin()}
            type={'primary'}
            text={'Login'}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
