import React, { useState, useEffect, useCallback } from 'react';
import Cookie from 'js-cookie';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import { SocialShareModal } from '../../components/SocialShareButton';
import HelpIcon from '@mui/icons-material/Help';
import VolunteerProfile from '../../components/VolunteerProfile';
import {
  getUrlParam,
  redirectToLoginAndBack,
  removeUrlParam,
} from '../../utils/url';
import { toTitleCase } from '../../utils/text';
import {
  getErrorCodeFromResponse,
  getErrorMessageFromResponse,
} from '../../utils/error';
import { formatDate, getMomentWithTimezone } from '../../utils/time';
import api from '../../api';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import useGetGroupEvents from '../../apiHooks/groupEvents/useGetGroupEvents';
import useGetJoinCall from '../../hooks/useGetJoinCall';

import './group-event-details.scss';
import AddGroupEventToCalendarButton from 'atoms/AddGroupEventToCalendarButton';
import { DashboardFilledButton } from 'styling/buttons';
import { IconButton, Tooltip } from '@mui/material';
import { CustomThemeBackground } from 'styling/backgrounds';
import DOMPurify from 'dompurify';
import { selectGroupEventMasterclassName, selectGroupEventWebinarName, selectGroupEventWorkshopName } from 'features/reduxStore/groupEventsName';
import { useSelector } from 'react-redux';

const CTAToolTip = ({ text }: { text: string }) => {
  const [showTooltip1, setShowTooltip1] = useState(false);
  return (
    <Tooltip
      open={showTooltip1}
      onMouseEnter={() => setShowTooltip1(true)}
      onMouseLeave={() => setShowTooltip1(false)}
      placement="top-end"
      title={text}
    >
      <IconButton sx={{ fontSize: 'small', marginTop: '24px' }}>
        <HelpIcon sx={{ fontSize: 'medium', color: '#2f80ed' }} />
      </IconButton>
    </Tooltip>
  );
};
export const HtmlContent = (htmlContent: string) => {
  // Sanitize the HTML content before setting it
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);

  return (
    <div>
      {/* Using dangerouslySetInnerHTML to inject sanitized HTML */}
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </div>
  );
};
const GroupEventDetails = () => {
  const loggedIn = !!Cookie.get('accessOrgTokenDB');
  const navigate = useNavigate();

  const joinCallParam = getUrlParam('join_call') as string;
  const [shouldJoinCall, setShouldJoinCall] = useState(!!joinCallParam);
  const [socialShareModalVisible, setSocialShareModalVisible] = useState(false);

  const groupEventCode = getUrlParam('code') as string;
  const registerParam = getUrlParam('register') as string;
  const shareLink = removeUrlParam(
    removeUrlParam(window.location.href, 'register'),
    'join_call'
  );

  const { partner } = useSelectProfileData();
  const { userLocation, needsToFinishSignup, organisationProfile } =
    useSelectProfileData();
  const { timezone } = userLocation || {};
  const {
    groupEventData: groupEvent,
    hasFinishedLoadingGroupEvents,
    groupEventError,
    refetchGroupEvent,
  } = useGetGroupEvents({ code: groupEventCode });

  const {
    duration,
    interest,
    title,
    organisation_registered,
    start_time = '',
    type,
    volunteer: volunteerProfile,
    description,
    id,
    attendee_id,
  } = groupEvent;

  const masterclassName =
  useSelector(selectGroupEventMasterclassName) || 'Masterclass';
const webinarName = useSelector(selectGroupEventWebinarName) || 'Webinar';
const workshopName = useSelector(selectGroupEventWorkshopName) || 'Workshop';
const eventType =
type === 'masterclass'
    ? masterclassName
    : type === 'webinar'
    ? webinarName
    : workshopName;


  const { joinCall } = useGetJoinCall({ callInfo: groupEvent });
  const subject = title || '';
  const subTitle = interest?.name || '';

  const timezoneToUse = timezone || moment.tz.guess(true);
  const formattedDate = formatDate(start_time, timezoneToUse, duration || 60);

  const startTimeIsInFuture = moment().isBefore(
    getMomentWithTimezone(start_time, timezoneToUse)
  );
  const endTimeIsInPast = moment().isAfter(
    getMomentWithTimezone(start_time, timezoneToUse).add(
      duration || 60,
      'minutes'
    )
  );
  const durationUntilEventStarts = moment.duration(
    getMomentWithTimezone(start_time, timezoneToUse).diff(moment())
  );
  const hoursUntilEventStarts = durationUntilEventStarts.asHours();
  const hoursToActivateBeforeEventStarts: number = 0.5;
  // < 30 mins BEFORE || 1 hour AFTER start_time
  const lessThan30MinutesUntilEvent = !(
    hoursUntilEventStarts < -1 ||
    hoursUntilEventStarts > hoursToActivateBeforeEventStarts
  );

  const joinCallText = `${
    endTimeIsInPast ? 'Finished' : startTimeIsInFuture ? 'Starts' : 'Started'
  } ${moment().utc().to(moment(start_time))}${
    lessThan30MinutesUntilEvent
      ? ` - Join ${eventType ? toTitleCase(eventType) : 'Call'}`
      : ''
  }`;

  const primaryCTAText = `Register for ${eventType && toTitleCase(eventType)}`;
  const RegisterButtonEnabled = moment().isBefore(
    getMomentWithTimezone(start_time, timezoneToUse).add(45, 'minutes')
  );
  const [firstLoadFinished, setFirstLoadFinished] = useState(false);
  const [registerErrorMessage, setRegisterErrorMessage] = useState('');

  const [registerSuccessPanelVisible, setRegisterSuccessPanelVisible] =
    useState(false);

  useEffect(() => {
    if (hasFinishedLoadingGroupEvents && !firstLoadFinished) {
      setFirstLoadFinished(true);
    }
  }, [firstLoadFinished, hasFinishedLoadingGroupEvents]);

  useEffect(() => {
    if (shouldJoinCall && hasFinishedLoadingGroupEvents) {
      if (
        id &&
        attendee_id &&
        organisationProfile &&
        organisationProfile.id !== 0
      ) {
        api.updateAttendeeJoinCallClicked(
          attendee_id,
          id,
          organisationProfile?.id
        );
      }
      joinCall();
      setShouldJoinCall(false);
    }
  }, [
    shouldJoinCall,
    hasFinishedLoadingGroupEvents,
    joinCall,
    organisationProfile,
    id,
    attendee_id,
  ]);

  const register = useCallback(async () => {
    setRegisterErrorMessage('');

    // TODO: Correct flow if masterclass
    try {
      await api.registerForGroupEvent({ groupEventCode });

      await refetchGroupEvent();
      navigate(`/group-event-registered?code=${groupEventCode}`);
    } catch (err) {
      console.log(err);
      setRegisterErrorMessage(getErrorMessageFromResponse(err));
    }
  }, [groupEventCode, refetchGroupEvent, navigate]);

  useEffect(() => {
    (async () => {
      if (
        loggedIn &&
        hasFinishedLoadingGroupEvents &&
        registerParam &&
        !organisation_registered
      ) {
        // Add artifical loading for nicer UX
        await new Promise((resolve) => setTimeout(resolve, 2000));
        register();
      }
    })();
  }, [
    registerParam,
    organisation_registered,
    hasFinishedLoadingGroupEvents,
    register,
    loggedIn,
  ]);

  const inAnHour = 1 / 24;

  const handleRegisterClick = useCallback(() => {
    if (!loggedIn) {
      Cookie.set('groupEventSignup', groupEventCode, { expires: inAnHour });
      redirectToLoginAndBack();
    } else if (needsToFinishSignup) {
      Cookie.set('groupEventSignup', groupEventCode, { expires: inAnHour });

      navigate(`/add-details`);
    } else if (organisation_registered) {
      if (
        id &&
        attendee_id &&
        organisationProfile &&
        organisationProfile.id !== 0
      ) {
        api.updateAttendeeJoinCallClicked(
          attendee_id,
          id,
          organisationProfile.id
        );
      }
      joinCall();
    } else {
      register();
    }
  }, [
    loggedIn,
    groupEventCode,
    organisation_registered,
    joinCall,
    needsToFinishSignup,
    inAnHour,
    register,
    navigate,
    attendee_id,
    id,
    organisationProfile,
  ]);

  const handleJoinClick = useCallback(() => {
    if (
      id &&
      attendee_id &&
      organisationProfile &&
      organisationProfile.id !== 0
    ) {
      api.updateAttendeeJoinCallClicked(
        attendee_id,
        id,
        organisationProfile.id
      );
    }
    joinCall();
  }, [id, attendee_id, organisationProfile, joinCall]);

  let metaTitle = 'Digital Boost';
  if (eventType && interest) {
    metaTitle = toTitleCase(interest?.name) + ' ' + toTitleCase(eventType);
  }

  const addToCalendarParam = getUrlParam('add_to_calendar') as string;
  if (addToCalendarParam && !loggedIn) {
    const urlToRedirect = `/login?redirect=${
      window.location.pathname + window.location.search
    }`;

    navigate(urlToRedirect);
  }

  return firstLoadFinished && !!volunteerProfile ? (
    <>
      <Helmet>
        <title>{metaTitle} | Register for free</title>
        <meta
          name="description"
          content={`Register to attend our free online ${metaTitle}`}
        ></meta>
      </Helmet>
      <div className="group-event-details__container">
        {socialShareModalVisible && (
          <div
            onClick={() => setSocialShareModalVisible(false)}
            className="group-event-details__modal-overlay"
          />
        )}
        <div className="group-event-details__card">
          <div className="group-event-details__header">
            <div className="group-event-details__header-text-container">
              <div className="group-event-details__title-container">
                <h1 className="group-event-details__title">
                  {toTitleCase(subject)}
                </h1>
                <h2 className="group-event-details__subtitle">
                  {eventType && eventType.toUpperCase()} ON{' '}
                  {subTitle.toUpperCase()}
                </h2>
              </div>
              {formattedDate && (
                <div className="group-event-details__header-date-container">
                  <div className="group-event-details__header-date-wrapper">
                    <span className="group-event-details__header-time-text">
                      {formattedDate?.time?.hour},{' '}
                    </span>
                    <span className="group-event-details__header-date-text">
                      {formattedDate?.date?.day} {formattedDate?.date?.month}{' '}
                      {formattedDate?.time?.timeZoneCode ||
                        formattedDate?.time?.timeZone}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="group-event-details__header-buttons-container">
              <div className="group-event-details__header-buttons-subcontainer">
                <div className="group-event-details__social-share-container">
                  <Button
                    type="secondary"
                    className={`group-event-details__social-share-button ${
                      partner &&
                      partner?.white_label &&
                      'group-event-details__social-share-button-border'
                    }`}
                    onClickFunction={() =>
                      setSocialShareModalVisible(!socialShareModalVisible)
                    }
                  >
                    <img src="/share.png" alt="share" />
                    <span>Share</span>
                  </Button>
                  {socialShareModalVisible && (
                    <SocialShareModal
                      eventType={eventType}
                      link={shareLink}
                      subject={subTitle}
                      name={`${volunteerProfile.first_name} ${
                        volunteerProfile.last_name
                          ? volunteerProfile.last_name
                          : ''
                      }`}
                      companyName={volunteerProfile.employer}
                      startTime={groupEvent.start_time}
                      onCloseClick={() => setSocialShareModalVisible(false)}
                      className="group-event-details__social-share-modal"
                    />
                  )}
                </div>

                {hasFinishedLoadingGroupEvents && organisation_registered && (
                  <>
                    {formattedDate && (
                      <div className="group-event-details__atc-button-container">
                        <AddGroupEventToCalendarButton
                          eventInfo={groupEvent}
                          timeZoneString={timezone || ''}
                        />
                        {registerSuccessPanelVisible && (
                          <img
                            src="curved-arrow.png"
                            alt="arrow"
                            className="group-event-details__atc-pointer-arrow"
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="group-event-details__register-button-container">
                {registerErrorMessage && (
                  <>
                    {registerErrorMessage.includes(
                      'Group event is at maximum capacity'
                    ) ? (
                      <span className="group-event-details__error">
                        We're very sorry but this Masterclass is now fully
                        booked. Visit our Events page to register for our other
                        Masterclasses and Workshops.
                      </span>
                    ) : (
                      <span className="group-event-details__error">{`Error: ${registerErrorMessage}`}</span>
                    )}
                  </>
                )}
                {organisation_registered &&
                startTimeIsInFuture &&
                !lessThan30MinutesUntilEvent ? (
                  <CustomThemeBackground
                    sx={{
                      fontWeight: ' 600',
                      color: 'white',
                      minWidth: '160px',
                      textAlign: 'center',
                      fontSize: '14px',
                      lineHeight: '24px',
                      padding: '10px 16px',
                      borderRadius: '30px',
                    }}
                  >
                    You are Registered!
                  </CustomThemeBackground>
                ) : null}
                {!organisation_registered && RegisterButtonEnabled ? (
                  <DashboardFilledButton
                    variant="contained"
                    sx={{ minWidth: '250px', alignSelf: 'flex-end' }}
                    onClick={handleRegisterClick}
                    disabled={organisation_registered}
                  >
                    {primaryCTAText}
                  </DashboardFilledButton>
                ) : null}
                {!organisation_registered && !RegisterButtonEnabled ? (
                  <div className="group-event-details__location-title">
                    Sorry, registrations are now closed
                  </div>
                ) : null}
                {organisation_registered ? (
                  <DashboardFilledButton
                    variant="contained"
                    sx={{ minWidth: '250px', alignSelf: 'flex-end' }}
                    onClick={handleJoinClick}
                    disabled={endTimeIsInPast || !lessThan30MinutesUntilEvent}
                  >
                    {joinCallText}
                  </DashboardFilledButton>
                ) : null}
                {organisation_registered && eventType && !endTimeIsInPast ? (
                  <CTAToolTip
                    text={`The button to join the ${toTitleCase(
                      eventType
                    )} will become active ${
                      hoursToActivateBeforeEventStarts * 60
                    } minutes before it begins.`}
                  />
                ) : null}
              </div>

              {registerSuccessPanelVisible && (
                <div className="group-event-details__register-success-panel">
                  <div className="group-event-details__register-success-panel-button-container">
                    <Button
                      text="&#x00D7;"
                      type="ghost"
                      onClickFunction={() =>
                        setRegisterSuccessPanelVisible(false)
                      }
                      className="group-event-details__register-success-panel-button"
                    />
                  </div>
                  <div className="group-event-details__register-success-panel-text-container">
                    <h2 className="group-event-details__register-success-panel-title">
                      Thank you!
                    </h2>
                    <p className="group-event-details__register-success-panel-text">
                      {`You have successfully registered for the ${toTitleCase(
                        subject
                      )} ${toTitleCase(eventType)} at ${
                        formattedDate?.time?.hour
                      } on ${formattedDate?.date?.day} ${
                        formattedDate?.date?.month
                      }.`}
                    </p>
                    <p className="group-event-details__register-success-panel-text">
                      {`We have sent you a confirmation email and have added it to your dashboard. Make sure to add it to your calendar and feel free to share it with your network.`}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="group-event-details__body">
            {registerSuccessPanelVisible && (
              <div
                className="group-event-details__panel-overlay"
                onClick={() => setRegisterSuccessPanelVisible(false)}
              />
            )}
            <div className="group-event-details__description-container">
              <h3>About this {toTitleCase(eventType)}</h3>
              {description && HtmlContent(description)}
              <div className="group-event-details__date-location-container">
                {formattedDate && (
                  <>
                    <span className="group-event-details__date-title">
                      Date and Time
                    </span>
                    <span className="group-event-details__date-text">
                      {formattedDate?.time?.day}, {formattedDate?.date?.day}{' '}
                      {formattedDate?.date?.month}
                    </span>
                    <span className="group-event-details__start-time-text">
                      {formattedDate?.time?.hour} -{' '}
                      {formattedDate?.endDate?.time?.hour},{' '}
                      {formattedDate?.time?.timeZoneCode ||
                        formattedDate?.time?.timeZone}
                    </span>
                  </>
                )}

                <span className="group-event-details__location-title">
                  Location
                </span>
                <span className="group-event-details__location-text">
                  Online Event
                </span>
                {groupEvent.sponsored_by ? (
                  <span className="group-event-details__location-title">
                    Sponsored by
                    <div
                      className="sponsor-logo"
                      style={{
                        backgroundImage: `url(${groupEvent.sponsored_by.partner_logo_url})`,
                      }}
                    ></div>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="group-event-details__volunteer-profile-container">
              {hasFinishedLoadingGroupEvents && volunteerProfile && (
                <VolunteerProfile
                  volunteerProfile={volunteerProfile}
                  compact
                  hideSkills
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="group-event-details__loading-container">
      {groupEventError ? (
        <div>
          {getErrorCodeFromResponse(groupEventError) === 404 ? (
            <div>
              <h1 className="group-event-details__error">
                {' '}
                Oops you found one of our closed events
              </h1>
              <DashboardFilledButton
                onClick={() => navigate('/dashboard/recommendations')}
              >
                Click here to see all of our open events
              </DashboardFilledButton>
            </div>
          ) : (
            <h1 className="group-event-details__error">
              {' '}
              {getErrorMessageFromResponse(groupEventError)}
            </h1>
          )}
        </div>
      ) : (
        <img
          className="group-event-details__loading-spinner"
          src="/loading.gif"
          alt="loading"
        />
      )}
    </div>
  );
};

export default GroupEventDetails;
