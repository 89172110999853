import { AxiosResponse } from 'axios';
import { apiInstanceUnprotected } from '../api/config';
// Note: using unprotected axios instance since we don't want to interept 401/404s on auth calls

type LoginProps = {
  email: string;
  password: string;
  partner?: number | null;
  signup_options?: number[] | null;
};
type LoginResponse = { access: string; refresh: string };

export const login = ({
  email,
  password,
}: LoginProps): Promise<AxiosResponse<LoginResponse>> =>
  apiInstanceUnprotected(`api/token/`, {
    method: 'POST',
    data: {
      username: email,
      password: password,
      user_type: 'organisation',
    },
  });

export const signup = async ({
  email,
  password,
  partner,
  signup_options,
}: LoginProps) =>
  apiInstanceUnprotected(`api/core/signup/`, {
    method: 'POST',
    data: {
      username: email,
      password: password,
      partner_id: partner,
      user_type: 'organisation',
      signup_options: signup_options,
    },
  });

export type ResendVerificationProps = {
  email: string;
};

export const resendVerification = ({ email }: ResendVerificationProps) =>
  apiInstanceUnprotected(`api/core/sendactivation/`, {
    method: 'POST',
    data: {
      email: email,
      user_type: 'organisation',
    },
  });

export type VerifyAccountProps = {
  id: string;
  token: string;
};

export const verifyAccountAction = ({
  id,
  token,
}: VerifyAccountProps): Promise<AxiosResponse<LoginResponse>> =>
  apiInstanceUnprotected(`api/core/verify/`, {
    method: 'POST',
    data: {
      user_id: id,
      confirmation_token: token,
    },
  });
