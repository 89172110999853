import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Button, Popover } from '@mui/material';
import { CustomTimeLabel } from 'pages/Dashboard/styling';
import { IoIosClose } from 'react-icons/io';
import useWindowSize from 'hooks/useWindowSize';
import { TabletBreakpoint, mobileBreakpoint } from 'styling/screenSizes';
type Props = {
  setNewDate: Dispatch<SetStateAction<Date | undefined>>;
  completeDaysArray: number[];
  position: number;
};

const SelectDate = ({ setNewDate, completeDaysArray, position }: Props) => {
  function disableWeekends(date: Date) {
    if (date.getDay() === 0) return completeDaysArray.includes(6);
    else return completeDaysArray.includes(date.getDay() - 1);
  }
  const [calendarDay, setCalendarDay] = useState<any>(null);
  useEffect(() => {});
  const setDate = (date: Date | null, save: string) => {
    if (save === 'calendar') {
      setCalendarDay(date);
      if (date) setNewDate(date);
    }
  };
  const { width } = useWindowSize();
  const isMobile = width < mobileBreakpoint;
  const isTablet = width < TabletBreakpoint && width > mobileBreakpoint;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div>
        <Box sx={{ width: isMobile ? 'auto' : 260, display: 'flex' }}>
          <Button
            sx={{
              height: '50px',
              textTransform: 'none',
              display: 'flex',
              justifyContent: 'space-between',
              minWidth: isTablet ? '320px' : '262px',
              borderRadius: '16px',
              fontFamily: 'Gilroy',
              fontSize: '13px',
              fontWeight: '500',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              lineHeight: '15px',
              color: '#181F39',
            }}
            endIcon={
              calendarDay ? (
                <IoIosClose
                  size={20}
                  onClick={() => {
                    setCalendarDay(undefined);
                    setNewDate(undefined);
                  }}
                />
              ) : null
            }
            aria-describedby={id}
            variant="outlined"
            onClick={handleClick}
          >
            {calendarDay ? (
              `#${position} ` + calendarDay.toString().slice(0, 10)
            ) : (
              <CustomTimeLabel sx={{ textAlign: 'left', width: '100%' }}>
                #{position} Click to select a date
              </CustomTimeLabel>
            )}
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <DatePicker
              dateFormat="Pp"
              selected={calendarDay}
              onChange={(date) => {
                setDate(date, 'calendar');
                handleClose();
              }}
              minDate={new Date(moment().add('days', 3).toDate())}
              disabledKeyboardNavigation
              inline
              filterDate={disableWeekends}
              renderCustomHeader={({
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ padding: '0 14px' }}
                >
                  <div className="calendar-date">
                    {moment(date).format('MMMM YYYY')}
                  </div>
                  <Grid>
                    <button
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      <IoIosArrowBack size={14} />
                    </button>
                    <button
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      <IoIosArrowForward size={14} />
                    </button>
                  </Grid>
                </Grid>
              )}
            />
          </Popover>
        </Box>
      </div>
    </div>
  );
};

export default SelectDate;
