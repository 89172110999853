import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MessageActionButton from '../../MessageActionButton';

import './style.scss';

interface MessageCenterProps {
  info: any;
  clickRequest?: () => void;
  clickBlock?: () => void;
  blocked: boolean;
  isMobile: boolean;
  clickBack: () => void;
  clickImage: () => void;
}

const MessageCenterHeader = ({
  info,
  clickRequest,
  clickBlock,
  blocked,
  isMobile,
  clickBack,
  clickImage,
}: MessageCenterProps) => (
  <div className="message-center-header-container">
    <div className="message-center-header-wrapper">
      {isMobile && (
        <button className="message-center-mobile-back" onClick={clickBack}>
          <ArrowBackIosIcon />
        </button>
      )}
      <img
        className="message-center-header-photo"
        src={info?.volunteer?.profile_picture || 'avatar.png'}
        alt="User"
        onClick={clickImage}
      />
      <div className="message-center-header-name" onClick={clickImage}>
        {info?.volunteer?.first_name} {info?.volunteer?.last_name}
      </div>
    </div>

    <div className="message-center-header-wrapper">
      <div className="message-center-header-request" onClick={clickRequest}>
        {isMobile
          ? 'Request a session'
          : `Request a session with ${info?.volunteer?.first_name}`}
      </div>
      <MessageActionButton
        clickBlock={clickBlock && clickBlock}
        blocked={blocked}
      />
    </div>
  </div>
);

export default MessageCenterHeader;
