import apiInstance from './config';

export const getEthnicityList = () => {
  return apiInstance
    .get(`api/ethnicity/ethnicity/?limit=1000`, {})
    .then((response: any) => {
      return response;
    })
    .catch((err: any) => err);
};
