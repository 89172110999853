import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { logout } from '../auth';
import type { RootState } from '../../store';
import type { Partner, PartnersResponse } from '../../types';
import { getUrlParam } from 'utils/url';
import { getAccessTokenCookie } from 'utils/auth';
import apiInstance from 'api/config';

/* --- SLICE --- */

interface PartnerState {
  status: 'init' | 'pending' | 'fulfilled' | 'rejected';
  profile?: Partner | null;
  error?: string | null;
}

const initialState = {
  status: 'init',
  profile: null,
  error: null,
} as PartnerState;

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    clearPartner: (state) => {
      return initialState;
    },
    setPartner: (state, action) => {
      state.profile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // logout
      .addCase(logout, () => initialState)
      // fetchPartner
      .addCase(fetchPartnerAction.pending, (state, action) => {
        state.status = action.meta.requestStatus;
        state.error = null;
      })
      .addCase(fetchPartnerAction.fulfilled, (state, action) => {
        state.status = action.meta.requestStatus;
        state.profile = action.payload;
      })
      .addCase(fetchPartnerAction.rejected, (state, action) => {
        const { error } = action;
        state.error = error.message;
        state.status = action.meta.requestStatus;
      });
  },
});

/* --- SELECTORS --- */

// fetchPartner
export const selectPartner = (state: RootState) => state.partner.profile;
export const selectPartnerStatus = (state: RootState) => state.partner.status;
export const selectHasNotFetchedPartner = (state: RootState) =>
  selectPartnerStatus(state) === 'init';
export const selectHasFinishedLoadingPartner = (state: RootState) =>
  selectPartnerStatus(state) === 'fulfilled' ||
  selectPartnerStatus(state) === 'rejected';

/* --- ACTIONS --- */

export const { clearPartner, setPartner } = partnerSlice.actions;

/* --- THUNKS --- */

// fetchPartner
export const fetchPartnerAction = createAsyncThunk(
  `${partnerSlice.name}/fetchPartner`,
  async (arg, thunkAPI) => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const hostnameParts = url.hostname.split('.');
    let subdomain = hostnameParts[0];

    if (subdomain === 'www') {
      subdomain = hostnameParts[1];
    }
    const loggedIn = getAccessTokenCookie();
    let partnerId = !loggedIn ? parseInt(getUrlParam('id') as string) : null;

    // don't even bother checking if it's a premium partner if the subdomain is localhost or organistaion (no need to waste the API call).
    if (
      (subdomain !== 'organisation' && subdomain !== 'localhost:3010') ||
      partnerId
    ) {
      const partnerResponse = await apiInstance.get<PartnersResponse>(
        `/api/partner/partners/search/?subdomain=${subdomain}`
      );
      const partnerData = partnerResponse.data.results[0];

      return partnerData;
    }
  }
);

export default partnerSlice.reducer;
