import React, { useState, useEffect, useMemo } from 'react';
import { getUrlParam } from '../../utils/url';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import api from 'api';
import Button from 'components/Button';
import useSelectProfileData from 'hooks/useSelectProfileData';
import { getAvailability, timeZoneAbbreviation } from 'utils/time';
import RadioButton from 'components/RadioButton';
import ReactTooltip from 'react-tooltip';
import { DashboardFilledButton } from 'styling/buttons';
import PageNotFound from 'pages/PageNotFound';
import FullScreenLoading from 'components/FullScreenLoading';
import VolunteerNotAvailable from './VolunteerNotAvailable';

const daysOfWeek: { [arg: number]: string } = {
  0: 'Mon',
  1: 'Tue',
  2: 'Wed',
  3: 'Thu',
  4: 'Fri',
  5: 'Sat',
  6: 'Sun',
};

const NewVolunteerDetails = () => {
  const followUpId = getUrlParam('follow_up');
  const isFollowUp = !!followUpId;
  const [isLoading, setIsLoading] = useState(true);
  const keyword = getUrlParam('keyword');

  const { userLocation, organisationProfile } = useSelectProfileData();
  const { timezone = Intl.DateTimeFormat().resolvedOptions().timeZone } =
    userLocation || {};
  let navigate = useNavigate();
  const volunteerId = getUrlParam('volunteer') as string[];

  const [volunteer, setVolunteer] = useState<any>(null);
  const [interests, setInterests] = useState<any>(null);
  const [aboutMeExpanded, setAboutMeExpanded] = useState<boolean>(false);
  const [selectedInterest, setSelectedInterest] = useState<any>(null);

  useEffect(() => {
    async function getVolunteerInfo() {
      try {
        const volunteer = await api.searchUniqueMentor(volunteerId, 1);
        const taxonomy = await api.getTaxonomy(
          volunteer?.results?.[0]?.interests_ids
        );
        const array: any = [];
        taxonomy?.flat()?.forEach((item: any) => {
          array.push(item.child);
        });

        const interests = array
          ?.flat()
          ?.sort((a: any, b: any) =>
            a.order === b.order ? 0 : a.order > b.order ? 1 : -1
          );

        setInterests(interests);
        volunteer.count === 0
          ? setVolunteer(null)
          : setVolunteer(volunteer?.results?.[0]);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    }

    if (volunteerId) {
      getVolunteerInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [interests]);

  const time = useMemo(() => {
    if (volunteer) {
      const { availabilityStartTimeLocal, availabilityEndTimeLocal } =
        getAvailability(
          { availabilityObj: volunteer?.availability },
          volunteer?.timezone,
          timezone
        );

      return { availabilityStartTimeLocal, availabilityEndTimeLocal };
    }
  }, [timezone, volunteer]);
  const userTimezone = timeZoneAbbreviation(timezone);

  const body = (
    <div className="dashboard-container-info">
      <div className="vol-details-container">
        <div className="vol-details-header">
          <img
            src={volunteer?.profile_picture || 'avatar.png'}
            alt="volunteer profile"
            className="vol-details-photo"
          />
        </div>

        <div className="vol-details-info-container">
          <div className="vol-details-info-left">
            <div className="vol-details-name">
              {volunteer?.first_name} {volunteer?.last_name}
            </div>
            <div className="vol-details-employer">
              {volunteer?.current_role} | {volunteer?.employer}
            </div>

            {volunteer?.summary?.length && volunteer?.summary?.length > 0 ? (
              <>
                <div className="vol-details-label">About Me</div>
                {volunteer?.summary && volunteer?.summary?.length < 180 ? (
                  <div className="vol-details-summary">
                    {volunteer?.summary}
                  </div>
                ) : (
                  <>
                    <div className="vol-details-summary">
                      {aboutMeExpanded
                        ? volunteer?.summary
                        : `${volunteer?.summary?.substring(0, 180)}...`}
                    </div>
                    <Button
                      type="link"
                      text={aboutMeExpanded ? 'Read less' : 'Read more'}
                      onClickFunction={() =>
                        setAboutMeExpanded(!aboutMeExpanded)
                      }
                      className="vol-details-read-button"
                    />
                  </>
                )}
              </>
            ) : null}

            {volunteer?.languages && volunteer?.languages?.length > 0 ? (
              <>
                <div className="vol-details-label">Languages</div>
                <div className="vol-details-summary">
                  {volunteer?.languages.map((l: string, index: number) => (
                    <span key={`language-${index}`}>{`${l}${
                      volunteer?.languages &&
                      index < volunteer?.languages?.length - 1
                        ? ', '
                        : ''
                    }`}</span>
                  ))}
                </div>
              </>
            ) : null}
          </div>

          <div className="vol-details-info-right">
            <div className="vol-details-label">Availability</div>
            <div className="vol-details-day-wrapper">
              {Object.entries(daysOfWeek).map(([i, day], index) => (
                <div key={`${volunteer?.id}-day-${index}`}>
                  <div
                    className={
                      !!volunteer?.availability?.days?.includes(Number(i))
                        ? 'vol-details-day-tag'
                        : 'vol-details-day-tag vol-details-day-tag-disabled'
                    }
                  >
                    {day}
                  </div>
                </div>
              ))}
            </div>

            <div className="vol-details-label">Time</div>
            <div className="vol-details-time">
              {time?.availabilityStartTimeLocal} to{' '}
              {time?.availabilityEndTimeLocal} {userTimezone}
            </div>

            <div className="vol-details-divider" />

            <div className="vol-details-help">
              {volunteer?.first_name} can help on the following topics. Please
              select one to discuss in this session.
            </div>

            <div className="vol-details-interests-wrapper">
              {interests?.map((interest: any) => (
                <div
                  className="vol-details-interests"
                  onClick={() => setSelectedInterest(interest)}
                >
                  <RadioButton
                    rebook
                    selected={selectedInterest?.id === interest?.id}
                  />
                  <div>
                    {interest?.name}{' '}
                    <span
                      className="vol-details-question"
                      data-tip={interest?.description}
                    >
                      <img
                        width="5px"
                        height="8px"
                        src="/question.png"
                        alt="question"
                      />
                    </span>
                  </div>
                </div>
              ))}
            </div>

            <div className="vol-details-request-container">
              <DashboardFilledButton
                sx={{ width: '180px' }}
                variant="contained"
                onClick={() =>
                  organisationProfile
                    ? navigate(
                        `/book-session?volunteer=${volunteer?.id}&interest=${
                          selectedInterest?.id
                        }&rebook=true${
                          isFollowUp ? `&follow_up=${followUpId}` : ''
                        }&keyword=${keyword ? keyword : 'sharable-profile'}`
                      )
                    : navigate(
                        `/login?redirect=${encodeURIComponent(
                          `/volunteer-details?volunteer[]=${volunteer?.id}`
                        )}`
                      )
                }
                disabled={!selectedInterest}
              >
                Request a session
              </DashboardFilledButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  if (isLoading) return <FullScreenLoading />;
  else if (volunteer === undefined || interests === undefined)
    return <PageNotFound />;
  else if (volunteer === null) return <VolunteerNotAvailable />;
  return (
    <>
      <ReactTooltip
        key="mentor-search-filters"
        className="tooltip"
        place={'top'}
        arrowColor={'#333333'}
        multiline={true}
      />
      {body}
    </>
  );
};

export default NewVolunteerDetails;
