import React from 'react';
import Box from '@mui/material/Box';
import { CustomFormLabel, CustomTextField } from 'styling/generalStyling';

type Props = {
  setValue: (value: any, input: string) => void;
  value?: {
    website: string;
  };
};
export default function EditWebsiteForm({ value, setValue }: Props) {
  const [error, setError] = React.useState('');
  return (
    <div>
      <div className="error">{error}</div>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <CustomTextField
          id="outlined-basic"
          label={<CustomFormLabel>Website</CustomFormLabel>}
          defaultValue={value ? value : null}
          onChange={(event) => {
            if (
              event.target.value !== undefined &&
              !(
                event.target.value.startsWith('http') ||
                event.target.value.startsWith('www')
              )
            )
              setError('Please introduce a valid website');
            else setError('');
            setValue(event.target.value, 'website');
          }}
          variant="outlined"
        />
      </Box>
    </div>
  );
}
