import React from 'react';
import './tag.scss';
import { IoIosClose } from 'react-icons/io';
import shortId from 'shortid';
interface Props {
  text: string;
  onRemove?: () => void;
  smallCloseButton?: boolean;
  type?: 'primary' | 'secondary' | 'tertiary';
  hoverType?: 'primary' | 'secondary' | 'tertiary';
  onClick?: () => void;
  className?: string;
  dataCy?: string;
}
const Tag = ({
  text,
  type = 'primary',
  hoverType,
  onClick,
  className,
  dataCy,
  onRemove,
  smallCloseButton,
}: Props) => {
  const dataCyProps = dataCy ? { 'data-cy': dataCy } : {};
  return (
    <span
      key={shortId.generate()}
      className={`tag ${type} ${!!onClick ? 'tag__clickable' : ''} ${
        className ? className : ''
      } ${hoverType ? `tag__hover-type--${hoverType}` : ''} ${
        smallCloseButton ? 'tag__padding-right' : ''
      }`}
      onClick={onClick || onRemove || (() => {})}
      data-cy="selected-skill"
      {...dataCyProps}
    >
      {text}{' '}
      {onRemove && (
        <IoIosClose
          size={22}
          className={`${
            smallCloseButton ? 'tag__close-button--small' : 'tag__close-button'
          }`}
          onClick={onRemove}
        />
      )}
    </span>
  );
};
export default Tag;
