import React, { useState } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useGetIndustries from '../../../apiHooks/useGetIndustries';

type Props = {
  setValue: (value: any, input: string) => void;
  value?: number;
};

export default function EditSectorForm({ value, setValue }: Props) {
  const [industry, seIndustry] = useState<number>(value ? value : 1);
  const { industries: industryList } = useGetIndustries();

  const handleChange = (event: any) => {
    seIndustry(event.target.value);
    setValue(event.target.value, 'industry');
  };
  return (
    <Box sx={{ width: 240 }}>
      <FormControl fullWidth>
        <Select
          MenuProps={{ transitionDuration: 0 }}
          labelId="ethnicity-select-label"
          id="ethnicity-select"
          value={industry}
          onChange={handleChange}
          sx={{ fontFamily: 'Gilroy' }}
        >
          {industryList.map((ind) => (
            <MenuItem
              sx={{
                '&.MuiMenuItem-root': {
                  fontFamily: 'Gilroy',
                },
              }}
              key={ind.id}
              value={ind.id}
            >
              {ind.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
