import useFetchUserProfile from '../features/userProfile/hooks/useFetchUserProfile';
import useFetchUserLocation from '../features/userLocation/hooks/useFetchUserLocation';
import useFetchOrganisationProfile from '../features/organisationProfile/hooks/useFetchOrganisationProfile';
import useFetchPartner from '../features/partner/hooks/useFetchPartner';

type Props = { autoFetch: boolean };

// Used to access state.userProfile, state.userLocation & state.organisationProfile
// But does not automatically fire the actions to make the API calls
const useSelectProfileData = ({ autoFetch }: Props = { autoFetch: false }) => {
  // USER PROFILE
  const {
    userProfile,
    fetchUserProfile,
    setUserProfile,
    clearUserProfile,
    hasFinishedLoadingUserProfile,
  } = useFetchUserProfile({
    autoFetch,
  });

  // PARTNER
  const { partner, fetchPartner, hasFinishedLoadingPartner } = useFetchPartner({
    autoFetch,
  });

  // USER LOCATION
  const {
    userLocation,
    fetchUserLocation,
    setUserLocation,
    clearUserLocation,
    hasFinishedLoadingUserLocation,
  } = useFetchUserLocation({ autoFetch });

  // ORG PROFILE
  const {
    organisationProfile,
    fetchOrganisationProfile,
    setOrganisationProfile,
    clearOrganisationProfile,
    needsToFinishSignup,
    hasFinishedLoadingOrganisationProfile,
  } = useFetchOrganisationProfile({ autoFetch });

  // FETCH ALL
  const fetchAllProfileData = async () => {
    const [
      userProfileResult,
      userLocationResult,
      orgProfileResult,
      fetchPartnerResult,
    ] = await Promise.allSettled([
      fetchUserProfile(),
      fetchUserLocation(),
      fetchOrganisationProfile(),
      fetchPartner(),
    ]);

    return {
      userProfileResult,
      userLocationResult,
      orgProfileResult,
      fetchPartnerResult,
    };
  };

  const hasFinishedFetchingProfileAndLocation =
    hasFinishedLoadingUserProfile &&
    hasFinishedLoadingUserLocation &&
    hasFinishedLoadingOrganisationProfile &&
    hasFinishedLoadingPartner;

  return {
    userProfile,
    userLocation,
    organisationProfile,
    partner,
    fetchAllProfileData,
    hasFinishedFetchingProfileAndLocation,
    needsToFinishSignup,
    hasFinishedLoadingOrganisationProfile,
    setUserProfile,
    clearUserProfile,
    setUserLocation,
    clearUserLocation,
    setOrganisationProfile,
    clearOrganisationProfile,
    hasFinishedLoadingPartner,
  };
};

export default useSelectProfileData;
