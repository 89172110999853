import { useAxios } from '../../api/config';

const useGetRecommendedMentors = () => {
  const [{ data, loading }, refetch] = useAxios<any>({
    url: `api/recommendation/recommendedvolunteers/`,
  });

  return {
    recommendedResultsData: data?.results || [],
    hasFinishedLoadingRecommendedMentors: !loading,
    refetchRecommendedData: refetch,
  };
};

export default useGetRecommendedMentors;
