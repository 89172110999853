import useSelectProfileData from '../../hooks/useSelectProfileData';

export function useGetPreferences() {
  const { userProfile, userLocation, organisationProfile } =
    useSelectProfileData();

  let profile = { ...organisationProfile, ...userProfile, ...userLocation };
  const {
    availability,
    industries,
    interests,
    partner,
    postcode,
    languages,
    id,
    user,
    is_beta_user,
    region,
    privacy_preferences,
  } = profile;

  const updatedPreferences = {
    availability,
    privacy_preferences,
    industries,
    interests,
    partner,
    postcode,
    languages,
    id,
    user,
    is_beta_user,
    region,
  };
  return updatedPreferences;
}
