import Cookie from 'js-cookie';
import apiInstance from './config';
import api from '.';
import { getErrorMessageFromResponse } from '../utils/error';
import { FollowUpRequestResponse } from '../types';

type SubmitRequestProps = {
  params: {
    skillIds: number[];
    volunteerIds: number[];
    timeSlots: any[];
    descriptionText: string;
    followUpId?: number;
    accessibility_req?:string|undefined;
    partner: null | number;
    topPrioritySkill?: number;
    ignoreVolunteersAvailability?: boolean;
    chooseForMe: boolean;
    allowMultipleSessions?: boolean;
    interestExpertise?: string;
    recommendedId?: string | undefined;
    mentoringKind?: 'one_off_mentoring' | 'ongoing_mentoring';
    acceptSubstitutes?: boolean;
    keyword?: string;
  };
  onSuccess: () => void;
  onError: (arg: any) => void;
  onAccessPaused: () => void;
};

// Note: We are gradually moving away from individual axios calls toward calls made with axios-hooks
// axios-hooks allows for automatica management fo call-state (e.g. loading, error etc.), as well as grouping related business logic together
// As such new api calls should be created using axios-hooks - not in the /apiCalls/ directory
// See /apiHooks/ directory for examples

export const submitRequest = ({
  params,
  onSuccess,
  onError,
  onAccessPaused,
}: SubmitRequestProps) => {
  const {
    skillIds,
    volunteerIds,
    timeSlots,
    accessibility_req,
    descriptionText,
    followUpId,
    partner,
    topPrioritySkill,
    chooseForMe,
    ignoreVolunteersAvailability = false,
    allowMultipleSessions = true,
    interestExpertise,
    recommendedId,
    mentoringKind,
    acceptSubstitutes,
    keyword,
  } = params;

  const followUpParams = followUpId ? { followup_request: followUpId } : {};
  const chooseForMeValue = followUpId ? false : chooseForMe;
  const keywordValue = chooseForMeValue ? 'choose-for-me' : keyword;

  apiInstance
    .post(
      `api/event/boostcallrequests/`,
      {
        help_needed: descriptionText,
        interests: skillIds,
        invited_volunteers: volunteerIds,
        time_slots: timeSlots,
        top_priority: topPrioritySkill,
        partner: partner,
        ignore_volunteers_availability: ignoreVolunteersAvailability,
        allow_multiple_sessions: allowMultipleSessions,
        interest_expertise: interestExpertise,
        recommended_volunteer: recommendedId,
        accessibility_req,
        ...followUpParams,
        mentoring_kind: mentoringKind,
        accept_substitutes: acceptSubstitutes,
        keyword: keywordValue,
        choose_for_me: chooseForMeValue,
      },
      {
        headers: { Authorization: `Bearer ${Cookie.get('accessOrgTokenDB')}` },
      }
    )
    .then((response: any) => {
      if (response.status === 200 || response.status === 201) {
        Cookie.remove('draftBoostCallUUID', { path: '/' });
        onSuccess();
      }
    })
    .catch((err: any) => {
      const errorMessage = getErrorMessageFromResponse(err);
      if (errorMessage === 'Access Paused.') {
        onAccessPaused();
      } else if (errorMessage.includes('This field must be unique')) {
        onError('A call request has already been made');
      } else {
        onError(errorMessage);
      }

      return err;
    });
};

export const getAllFollowUpRequests = async () => {
  // can we update API to allow follow_up_status= url param?
  const { data } = await apiInstance.get<FollowUpRequestResponse>(
    `api/netpromoterscore/followuprequest/?limit=1000`
  );
  return data;
};

export const acceptFollowUpRequest = async (
  selectedSkillList: number[],
  selectedMentorsList: number[],
  onDraftComplete: () => void
) => {
  await api.submitDraftBoostCall({ selectedSkillList, selectedMentorsList });
  onDraftComplete();
};

export const declineFollowUpRequest = async (
  followUpId: string | number,
  onDecline: () => void
) => {
  await apiInstance.patch(
    `api/netpromoterscore/followuprequest/${followUpId}/`,
    {
      follow_up_status: 'declined',
    }
  );
  onDecline();
};

export const acceptProposedTime = async (
  code: string,
  selectedTimeSlot: any,
  callURL: string | undefined
) => {
  return apiInstance.post(
    `api/event/boostcallinvites/${code}/acceptinvite/`,
    {
      time_slot: selectedTimeSlot,
      call_url: callURL,
    },
    {}
  );
};

type DeclineProps = {
  code: string;
};

export const declineProposedTime = ({ code }: DeclineProps) => {
  apiInstance.post(
    `api/event/boostcallinvites/${code}/declineinvite/`,
    { reason: 'unavailable' },
    {}
  );
};
