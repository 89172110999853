import { useAxios } from '../../api/config';
import { getErrorMessageFromResponse } from '../../utils/error';

type SendPasswordResetProps = {
  email: string;
  token: string;
  partnerId?: number | null;
};

const useSendPasswordReset = () => {
  const [{ data, loading, error }, executeSendPasswordReset] = useAxios(
    {
      url: `api/core/forgotpassword/`,
      method: 'POST',
      headers: {},
    },
    { manual: true }
  );

  const typedSendPasswordReset = async ({
    email,
    token,
    partnerId,
  }: SendPasswordResetProps) => {
    const sendPasswordResetResponse = await executeSendPasswordReset({
      data: {
        username: email,
        token: token,
        partner_id: partnerId,
      },
    });
    return sendPasswordResetResponse;
  };

  return {
    sendPasswordReset: typedSendPasswordReset,
    sendPasswordResetResponseData: data,
    isLoadingSendPasswordReset: loading,
    sendPasswordResetError: error ? getErrorMessageFromResponse(error) : null,
  };
};

export default useSendPasswordReset;
