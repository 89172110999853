import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

/* --- SLICE --- */

interface SessionTitleState {
  title: string | null;
}

const initialState: SessionTitleState = {
  title: null,
};

const sessionTitleSlice = createSlice({
  name: 'sessionTitle',
  initialState,
  reducers: {
    setSessionTitle: (state, action) => {
      state.title = action.payload;
    },
    clearSessionTitle: (state) => {
      state.title = null;
    },
  },
});

/* --- SELECTORS --- */

export const selectSessionTitle = (state: RootState) =>
  state.sessionTitle.title;

/* --- ACTIONS --- */

export const { setSessionTitle, clearSessionTitle } = sessionTitleSlice.actions;

export default sessionTitleSlice.reducer;
