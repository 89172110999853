import React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';

import { DashboardEmptyButton, DashboardFilledButton } from 'styling/buttons';
import useWindowSize from 'hooks/useWindowSize';
import { deleteActionTask } from 'api/actionPlan';

interface DeleteCardPopupProps {
  open: boolean;
  onClose: () => void;
  deleteCardId: number;
  setCounterChange: () => void;
}

const DeleteCardPopup: React.FC<DeleteCardPopupProps> = ({
  open,
  onClose,
  deleteCardId,
  setCounterChange,
}) => {
  const deleteCard = async () => {
    await deleteActionTask(deleteCardId.toString())
      .then((response) => {
        if (response.success) {
          setCounterChange();
          onClose();
        } else {
          console.error('Error deleting task:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error in task:', error);
      });
    handleClose();
  };
  const handleClose = () => {
    onClose();
  };
  const { isMobile } = useWindowSize();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        borderRadius: '10px',
        border: '1px solid #A3A3A3',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.15)',
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          paddingTop: isMobile ? '50px' : '63px',
          width: isMobile ? '250px' : '550px',
          height: isMobile ? 'auto' : '200px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '20px',
          }}
        >
          <div className="popup-text">
            Are you sure you want to delete this card?
          </div>
          <div>
            <DashboardFilledButton
              variant="contained"
              sx={{
                width: isMobile ? '80px' : '189px',
                height: isMobile ? '35px' : '48px',
              }}
              onClick={deleteCard}
            >
              Yes
            </DashboardFilledButton>
            <DashboardEmptyButton
              variant="outlined"
              sx={{
                width: isMobile ? '80px' : '189px',
                height: isMobile ? '35px' : '48px',
                marginLeft: isMobile ? '10px' : '22px',
              }}
              onClick={onClose}
            >
              No
            </DashboardEmptyButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteCardPopup;
