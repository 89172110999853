import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import './style.scss';
import '../components/styling/card-styling.scss';
import { formatDate } from '../../../utils/time';
import { TimeSlot } from '../../../types';

import '../components/styling/card-styling.scss';
import { TimeSlotButton } from '../NewUpcomingCard/style';
import { DashboardFilledButton } from 'styling/buttons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from 'features/reduxStore/sessionTitle';

interface Props {
  appointment: any;
  timeZoneString: string;
}

const NewRequestedCard = ({ appointment, timeZoneString }: Props) => {
  const navigate = useNavigate();
  const { name: orgName } = appointment.organisation || {};
  const id = appointment.id;
  const sessionTitle = useSelector(selectSessionTitle);
  const hasProposedTimeslot = appointment?.time_slots?.filter(
    (itm: TimeSlot) => itm.status === 'time_slot_proposed'
  );

  return (
    <div className="requests-box">
      <Box
        sx={{
          '& > :not(style)': {
            width: '100%',
            height: '100%',
          },
        }}
      >
        <Paper
          key={id}
          sx={{
            borderRadius: '8px',
            boxShadow: '8px 5px 30px 0 rgba(0, 0, 0, 0.06)',
          }}
        >
          <div>
            <div className="rectangle-icon-container">
              <div className="rectangle-icon">
                <div className="rectangle-icon-text">Requested</div>
              </div>
            </div>
            <div className="dashboard-card__container">
              <div className="profile-photo-container">
                <img
                  className="profile-photo-card"
                  src={'/avatar.png'}
                  alt={'profile'}
                />
              </div>
              <div className="dashboard-card-header-info">
                <div className="dashboard-card-title">
                  <div className="dashboard-card-name">
                    Requested {sessionTitle}
                  </div>
                </div>
                <div className="request-paper-organisation">{orgName}</div>
                <div className="request-paper-topic">
                  {appointment.top_priority_name}
                </div>

                <div className="request-paper-slots">
                  {hasProposedTimeslot?.length > 0
                    ? 'You requested a call for the following time'
                    : 'Requested timeslots'}
                </div>

                <div
                  className={
                    hasProposedTimeslot?.length > 0
                      ? 'request-paper-time-slots-proposed'
                      : 'request-paper-time-slots'
                  }
                >
                  {appointment.time_slots
                    .filter(
                      (itm: TimeSlot) => itm.status === 'time_slot_pending'
                    )
                    .map((timeSlot: TimeSlot) => {
                      const dateFormatted = formatDate(
                        timeSlot.start_time,
                        timeZoneString
                      );

                      const time = {
                        time: dateFormatted?.time,
                        date: dateFormatted?.date,
                      };

                      return (
                        <div className="requested-paper-slots">
                          {time.date?.day} {time.date?.monthThreeLetterFormat}{' '}
                          {time.time?.hour}{' '}
                        </div>
                      );
                    })}
                </div>

                {hasProposedTimeslot?.length > 0 ? (
                  <>
                    <div className="request-paper-slots">
                      A volunteer has proposed an alternative time:
                    </div>
                    <div className="request-paper-time-slots">
                      {appointment.time_slots.map((timeSlot: TimeSlot) => {
                        const dateFormatted = formatDate(
                          timeSlot.start_time,
                          timeZoneString
                        );

                        const time = {
                          time: dateFormatted?.time,
                          date: dateFormatted?.date,
                        };

                        return timeSlot?.status === 'time_slot_proposed' ? (
                          <TimeSlotButton
                            variant="outlined"
                            onClick={() =>
                              navigate(
                                `/call-details?type=requests&cid=${appointment.id}&time_slot_id=${timeSlot.id}`
                              )
                            }
                          >
                            {time.date?.day} {time.date?.monthThreeLetterFormat}{' '}
                            {time.time?.hour}{' '}
                          </TimeSlotButton>
                        ) : null;
                      })}
                    </div>
                  </>
                ) : null}

                <div className="request-paper-button-wrapper">
                  <div className="requested-dashboard-button-container">
                    <DashboardFilledButton
                      sx={{ width: '180px' }}
                      variant="contained"
                      onClick={() =>
                        navigate(
                          `/call-details?type=requests&cid=${appointment.id}`
                        )
                      }
                    >
                      View Details
                    </DashboardFilledButton>
                  </div>{' '}
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default NewRequestedCard;
