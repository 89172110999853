import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import useSelectProfileData from '../../hooks/useSelectProfileData';

import './access-paused.scss';

const AccessPaused = () => {
  const navigate = useNavigate();
  const [accessPauseEnds, setAccessPauseEnds] = useState<string | undefined>(
    ''
  );
  const { organisationProfile, hasFinishedFetchingProfileAndLocation } =
    useSelectProfileData();

  useEffect(() => {
    if (!!hasFinishedFetchingProfileAndLocation) {
      const { access_pause_end } = organisationProfile || {};
      setAccessPauseEnds(access_pause_end);
    }
  }, [organisationProfile, hasFinishedFetchingProfileAndLocation, navigate]);

  return hasFinishedFetchingProfileAndLocation ? (
    <div className="access-paused">
      <div className="border" />

      <div className="access-paused-message">
        <h2>Access Paused</h2>

        <div>
          We are sorry to say that your ability to request mentoring sessions is
          on pause just now due to violations of our code of conduct regarding
          meeting attendance.
        </div>
        {!accessPauseEnds ? (
          <div>
            You can get in touch via our{' '}
            <a href="https://www.digitalboost.org.uk/contact">Contact Form</a>.
            We invite you to consider joining a workshop or online course in the
            meantime. Please find our mentoring Code of Conduct{' '}
            <a href="https://www.digitalboost.org.uk/faq/mentees#Etiquette">
              here
            </a>
            .
          </div>
        ) : (
          <div>
            Our records show that your mentoring request access will be
            re-instated on {new Date(accessPauseEnds).toLocaleDateString()}, and
            we invite you to consider joining a workshop or online course in the
            meantime. Please find our mentoring Code of Conduct{' '}
            <a href="https://www.digitalboost.org.uk/faq/mentees#Etiquette">
              here
            </a>
            .
          </div>
        )}
        <div>
          Thank you for understanding, <br />
          The Digital Boost team.
        </div>

        <div className="button-group">
          <Button
            type={'secondary'}
            onClickFunction={() => {
              navigate('/account');
            }}
            text={'My Account'}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="access-paused__loading-container">
      <img
        className="access-paused__loading-spinner"
        src="/loading.gif"
        alt="loading"
      />
    </div>
  );
};

export default AccessPaused;
