import { useAxios } from '../../api/config';
import {
  BoostCallEvents,
  BoostCallEventWithVolunteerProfile,
} from '../../types';

const useGetGroupEventsCompletedAndRegistered = () => {
  const [{ data, loading, error }, refetch] = useAxios<BoostCallEvents>({
    url: `api/groupevent/groupevent/?status=complete&organisation_registered=True&limit=400`,
  });
  return {
    boostCallGroupEventsCompleted:
      (data?.results as BoostCallEventWithVolunteerProfile[]) || [],
    boostCallGroupEventsCompletedCount: data?.count || 0,
    hasFinishedLoadingBoostCallGroupEventsCompleted: !loading,
    boostCallGroupEventsCompletedError: error,
    refetchBoostCallGroupEventsCompleted: refetch,
  };
};

export default useGetGroupEventsCompletedAndRegistered;
