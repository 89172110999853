import React, { useEffect, useState } from 'react';
import './styling/bookings.scss';
import MasterclassEmptyPage from './masterclassEmptyPage';
import NewRecordingCard from '../NewRecordingCard';
import Tab from '@mui/material/Tab';
import useWindowSize from 'hooks/useWindowSize';
import {
  CustomRequestsLabel,
  CustomMobileRequestsLabel,
  LoadMoreButton,
} from '../styling';
import { Box, IconButton, styled, Tabs } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Menu from '@mui/material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { CheckboxCustomTypography } from 'styling/generalStyling';
import { GroupEvents } from 'types';
import NewUpcomingCard from '../NewUpcomingCard';
interface Props {
  groupEventsCompleted: any[];
  timeZoneString?: string;
  refetchEvents: any;
  upcomingGroupEvents: GroupEvents[];
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: isMobile ? 0 : 3 }}>{children}</Box>}
    </div>
  );
}

const Events = ({
  groupEventsCompleted,
  timeZoneString,
  refetchEvents,
  upcomingGroupEvents,
}: Props) => {
  const [checked, setChecked] = useState<number[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<any[]>([]);
  const [counter, setCounter] = useState(4);
  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setanchorElem(event.currentTarget);
  };
  const handleClose = () => {
    setanchorElem(null);
  };
  const { width: screenWidth = null } = useWindowSize();
  const isMobile = screenWidth && screenWidth < 950;
  const ReplaysTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
      backgroundColor: 'primary',
    },
  });
  const interestList = [];
  const interestIds = new Set();
  const [valueTab, setValueTab] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  for (const groupEvent of groupEventsCompleted) {
    const { id, name } = groupEvent.interest;

    if (!interestIds.has(id)) {
      interestIds.add(id);
      interestList.push({ id, name });
    }
  }


  const numberOfFilters = () => {
    if (checked.length === 0)
      return <div className="filters-dashboard">Filter by subject</div>;
    else if (checked.length === 1)
      return <div className="filters-dashboard">1 filter</div>;
    else
      return <div className="filters-dashboard">{checked.length} filters</div>;
  };

  useEffect(() => {
    if (checked.length === 0) {
      // No filter selected, display all events
      setFilteredEvents(groupEventsCompleted);
    } else {
      // Filter events based on selected interests
      const filtered = groupEventsCompleted.filter((event) =>
        checked.includes(event.interest.id)
      );
      setFilteredEvents(filtered);
    }
  }, [checked, groupEventsCompleted]);

  useEffect(() => {
    setTimeout(() => {}, 3000);
  }, []);

  const [anchorElem, setanchorElem] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorElem);
  const numberOfReplays = groupEventsCompleted.filter(
    (event) => event.recording_url !== null
  ).length;

  return (
    <div className="dashboard-bookings-container">
      <div className="dashboard-title-component">
        Events
        <div>
          {valueTab === 1 ? (
            <IconButton
              sx={{
                color: '#333',
                padding: 0,
                fontFamily: 'Gilroy',
                fontSize: '14px',
              }}
              aria-controls={open ? 'basic-menu-2' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <FilterAltOutlinedIcon sx={{ color: '#333' }} />
              {numberOfFilters()}
            </IconButton>
          ) : null}
        </div>
      </div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <ReplaysTabs
          value={valueTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            value={0}
            label={
              !isMobile ? (
                <CustomRequestsLabel>Upcoming</CustomRequestsLabel>
              ) : (
                <CustomMobileRequestsLabel>Upcoming</CustomMobileRequestsLabel>
              )
            }
          />
          <Tab
            value={1}
            label={
              !isMobile ? (
                <CustomRequestsLabel>Replays</CustomRequestsLabel>
              ) : (
                <CustomMobileRequestsLabel>Replays</CustomMobileRequestsLabel>
              )
            }
          />
        </ReplaysTabs>
      </Box>
      <TabPanel value={valueTab} index={0} key={0}>
        {upcomingGroupEvents.length > 0 ? (
          <div className="dashboard-cards">
            {upcomingGroupEvents.slice(0, counter).map((event, index) => (
              <NewUpcomingCard
                key={index}
                timeZone={timeZoneString}
                appointmentData={event}
                refetchEvents={refetchEvents}
                subject={'Masterclass'}
              />
            ))}
            {counter < upcomingGroupEvents.length ? (
              <LoadMoreButton
                disabled={counter >= upcomingGroupEvents.length}
                variant="contained"
                onClick={() => setCounter(counter + 4)}
              >
                Load more
              </LoadMoreButton>
            ) : null}
          </div>
        ) : (
          <MasterclassEmptyPage value={0} />
        )}
      </TabPanel>
      <TabPanel value={valueTab} index={1} key={1}>
        {numberOfReplays > 0 ? (
          <div>
            <Menu
              id="basic-menu-2"
              anchorEl={anchorElem}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div className="filter-menu-title">Filter by interest</div>
              <List sx={{ padding: 0 }}>
                {interestList.map((interest) => {
                  const labelId = `checkbox-list-label-${interest.id}`;

                  return (
                    <ListItem
                      sx={{ marginBottom: '-15px', padding: 0 }}
                      key={interest.id}
                      disablePadding
                    >
                      <ListItemButton
                        sx={{ paddingLeft: 0 }}
                        role={undefined}
                        onClick={handleToggle(interest.id)}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            color="primary"
                            checked={checked.indexOf(interest.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={
                            <CheckboxCustomTypography>
                              {interest.name}
                            </CheckboxCustomTypography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Menu>

            <div className="tab-panel-bookings-container">
              {filteredEvents.map((event) =>
                event.recording_url ? (
                  <NewRecordingCard
                    timeZone={timeZoneString}
                    appointmentData={event}
                    subject="Upcoming"
                    key={event.id} // Make sure to add a unique key prop
                  />
                ) : null
              )}
            </div>
          </div>
        ) : (
          <MasterclassEmptyPage value={1} />
        )}
      </TabPanel>
    </div>
  );
};

export default Events;
