import React, { memo } from 'react';
import { formatDate } from '../../../utils/time';
import './style.scss';
import { BoostCallEvent } from '../../../types';

import '../components/styling/card-styling.scss';

import { Box, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import useSelectProfileData from '../../../hooks/useSelectProfileData';
import NewAddToCalendarButton from 'atoms/NewAddToCalendarButton';

type Props = {
  event: any;
  setConfirmRescheduleModalOpen: any;
  isOpen: boolean;
};
const style = {
  maxHeight: '90vh',
  maxWidth: '90vw',
  bgcolor: 'background.paper',
};
const ConfirmRescheduleDialog = ({
  isOpen,
  setConfirmRescheduleModalOpen,
  event,
}: Props) => {
  const { reschedule_request_time, start_time } =
    (event as BoostCallEvent) || {};

  const rescheduleDateFormatted = formatDate(
    reschedule_request_time || start_time || '',
    event.timeZone
  );
  const rescheduledTime = {
    time: rescheduleDateFormatted?.time,
    date: rescheduleDateFormatted?.date,
  };

  const { userLocation } = useSelectProfileData();
  const timeZoneString =
    userLocation && userLocation.timezone ? userLocation.timezone : '';
  return (
    <Dialog open={isOpen} style={style}>
      <Box style={style}>
        <div className="close-dialog-icon">
          <IconButton
            color="primary"
            onClick={async () => {
              setConfirmRescheduleModalOpen(false);
              window.location.reload();
            }}
            sx={{ padding: '0px' }}
          >
            <CloseIcon sx={{ color: 'black' }} fontSize="medium" />
          </IconButton>
        </div>

        <div className="confirm-reschedule-dialog-title">
          Your session has been successfully rescheduled
        </div>

        <div className="confirm-dialog-info">
          <div className="reschedule-dialog-date">
            <div>Your session is now on:</div>
            <div className="reschedule-confirm-date-text">
              {rescheduledTime.date?.day}{' '}
              {rescheduledTime.date?.monthThreeLetterFormat}{' '}
              {rescheduledTime.date?.year} {rescheduledTime.time?.hour}{' '}
            </div>
          </div>
          <div>
            We have sent you a confirmation email and have updated your
            dashboard. Make sure to add the new time to your calendar.
          </div>
          <div className="confirm-reschedule-button">
            <div className="confirmation-avatar">
              <NewAddToCalendarButton
                callInfo={event}
                timeZoneString={timeZoneString}
              />
            </div>
          </div>
        </div>
      </Box>
    </Dialog>
  );
};

export default memo(ConfirmRescheduleDialog);
