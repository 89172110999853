import React from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import './style.scss';
import { formatDate } from '../../../utils/time';
import Paper from '@mui/material/Paper';
import { GroupEvent, BoostCallEventWithVolunteerProfile } from '../../../types';
import '../components/styling/card-styling.scss';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

type Props = {
  appointmentData: any;
  timeZone?: string;
  subject: string;
};

const NewRecordingCard = ({ timeZone, appointmentData }: Props) => {
  const {
    id: boostCallId,
    start_time,
    volunteer,
    title,
    type,
  } = (appointmentData as BoostCallEventWithVolunteerProfile) || {};

  const { code } = (appointmentData as GroupEvent) || {};

  const id = code || boostCallId;

  const dateFormatted = formatDate(start_time, timeZone);

  const time = {
    time: dateFormatted?.time,
    date: dateFormatted?.date,
  };
  function capitalizeFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  return (
    <div className="recording-card__container">
      <Paper
        key={id}
        sx={{
          borderRadius: '8px',
          boxShadow: '8px 5px 30px 0 rgba(0, 0, 0, 0.06)',
        }}
      >
        <div>
          <div className="recording-dashboard-card__container">
            <div className="dashboard-card-header-info">
              <div className="recording-paper-topic">{title}</div>
              <div className="recording-paper-topic">
                {capitalizeFirstLetter(type)} on {appointmentData.interest.name}
              </div>
              <div className="recording-card-title">
                Event led by: {volunteer.first_name}
              </div>
              <div className="recording-paper-time">
                <div className="dashboard-paper-time-day">
                  <CalendarTodayOutlinedIcon
                    sx={{ paddingBottom: '3px' }}
                    fontSize="small"
                  />{' '}
                  {time.date?.day} {time?.date?.monthThreeLetterFormat}{' '}
                  {time?.date?.year}
                </div>
                <div className="dashboard-paper-time-hour">
                  <ScheduleIcon
                    fontSize="small"
                    sx={{ paddingBottom: '3px' }}
                  />{' '}
                  {time.time?.hour}
                </div>
              </div>
              <video id="booking-recordings" controls controlsList="nodownload">
                <source src={appointmentData.recording_url} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default NewRecordingCard;
