import { useState, useEffect } from 'react';

type WindowSize = {
  width: number;
  height: number;
};

const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  // The current width of the viewport
  // The width below which the mobile view should be rendered
  const mobileBreakpoint = 750;
  const tabletBreakpoint = 900;
  const smallScreenBreakpoint = 1430;
  const mediumScreenBreakpoint = 1820;

  const isLargeScreen = windowSize.width > mediumScreenBreakpoint;
  const isMediumScreen =
    windowSize.width < mediumScreenBreakpoint &&
    windowSize.width > smallScreenBreakpoint;
  const isTablet =
    windowSize.width < tabletBreakpoint && windowSize.width > mobileBreakpoint;
  const isMobile = windowSize.width < mobileBreakpoint;

  return { ...windowSize, isLargeScreen, isMediumScreen, isMobile, isTablet };
};

export default useWindowSize;
