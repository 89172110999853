import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { PrivacyPreferences } from '../types';
import {
  CustomFormControlLabel,
  CustomTypography,
} from 'styling/generalStyling';

type Props = {
  setValue: (value: any, input: string) => void;
  value: PrivacyPreferences;
};

export default function EditEmailSettingsForm({ value, setValue }: Props) {
  const [values, setValues] = useState(value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };
  return (
    <FormControl component="fieldset" variant="standard">
      <FormGroup>
        <CustomFormControlLabel
          control={
            <Checkbox
              checked={values.learning_nudges}
              onChange={(event) => {
                handleChange(event);
                setValue(!values.learning_nudges, 'learning_nudges');
              }}
              name="learning_nudges"
              color="primary"
            />
          }
          label={
            <CustomTypography>
              Learning Content Recommendations
            </CustomTypography>
          }
        />
        <CustomFormControlLabel
          control={
            <Checkbox
              checked={values.newsletter}
              onChange={(event) => {
                handleChange(event);
                setValue(!values.newsletter, 'newsletter');
              }}
              name="newsletter"
              color="primary"
            />
          }
          label={<CustomTypography>Newsletter</CustomTypography>}
        />
        <CustomFormControlLabel
          control={
            <Checkbox
              checked={values.volunteer_nudges}
              onChange={(event) => {
                handleChange(event);
                setValue(!values.volunteer_nudges, 'volunteer_nudges');
              }}
              name="volunteer_nudges"
              color="primary"
            />
          }
          label={<CustomTypography>Mentor Recommendations</CustomTypography>}
        />
        <CustomFormControlLabel
          control={
            <Checkbox
              checked={values.show_linkedin_profile}
              onChange={(event) => {
                handleChange(event);
                setValue(
                  !values.show_linkedin_profile,
                  'show_linkedin_profile'
                );
              }}
              name="show_linkedin_profile"
              color="primary"
            />
          }
          label={<CustomTypography>LinkedIn Profile</CustomTypography>}
        />
      </FormGroup>
    </FormControl>
  );
}
