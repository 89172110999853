import React from 'react';
import apiInstance from 'api/config';

import './style.scss';
import { CustomPaper } from 'styling/backgrounds';
import { DashboardEmptyButton } from 'styling/buttons';
import { IoIosClose } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

type Props = {
  mentor: any;
  refetchRecommendedData: any;
};

const handleHideRecommendation = async (
  recommendedId: number,
  refetchRecommendedData: any
) => {
  if (recommendedId && refetchRecommendedData) {
    await apiInstance.patch(
      `api/recommendation/recommendedvolunteers/${recommendedId}/`,
      {
        hidden_by_user: true,
      }
    );
    refetchRecommendedData();
  }
};

const RecMentorCard = ({ mentor, refetchRecommendedData }: Props) => {
  const navigate = useNavigate();

  const { volunteer, id } = mentor || {};

  const handleViewMentor = async () => {
    if (id) {
      await apiInstance.patch(
        `api/recommendation/recommendedvolunteers/${id}/`,
        {
          clicked_times: mentor.clicked_times + 1,
        }
      );
    }
    navigate(
      `/volunteer-details?volunteer[]=${volunteer?.id}&keyword=recommended-mentor`
    );
  };

  return (
    <CustomPaper sx={{ height: '240px', padding: '12px' }}>
      <div className="mentor-card__container">
        <div
          className="mentor-card__delete"
          onClick={() =>
            handleHideRecommendation(mentor.id, refetchRecommendedData)
          }
        >
          <IoIosClose size={35} />
        </div>

        <div className="mentor-card__image-wrapper">
          <div className="mentor-card__image">
            <img src={volunteer.profile_picture} alt="Mentor" />
          </div>
          <div className="mentor-card__name">{volunteer?.first_name}</div>
        </div>
        <div className="mentor-card__interests">
          {volunteer?.interests.slice(0, 2).join(', ')}
        </div>
        <DashboardEmptyButton
          sx={{
            borderRadius: '30px',
            paddingLeft: '20px',
            paddingRight: '20px',
            fontSize: '12px',
            height: '30px',
            width: '120px',
            color: '#333',
          }}
          onClick={() => handleViewMentor()}
          variant="outlined"
        >
          View mentor
        </DashboardEmptyButton>
      </div>
    </CustomPaper>
  );
};

export default RecMentorCard;
