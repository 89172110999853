import api from 'api';
import MentorModal from 'components/MentorCard/MentorModal';
import React, { useEffect, useState } from 'react';
import { Mentor } from '../../../types';

import './proposed-volunteer.scss';

type Props = {
  id: number | undefined;
};

const ProposedVolunteer = ({ id }: Props) => {
  const [mentor, setMentor] = useState<Mentor>();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const { results } = await api.searchMentors([id], 1);
      if (results && results.length && results.length > 0)
        setMentor(results[0]);
    })();
  }, [id]);

  const openModal = () => {
    if (!modalOpen) setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="reschedule-volunteer-info" onClick={openModal}>
      <img src={mentor?.profile_picture} alt="Volunteer" />
      <div className="reschedule-volunteer-wrapper">
        <div className="reschedule-volunteer-name">
          {mentor?.first_name} {mentor?.last_name}
        </div>
        <div className="reschedule-volunteer-org-name">{mentor?.employer}</div>
      </div>
      {modalOpen && (
        <MentorModal
          mentor={mentor as Mentor}
          modalOpen={modalOpen}
          closeModal={closeModal}
          hideSelect
        />
      )}
    </div>
  );
};

export default ProposedVolunteer;
