import { login, signup, resendVerification, verifyAccountAction } from './auth';
import {
  cancelRequest,
  cancelBoostCallEvent,
  getBoostCallEvents,
  proposeRescheduleRequest,
  acceptRescheduleRequest,
  declineRescheduleRequest,
} from './boostCallEvents';
import {
  submitRequest,
  getAllFollowUpRequests,
  acceptFollowUpRequest,
  declineFollowUpRequest,
  acceptProposedTime,
  declineProposedTime,
} from './callRequests';
import {
  getDraftBoostCall,
  submitDraftBoostCall,
} from './draftBoostCallRequests';
import {
  registerForGroupEvent,
  registerQuestionGroupEvent,
  updateQuestionGroupEvent,
  declineGroupEvent,
  getGroupEvent,
  updateAttendeeJoinCallClicked,
} from './groupEvents';
import { getCountryList, updateTimeZone } from './location';
import {
  getMessages,
  markMessagesRead,
  sendMessage,
  blockMessages,
} from './messages';
import { getEthnicityList } from './preferences';
import { searchMentors, searchUniqueMentor } from './search';
import { getTaxonomy } from './taxonomy';
import {
  getUserLocation,
  getOrgProfile,
  getUserProfile,
  fetchAllProfileData,
  getPartnerIntegratedData,
} from './user';
import { getResources, resourceClick } from './resources';
// Centralised API object from which all axios API calls are accessed
const api = {
  login,
  signup,
  searchUniqueMentor,
  resendVerification,
  verifyAccountAction,
  cancelRequest,
  cancelBoostCallEvent,
  getBoostCallEvents,
  proposeRescheduleRequest,
  acceptRescheduleRequest,
  declineRescheduleRequest,
  submitRequest,
  getAllFollowUpRequests,
  acceptFollowUpRequest,
  declineFollowUpRequest,
  acceptProposedTime,
  declineProposedTime,
  getDraftBoostCall,
  submitDraftBoostCall,
  registerForGroupEvent,
  registerQuestionGroupEvent,
  updateQuestionGroupEvent,
  declineGroupEvent,
  getGroupEvent,
  updateAttendeeJoinCallClicked,
  getCountryList,
  updateTimeZone,
  getMessages,
  markMessagesRead,
  sendMessage,
  blockMessages,
  getEthnicityList,
  searchMentors,
  getTaxonomy,
  getUserLocation,
  getOrgProfile,
  getUserProfile,
  fetchAllProfileData,
  getResources,
  resourceClick,
  getPartnerIntegratedData,
};

export default api;
