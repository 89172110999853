import { createSlice } from '@reduxjs/toolkit';
import theme from '../../../styling/theme';

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    value: theme,
  },
  reducers: {
    updateTheme: (state, action) => {
      return { ...state, value: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateTheme } = themeSlice.actions;

export default themeSlice.reducer;
