import React from 'react';
import { useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';
import { getUrlParam } from '../../utils/url';
import Button from '../../components/Button';
import './error.scss';

type ErrorTypes = {
  [code: number]: string;
};

type ButtonConfigs = {
  [buttonCode: string]: {
    text: string;
    path: string;
  };
};

const errorTypes: ErrorTypes = {
  1: "We're sorry, there has been an unexpected error.",
};

const buttonConfigs: ButtonConfigs = {
  db: {
    text: 'Go To Dashboard',
    path: '/dashboard/actions',
  },
  ms: {
    text: 'Search For A Mentor',
    path: '/mentor-select',
  },
};

const ErrorPage = () => {
  const navigate = useNavigate();
  const loggedIn = !!Cookie.get('accessOrgTokenDB');
  const errorCode = getUrlParam('error_code') as string;
  const buttons = (getUrlParam('b') as string[]) || ['db'];
  const errorMessage = errorTypes[Number(errorCode)] || errorTypes[1];

  return (
    <div className="error-page__container">
      <div className="error-page__inner-container">
        <h1>{errorMessage}</h1>
        <div className="error-page__button-container">
          {buttons.map((buttonParam, index) => (
            <Button
              type={index === 0 ? 'tertiary' : 'secondary'}
              text={buttonConfigs[buttonParam].text}
              onClickFunction={() =>
                !loggedIn
                  ? navigate(
                      `/login?redirect=${buttonConfigs[buttonParam].path}`
                    )
                  : navigate(buttonConfigs[buttonParam].path)
              }
              className="error-page__button"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
