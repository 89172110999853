import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Profile from './Components/Profile';
import Security from './Components/Security';
import Privacy from './Components/Privacy';
import { VolunteerProfile } from './types';

type Props = {
  tab: string;
  data: VolunteerProfile;
};
const AccountInfo = ({ tab, data }: Props) => {
  const [updatedProfile] = useState(data);
  if (tab === 'profile' && updatedProfile)
    return <Profile data={updatedProfile} />;
  else if (tab === 'security') return <Security data={updatedProfile} />;
  else if (tab === 'privacy') return <Privacy data={updatedProfile} />;
  else return null;
};

export default AccountInfo;
