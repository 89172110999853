import React from 'react';
import './styling/requests.scss';

const RecommendationEmptyPage = () => {
  return (
    <div className="empty-page-container">
      <img
        width="100px"
        className="not-found-image"
        src={'/group-conversation.png'}
        alt={'not-found'}
      />
      <div className="not-found-text">
        {' '}
        There are currently no recommended events
      </div>
      <div className="not-found-text"> Please come back later</div>
    </div>
  );
};

export default RecommendationEmptyPage;
