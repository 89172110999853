import React from 'react';
import './styling/impact.scss';

const ImpactEmptyPage = ({ tab }: { tab?: string }) => {
  let display = 'My Progress will be shown here. ';
  let display2 = '';

  if (tab === 'feedback') {
    display = 'Your personal feedback will be shown here. ';
    display2 = 'You can ask mentors to give you personal feedback.';
  }

  return (
    <div className="impact-empty-page-container">
      <img
        className="not-found-image"
        src={
          tab === 'activity'
            ? '/feedback-not-found.png'
            : '/feedback-not-found-2.png'
        }
        alt={'not-found'}
      />
      <div className="not-found-text">{display}</div>
      <div className="not-found-small-text">{display2}</div>
    </div>
  );
};

export default ImpactEmptyPage;
