import React, { memo, useEffect, useMemo, useState } from 'react';
import './style.scss';
import { formatDate } from 'utils/time';
import { TimeSlot } from 'types';
import '../Dashboard/RescheduleDetails/style.scss';
import { getUrlParam } from 'utils/url';
import { acceptProposedTime, declineProposedTime } from 'api/callRequests';
import ProposedVolunteer from 'components/ProposedVolunteer';
import { TailSpin } from 'react-loader-spinner';
import { DashboardEmptyButton, DashboardFilledButton } from 'styling/buttons';
import { CustomCallPagePaper } from 'styling/backgrounds';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from 'features/reduxStore/sessionTitle';

type Props = {
  event: any;
  setDisplayUpcomingEventDetails?: any;
};

const NewCallDetails = ({ event }: Props) => {
  const [loading, isLoading] = useState(false);
  const [selectedProposedTime, setSelectedProposedTime] =
    useState<any>(undefined);
  const slotId = parseInt(getUrlParam('slotId') as string);
  const {
    interest_expertise,
    top_priority_name,
    help_needed,
    status,
    time_slots,
    accept_substitutes,
  } = event;

  useEffect(() => {
    if (slotId) {
      const selectedTime = time_slots?.filter(
        (slot: TimeSlot) => slot.id === slotId
      )?.[0];
      setSelectedProposedTime(selectedTime);
    } else {
      const selectedTime = time_slots?.filter(
        (slot: TimeSlot) => slot.status === 'time_slot_proposed'
      )?.[0];
      setSelectedProposedTime(selectedTime);
    }
  }, [time_slots, slotId]);

  const proposedTimes = useMemo(() => {
    const proposedTime = time_slots?.filter(
      (item: TimeSlot) =>
        item.status !== 'time_slot_pending' &&
        item.status !== 'time_slot_cancelled' &&
        item.status !== 'time_slot_scheduled' &&
        item.status !== 'time_slot_expired'
    );

    return proposedTime;
  }, [time_slots]);

  const getExpertise = (expertise: string) => {
    switch (expertise) {
      case 'nothing_at_all':
        return 'Nothing at all';
      case 'i_know_some':
        return 'I know some';
      case 'i_know_a_lot':
        return 'I know a lot, just need help with some specifics';
      default:
        return 'Nothing at all';
    }
  };
  const navigate = useNavigate();
  const acceptAndRefresh = async () => {
    if (!selectedProposedTime?.code) return;
    try {
      isLoading(true);
      const acceptedCall = await acceptProposedTime(
        selectedProposedTime?.code,
        selectedProposedTime?.id,
        selectedProposedTime?.call_url
      );
      if (acceptedCall?.status === 200) {
        navigate(`/call-confirmation?id=${acceptedCall?.data?.id}`);
      } else {
        isLoading(false);
      }
    } catch (err) {
      console.log(err);
      isLoading(false);
    }
  };
  const declineConfirm = async () => {
    if (!selectedProposedTime?.start_time) return;
    try {
      isLoading(true);
      await declineProposedTime({
        code: selectedProposedTime?.code,
      });
      setTimeout(() => {
        isLoading(false);
        window.location.reload();
      }, 3000);
    } catch (err) {
      console.log(err);
      isLoading(false);
    }
  };
  const sessionTitle = useSelector(selectSessionTitle);
  return (
    <div className="reschedule-details-container">
      <div className="reschedule-details-title">
        Requested {sessionTitle} on {top_priority_name}
      </div>
      <CustomCallPagePaper>
        {proposedTimes?.length > 0 &&
        (status === 'pending' || accept_substitutes) ? (
          <div>
            <div className="propose-time-box__normal-text">{`New proposed time${
              proposedTimes.length > 1 ? 's' : ''
            }:`}</div>
            <div className="reschedule-box__proposed-times">
              {proposedTimes?.map((time: TimeSlot) => {
                const rescheduleDateFormatted = formatDate(
                  time?.start_time as string
                );
                const rescheduledTime = {
                  time: rescheduleDateFormatted?.time,
                  date: rescheduleDateFormatted?.date,
                };

                return (
                  <div
                    className={
                      selectedProposedTime?.id === time?.id
                        ? 'reschedule-box__box-info'
                        : 'reschedule-box__box-info'
                    }
                    onClick={() => setSelectedProposedTime(time)}
                  >
                    <div>
                      <div className="reschedule-box__normal-text">
                        {rescheduledTime.date?.day}{' '}
                        {rescheduledTime.date?.monthThreeLetterFormat}{' '}
                        {rescheduledTime.date?.year}
                      </div>
                      <div className="reschedule-box__normal-text">
                        <span>{rescheduledTime.time?.day}</span>
                      </div>
                    </div>
                    <div className="reschedule-box__normal-text">
                      {rescheduledTime.time?.hour}{' '}
                    </div>
                    <ProposedVolunteer id={time?.volunteer} />
                  </div>
                );
              })}
            </div>

            {selectedProposedTime && (
              <div className="reschedule-buttons-container">
                <DashboardFilledButton
                  sx={{ width: '220px' }}
                  variant="contained"
                  onClick={acceptAndRefresh}
                >
                  {loading && (
                    <TailSpin color="#a6a6a6" height={20} width={20} />
                  )}
                  Accept new time
                </DashboardFilledButton>
                <DashboardEmptyButton variant="text" onClick={declineConfirm}>
                  {loading && (
                    <TailSpin color="#a6a6a6" height={20} width={20} />
                  )}
                  Decline
                </DashboardEmptyButton>
              </div>
            )}
          </div>
        ) : status === 'pending' ? (
          <div className="reschedule-box">
            <div className="reschedule-box__proposed-times">
              {time_slots
                ?.filter(
                  (item: TimeSlot) => item.status === 'time_slot_pending'
                )
                .map((time: TimeSlot) => {
                  const dateFormatted = formatDate(time?.start_time as string);
                  const requestedTime = {
                    time: dateFormatted?.time,
                    date: dateFormatted?.date,
                  };
                  return (
                    <div className="reschedule-box__box-info">
                      <div>
                        <div className="reschedule-box__normal-text">
                          {requestedTime.date?.day}{' '}
                          {requestedTime.date?.monthThreeLetterFormat}{' '}
                          {requestedTime.date?.year}
                        </div>
                        <div className="reschedule-box__normal-text">
                          <span>{requestedTime.time?.day}</span>
                        </div>
                      </div>
                      <div className="reschedule-box__normal-text">
                        {requestedTime.time?.hour}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="reschedule-waiting-text">
              Waiting for a volunteer to accept this request...
            </div>
          </div>
        ) : null}
      </CustomCallPagePaper>
      <div className="reschedule-info-box">
        <div className="reschedule-info-label">You requested help with</div>
        <div className="reschedule-info-text">{top_priority_name}</div>

        <div className="reschedule-info-label">Your request</div>
        <div className="reschedule-info-text">{help_needed}</div>

        <div className="reschedule-info-label">Current experience</div>
        <div className="reschedule-info-text">
          {getExpertise(interest_expertise)}
        </div>
      </div>
    </div>
  );
};

export default memo(NewCallDetails);
