import React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import './filePopupStyle.scss';

interface FileUploadPopupProps {
  open: boolean;
  onClose: () => void;
}

const FileUploadPopup: React.FC<FileUploadPopupProps> = ({ open, onClose }) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        borderRadius: '15px',
        border: '1px solid #A3A3A3',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.15)',
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ marginTop: '20px', width: '595px', height: '221px' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '20px',
          }}
        >
          <img
            width="75px"
            height="75px"
            src={'/loading-icon.svg'}
            alt="loading-icon"
          />
          <div className="popup-text">
            Sorry, but your file cannot be uploaded.
            <br />
            Your storage has 1MB of free space. Choose another file or free up
            your storage space{' '}
            <a
              href="..library"
              style={{
                color: '#FF5F5F',
                fontFamily: 'Gilroy',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '120%',
                letterSpacing: '0.2px',
              }}
            >
              here
            </a>
            .
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FileUploadPopup;
