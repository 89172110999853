import useWindowSize from 'hooks/useWindowSize';
import React from 'react';
import useSelectProfileData from '../../hooks/useSelectProfileData';

import './new-nav.scss';

interface Props {
  footer?: boolean;
}

const Logo = ({ footer }: Props) => {
  const { width = window.innerWidth } = useWindowSize();
  const isMobile = width && width < 750;
  const { organisationProfile, partner } = useSelectProfileData();
  if (
    (organisationProfile?.partnerInfo &&
      organisationProfile?.partnerInfo?.white_label) ||
    partner?.white_label
  ) {
    let logoSrc = organisationProfile?.partnerInfo?.partner_logo_url
      ? organisationProfile.partnerInfo?.partner_logo_url
      : !footer
      ? '/main-logo.png'
      : '/logo-footer.png';

    if (partner?.partner_logo_url) {
      logoSrc = partner.partner_logo_url;
    }

    return (
      <a href="./">
        <img className="nav__logo" src={logoSrc} alt="digital boost" />
      </a>
    );
  } else {
    return (
      <a href="/dashboard/actions">
        {footer && (
          <img className="nav__logo" src="/main-logo.png" alt="digital boost" />
        )}
        {!footer && (
          <img
            className="nav__logo"
            src="/main-logo.png"
            style={{ marginLeft: !isMobile ? '30px' : undefined }}
            alt="digital boost"
          />
        )}
      </a>
    );
  }
};

export default Logo;
