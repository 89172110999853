import { CardsType } from '../../types';
import apiInstance from './config';

export const createActionPlan = async (name: string) => {
  try {
    const response = await apiInstance.post(
      'api/actionplan/actionplan/create/',
      {
        name: name,
      }
    );

    const data = response.data;

    return {
      plan: data,
    };
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const updateActionPlan = async (
  planName: string,
  planId: number,
  status: string
) => {
  try {
    const response = await apiInstance.patch(
      `api/actionplan/actionplan/${planId}/update/`,
      {
        name: planName,
        status: status,
      }
    );
    return {
      success: true,
      plan: response.data,
    };
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const deleteActionPlan = async (id: string) => {
  try {
    await apiInstance.delete(`api/actionplan/actionplan/${id}/delete`);
    return {
      success: true,
      message: 'Action plan deleted successfully',
    };
  } catch (error) {
    return {
      success: false,
      error: error,
    };
  }
};

export const getActionPlans = async () => {
  try {
    const response = await apiInstance.get(`api/actionplan/actionplan/search`);
    const data = response.data;

    return {
      success: true,
      plans: data?.results,
    };
  } catch (error) {
    return {
      success: false,
      error: error,
    };
  }
};
export const getActionPlansPagination = async (
  limit = 10,
  nextUrl?: string
) => {
  const urlToFetch = nextUrl
    ? new URL(nextUrl).pathname + new URL(nextUrl).search
    : 'api/actionplan/actionplan/search/?limit=10&offset=0';
  try {
    const response = await apiInstance.get(urlToFetch);
    const data = response.data;

    return {
      success: true,
      plans: data?.results,
      pagination: {
        limit,
        nextUrl: data.next,
        totalRecords: data?.count,
      },
    };
  } catch (error) {
    return {
      success: false,
      error: 'An error occurred while fetching action plans.',
    };
  }
};
export const getActionPlan = async (id: string | undefined) => {
  if (id)
    try {
      const response = await apiInstance.get(
        `api/actionplan/actionplan/search/?action_plan_id=${id}`
      );
      const data = response.data;

      return {
        success: true,
        plan: data?.results,
      };
    } catch (error) {
      return {
        success: false,
        error: error,
      };
    }
  else
    return {
      success: false,
    };
};
export const getCardDetails = async (pid: string, cid: string | undefined) => {
  if (cid)
    try {
      const response = await apiInstance.get(
        `api/actionplan/task/search/?action_plan_id=${pid}&&action_task_id=${cid}`
      );
      const data = response.data;

      return {
        success: true,
        card: data?.results,
      };
    } catch (error) {
      return {
        success: false,
        error: error,
      };
    }
  else
    return {
      success: false,
    };
};
export const getActionTasks = async (id: string | undefined) => {
  if (id)
    try {
      const response = await apiInstance.get(
        `api/actionplan/task/search/?action_plan_id=${id}`
      );
      const data = response.data;

      return {
        success: true,
        tasks: data?.results,
      };
    } catch (error) {
      return {
        success: false,
        error: error,
      };
    }
  else
    return {
      success: false,
    };
};

export const getActionTasksPagination = async (
  id: string | undefined,
  limit = 10,
  nextUrl?: string
) => {
  if (id) {
    const urlToFetch = nextUrl
      ? new URL(nextUrl).pathname + new URL(nextUrl).search
      : `api/actionplan/task/search/?action_plan_id=${id}&limit=${limit}&offset=0`;
    try {
      const response = await apiInstance.get(urlToFetch);
      const data = response.data;

      return {
        success: true,
        tasks: data?.results,
        pagination: {
          limit,
          nextUrl: data.next,
          totalRecords: data?.count,
        },
      };
    } catch (error) {
      return {
        success: false,
        error: 'An error occurred while fetching action tasks.',
      };
    }
  } else {
    return {
      success: false,
      error: 'No ID provided.',
    };
  }
};

interface ActionTaskData {
  title: string;
  action_plan: number;
  status: string;
  description?: string | undefined;
  interest?: number | undefined;
  deadline?: Date | undefined;
}
export const createActionTask = async (
  name: string,
  actionPlanId: number,
  deadline: Date | undefined,
  description: string | undefined,
  interest: number | undefined,
  status: string
) => {
  let objectData: ActionTaskData = {
    title: name,
    action_plan: actionPlanId,
    status,
  };
  if (interest) {
    objectData.interest = interest;
  }

  if (description) {
    objectData.description = description;
  }

  if (deadline) {
    objectData.deadline = deadline;
  }
  try {
    const response = await apiInstance.post(
      'api/actionplan/task/create/',
      objectData
    );
    return {
      success: true,
      task: response.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error,
    };
  }
};

export const updateActionTask = async (
  card: CardsType,
  status: string,
  interest?: number
) => {
  const objectData = card.deadline
    ? {
        title: card.title,
        deadline: card.deadline,
        description: card.description ? card.description : undefined,
        interest: interest ? interest : card.interest?.id,
        status: status,
      }
    : {
        title: card.title,
        description: card.description ? card.description : undefined,
        interest: interest ? interest : card.interest?.id,
        status: status,
      };
  try {
    const response = await apiInstance.patch(
      `api/actionplan/task/${card.id}/update/`,
      objectData
    );
    return {
      success: true,
      task: response.data,
    };
  } catch (error) {
    return {
      error: error,
    };
  }
};

export const deleteActionTask = async (id: string) => {
  try {
    await apiInstance.delete(`api/actionplan/task/${id}/delete`);
    return {
      success: true,
      message: 'Action task deleted successfully',
    };
  } catch (error) {
    return {
      success: false,
      error: error,
    };
  }
};

export const shareActionPlan = async (
  actionPlanId: number,
  userId: number,
  permission: string
) => {
  try {
    const response = await apiInstance.post(
      'api/actionplan/permissionactionplan/create/',
      {
        action_plan: actionPlanId,
        permission_type: permission,
        user_id: userId,
      }
    );
    return {
      success: true,
      permission: response.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error,
    };
  }
};
export const getActionPlanPermission = async (actionPlanId: number) => {
  try {
    const response = await apiInstance.get(
      `api/actionplan/permissionactionplan/search/?action_plan_id=${actionPlanId}`
    );
    return {
      success: true,
      permissions: response.data.results,
      count: response.data.count,
    };
  } catch (error) {
    return {
      success: false,
      error: error,
    };
  }
};

export const deletePermission = async (userPermissionId: number) => {
  try {
    await apiInstance.delete(
      `api/actionplan/permissionactionplan/${userPermissionId}/delete/`
    );
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error: error,
    };
  }
};

export const updatePermission = async (
  userPermissionId: number,
  permission: string
) => {
  try {
    await apiInstance.patch(
      `api/actionplan/permissionactionplan/${userPermissionId}/update/`,
      {
        permission_type: permission,
      }
    );
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error: error,
    };
  }
};
