import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Cookie from 'js-cookie';
import qs from 'qs';
import {
  getAccessTokenCookie,
  getRefreshTokenCookie,
  redirectProtecedRoutesToLogin,
  redirectToFinishSignup,
  loginWithOTLC,
  loginWithRefreshToken,
} from '../utils/auth';
import { getUrlParam } from '../utils/url';

const apiUrl = process.env.REACT_APP_API_URL;

// our own reusable axios instance
const apiInstance = axios.create({
  baseURL: apiUrl,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

export const apiInstanceUnprotected = axios.create({
  baseURL: apiUrl,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

// axios-hooks useAxios hook created using our own configured axios instance: 'api'
// used in apiHooks
export const useAxios = makeUseAxios({
  axios: apiInstance,
});

// Use interceptor to always inject the token to requests
apiInstance.interceptors.request.use((request) => {
  if (!!getAccessTokenCookie() && request.headers) {
    request.headers['Authorization'] = `Bearer ${getAccessTokenCookie()}`;
  }
  return request;
});

// Logic used by axios-auth-refresh to handle 401 api responses
// failedRequest variable is the original request that gave the 401
const refreshAuthLogic = async (failedRequest: any) => {
  if (failedRequest?.response?.status === 404) {
    // handle 404 on organisation profile -> force to finish signup
    if (
      failedRequest?.response?.config?.url.includes(
        '/organisation/organisations/me/'
      )
    ) {
      return redirectToFinishSignup();
    }
    // don't handle any other 404s
    return;
  }

  const refreshToken = getRefreshTokenCookie();
  const otlc = getUrlParam('otlc') as string;
  try {
    if (!refreshToken) {
      // if otlc present - use otlc to log in
      if (!!otlc) {
        const response = await loginWithOTLC(otlc);

        if (response.status === 401) {
          return Promise.reject(redirectProtecedRoutesToLogin());
        }
        return Promise.resolve();
      }
      return Promise.reject(redirectProtecedRoutesToLogin());
    }
    // Note: Using a different instance of axios from our api
    // This is so exception thrown by axios when the refreshToken itself is stale or missing won't get caught in the interceptor created by the axios-auth-refresh module
    const newAccessToken = (await loginWithRefreshToken()) as string;

    // set cookie that expires in a day
    Cookie.set('accessOrgTokenDB', newAccessToken, {
      expires: 1,
    });
    failedRequest.response.config.headers['Authorization'] =
      'Bearer ' + newAccessToken;

    return Promise.resolve();
  } catch (err) {
    Cookie.remove('accessOrgTokenDB');
    Cookie.remove('refreshOrgTokenDB');
    return Promise.reject(redirectProtecedRoutesToLogin());
  }
};

createAuthRefreshInterceptor(apiInstance, refreshAuthLogic, {
  statusCodes: [401, 404],
});

export default apiInstance;
