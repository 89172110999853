import React, { useState } from 'react';
import moment from 'moment';
import './style.scss';
import '../components/styling/card-styling.scss';
import { formatDate } from 'utils/time';
import useGetJoinCall from '../../../hooks/useGetJoinCall';
import MessagesModal from 'components/MessagesModal';
import { BoostCallEventWithVolunteerProfile } from 'types';
import RescheduleUpcomingCard from 'components/RescheduleUpcomingCard';
import { TailSpin } from 'react-loader-spinner';
import {
  acceptRescheduleRequest,
  declineRescheduleRequest,
} from 'api/boostCallEvents';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import MentorModal from 'components/MentorCard/MentorModal';
import api from 'api';
import { Mentor } from '../../../../types';
import { useNavigate } from 'react-router-dom';
import { DashboardEmptyButton, DashboardFilledButton } from 'styling/buttons';
import { useSystemTogglesContext } from 'context/SystemToggles';
import { useSelector } from 'react-redux';
import { selectSessionTitle } from 'features/reduxStore/sessionTitle';

interface Props {
  details?: any;
  timeZone?: string;
  refetchEvents?: any;
}

const UpcomingDetails = ({ details, timeZone, refetchEvents }: Props) => {
  const { toggles } = useSystemTogglesContext();
  const showMessagingUI = toggles['NEW_MESSAGING_UI'];

  const navigate = useNavigate();
  const [loading, isLoading] = useState(false);
  const [openMessagesModal, setOpenMessagesModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [mentor, setMentor] = useState<Mentor | undefined>(undefined);
  const { joinCall } = useGetJoinCall({ callInfo: details });

  const {
    id: boostCallId,
    interest_expertise,
    help_needed,
    volunteer,
    start_time,
    reschedule_status,
    reschedule_requested_by,
    reschedule_request_time,
    top_priority,
  } = details;
  const today = moment();
  const startTime = moment(start_time);

  const id = boostCallId;
  const profileData = {
    name: volunteer?.first_name,
    picture: volunteer?.profile_picture,
    companyName: volunteer?.employer,
    currentRole: volunteer?.current_role,
    showPresentedBy: false,
  };

  const dateFormatted = formatDate(start_time, timeZone);
  const time = {
    time: dateFormatted?.time,
    date: dateFormatted?.date,
  };

  const rescheduleDateFormatted = formatDate(reschedule_request_time, timeZone);
  const rescheduleTime = {
    time: rescheduleDateFormatted?.time,
    date: rescheduleDateFormatted?.date,
  };

  const getExpertise = (expertise: string) => {
    switch (expertise) {
      case 'nothing_at_all':
        return 'Nothing at all';
      case 'i_know_some':
        return 'I know some';
      case 'i_know_a_lot':
        return 'I know a lot, just need help with some specifics';
      default:
        return 'Nothing at all';
    }
  };

  const acceptAndRefresh = async () => {
    if (!reschedule_request_time) return;
    try {
      isLoading(true);
      await acceptRescheduleRequest({
        id: boostCallId,
        newStartDateTime: reschedule_request_time,
      });
      setTimeout(() => window.location.reload(), 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const declineConfirm = async () => {
    if (!reschedule_request_time) return;
    try {
      isLoading(true);
      await declineRescheduleRequest({
        id: boostCallId,
        newStartDateTime: reschedule_request_time,
        cancel: true,
        message: 'Cancel the session.',
      });
      setTimeout(() => window.location.reload(), 3000);
    } catch (err) {
      console.log(err);
    }
  };

  const openModal = async () => {
    const { results } = await api.searchMentors([volunteer?.id], 1);
    if (results && results.length && results.length > 0) {
      setMentor(results[0]);
    }
  };

  const closeModal = () => {
    setMentor(undefined);
  };
  const sessionTitle = useSelector(selectSessionTitle);
  return (
    <div className="upcoming-details-container">
      <div className="upcoming-title-wrapper">
        {reschedule_status === 'pending' &&
        reschedule_requested_by !== 'organisation' ? (
          <div className="upcoming-details-title">
            {volunteer?.first_name} {volunteer?.last_name} has proposed a new
            time
          </div>
        ) : reschedule_status === 'pending' &&
          reschedule_requested_by === 'organisation' ? (
          <div className="upcoming-details-title">
            You have sent a reschedule request
          </div>
        ) : (
          <div className="upcoming-details-title">
            {sessionTitle} with{' '}
            <span>
              {volunteer?.first_name} {volunteer?.last_name}
            </span>
          </div>
        )}
        <div className="upcoming-view-volunteer" onClick={openModal}>
          View volunteer profile
        </div>
      </div>

      {reschedule_status === 'pending' &&
      reschedule_requested_by !== 'organisation' ? (
        <div className="upcoming-box">
          <div className="upcoming-date-info">
            <div className="upcoming-proposed-time">New proposed time:</div>
            <div className="upcoming-time-slot">
              <div className="upcoming-time-slot-txt">
                {rescheduleTime?.date?.day} {rescheduleTime?.date?.month}{' '}
                {rescheduleTime?.date?.year}
              </div>
              <div className="upcoming-time-slot-txt">
                <span>{rescheduleTime?.time?.day}</span>
              </div>
              <div className="upcoming-time-slot-txt">
                {rescheduleTime?.time?.hour}{' '}
              </div>
            </div>

            <div className="upcoming-box-buttons-wrapper">
              <DashboardFilledButton
                sx={{ width: '220px' }}
                variant="contained"
                onClick={acceptAndRefresh}
              >
                {loading && <TailSpin color="#a6a6a6" height={20} width={20} />}
                Accept new time
              </DashboardFilledButton>
              <div
                className={
                  loading
                    ? 'upcoming-decline-btn upcoming-decline-btn__loading'
                    : 'upcoming-decline-btn'
                }
                onClick={declineConfirm}
              >
                {loading ? (
                  <TailSpin color="#a6a6a6" height={20} width={20} />
                ) : (
                  <img src="/decline-icon.svg" alt="Decline new time" />
                )}
                Decline
              </div>
            </div>
          </div>
        </div>
      ) : reschedule_status === 'pending' &&
        reschedule_requested_by === 'organisation' ? (
        <div className="upcoming-box">
          <div className="upcoming-date-info">
            <div className="upcoming-date-wrapper">
              <div>
                <div className="upcoming-proposed-time">New proposed time:</div>
                <div className="upcoming-time-slot">
                  <div className="upcoming-time-slot-txt">
                    {rescheduleTime?.date?.day} {rescheduleTime?.date?.month}{' '}
                    {rescheduleTime?.date?.year}
                  </div>
                  <div className="upcoming-time-slot-txt">
                    <span>{rescheduleTime?.time?.day}</span>
                  </div>
                  <div className="upcoming-time-slot-txt">
                    {rescheduleTime?.time?.hour}{' '}
                  </div>
                </div>
              </div>

              <div>
                <div className="upcoming-proposed-time">Original time:</div>
                <div className="upcoming-time-slot">
                  <div className="upcoming-time-slot-txt">
                    {time?.date?.day} {time?.date?.month} {time?.date?.year}
                  </div>
                  <div className="upcoming-time-slot-txt">
                    <span>{time?.time?.day}</span>
                  </div>
                  <div className="upcoming-time-slot-txt">
                    {time?.time?.hour}{' '}
                  </div>
                </div>
              </div>
            </div>

            <div className="upcoming-box-buttons-wrapper">
              <DashboardFilledButton
                variant="contained"
                onClick={() => joinCall()}
              >
                Join Session
              </DashboardFilledButton>
              <DashboardEmptyButton
                variant="text"
                onClick={() => {
                  if (showMessagingUI) {
                    navigate(`/messaging-centre?id=${details?.volunteer?.id}`);
                    return;
                  }
                  setOpenMessagesModal(true);
                }}
              >
                <EmailOutlinedIcon />
                Messages
              </DashboardEmptyButton>
              <div
                className={
                  loading
                    ? 'upcoming-decline-btn upcoming-decline-btn__loading'
                    : 'upcoming-decline-btn'
                }
                onClick={() => navigate(`/cancel-call?id=${id}`)}
              >
                {loading ? (
                  <TailSpin color="#a6a6a6" height={20} width={20} />
                ) : (
                  <img src="/decline-icon.svg" alt="Decline new time" />
                )}
                Cancel session
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="upcoming-box">
          <div className="upcoming-date-info">
            <div className="upcoming-date">
              {time?.date?.day} {time?.date?.month} {time?.date?.year}
            </div>
            <div className="upcoming-day">{time?.time?.day}</div>
            <div className="upcoming-time">{time?.time?.hour} </div>

            <div className="upcoming-box-buttons-wrapper">
              <DashboardFilledButton
                variant="contained"
                onClick={() => joinCall()}
              >
                Join Session
              </DashboardFilledButton>
              <DashboardEmptyButton
                variant="text"
                onClick={() => {
                  if (showMessagingUI) {
                    navigate(`/messaging-centre?id=${details?.volunteer?.id}`);
                    return;
                  }
                  setOpenMessagesModal(true);
                }}
              >
                <EmailOutlinedIcon sx={{ marginRight: '5px' }} />
                Messages
              </DashboardEmptyButton>
              {reschedule_status === 'rescheduled' ? (
                <DashboardEmptyButton
                  variant="text"
                  onClick={() => setRescheduleModal(true)}
                >
                  <HistoryOutlinedIcon sx={{ marginRight: '5px' }} />
                  Reschedule
                </DashboardEmptyButton>
              ) : null}
            </div>
          </div>

          <div className="starts-box">
            <div className="starts-small-text">Starts in</div>
            <div className="starts-big-text">
              {moment(startTime.diff(today)).format('D')}
            </div>{' '}
            <div className="starts-small-text">days</div>
          </div>
        </div>
      )}

      <div className="upcoming-info-box">
        <div className="upcoming-info-label">You requested help with</div>
        <div className="upcoming-info-text">{top_priority?.name}</div>

        <div className="upcoming-info-label">Your request</div>
        <div className="upcoming-info-text">{help_needed}</div>

        <div className="upcoming-info-label">Current experience</div>
        <div className="upcoming-info-text">
          {getExpertise(interest_expertise)}
        </div>

        <div className="upcoming-info-label">Volunteer details</div>
        <div className="upcoming-info-text">
          <div className="upcoming-vol-info">
            <img src={volunteer?.profile_picture} alt={volunteer?.first_name} />
            <div className="upcoming-vol-info-column">
              <div className="upcoming-vol-name">
                {volunteer?.first_name} {volunteer?.last_name}
              </div>
              <div className="upcoming-vol-role">{volunteer?.current_role}</div>
            </div>
          </div>
        </div>
      </div>

      {openMessagesModal ? (
        <MessagesModal
          boostCallEvent={details as BoostCallEventWithVolunteerProfile}
          onClose={() => setOpenMessagesModal(false)}
          openMessagesModal={openMessagesModal}
          onReadMessages={() => null}
        />
      ) : null}
      <RescheduleUpcomingCard
        profileData={profileData}
        timeZone={timeZone}
        id={id}
        time={time}
        setRescheduleModal={setRescheduleModal}
        rescheduleModal={rescheduleModal}
      />
      {mentor && (
        <MentorModal
          mentor={mentor}
          modalOpen={Boolean(mentor)}
          closeModal={closeModal}
          hideSelect
        />
      )}
    </div>
  );
};

export default UpcomingDetails;
