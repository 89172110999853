import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import VerifiedModal from '../../components/VerifiedModal';
import useVerifyAccount from '../../features/auth/hooks/useVerifyAccount';
import useSelectProfileData from '../../hooks/useSelectProfileData';
import styles from './verify.module.scss';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    zIndex: 30,
  },
  content: {
    maxWidth: '630px',
    maxHeight: '300px',
    margin: '15vh auto 0',
  },
};

const Verify = () => {
  const {
    hasVerifiedAccount,
    verifyAccountError,
    invalidToken,
    resendVerification,
    isLoadingResendVerification,
    hasResentVerification,
  } = useVerifyAccount();
  const {
    organisationProfile,
    userProfile,
    hasFinishedFetchingProfileAndLocation,
  } = useSelectProfileData();
  const { username } = userProfile || {};
  const hasProfile = !!organisationProfile;
  const navigate = useNavigate();

  const onButtonPress = useCallback(async () => {
    if (invalidToken && username) {
      try {
        await resendVerification();
      } catch (err) {}
    } else {
      return navigate(hasProfile ? '/dashboard/actions' : '/add-details');
    }
  }, [hasProfile, navigate, invalidToken, resendVerification, username]);

  return (
    <div className={styles.container}>
      {!hasFinishedFetchingProfileAndLocation ? (
        <div className={styles.container}>
          <img
            className={styles.loadingSpinner}
            src="/loading.gif"
            alt="loading"
          />
        </div>
      ) : hasVerifiedAccount && !!verifyAccountError ? (
        <VerifiedModal
          invalidToken={invalidToken}
          verificationResent={hasResentVerification}
          errorMessage={verifyAccountError}
          customStyles={customStyles}
          onButtonPress={onButtonPress}
          loading={isLoadingResendVerification}
          hasProfile={!!organisationProfile}
        />
      ) : (
        <VerifiedModal
          customStyles={customStyles}
          hasProfile={!!organisationProfile}
          onButtonPress={onButtonPress}
        />
      )}
    </div>
  );
};

const VerifyHOC = () => {
  const { hasFinishedFetchingProfileAndLocation } = useSelectProfileData({
    autoFetch: true,
  });

  return hasFinishedFetchingProfileAndLocation ? <Verify /> : null;
};

export default VerifyHOC;
